import { Input, Select } from 'antd'
import IconSearch from 'assets/icons/IconSearch';
import moment from 'moment'
import React from 'react'
import Constant from 'utils/Constants'
import { ButtonAdvancedFilter } from './Buttons';

export const FieldView = ({ value }) => {
    return (
        <span className='field-view'>{value || '-'}</span>
    )
}

export const FieldSwitchView = ({ value }) => {
    return (
        <span className='field-view'>{value ? "Yes" : "No" || '-'}</span>
    )
}

export const FieldDateView = ({ value }) => {
    return (
        <span className='field-view'>{value ? moment(value).format(Constant.datePickerFormat) : '-'}</span>
    )
}

export const FieldDateRangeView = ({ value1, value2 }) => {
    return (
        <>
            <span className='field-view'>{value1 ? moment(value1).format(Constant.datePickerFormat) : '-'}</span>
            {" - "}
            <span className='field-view'>{value2 ? moment(value2).format(Constant.datePickerFormat) : '-'}</span>
        </>
    )
}

//use this if you dont have a label from backend
export const FieldSelect = ({ value, options = [] }) => {
    return (
        <Select
            value={value}
            options={options}
            disabled
            size="large"
            className='field-view select'
        />
    )
}

export const CustomFieldSearch = (props) => {
    return (
        <Input  {...props}
            addonBefore={<IconSearch />}
            placeholder="Search"
            allowClear
            size='large'
            className='custom-field-search'
            onChange={(e) => props.onChange(e.target.value)} />
    )
}

export const CustomFieldSearchWithAdvanceFilter = ({
    value,
    onChange = () => { },
    withAdvanceFilter = true,
    disabledAdvanceFilter = false,
    onClickAdvanceFilter = () => { },
    isFilled = false,
}) => {
    return (
        <div className='custom-field-search-with-advance-filter'>
            <CustomFieldSearch
                value={value}
                onChange={onChange}
            />
            {withAdvanceFilter && <ButtonAdvancedFilter disabled={disabledAdvanceFilter} onClick={onClickAdvanceFilter} isFilled={isFilled} />}
        </div>
    )
}