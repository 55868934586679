import { Col } from "antd";
import GoodsItem from "./GoodsItem.jsx";
import ServicesItem from "./ServicesItem.jsx";
import Constant from "utils/Constants.js";
import { headerROGSAtom } from "store/ROGS/rogsAtom.js";
import { useAtom } from "jotai";
import dayjs from 'dayjs'
import { CustomFormItemDate } from "components/Common/CustomFormItem.jsx";

export default function SectionItemsRoGS(props) {
    const { initForm, isView = false } = props;
    const [header, setHeader] = useAtom(headerROGSAtom)

    return (
        <div className="rogs-section-items">
            <Col xs={24} md={6}>
                <CustomFormItemDate
                    nameFormItem="rescheduleDate"
                    label="Rescheduled Date"
                    value={header?.rescheduleDate}
                    isView={isView}
                    disabledDate={d => !d || d.isBefore(dayjs().format(Constant.datePickerFormat))}
                    onChange={(d, dString) => {
                        setHeader(prev => {
                            return {
                                ...prev,
                                rescheduleDate: dString ? dayjs(dString).format(Constant.stateDateFormat) : null
                            }
                        })
                    }}
                />
            </Col>
            {/* <Form.Item
                name="rescheduleDate"
                label="Rescheduled Date"
                style={styleFormItem}
                initialValue={dayjs(header?.rescheduleDate)}
            >
                <DatePicker
                    format={Constant.datePickerFormat}
                    onChange={(d, dString) => {
                        setHeader(prev => {
                            return {
                                ...prev,
                                rescheduleDate: dString ? moment(dString).format(Constant.stateDateFormat) : null
                            }
                        })
                    }}
                    disabledDate={d => !d || d.isBefore(moment().format(Constant.datePickerFormat))}
                    disabled={isView}
                />
            </Form.Item> */}

            <GoodsItem
                isView={isView}
                initForm={initForm}
            />
            <ServicesItem
                isView={isView}
                initForm={initForm}
            />
        </div>
    );
}
