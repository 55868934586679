import { Form, Space } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import SectionHeader from '../SectionHeader';
import SectionItems from '../SectionItems';
import CustomCollapse from 'components/Common/CustomCollapse';


export default function BaseDetailItemCatalog(props) {
    const {
        onClose,
        loading = false,
        initForm,
    } = props

    const handleCloseDrawer = () => {
        onClose()
    }

    const itemsCollapse = [
        {
            key: 1,
            label: "Item Catalog Header",
            forceRender: true,
            children: <SectionHeader initForm={initForm} isView={true} />
        },
        {
            key: 2,
            label: "Items",
            forceRender: true,
            className: "item-catalog-form-collapse-panel-items",
            children: <SectionItems initForm={initForm} isView={true} />
        },
    ]

    return (
        <BaseDrawer
            title={"Detail Item Catalog"}
            placement="right"
            size="large"
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
        >
            <Space direction="vertical" size="small" className='wrap-item-catalog-form space-vertical-flex' >
                <Form
                    name="form-item-catalog"
                    form={initForm}
                    layout='vertical'
                >
                    <CustomCollapse className="item-catalog-form-collapse" activeKey={['1', '2']} items={itemsCollapse} />
                </Form>
            </Space >
        </BaseDrawer>
    )
}
