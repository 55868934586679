import { Form, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import SectionHeaderPR from 'components/PurchaseRequest/SectionHeaderPR';
import SectionItems from 'components/PurchaseRequest/SectionItems';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import CustomAlert from 'utils/CustomAlert';
import { useAtom } from 'jotai';
import { remarksApproval, } from 'store/PurchaseRequest/purchaseRequestAtom';
import usePRRepo from 'services/repo/usePRRepo';
import { dismissToast, errorToast, loadingToastId, successToast } from 'utils/Toast';
import Constant from 'utils/Constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APP_ROUTE_PREFIX, RouteConstant } from 'routes/routesConstant';
import SectionWorkflowHistory from 'components/PurchaseRequest/SectionWorkflowHistory';
import DevLog from 'utils/DevLog';
import { ButtonApprove, ButtonBack, ButtonReject, ButtonRevise } from 'components/Common/Buttons';
import SectionRemarksApproval from 'components/Collapse.Item/SectionRemarksApproval';
import useFormPRHook from 'hooks/useFormPRHook';
import BaseSectionAttachment from 'components/PurchaseRequest/BaseSectionAttachment';
import useCheckWorkflowTaskHook from 'hooks/useCheckWorkflowTaskHook';
import CustomCollapse from 'components/Common/CustomCollapse';
import SkeletonPage from 'components/Common/SkeletonPage';

export default function ApprovalPR() {
    useTopbarTitleHooks("Approval Purchase Request");
    const { isLoading: isLoadingApprove, approvePR } = usePRRepo(false);
    const { isLoading: isLoadingReject, rejectPR } = usePRRepo(false);
    const { isLoading: isLoadingRevise, revisePR } = usePRRepo(false);
    const { isLoading: isLoadingGetPRById, getPRById } = usePRRepo(false);
    const { setDataFormPR, clearFormAndAtomPR } = useFormPRHook()
    const { isLoading: isLoadingCheckIsTaskExpired, checkIsTaskExpired } = useCheckWorkflowTaskHook()

    const [isRequiredNote, setIsRequiredNote] = useState(false)

    const [searchParams] = useSearchParams();
    const prId = searchParams.get('prId');
    const tId = searchParams.get('tId');

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState(["1", "2", "4", "5", "6"]);
    const [dataRemarks, setDataRemarks] = useAtom(remarksApproval);
    const [isTaskExpired, setIsTaskExpired] = useState(true);

    useEffect(() => {
        clearFormAndAtomPR(form, true, true)
        return () => {
            DevLog("cleanup state pr form");
            clearFormAndAtomPR(form, true)
        };
    }, []);

    function handleClick(key) {
        setActiveKey(key);
    }

    const checkErrorToOpenCollapse = (errors, nameFields) => {
        return errors.some(x => nameFields.some(f => x.name.indexOf(f) >= 0));
    };

    const fetchGetPRById = async (id) => {
        loadingToastId(Constant.message.loading, id)
        let response = await getPRById({ prId: id });
        dismissToast(id)
        if (response?.isValid && response?.statusCode === 200) {
            let data = response?.data;
            setDataFormPR(form, data)
        }
    };

    const doApprove = async () => {
        if (tId) {
            let payloadData = {
                taskId: tId,
                remarks: dataRemarks
            };
            loadingToastId(Constant.message.savingData, payloadData);
            let response = await approvePR(payloadData);
            dismissToast(payloadData);
            if (response?.isValid && response?.statusCode === 200) {
                successToast(Constant.message.saveDataSuccess);
                navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.pendingTask);
            }
        }
    }

    const doReject = async () => {
        if (tId) {
            let payloadData = {
                taskId: tId,
                remarks: dataRemarks
            };
            loadingToastId(Constant.message.savingData, payloadData);
            let response = await rejectPR(payloadData);
            dismissToast(payloadData);
            if (response?.isValid && response?.statusCode === 200) {
                successToast(Constant.message.saveDataSuccess);
                navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.pendingTask);
            }
        }
    }

    const doRevise = async () => {
        if (tId) {
            let payloadData = {
                taskId: tId,
                remarks: dataRemarks
            };
            loadingToastId(Constant.message.savingData, payloadData);
            let response = await revisePR(payloadData);
            dismissToast(payloadData);
            if (response?.isValid && response?.statusCode === 200) {
                successToast(Constant.message.saveDataSuccess);
                navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.pendingTask);
            }
        }
    }

    const validateRemarks = (type) => {
        form.validateFields()
            .then((values) => {
                if (type === "reject") {
                    return doReject()
                }
                if (type === "revise") {
                    return doRevise()
                }
            })
            .catch((errorInfo) => {
                errorToast(Constant.message.errorForm)
                if (checkErrorToOpenCollapse(errorInfo?.errorFields, ["remarksApproval"])) {
                    setActiveKey(prev => ([...new Set([...prev, '5'])]));
                }
            });
    }

    const handleApprove = (e) => {
        e.preventDefault()
        CustomAlert.confirm({
            title: "Approve Purchase Request!",
            description: `Are you sure you want to approve this data?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => doApprove()
        });
    };

    const handleReject = (e) => {
        e.preventDefault()
        CustomAlert.confirm({
            title: "Reject Purchase Request!",
            description: `Are you sure you want to reject this data?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => {
                setIsRequiredNote(true)
                validateRemarks("reject")
            },
            onCancelButtonClicked: () => setIsRequiredNote(false)
        });
    };

    const handleRevise = (e) => {
        e.preventDefault()
        CustomAlert.confirm({
            title: "Revise Purchase Request!",
            description: `Are you sure you want to revise this data?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => {
                setIsRequiredNote(true)
                validateRemarks("revise")
            },
            onCancelButtonClicked: () => setIsRequiredNote(false)
        });
    };

    useEffect(() => {
        if (prId && tId) {
            checkIsTaskExpired(tId, (status) => {
                setIsTaskExpired(status)
                fetchGetPRById(prId)
            })
        } else {
            navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.pendingTask)
        }
    }, [prId, tId]);

    return (
        <Space direction="vertical" size="small" className='wrap-pr-form space-vertical-flex'>
            {(isLoadingCheckIsTaskExpired || isLoadingGetPRById) ? <SkeletonPage /> :
                <Form
                    name="form-approval-pr"
                    form={form}
                    layout='vertical'
                >

                    <CustomCollapse
                        title="Approval Purchase Request"
                        className="pr-form-collapse"
                        onChange={handleClick}
                        activeKey={activeKey}
                        items={[
                            {
                                key: 1,
                                label: "Purchase Request Header",
                                forceRender: true,
                                children: <SectionHeaderPR initForm={form} isView={true} />
                            },
                            {
                                key: 2,
                                label: "Items",
                                forceRender: true,
                                className: "rogs-form-collapse-panel-items",
                                children: <SectionItems initForm={form} isView={true} />
                            },
                            {
                                key: 4,
                                label: "Attachments",
                                forceRender: true,
                                children: <BaseSectionAttachment initForm={form} isView={true} />
                            },
                            {
                                key: 5,
                                label: "Approval Remark",
                                forceRender: true,
                                children: <SectionRemarksApproval initForm={form} isView={isTaskExpired} isRequired={isRequiredNote} setDataRemarks={setDataRemarks} />
                            },
                            {
                                key: 6,
                                label: "Workflow History",
                                forceRender: true,
                                children: <SectionWorkflowHistory prId={prId} />
                            }
                        ]} />

                    <div style={{ display: "flex", gap: "10px", margin: "10px 0" }}>
                        <ButtonBack onClick={() => navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.pendingTask)} disabled={isLoadingGetPRById || isLoadingApprove || isLoadingRevise || isLoadingReject} />
                        <ButtonApprove
                            onClick={handleApprove}
                            loading={isLoadingApprove}
                            disabled={isTaskExpired || isLoadingGetPRById || isLoadingRevise || isLoadingReject}
                        />
                        <ButtonRevise
                            onClick={handleRevise}
                            loading={isLoadingRevise}
                            disabled={isTaskExpired || isLoadingGetPRById || isLoadingApprove || isLoadingReject}
                        />
                        <ButtonReject
                            disabled={isTaskExpired || isLoadingGetPRById || isLoadingApprove || isLoadingRevise}
                            loading={isLoadingReject}
                            onClick={handleReject}
                        />
                    </div>
                </Form>
            }
        </Space>
    );
}