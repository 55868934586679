import colors from 'assets/scss/colors/colors.scss'
const AntConfig = {
    token: {
        colorPrimary: colors.brandColor,
        colorInfo: colors.infoColor,
        colorError: colors.errorColor,
        colorTextPlaceholder: 'rgba(0, 0, 0, 0.3)',
    },
}
export default AntConfig;
