import { Card } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import usePRRepo from 'services/repo/usePRRepo';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { displayDate, sorterTable } from 'utils/Helper';
import CellTable from 'components/Common/CellTable';

export default function BaseDetailDetailPO(props) {
    const { onClose, loading = false, prId = null, prNo = null } = props
    const { isLoading: isLoadingDetailPO, detailPO } = usePRRepo(false)
    const [dataDetailPO, setDataDetailPO] = useState([])
    const [totalDataDetailPO, setTotalDataDetailPO] = useState(0)

    const [tableParamsDetailPO, setTableParamsDetailPO] = useState({
        pageNumber: 1,
        pageSize: 10,
        filter: {
            prId: null
        },
        orderBy: "poNo",
        isAscending: false
    });

    const handleCloseDrawer = () => {
        onClose()
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParamsDetailPO(prevParams => ({
            ...prevParams,
            pageNumber: pagination?.current,
            pageSize: pagination?.pageSize,
            orderBy: sorter?.field || prevParams?.orderBy,
            isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
        }));

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParamsDetailPO?.pageSize) {
            setTotalDataDetailPO([]);
        }
    };

    const fetchDetailPO = async () => {
        const res = await detailPO(tableParamsDetailPO)
        if (res?.isValid && res?.statusCode === 200) {
            setDataDetailPO(res?.data?.listData)
            setTotalDataDetailPO(res?.data?.totalRecords)
        }
    };

    useEffect(() => {
        if (prId !== null) {
            setTableParamsDetailPO(prev => ({
                ...prev,
                filter: { prId: prId }
            }))
        }
    }, [prId])

    useEffect(() => {
        if (tableParamsDetailPO?.filter?.prId !== null) {
            fetchDetailPO();
        }
    }, [JSON.stringify(tableParamsDetailPO)]);

    const columns = [
        {
            title: 'Purchase Order Number',
            dataIndex: 'poNo',
            sorter: true,
            width: "130px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Purchase Order Date',
            dataIndex: 'poSubmitDate',
            sorter: true,
            width: "120px",
            render: (data) => displayDate(data),
        },
        {
            title: 'Remark',
            dataIndex: 'remarksToVendor',
            sorter: true,
            width: "180px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Vendor Name',
            dataIndex: 'vendorName',
            sorter: true,
            width: "150px",
            render: (data) => <CellTable data={data} />
        },
    ]

    return (
        <BaseDrawer
            title={`List Purchase Order - ${prNo}`}
            placement="right"
            size="large"
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
        >
            <Card>
                <CustomAntdTable
                    columns={columns}
                    dataSource={dataDetailPO}
                    rowKey={"prId"}
                    tableParam={setTableParamsDetailPO}
                    totalData={totalDataDetailPO}
                    loading={isLoadingDetailPO}
                    onChange={handleTableChange}
                    ignoreRowClick={true}
                />
            </Card>
        </BaseDrawer>
    )
}
