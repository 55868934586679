import { Col, Collapse, Form, Input, Modal, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import Constant from 'utils/Constants'
import CustomAntdTable from 'components/Common/CustomAntdTable'
import { formatterAmount, sorterTable } from 'utils/Helper'
import ButtonAction from 'components/Common/ButtonAction';
import useItemCategoryRepo from 'services/repo/useItemCategoryRepo';
import useItemRepo from 'services/repo/useItemRepo'

export default function LookupItems(props) {
    const { onClose, onSelect, itemType = Constant.ItemType.GOODS } = props
    const { isLoading: isLoadingListDataTable, getItemList } = useItemRepo(false)
    const { isLoading: isLoadingItemCategory, getItemCategoryList } = useItemCategoryRepo(false)

    const [filters, setFilters] = useState({
        previousItemId: null,
        previousContracCatalogItemDetailId: null,
        itemCode: "",
        itemName: "",
        itemTypeId: itemType,
        itemCategoryId: null,
        isActive: true
    })
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0)
    const [tableParams, setTableParams] = useState({
        pageNumber: 1,
        pageSize: 10,
        filter: {
            ...filters
        },
        orderBy: "ItemId",
        isAscending: true
    });

    const [dataItemCategory, setDataItemCategory] = useState([]);

    //Item Category DDL
    const fetchDataGetItemCategory = async () => {
        const payload = {
            pageNumber: 1,
            pageSize: 0,
            orderBy: "ItemCategoryId",
            isAscending: true,
            filter: {
                previousItemCategoryId: null,
                itemCategoryCode: "",
                itemCategoryName: "",
                isActive: true
            }
        }
        const res = await getItemCategoryList(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setDataItemCategory(() => {
                return res?.data?.listData?.map(d => {
                    return { label: d?.itemCategoryName, value: d?.itemCategoryId }
                })
            })
        }
    };

    const fetchData = async () => {
        const payload = {
            ...tableParams
        }
        const res = await getItemList(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setData(res?.data?.listData)
            setTotalData(res?.data?.totalRecords)
            setTableParams(prevParams => ({
                ...prevParams,
            }));
        }
    };

    const onSearch = (value) => {
        setTableParams(prev => ({
            ...prev,
            pageNumber: 1,
            filter: {
                ...filters
            }
        }))
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams(prevParams => ({
            ...prevParams,
            pageNumber: pagination?.current,
            pageSize: pagination?.pageSize,
            orderBy: sorter?.field || prevParams?.orderBy,
            isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
        }));

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams?.pageSize) {
            setData([]);
        }
    };

    const handleSelect = (data) => {
        onSelect(data)
        // onClose()
    }

    const handleFieldFilterChange = (field, value) => {
        const updatedFilters = {
            ...filters,
            [field]: value
        };
        setFilters(updatedFilters);
    }

    useEffect(() => {
        fetchDataGetItemCategory();
    }, []);

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams)]);

    useEffect(() => {
        const timeOutId = setTimeout(() => onSearch(), Constant.delayFilter);
        return () => clearTimeout(timeOutId);
    }, [filters])

    const catalogColumns = [
        {
            title: 'Action',
            key: 'action',
            width: "60px",
            render: (_, record) => (
                <>
                    <ButtonAction
                        buttons={['add']}
                        onAdd={() => handleSelect(_)}
                    />
                </>
            )
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            sorter: true,
            width: "200px",
        },
        {
            title: 'Item Code',
            dataIndex: 'itemCode',
            sorter: true,
            width: "100px",
        },
        {
            title: 'Item Description',
            dataIndex: 'description',
            sorter: true,
            width: "300px",
        },
        {
            title: 'Item Category',
            dataIndex: 'itemCategory',
            sorter: true,
            width: "200px",
            render: (itemCategory) => itemCategory?.itemCategoryName
        },
        {
            title: 'UOM',
            dataIndex: 'uom',
            sorter: true,
            width: "70px",
            render: (uom) => uom?.name
        },
        {
            title: 'Price/Unit',
            dataIndex: 'price',
            sorter: true,
            align: "right",
            width: "200px",
            render: (price) => {
                return formatterAmount(price)
            }
        },
    ];

    return (
        <>
            <Modal
                title={`${itemType === Constant.ItemType.GOODS ? 'Goods' : 'Service'} Item Lookup`}
                open={true}
                onOk={onClose}
                onCancel={onClose}
                width={Constant.modalWidth.lg}
                footer={null}
            >
                <Collapse defaultActiveKey={[1]} className='mb-1 mt-1' items={[
                    {
                        key: 1,
                        label: "Filter",
                        children: <Form layout="vertical">
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item
                                        name="itemCode"
                                        label="Item Code"
                                    >
                                        <Input
                                            allowClear
                                            placeholder="Item Code"
                                            onChange={(e) => {
                                                handleFieldFilterChange('itemCode', e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item
                                        name="itemName"
                                        label="Item Name"
                                    >
                                        <Input
                                            allowClear
                                            placeholder="Item Name"
                                            onChange={(e) => {
                                                handleFieldFilterChange('itemName', e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item
                                        name="itemCategoryId"
                                        label="Item Category"
                                    >
                                        <Select
                                            allowClear
                                            placeholder="Select Item Category"
                                            onChange={(e) => {
                                                handleFieldFilterChange('itemCategoryId', e || null)
                                            }}
                                            options={dataItemCategory}
                                            loading={isLoadingItemCategory}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    }
                ]} />
                <CustomAntdTable
                    columns={catalogColumns}
                    dataSource={data}
                    rowKey={"itemId"}
                    tableParam={tableParams}
                    totalData={totalData}
                    loading={isLoadingListDataTable}
                    onChange={handleTableChange}
                />
            </Modal>
        </>
    )
}
