import { Card, Col, Divider, Form, Input, Row, Space, Tooltip, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ButtonAction from 'components/Common/ButtonAction';
import { ButtonAdd } from 'components/Common/Buttons';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { dismissToast, loadingToastId } from 'utils/Toast';
import Constant from 'utils/Constants';
import { sorterTable } from 'utils/Helper';
import { ButtonAdvanceFilter } from "../../../components/Common/ButtonAdvanceFilter";
import BaseAdvanceFilter from "../../../components/CustomDrawer/BaseAdvanceFilter";
import useIsMobileHooks from "../../../hooks/useIsMobileHooks";
import useDivisionRepo from "../../../services/repo/useDivisionRepo";
import BaseDrawerDivision from "../../../components/MasterData/Division/BaseDrawerDivision";
import SkeletonPage from "../../../components/Common/SkeletonPage";
import Error403 from "../../../components/Error/Error403";
import useAccessPermissionHook from "../../../hooks/useAccessPermissionHook";
import { PRIVILEGE_ID } from "../../../utils/AccessPermissionConfig";
import CellTable from 'components/Common/CellTable';
import TagActiveInCell from 'components/Common/TagActiveInCell';

export default function Division() {
  useTopbarTitleHooks("Master Data Division");
  const { isLoading: isLoadingList, getDivisionList } = useDivisionRepo(false);
  const { isLoading: isLoadingById, getDivisionById } = useDivisionRepo(false);
  const [data, setData] = useState();
  const [totalData, setTotalData] = useState(0)
  const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false);
  const isMobile = useIsMobileHooks()

  const [isViewDrawer, setIsViewDrawer] = useState(false)
  const [dataEditDrawer, setDataEditDrawer] = useState(null)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [idViewData, setIdViewData] = useState(null)

  const { isHasPrivilege } = useAccessPermissionHook()
  const isLoadingPrivilege = useRef(true)
  const hasPrivilegePage = useRef(true)
  const [showButtonAdd, setShowButtonAdd] = useState(false)

  const [form] = Form.useForm()

  const [filters, setFilters] = useState({
    divisionId: null,
    divisionCode: "",
    divisionName: "",
    isActive: null
  })

  const [tableParams, setTableParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    filter: { ...filters },
    orderBy: "divisionCode",//== DivisionCode
    isAscending: true
  });

  const onSearch = () => {
    setTableParams(prev => ({
      ...prev,
      pageNumber: 1,
      filter: { ...filters }
    }))
    setIsOpenAdvanceFilter(false)
  };

  const onSearchClick = () => {
    onSearch()
  }

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
    setDataEditDrawer(null)
    setIdViewData(null)
  };

  const fetchData = async () => {
    const payload = { ...tableParams }
    const res = await getDivisionList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setData(res?.data?.listData)
      setTotalData(res?.data?.totalRecords)
      setTableParams(prevParams => ({ ...prevParams }));
    }
  };

  const onSearchCode = (value) => {
    setTableParams({
      ...tableParams,
      pageNumber: 1,
      filter: {
        code: value
        //! ❌ divisionCode: value
      }
    })
  };
  const onSearchName = (value) => {
    setTableParams({
      ...tableParams,
      pageNumber: 1,
      filter: {
        name: value
        //! ❌ divisionName: value
      }
    })
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams(prevParams => ({
      ...prevParams,
      pageNumber: pagination?.current,
      pageSize: pagination?.pageSize,
      orderBy: sorter?.field || prevParams?.orderBy,
      isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
    }));
    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams?.pageSize) {
      setData([]);
    }
  };

  const handleAdd = () => {
    setDataEditDrawer(null)
    setIsViewDrawer(false)
    setIsOpenDrawer(true)
  }

  const handleRowClick = async (data) => {
    // debugger
    let id = data?.divisionId
    const payload = { divisionId: id }
    loadingToastId(Constant.message.loading, id)
    const res = await getDivisionById(payload)
    if (res?.isValid && res?.statusCode === 200) {
      dismissToast(id)
      setIsViewDrawer(true)
      setIsOpenDrawer(true)
      setDataEditDrawer(res?.data)
      setIdViewData(res.data.divisionId)
    }
  }

  const handleEdit = async (data) => {
    let id = data?.divisionId
    const payload = { divisionId: id }
    loadingToastId(Constant.message.loading, id)
    const res = await getDivisionById(payload)
    if (res?.isValid && res?.statusCode === 200) {
      dismissToast(id)
      setIsViewDrawer(false)
      setIsOpenDrawer(true)
      setDataEditDrawer(res?.data)
    }
  }

  const fetchCheckPrivilege = async () => {
    loadingToastId(Constant.message.loading, PRIVILEGE_ID.AddEditDivision)
    // loadingToastId(Constant.message.loading, PRIVILEGE_ID.Division)
    let pvlgAddEdit = await isHasPrivilege(PRIVILEGE_ID.AddEditDivision)
    let pvlgAccess = await isHasPrivilege(PRIVILEGE_ID.Division)
    Promise.all([pvlgAccess, pvlgAddEdit]).then(
      ([pvlgAccessValue, pvlgAddEditValue]) => {
        setShowButtonAdd(pvlgAddEditValue)
        isLoadingPrivilege.current = false
        if (pvlgAccessValue) {
          hasPrivilegePage.current = true;
        } else {
          hasPrivilegePage.current = false
        }
        // dismissToast(PRIVILEGE_ID.Division)
        dismissToast(PRIVILEGE_ID.AddEditDivision)
      });
  }

  useEffect(() => {
    // Wait for API data then check privilege
    fetchCheckPrivilege()
    fetchData();
  }, [JSON.stringify(tableParams)]);

  const filter = () => {
    return (
      <Form layout="horizontal" form={form}>
        <Row gutter={[16, 16]}
          justify='start'
          align='middle'>
          <Col xs={isMobile ? 24 : null} md={isMobile ? 6 : null}>
            <Typography style={{ marginBottom: 25 }}>Filter by</Typography>
          </Col>
          <Col xs={isMobile ? 24 : null} md={isMobile ? 6 : null}>
            <Form.Item name='divisionCode'>
              <Input
                style={{ width: 'auto' }}
                placeholder={Constant.placeholder.enterCode}
                onChange={(e) => {
                  if (isOpenAdvanceFilter) {
                    setFilters(prev => ({
                      ...prev,
                      code: e.target.value
                    }))
                  } else {
                    onSearchCode(e.target.value)
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={isMobile ? 24 : null} md={isMobile ? 6 : null}>
            <Form.Item name='divisionName'>
              <Input
                style={{ width: 'auto' }}
                placeholder={Constant.placeholder.enterName}
                onChange={e => {
                  if (isOpenAdvanceFilter) {
                    setFilters(prev => ({
                      ...prev,
                      name: e.target.value
                    }))
                  } else {
                    onSearchName(e.target.value)
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  const columns = [
    {
      title: 'Action',
      key: 'action',
      width: "60px",
      render: (_, record) => (
        <>
          {showButtonAdd ?
            <ButtonAction
              buttons={['edit', 'view']}
              onEdit={handleEdit}
              onView={handleRowClick}
              data={record}
            /> :
            <ButtonAction
              buttons={['view']}
              onView={handleRowClick}
              data={record}
            />
          }
        </>
      )
    },
    {
      title: 'Code',
      dataIndex: 'divisionCode',
      sorter: true,
      width: "100px",
      render: (data) => <CellTable data={data} />
    },
    {
      title: 'Name',
      dataIndex: 'divisionName',
      sorter: true,
      width: "200px",
      render: (data) => <CellTable data={data} />
    },
    {
      width: "60px",
      title: 'Is Active',
      dataIndex: 'isActive',
      render: (isActive) => (
        <>
          <TagActiveInCell isActive={isActive} />
        </>
      ),
    },
  ];

  return (
    <>
      {hasPrivilegePage.current ?
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <Card size="big" bordered={false}>
            {isLoadingPrivilege.current ? <SkeletonPage /> :
              <>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={20} xl={18}>
                    {showButtonAdd ?
                      <Tooltip placement="top" title="add division">
                        <ButtonAdd onClick={handleAdd} />
                      </Tooltip> :
                      null
                    }
                  </Col>
                </Row>
                <Divider dashed className='mb-1 mt-1' />

                {isMobile ?
                  <ButtonAdvanceFilter onClick={() => setIsOpenAdvanceFilter(true)} />
                  : filter()
                }

                <CustomAntdTable
                  columns={columns}
                  dataSource={data}
                  rowKey={"divisionId"}
                  tableParam={tableParams}
                  totalData={totalData}
                  loading={isLoadingList}
                  onChange={handleTableChange}
                  rowClick={handleRowClick}
                  isOpenDrawer={isOpenDrawer}
                  idDataHighlight={idViewData}
                />
              </>
            }
          </Card>
        </Space> : (<Error403 />)
      }

      {isOpenDrawer && (
        <BaseDrawerDivision
          onClose={onCloseDrawer}
          isOpen={isOpenDrawer}
          isView={isViewDrawer}
          dataEdit={dataEditDrawer}
          loading={isLoadingById}
          onRefreshList={fetchData}
          setModeView={(d) => setIsViewDrawer(d)}
          isAddEditPrivileged={showButtonAdd}
          handleUnhighlight={() => {
            setIdViewData(null)
          }}
        />
      )}

      {isOpenAdvanceFilter &&
        <BaseAdvanceFilter
          onClose={() => setIsOpenAdvanceFilter(false)}
          onSearchClick={onSearchClick}>
          {filter()}
        </BaseAdvanceFilter>}
    </>
  )
}
