import { Alert, Button, Card, Form, Input, Space } from 'antd'
import useSimpleCaptcha from 'hooks/useSimpleCaptcha'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTE_PREFIX, RouteConstant } from 'routes/routesConstant'
import useAuthRepo from 'services/repo/useAuthRepo'
import Constant from 'utils/Constants'
import DevLog from 'utils/DevLog'
import { dateGreaterThan } from 'utils/Helper'
import LocalStorageUtils from 'utils/LocalStorage'
import { successToast } from 'utils/Toast'
import BrandLogo from 'assets/img/LOGO-FULL-DARK.png'
import useEncriptRSAHook from 'hooks/useEncriptRSAHook'

const propsItem = {
    colon: false,
    labelCol: { sm: 12 },
    validateTrigger: false,
    className: "form-item-login"
}

const styleFormItem = {
    marginBottom: "0"
}

export default function Login() {
    const { login } = useAuthRepo(false);
    const {
        captchaUI,
        isValid: isValidCaptcha,
        handleInputCaptchaChange,
        generateCaptcha
    } = useSimpleCaptcha();
    const { getEncriptedText } = useEncriptRSAHook()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [, setCaptcha] = useState("")
    const [tryLoginCount, setTryLoginCount] = useState(Number(LocalStorageUtils.tryLoginCount.get()))
    const navigate = useNavigate()
    let location = useLocation();
    const [isLoadingLogin, setIsLoadingLogin] = useState(false)
    const [isAlreadyHaveJWT, setIsAlreadyHaveJWT] = useState(false)

    const checkHasJWT = useRef(true)

    const onFinish = async (values) => {
        setIsLoadingLogin(true)
        checkHasJWT.current = false
        const encriptedPassword = await getEncriptedText(password)
        if (encriptedPassword) {
            const res = await login(username, encriptedPassword)
            if (res?.isValid && res?.statusCode === 200) {
                successToast('Login success! Redirecting...')
                setTimeout(() => {
                    const defaultRedirect = APP_ROUTE_PREFIX + "/" + RouteConstant.dashboard
                    const redirectTo = location.search?.split("?redirectTo=")?.[1]
                    if (redirectTo) {
                        navigate(redirectTo, { replace: true })
                    } else {
                        navigate(defaultRedirect, { replace: true })
                    }
                }, 1500)
            } else {
                let currentCount = Number(LocalStorageUtils.tryLoginCount.get())
                const sumCurrentCount = currentCount + 1
                LocalStorageUtils.tryLoginCount.set(sumCurrentCount)
                setTryLoginCount(sumCurrentCount)
                if (sumCurrentCount >= 3) {
                    window.location.reload();
                }
                setIsLoadingLogin(false)
                checkHasJWT.current = true
            }
        } else {
            setIsLoadingLogin(false)
            checkHasJWT.current = true
        }
    };

    const onFinishFailed = (errorInfo) => {
        DevLog('Failed:', errorInfo);
    };

    useEffect(() => {
        if (LocalStorageUtils.jwtToken.get() !== '') {
            tryRelog()
        }
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentToken = LocalStorageUtils.jwtToken.get();
            if (currentToken !== '' && checkHasJWT.current) {
                if (!LocalStorageUtils.checkTokenExpired()) {
                    setIsAlreadyHaveJWT(true);
                }
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [])

    const tryRelog = () => {
        let token = LocalStorageUtils.jwtToken.get()
        let decoded = jwtDecode(token);
        let exp = moment.unix(decoded?.exp)
        let checkDiffTime = dateGreaterThan(moment(), exp)
        if (!checkDiffTime) {
            navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.dashboard, { replace: true })
        }
    }

    return (
        <div className='login-page'>
            <div className='wrap-login'>
                {isAlreadyHaveJWT && <div className="alert-already-login">
                    <Alert
                        message={'You signed in with another tab or window. Reload to refresh your session!'}
                        type="warning"
                        action={
                            <Space>
                                <Button type="primary" size="small" onClick={() => window.location.reload()}>
                                    Reload
                                </Button>
                            </Space>
                        }
                    />
                </div>}
                <Card className='card'>
                    <img src={BrandLogo} alt='brand logo' height={60} className='brand-img' />
                    <p className="auth-container-login-title">Login</p>
                    <p className="auth-container-login-subtitle">Welcome Back!</p>
                    <Form
                        name="login"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            {...propsItem}
                            name="username"
                            style={styleFormItem}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input placeholder='Username' onChange={(e) => {
                                setUsername(e.target.value)
                            }} />
                        </Form.Item>
                        <Form.Item
                            {...propsItem}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            name="password"
                            style={styleFormItem}
                        >
                            <Input.Password
                                className='password'
                                placeholder='Password'
                                type='password'
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }} />
                        </Form.Item>

                        {(Constant.useCaptchaValidationLogin && (tryLoginCount >= 3)) && <>
                            <Form.Item
                                {...propsItem}
                                style={styleFormItem}
                            >
                                {captchaUI}
                            </Form.Item>
                            <Form.Item
                                {...propsItem}
                                name="captcha"
                                style={styleFormItem}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!isValidCaptcha) {
                                                generateCaptcha()
                                                return Promise.reject(new Error('Invalid Captcha!'));
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Input placeholder='Captcha' onChange={(e) => {
                                    setCaptcha(e.target.value)
                                    handleInputCaptchaChange(e.target.value)
                                }} />
                            </Form.Item>
                        </>}
                        <Link to={RouteConstant.forgotPassword}><p className='forgot-password-link'>Forgot password?</p></Link>
                        <div className='wrap-button'>
                            <Button type='primary' htmlType='submit' loading={isLoadingLogin} size='large' disabled={isAlreadyHaveJWT}>Login</Button>
                        </div>
                    </Form>
                </Card>
            </div>
        </div >
    )
}
