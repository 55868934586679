import { useEffect } from "react";
import { formatterAmount, setFieldValues } from "utils/Helper";
import GoodsItem from "./Items/GoodsItem";
import ServicesItem from "./Items/ServicesItem";
import { useAtom } from "jotai";
import { goodsItemIVCRAtom, headerIVCRAtom, servicesItemIVCRAtom } from "store/InvoiceReceive/invoiceReceiveAtom";
import CustomAntdTable from "components/Common/CustomAntdTable";
import { Table, Typography } from "antd";
import { CustomFormItemMoney, CustomFormItemTextArea } from "components/Common/CustomFormItem";
const { Text } = Typography;

export default function SectionItems(props) {
    const { initForm, isView = false } = props;
    const [dataHeader, setDataHeader] = useAtom(headerIVCRAtom)
    const [dataItemGoods] = useAtom(goodsItemIVCRAtom)
    const [dataItemServices] = useAtom(servicesItemIVCRAtom)

    useEffect(() => {
        let paidAmount = (dataHeader?.paidAmountGoods + dataHeader?.paidAmountServices) + dataHeader?.adjustmentAmount
        setFieldValues(initForm, "paidAmount", formatterAmount(paidAmount))
        setDataHeader(prev => ({
            ...prev,
            paidAmount: paidAmount,
            grandTotal: (dataHeader?.netAmountGoods + dataHeader?.netAmountServices) + dataHeader?.adjustmentAmount
        }));
    }, [
        dataHeader?.paidAmountGoods,
        dataHeader?.paidAmountServices,
        dataHeader.netAmountGoods,
        dataHeader.netAmountServices,
        dataHeader.adjustmentAmount
    ]);

    const columns = [
        {
            title: 'Item Type',
            dataIndex: 'itemType',
            className: "custom-table-edit-row-input",
            width: "80px",
        },
        {
            title: 'Net Amount',
            dataIndex: 'netAmountBeforeTax',
            className: "custom-table-edit-row-input",
            width: "140px",
            align: 'right',
        },
        {
            title: 'VAT Amount',
            dataIndex: 'vatAmount',
            className: "custom-table-edit-row-input",
            width: "110px",
            align: 'right',
        },
        {
            title: 'WHT Amount',
            dataIndex: 'whtAmount',
            className: "custom-table-edit-row-input",
            width: "110px",
            align: 'right',
        },
        {
            title: 'Net Amount (After Tax)',
            dataIndex: 'netAmountAfterTax',
            className: "custom-table-edit-row-input",
            width: "140px",
            align: 'right',
        },
    ]

    // console.log("header", dataHeader);

    return (
        <div className="invoice-receive-section-items">
            <GoodsItem
                isView={isView}
                initForm={initForm}
            />
            <ServicesItem
                isView={isView}
                initForm={initForm}
            />
            {/* <Divider dashed className="divider-total-items" /> */}
            {/* {(dataItemGoods?.length > 0 || dataItemServices?.length > 0) && <div className="wrap-section-total">
                <div className="section-total">
                    <p>Grand Total (Goods & Service)</p>
                    <p className="amount">{formatterAmount(dataHeader.grandTotal)}</p>
                </div>
            </div>} */}

            {
                (dataItemGoods.length > 0 || dataItemServices.length > 0) &&
                <>
                    <p className="font-semibold">Grand Total (Goods & Service)</p>
                    <CustomAntdTable
                        className='custom-table-edit-row table-goods-item'
                        rowKey={"index"}
                        columns={columns}
                        dataSource={[
                            dataItemGoods.length > 0 ? {
                                index: 1,
                                itemType: "Goods",
                                netAmountBeforeTax: formatterAmount(dataHeader.netAmountGoodsBeforeTax),
                                vatAmount: formatterAmount(dataHeader.totalAmountVatGoods),
                                whtAmount: formatterAmount(0),
                                netAmountAfterTax: formatterAmount(dataHeader.netAmountGoods),
                            } : null,
                            dataItemServices.length > 0 ? {
                                index: 2,
                                itemType: "Service",
                                netAmountBeforeTax: formatterAmount(dataHeader.netAmountServicesBeforeTax),
                                vatAmount: formatterAmount(dataHeader.totalAmountVatServices),
                                whtAmount: formatterAmount(dataHeader.totalWhtAmountServices),
                                netAmountAfterTax: formatterAmount(dataHeader.netAmountServices),
                            } : null,
                        ].filter(item => item !== null)}
                        pagination={false}
                        ignoreRowClick={true}
                        summary={pageData => (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell className="custom-table-edit-row-input summary-item total-include-tax">Adjustment</Table.Summary.Cell>
                                    <Table.Summary.Cell className="custom-table-edit-row-input" colSpan={3}>
                                        <CustomFormItemTextArea
                                            nameFormItem="adjustmentReason"
                                            value={dataHeader.adjustmentReason}
                                            isView={isView}
                                            rows={1}
                                            maxLength={8000}
                                            placeholder="Fill in Adjusment Reason"
                                            onChange={(e) => {
                                                setDataHeader(prev => ({
                                                    ...prev,
                                                    adjustmentReason: e.target.value
                                                }))
                                            }}
                                            useInTable={true}
                                        />
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="custom-table-edit-row-input summary-item total-include-tax" align="right">
                                        <CustomFormItemMoney
                                            nameFormItem="adjustmentAmount"
                                            nameInput="adjustmentAmount"
                                            initForm={initForm}
                                            min={null}
                                            customvalue={dataHeader?.adjustmentAmount}
                                            onChange={(val) => {
                                                setDataHeader(prev => ({
                                                    ...prev,
                                                    adjustmentAmount: val
                                                }))
                                            }}
                                            isView={isView}
                                            useInTable={true}
                                            alignView="right"
                                        />
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell className="summary-item total-include-tax" colSpan={4}>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell className="summary-item total-include-tax" align="right">
                                        <Text>{formatterAmount(dataHeader.grandTotal)}</Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        )}
                    />
                </>
            }

        </div>
    );
}
