/*
 * Oct/12/23
 * Refactor - Aris
 */


import { Card, Col, Divider, Form, Input, Row, Select, Space, Tooltip, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ButtonAction from 'components/Common/ButtonAction';
import { ButtonAdd } from 'components/Common/Buttons';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { dismissToast, loadingToastId } from 'utils/Toast';
import Constant from 'utils/Constants';
import { formatterAmount, sorterTable } from 'utils/Helper';
import DevLog from 'utils/DevLog';
import useItemRepo from 'services/repo/useItemRepo';
import useIsMobileHooks from 'hooks/useIsMobileHooks';
import useAccessPermissionHook from 'hooks/useAccessPermissionHook';
import useItemCategoryRepo from 'services/repo/useItemCategoryRepo';
import useItemTypeRepo from 'services/repo/useItemTypeRepo';
import { PRIVILEGE_ID } from 'utils/AccessPermissionConfig';
import SkeletonPage from 'components/Common/SkeletonPage';
import { ButtonAdvanceFilter } from 'components/Common/ButtonAdvanceFilter';
import BaseDrawerItem from 'components/MasterData/Item/BaseDrawerItem';
import BaseAdvanceFilter from 'components/CustomDrawer/BaseAdvanceFilter';
import Error403 from 'components/Error/Error403';
import TagActiveInCell from 'components/Common/TagActiveInCell';
import CellTable from 'components/Common/CellTable';

export default function Item() {
  useTopbarTitleHooks("Master Data Item");
  const { isLoading: isLoadingList, getItemList } = useItemRepo(false)
  const { isLoading: isLoadingById, getItemById } = useItemRepo(false)
  const { isLoading: isLoadingListIC, getItemCategoryList } = useItemCategoryRepo(false);
  const { isLoading: isLoadingListItemType, getItemTypeList } = useItemTypeRepo(false);
  const [form] = Form.useForm()
  const isMobile = useIsMobileHooks()
  const [data, setData] = useState()
  const [totalData, setTotalData] = useState(0)
  const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false)
  const [itemCategories, setItemCategories] = useState([])
  const [itemTypes, setItemTypes] = useState([])
  const [isViewDrawer, setIsViewDrawer] = useState(false)
  const [dataEditDrawer, setDataEditDrawer] = useState(null)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [idViewData, setIdViewData] = useState(null)

  const { isHasPrivilege } = useAccessPermissionHook()
  const isLoadingPrivilege = useRef(true)
  const hasPrivilegePage = useRef(true)
  const [showButtonAdd, setShowButtonAdd] = useState(false)
  const [isLoadedPrivilege, setIsLoadedPrivilege] = useState(false)


  const [filters, setFilters] = useState({
    itemId: null,
    itemName: "",
    itemCode: "",
    description: "",
    itemTypeId: null,
    itemCategoryId: null,
    uomId: null,
    currencyId: 1,
    price: null,
    priceInOc: null,
    isActive: null
  })

  const [tableParams, setTableParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    filter: { ...filters },
    orderBy: "itemId",
    isAscending: true
  });

  const fetchData = async () => {
    const payload = { ...tableParams }
    const res = await getItemList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setData(res?.data?.listData)
      setTotalData(res?.data?.totalRecords)
      setTableParams(prevParams => ({ ...prevParams }));
    }
  }

  //# For item category name dropdown filter by search
  const fetchDataIC = async (itemTypeId) => {
    const payload = {
      pageNumber: 1,
      pageSize: 10,
      filter: {
        itemCategoryId: null,
        itemCategoryCode: "",
        itemCategoryName: "",
        description: "",
        itemTypeId: itemTypeId || null,
        isActive: true,
      },
      orderBy: "itemCategoryId",
      isAscending: true
    }
    const res = await getItemCategoryList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setItemCategories(() => {
        return res?.data?.listData.map(item => {
          return {
            value: item?.itemCategoryId,
            label: item?.itemCategoryName
          }
        })
      })
    }
  }

  const fetchDataItemTypes = async () => {
    const payload = {
      pageNumber: 1,
      pageSize: 10,
      filter: {
        itemTypeId: null,
        itemTypeName: "",
        isActive: null,
      },
      orderBy: "itemTypeId",
      isAscending: true
    }
    const res = await getItemTypeList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setItemTypes(() => {
        return res?.data?.listData.map(item => {
          return {
            value: item?.itemTypeId,
            label: item?.itemTypeName
          }
        })
      })
    }
  }

  const onSearch = () => {
    setTableParams(prev => ({
      ...prev,
      pageNumber: 1,
      filter: {
        ...filters
      }
    }))
  };

  const onSearchClick = () => {
    onSearch()
    setIsOpenAdvanceFilter(false)
  }

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
    setDataEditDrawer(null)
    setIdViewData(null)
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams(prevParams => ({
      ...prevParams,
      pageNumber: pagination?.current,
      pageSize: pagination?.pageSize,
      orderBy: sorter?.field || prevParams?.orderBy,
      isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
    }));
    if (pagination.pageSize !== tableParams?.pageSize) {
      setData([]);
    }
  };

  const handleAdd = () => {
    setDataEditDrawer(null)
    setIsViewDrawer(false)
    setIsOpenDrawer(true)
  }

  const handleRowClick = async (data) => {
    let id = data?.itemId
    const payload = { itemId: id }
    loadingToastId(Constant.message.loading, id)
    const res = await getItemById(payload)
    if (res?.isValid && res?.statusCode === 200) {
      dismissToast(id)
      setIsViewDrawer(true)
      setIsOpenDrawer(true)
      setDataEditDrawer(res?.data)
      setIdViewData(res.data.itemId)
      DevLog('res?.data', res?.data)
    }
  }

  const handleEdit = async (data) => {
    let id = data?.itemId
    const payload = { itemId: id }
    loadingToastId(Constant.message.loading, id)
    const res = await getItemById(payload)
    if (res?.isValid && res?.statusCode === 200) {
      dismissToast(id)
      setIsViewDrawer(false)
      setIsOpenDrawer(true)
      setDataEditDrawer(res?.data)
    }
  }

  const fetchCheckPrivilege = async () => {
    loadingToastId(Constant.message.loading, PRIVILEGE_ID.AddEditItem)
    let pvlgAccess = await isHasPrivilege(PRIVILEGE_ID.Item)
    let pvlgAddEdit = await isHasPrivilege(PRIVILEGE_ID.AddEditItem)
    Promise.all([pvlgAccess, pvlgAddEdit]).then(
      ([pvlgAccessValue, pvlgAddEditValue]) => {
        setShowButtonAdd(pvlgAddEditValue)
        isLoadingPrivilege.current = false
        if (pvlgAccessValue) {
          setIsLoadedPrivilege(true)
          hasPrivilegePage.current = !!pvlgAccessValue;
          fetchData();
        } else {
          hasPrivilegePage.current = !pvlgAccessValue;
        }
        dismissToast(PRIVILEGE_ID.AddEditItem);
      });
  }

  useEffect(() => {
    fetchCheckPrivilege()
    fetchDataItemTypes()
  }, [])

  useEffect(() => {
    fetchDataIC(filters.itemTypeId)
  }, [filters.itemTypeId])


  useEffect(() => {
    if (isLoadedPrivilege) {
      fetchData();
    }
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (isLoadedPrivilege && !isMobile) {
        onSearch()
      }
    }, Constant.delayFilter);
    return () => clearTimeout(timeOutId);
  }, [filters]);

  const filter = () => {
    return (
      <Form layout="horizontal" form={form}>
        <Row gutter={16}>
          <Col xs={24} sm={8} md={6}>
            <Form.Item name='itemCode' label="Filter By">
              <Input
                style={{ width: '100%' }}
                placeholder={Constant.placeholder.enterItemCode}
                onChange={(e) => {
                  setFilters(prev => ({
                    ...prev,
                    itemCode: e.target.value
                  }))
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={6}>
            <Form.Item name='itemName'>
              <Input
                style={{ width: '100%' }}
                placeholder={Constant.placeholder.enterItemName}
                onChange={e => {
                  setFilters(prev => ({
                    ...prev,
                    itemName: e.target.value
                  }))
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={6}>
            <Form.Item name='itemTypeId'>
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder='Select Item Type'
                loading={isLoadingListItemType}
                value={undefined}
                options={itemTypes}
                onChange={value => {
                  setFilters(prev => ({
                    ...prev,
                    itemTypeId: value
                  }))
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={6}>
            <Form.Item name='itemCategoryId'>
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder='Select Item Category'
                loading={isLoadingListIC}
                value={undefined}
                options={itemCategories}
                onChange={value => {
                  setFilters(prev => ({
                    ...prev,
                    itemCategoryId: value
                  }))
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  const columns = [
    {
      title: 'Action',
      key: 'action',
      width: "60px",
      render: (_, record) => (
        <>
          {showButtonAdd ?
            <ButtonAction
              buttons={['edit', 'view']}
              onEdit={handleEdit}
              onView={handleRowClick}
              data={record}
            /> :
            <ButtonAction
              buttons={['view']}
              onView={handleRowClick}
              data={record}
            />
          }
        </>
      )
    },
    {
      title: 'Item Code',
      sorter: true,
      dataIndex: 'itemCode',
      width: "100px",
      render: (data) => <CellTable data={data} />
    },
    {
      title: 'Item Name',
      sorter: true,
      dataIndex: 'itemName',
      width: "200px",
      render: (data) => <CellTable data={data} />
    },
    {
      title: 'Description',
      sorter: true,
      dataIndex: 'description',
      width: "300px",
      render: (data) => <CellTable data={data} />
    },
    {
      title: 'Item Category Name',
      sorter: true,
      dataIndex: 'itemCategoryId',
      width: "200px",
      render: (itemCategoryId, listData) => {
        return <CellTable data={listData.itemCategory.itemCategoryName} />
      }
    },
    {
      title: 'UOM Name',
      sorter: true,
      dataIndex: 'uomId',
      width: "70px",
      render: (uomId, listData) => {
        return <CellTable data={listData.uom.name} />
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: "150px",
      render: (price, listData) => {
        return <Typography style={{ textAlign: 'end' }}>
          {/*{listData.price.toLocaleString()} //1,000,000*/}
          {formatterAmount(listData.price)}
        </Typography>
      },
      align: "right",
    },
    {
      title: 'Type',
      dataIndex: 'itemTypeId',
      width: "80px",
      render: (itemTypeId, listData) => {
        return <CellTable data={listData.itemType.itemTypeName} />
      },
      align: "right",
    },
    {
      width: "80px",
      title: 'Is Active',
      dataIndex: 'isActive',
      render: (isActive, listData) => {
        return (
          <>
            <TagActiveInCell isActive={isActive} />
          </>
        )
      },
    },
  ];

  return (
    <>
      {hasPrivilegePage.current ?
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <Card size="big" bordered={false}>
            {isLoadingPrivilege.current ? <SkeletonPage /> :
              <>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={20} xl={18}>
                    {showButtonAdd &&
                      (<Tooltip placement="top" title="add item">
                        <ButtonAdd onClick={handleAdd} />
                      </Tooltip>)}
                  </Col>
                </Row>
                <Divider dashed className='mb-1 mt-1' />

                {isMobile ?
                  <ButtonAdvanceFilter onClick={() => setIsOpenAdvanceFilter(true)} />
                  : filter()
                }

                <CustomAntdTable
                  columns={columns}
                  dataSource={data}
                  rowKey={"itemId"}
                  tableParam={tableParams}
                  totalData={totalData}
                  loading={isLoadingList}
                  onChange={handleTableChange}
                  rowClick={handleRowClick}
                  isOpenDrawer={isOpenDrawer}
                  idDataHighlight={idViewData}
                />
              </>
            }
          </Card>
        </Space> : (<Error403 />)
      }

      {isOpenDrawer && (
        <BaseDrawerItem
          onClose={onCloseDrawer}
          isView={isViewDrawer}
          dataEdit={dataEditDrawer}
          loading={isLoadingById}
          onRefreshList={fetchData}
          setModeView={(d) => setIsViewDrawer(d)}
          isAddEditPrivileged={showButtonAdd}
          handleUnhighlight={() => {
            setIdViewData(null)
          }}
        />
      )}

      {isOpenAdvanceFilter &&
        <BaseAdvanceFilter
          onClose={() => setIsOpenAdvanceFilter(false)}
          onSearchClick={onSearchClick}>
          {filter()}
        </BaseAdvanceFilter>}
    </>
  )
}
