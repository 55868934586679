import * as React from "react";

function IconPurchaseRequest(props) {
    return (
        <svg
            width={61}
            height={60}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.167 5v15.554c0 .425 0 .837.045 1.177.052.383.177.82.54 1.183.364.363.8.489 1.184.54.34.046.752.046 1.176.046h15.555V40c0 7.071 0 10.607-2.197 12.803C46.273 55 42.738 55 35.667 55h-10c-7.071 0-10.607 0-12.804-2.197-2.196-2.196-2.196-5.732-2.196-12.803V20c0-7.071 0-10.607 2.196-12.803C15.06 5 18.596 5 25.667 5h6.5zm2 0v15.5c0 .5.002.774.027.964l.001.007.008.001c.19.026.464.028.964.028h15.5c0-1.95-.01-2.945-.38-3.842-.381-.92-1.104-1.642-2.55-3.087L41.097 7.93C39.65 6.484 38.928 5.76 38.009 5.38 37.112 5.009 36.116 5 34.167 5zm-12 27.5a1 1 0 011-1h15a1 1 0 010 2h-15a1 1 0 01-1-1zm1 9a1 1 0 100 2h10a1 1 0 000-2h-10z"
                fill="#1C64F2"
            />
        </svg>
    );
}

export default IconPurchaseRequest;