import { atom } from 'jotai'

const topbarTitleAtom = atom('')
const topbarBackButton = atom(false)
const backButtonUrl = atom('')

const RouteAtom = {
    topbarTitleAtom,
    topbarBackButton,
    backButtonUrl
}

export default RouteAtom
