import SectionHeader from "../SectionHeader";
import SectionItems from "../SectionItems";
import { BaseDrawer } from "components/CustomDrawer/BaseDrawer";
import { Form, Space } from "antd";
import Error403 from "components/Error/Error403";
import SectionWorkflowHistory from "../SectionWorkflowHistory";
import { ButtonCancelDocument, ButtonCloseDocument, ButtonPaid } from "components/Common/Buttons";
import BaseSectionAttachment from "../BaseSectionAttachment";
import CustomCollapse from "components/Common/CustomCollapse";

export default function BaseDetailInvoice(props) {
    const {
        onClose,
        loading = false,
        initForm,
        dataHeader,
        handleCancel = () => { },
        handleClose = () => { },
        handlePaid = () => { },
        isLoadingCancel = false,
        isLoadingClose = false,
        isLoadingPaid = false,
        notAuthorized = false,
        showCancelButton = false,
        showCloseButton = false,
        showPaidButton = false,
    } = props

    const handleCloseDrawer = () => {
        onClose()
    }

    const itemsCollapseDefault = [
        {
            key: 1,
            label: "Invoice Header",
            forceRender: true,
            children: <SectionHeader initForm={initForm} isView={true} />
        },
        {
            key: 2,
            label: "PO Detail",
            forceRender: true,
            className: "invoice-receive-form-collapse-panel-items",
            children: <SectionItems initForm={initForm} isView={true} />
        },
        {
            key: 3,
            label: "Attachment",
            forceRender: true,
            children: <BaseSectionAttachment
                isView={true}
            />
        }
    ]

    let itemsCollapseWithWfHistory = [
        ...itemsCollapseDefault,
        {
            key: 4,
            label: "Workflow History",
            forceRender: true,
            children: <SectionWorkflowHistory ivcrId={dataHeader?.ivcrId} />
        }
    ]

    return (
        <BaseDrawer
            title={"Invoice"}
            placement="right"
            size="large"
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
            extras={
                <>
                    {showCancelButton && <ButtonCancelDocument
                        onClick={handleCancel}
                        loading={isLoadingCancel}
                        disabled={isLoadingClose || isLoadingPaid}
                        buttonText='Cancel Invoice'
                    />}
                    {showCloseButton && <ButtonCloseDocument
                        onClick={handleClose}
                        loading={isLoadingClose}
                        disabled={isLoadingCancel || isLoadingPaid}
                        buttonText='Close Invoice'
                    />}
                    {showPaidButton && <ButtonPaid
                        onClick={handlePaid}
                        loading={isLoadingPaid}
                        disabled={isLoadingCancel || isLoadingClose}
                        buttonText="Paid Invoice"
                    />}
                </>
            }
        >
            <Space direction="vertical" size="small" className='wrap-invoice-receive-form space-vertical-flex' >
                {notAuthorized ? <Error403 /> :
                    <Form
                        name="form-create-invoice-receive"
                        form={initForm}
                        layout='vertical'
                    >
                        <CustomCollapse
                            className="invoice-receive-form-collapse"
                            defaultActiveKey={["1", "2", '3', "4", "5"]} items={!dataHeader.isDraft ? itemsCollapseWithWfHistory : itemsCollapseDefault}
                        />
                    </Form>
                }
            </Space >
        </BaseDrawer>
    )
}
