import { Drawer, Skeleton } from 'antd'
import useIsMobileHooks from 'hooks/useIsMobileHooks'
import React, { useState } from 'react'
import { FullscreenExitOutlined, CloseOutlined } from '@ant-design/icons'
import Constant from 'utils/Constants'
import IconFullScreen from 'assets/icons/IconFullScreen'

export const BaseDrawer = (props) => {
    const { children, extras, loading = false, onClose = () => { } } = props
    const isMobile = useIsMobileHooks(Constant.mediaQuery.isLG)
    const [isFullScreen, setIsFullScreen] = useState(false)
    // console.log(loading);

    return (
        <Drawer {...props}
            rootClassName={`root-custom-base-drawer ${props.rootClassName ? props.rootClassName : ""} ${isFullScreen ? 'full-screen' : ''}`}
            className={`custom-base-drawer ${props.className ? props.className : ""}`}
            placement="right"
            width={isMobile ? "100%" : "45%"}
            mask={false}
            closable={false}
            title={
                !loading &&
                <div className='wrapper-extras'>
                    <div className='wrapper-title'>
                        <p className="title">{props.title}</p>
                        <div className="wrapper-buttons">
                            {extras}
                        </div>
                    </div>
                    <div className='wrapper-common-buttons'>
                        {!isMobile && <button className='btn-full-screen' onClick={() => setIsFullScreen(!isFullScreen)}>
                            {isFullScreen ? <FullscreenExitOutlined /> : <IconFullScreen />}
                        </button>}
                        <button className='btn-close' onClick={onClose}><CloseOutlined /></button>
                    </div>
                </div>
            }
        >
            {!loading ?
                children : <>
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </>}
        </Drawer>
    )
}
