import { formatterAmount } from "./Helper";
export const ConfigBarHorizontalChart = (data, label, withFormater = true, defaultOptionsDataset = {}) => {
    return {
        labels: data?.map((data) => data.label),
        datasets: [{
            ...defaultOptionsDataset,
            label: label,
            data: data?.map((data) => data.value),
            borderWidth: 0.1,
            datalabels: {
                anchor: 'auto', // Posisi label (end, start, center, atau auto)
                align: 'end', // Alihkan label (end, start, atau center)
                font: {
                    weight: "bold"
                },
                color: "black",
                formatter: function (value, context) {
                    // this function will run while chart use custom plugin(formater will run in custom plugin)
                    // return context.chart.data.labels[context.dataIndex];
                    if (withFormater) return formatterAmount(value)
                    return value;
                }
            },
        }]
    }
}
