import { Button, Card, Col, Form, Row, Tree } from 'antd'
import { CustomFormItemSwitch, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import useRoleRepo from 'services/repo/useRoleRepo'
import Constant from 'utils/Constants'
import CustomAlert from 'utils/CustomAlert'
import DevLog from 'utils/DevLog'
import { dismissToast, loadingToastId, successToast } from 'utils/Toast'

const init_field = {
    roleId: null,
    roleName: "",
    description: "",
    isActive: true,
    lastModifiedAt: '',
}

export default function BaseDrawerRole(props) {
    const {
        onClose,
        dataEdit = null,
        onRefreshList,
        isView = false,
        loading = false,
        isAddEditPrivileged,
        setModeView = () => { } } = props
    const { isLoading: isLoadingPrivilegeList, getPrivilegeList } = useRoleRepo(false);
    const { isLoading: isLoadingCreate, createRole } = useRoleRepo(false);
    const { isLoading: isLoadingUpdate, updateRole } = useRoleRepo(false);

    const [form] = Form.useForm()
    const [dataField, setDataField] = useState(init_field)
    const [privilegeList, setPrivilegeList] = useState([])
    const [selectedPrivileges, setSelectedPrivileges] = useState([])

    /**
     * TREE PRIVILEGE
     */

    const getNode = (key, treeData) => {
        if (treeData?.length > 0) {
            var result = null
            for (let node of treeData) {
                if (!result) {
                    if (node.key === key) {
                        result = node
                    } else {
                        result = getNode(key, node.children)
                    }
                }
            }
            return result
        }
    }

    const selectAncestors = (key, nodes) => {
        if (nodes?.length > 0) {
            for (let node of nodes) {
                if (node.key === key) {
                    node.isActive = true
                    return true
                }
                const found = selectAncestors(key, node.children)
                if (found) {
                    node.isActive = true
                    return true
                }
            }
        }
        return false
    }

    const generateSelectedKeys = (nodes, selectedKey = []) => {
        if (nodes?.length > 0) {
            for (let node of nodes) {
                if (node.isActive) {
                    selectedKey = selectedKey.concat(node.key)
                }
                selectedKey = generateSelectedKeys(node.children, selectedKey)
            }
        }
        return selectedKey
    }

    const unSelectDescendant = (node) => {
        if (node) {
            node.isActive = false;
            if (node?.children?.length > 0) {
                for (let child of node.children) {
                    child.isActive = false
                    unSelectDescendant(child)
                }
            }
        }
    }

    const handleCheckPrivilege = (checkedKeys, info) => {
        if (info.checked) {
            selectAncestors(info?.node?.key, privilegeList)
        } else {
            let currentNode = getNode(info?.node?.key, privilegeList)
            unSelectDescendant(currentNode)
        }

        let selectedKeys = generateSelectedKeys(privilegeList)
        setSelectedPrivileges(selectedKeys)

    };

    const fetchDataPrivileges = async (roleId) => {
        const payload = {
            RoleId: roleId || null
        }
        const res = await getPrivilegeList(payload)
        if (res?.isValid && res?.statusCode === 200) {
            const list = res?.data
            setPrivilegeList([...[list]])
            let selectedKeys = generateSelectedKeys([...[list]])
            setSelectedPrivileges(selectedKeys)
        }
    };

    /**
     * 
     */

    const handleCloseDrawer = () => {
        form.resetFields()
        setDataField(init_field)
        setModeView(true)
        onClose()
    }

    const onSave = async () => {
        let payload = { ...dataField }
        payload.listSelectedPrivilegeId = selectedPrivileges
        let roleId = dataField?.roleId
        if (roleId) {
            payload.roleId = roleId
        }
        loadingToastId(Constant.message.savingData, payload?.roleName)
        let response;
        if (roleId) {
            response = await updateRole(payload);
        } else {
            response = await createRole(payload)
        }
        dismissToast(payload?.roleName)
        if (response?.isValid && response?.statusCode === 200) {
            successToast(Constant.message.saveDataSuccess);
            handleCloseDrawer()
            onRefreshList()
        }
    }

    // Case: Submit button out of Form
    const handleFormSubmit = () => {
        CustomAlert.confirm({
            title: "Save Role!",
            description: `Are you sure you want to save this data?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => {
                form.validateFields()
                    .then((values) => {
                        onSave()
                    })
                    .catch((errorInfo) => {
                        DevLog("err", errorInfo);
                    });
            }
        });
    };

    useEffect(() => {
        setModeView(isView)
    }, [isView])

    useEffect(() => {
        // debugger
        fetchDataPrivileges(dataEdit?.roleId)
        if (dataEdit !== null) {
            setDataField(dataEdit)
            form.setFieldsValue({
                roleName: dataEdit?.roleName,
                isActive: dataEdit?.isActive,
                description: dataEdit?.description,
            })
        } else {
            setPrivilegeList([])
            setSelectedPrivileges([])
            form.setFieldsValue({
                roleName: init_field?.roleName,
                isActive: init_field?.isActive,
                description: init_field?.description,
            })
            setDataField(init_field)
        }
    }, [dataEdit])

    return (
        <>
            <BaseDrawer
                title={isView ? "View Role" : (dataEdit === null ? "Add Role" : "Edit Role")}
                placement="right"
                size="large"
                onClose={handleCloseDrawer}
                open={true}
                loading={loading}
                extras={
                    <>
                        {(isAddEditPrivileged && isView) &&
                            (<Button type="primary" onClick={() => setModeView(false)}>Edit</Button>)}
                        {(isAddEditPrivileged && !isView) &&
                            (<Button type="primary" onClick={handleFormSubmit} htmlType='submit' loading={isLoadingCreate || isLoadingUpdate}>
                                Submit
                            </Button>)}
                    </>
                }
            >
                <Card>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <CustomFormItemText
                                    nameFormItem="roleName"
                                    label="Role Name"
                                    rules={[
                                        {
                                            required: true
                                        },
                                    ]}
                                    placeholder="Fill in Role Name"
                                    value={dataField?.roleName}
                                    onChange={(e) => {
                                        setDataField(prev => ({
                                            ...prev,
                                            roleName: e.target.value
                                        }))
                                    }}
                                    isView={isView}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <CustomFormItemSwitch
                                    nameFormItem="isActive"
                                    label="Is Active"
                                    value={dataField?.isActive}
                                    isView={isView}
                                    onChange={(checked) => {
                                        setDataField(prev =>
                                            ({ ...prev, isActive: checked }))
                                    }}
                                />
                            </Col>
                            <Col xs={24}>
                                <CustomFormItemTextArea
                                    nameFormItem="description"
                                    label="Description"
                                    value={dataField?.description}
                                    isView={isView}
                                    placeholder="Fill in Description"
                                    onChange={(e) => {
                                        setDataField(prev => ({
                                            ...prev,
                                            description: e.target.value
                                        }))
                                    }}
                                />
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    style={{ marginBottom: "0px" }}
                                    name="privilege"
                                    rules={[
                                        {
                                            validator: (_) => {
                                                if (selectedPrivileges?.length > 0) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Please select privilege.'));
                                            }
                                        },
                                    ]}
                                >
                                    <h3 style={{ marginBottom: 0 }}>Privilege List</h3>
                                </Form.Item>
                                <div>
                                    {isLoadingPrivilegeList ? <p>Loading...</p> : <Tree
                                        checkable
                                        defaultExpandAll
                                        checkStrictly={true}
                                        selectable={false}
                                        treeData={privilegeList}
                                        onCheck={handleCheckPrivilege}
                                        checkedKeys={selectedPrivileges}
                                        disabled={isView}
                                    />}
                                </div>
                            </Col>
                        </Row>
                        {/* <Divider dashed /> */}
                        <br />

                    </Form>
                </Card>
            </BaseDrawer>
        </>
    )
}
