import React, { useEffect, useRef, useState } from 'react';
import { Col, Space } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { formatterAmount, setFieldValues, taxCalculation } from 'utils/Helper';
import ButtonAction from 'components/Common/ButtonAction';
import Constant from 'utils/Constants';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { errorToast, successToast } from 'utils/Toast';
import { headerPRAtom, servicesItemPr } from 'store/PurchaseRequest/purchaseRequestAtom';
import LookupItems from './Modal/LookupItems';
import IconRequired from 'components/Common/IconRequired';
import CellTable from 'components/Common/CellTable';
import { ButtonAdd } from 'components/Common/Buttons';
import { GrAttachment } from "react-icons/gr";
import { CustomFormItemCheckbox, CustomFormItemDate, CustomFormItemMoney, CustomFormItemNumber } from 'components/Common/CustomFormItem';

// const { Text } = Typography;

export default function ServicesItem(props) {
    const { initForm,
        isView = false,
        propsDrawer = () => { },
        idRowHighlight = null,
        // tableRef,
    } = props
    const [header, setHeader] = useAtom(headerPRAtom);
    const [dataItems, setDataItems] = useAtom(servicesItemPr);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ignoreRowClick, setIgnoreRowClick] = useState(false);
    const [rowHighlight, setRowHighlight] = useState(null);
    const indexPriceRef = useRef(null)

    // const styleSummary = {
    //     colSpan: header?.prTypeId === Constant.PRType.CATALOG ? 7 : 5,
    //     align: 'end',
    // };


    const onChangeInputRow = (field, value, index, filename, filesize) => {
        let datas = [...dataItems];
        let currentData = datas[index];
        currentData[field] = value;

        if (field === 'quantity') {
            let subTotal = value * currentData.price;
            currentData.subTotal = subTotal;

            // Trigger to change the total price services while the user inputs VAT first.
            let tax = initForm.getFieldValue('servicesTax');
            setHeader(prev => ({ ...prev, servicesVatPercentage: tax }));
        }

        if (field === "price") {
            let subTotal = value * currentData.quantity
            currentData.subTotal = subTotal;
            indexPriceRef.current = index
        } else {
            indexPriceRef.current = null
        }

        if (field === "requiredDate") {
            setFieldValues(initForm, `services_requiredDate_${index}`, currentData?.requiredDate ? dayjs(currentData?.requiredDate) : "");
        }

        if (field === 'isDifferentRequiredDate') {
            if (value) {
                currentData.requiredDate = null;
                setFieldValues(initForm, `services_requiredDate_${index}`, "");
            } else {
                if (header.requiredDate) {
                    currentData.requiredDate = header.requiredDate;
                    setFieldValues(initForm, `services_requiredDate_${index}`, currentData?.requiredDate ? dayjs(currentData?.requiredDate) : "");
                }
            }
        }

        // if (field === "attachmentFile") {
        //     currentData.attachmentFile = {
        //         fileOriginalName: filename,
        //         file: value,
        //         itemId: currentData?.itemId
        //     }
        // }

        setDataItems(datas);
    };

    // ITEMS
    const handleAddItem = () => {
        if (header?.prTypeId !== null) {
            setIsModalOpen(true)
        } else {
            errorToast("Please Select PR Type.")
        }
    };

    const handleDeleteItem = (data, index) => {
        let tmpDatas = [...dataItems];
        let newItems = tmpDatas.filter(el => el.itemId !== data?.itemId);
        setDataItems(newItems);
    };

    const handleViewItem = (data, index) => {
        propsDrawer(true, true, data?.itemId, data, Constant.typeItemPR.SERVICES);
    };

    const handleEditItem = (data, index) => {
        propsDrawer(false, true, data?.itemId, data, Constant.typeItemPR.SERVICES);
    };

    const handleOnFocusAndBlur = (ignore) => {
        if (isView) {
            setRowHighlight(rowHighlight)
            setIgnoreRowClick(ignore)
            propsDrawer(true, null, rowHighlight, 0)
        }
    };

    const handleSelectItem = (data) => {
        // Check if item is already added/selected
        const checkAlready = dataItems.some(x => x.itemId === data.itemId);
        if (checkAlready) {
            return errorToast(`The item ${data?.itemName} is already exists in Item List`)
        } else {
            // Add subtotal, quantity, remarks, requiredDate properties because those properties are not already present in the item
            let quantity = 0;
            let requiredDate = header.requiredDate ? header.requiredDate : null
            let newData = {
                sortKey: dataItems.length,
                itemId: data?.itemId,
                itemCode: data?.itemCode,
                itemName: data?.itemName,
                description: data?.description,
                itemCategoryId: data?.itemCategory?.itemCategoryId,
                itemCategoryName: data?.itemCategory?.itemCategoryName,
                vendorId: data?.vendorId,
                vendorName: data?.vendorName,
                quantity: quantity,
                uomId: data?.uom?.uomId,
                uomName: data?.uom?.name,
                uomAllowDecimal: data?.uom?.uomAllowDecimal,
                price: data?.price,
                priceInOc: data?.priceInOc,
                remarks: "",
                currencyId: data?.currency?.currencyId,
                currencyCode: data?.currency?.code,
                contractCatalogItemDetailId: data?.contractCatalogItemDetailId,
                contractCatalogId: data?.contractCatalogId,
                contractCatalogNo: data?.contractCatalogNo,
                subTotal: quantity * data.price,
                attachmentFile: "",
                isDifferentRequiredDate: false,
                requiredDate: requiredDate
                // requiredDate: dayjs().format(Constant.stateDateFormat)
            };

            //sort
            // setDataItems(prev => ([...prev, newData]));
            setDataItems(prev => {
                let newList = [...prev, newData]
                const newDataSort = newList.sort((a, b) => {
                    return b.sortKey - a.sortKey
                });
                return newDataSort
            })
            indexPriceRef.current = null
            return successToast(Constant.message.itemAddSuccess)
        }
    };

    const calculateTotalWithTax = (amountBeforeTax, amountTax) => {
        return amountBeforeTax + amountTax;
    };

    const updateFieldTableValues = (index, data) => {
        setFieldValues(initForm, `services_remarks_${index}`, data.remarks);
        setFieldValues(initForm, `services_requiredDate_${index}`, data?.requiredDate ? dayjs(data?.requiredDate) : "");
        setFieldValues(initForm, `services_quantity_${index}`, data.quantity);
        setFieldValues(initForm, `services_price_${index}`, data.price);
        if (indexPriceRef.current === index) {
            setFieldValues(initForm, `services_price_${index}`, data.price);
        } else {
            setFieldValues(initForm, `services_price_${index}`, formatterAmount(data.price));
        }
        setFieldValues(initForm, `services_isDifferentRequiredDate_${index}`, data.isDifferentRequiredDate);

        // setFieldValues(initForm, `service_attachmentFile_${index}`, data.attachmentFile);
    };

    useEffect(() => {
        // Trigger to change tax of total
        let totalAmountServicesVatPercentage = taxCalculation(header.totalAmountServicesBeforeTax, header?.servicesVatPercentage) || 0;
        setHeader(prev => ({
            ...prev,
            totalAmountServicesVatPercentage: totalAmountServicesVatPercentage,
            totalServicesAmountWithTax: calculateTotalWithTax(header.totalAmountServicesBeforeTax, totalAmountServicesVatPercentage)
        }));
    }, [header?.servicesVatPercentage]);

    useEffect(() => {
        // Trigger to change total amount tax, total before tax
        let sumTotalBeforeTax = 0;

        let newData = [...dataItems]?.map((data, index) => {
            updateFieldTableValues(index, data);
            sumTotalBeforeTax += data.subTotal;
            return sumTotalBeforeTax;
        });

        let amountVAT = taxCalculation(sumTotalBeforeTax, header.servicesVatPercentage);
        setHeader(prev => ({
            ...prev,
            totalAmountServicesVatPercentage: amountVAT,
            totalAmountServicesBeforeTax: sumTotalBeforeTax,
            totalServicesAmountWithTax: calculateTotalWithTax(sumTotalBeforeTax, amountVAT)
        }));
    }, [dataItems]);

    useEffect(() => {
        indexPriceRef.current = null
    }, [header.requiredDate])

    useEffect(() => {
        setRowHighlight(idRowHighlight);
    }, [idRowHighlight]);

    const catalogColumns = [
        {
            title: 'Action',
            key: 'action',
            align: 'left',
            width: "10px",
            className: 'custom-table-edit-row-input',
            render: (_, record, index) => {
                let buttons = !isView ? ['view', 'delete', 'edit'] : ['view'];
                return (
                    <>
                        <ButtonAction
                            buttons={buttons}
                            onView={() => handleViewItem(record)}
                            onDelete={() => handleDeleteItem(record, index)}
                            onEdit={() => handleEditItem(record, index)}
                        />
                    </>
                );
            }
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            className: 'custom-table-edit-row-input',
            width: "250px",
            render: (data, row) => <CellTable data={data} icon={row?.attachmentFile && <GrAttachment />} dataTooltipicon='This data has attachment.' />
        },
        {
            title: 'Catalog No',
            dataIndex: 'contractCatalogNo',
            className: "custom-table-edit-row-input",
            width: "150px",
            isCatalog: true,
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Vendor Name',
            dataIndex: 'vendorName',
            className: "custom-table-edit-row-input",
            width: "150px",
            isCatalog: true,
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Required Date',
            dataIndex: 'requiredDate',
            className: 'custom-table-edit-row-input',
            width: "160px",
            render: (text, record, index) => {
                // setFieldValues(initForm, `services_requiredDate_${index}`, dayjs(record?.requiredDate))
                return (
                    <div style={{ display: 'flex', gap: "10px", alignItems: "baseline" }}>
                        <CustomFormItemCheckbox
                            nameFormItem={'services_isDifferentRequiredDate_' + index}
                            value={record?.isDifferentRequiredDate && "checked"}
                            isView={isView}
                            onChange={(e) => {
                                onChangeInputRow("isDifferentRequiredDate", e.target.checked, index)
                            }}
                            title="Check To input different required date."
                            useInTable={true}
                        />
                        <CustomFormItemDate
                            nameFormItem={'services_requiredDate_' + index}
                            value={record?.requiredDate}
                            onChange={(d, dString) => {
                                onChangeInputRow("requiredDate", dString ? dayjs(dString).format(Constant.stateDateFormat) : "", index)
                            }}
                            disabledDate={d => !d || d.isBefore(dayjs().format(Constant.datePickerFormat))}
                            onFocus={() => handleOnFocusAndBlur(true)}
                            onBlur={() => handleOnFocusAndBlur(false)}
                            isView={isView || !record?.isDifferentRequiredDate}
                            useDatePickerView={!isView}
                            useInTable={true}
                        />
                    </div>
                );
            }
        },
        {
            title: <IconRequired text="Quantity" />,
            dataIndex: 'quantity',
            className: 'custom-table-edit-row-input',
            align: 'right',
            width: "90px",
            render: (text, record, index) => {
                // setFieldValues(initForm, `services_quantity_${index}`, record.quantity)
                return (
                    <Col>
                        <CustomFormItemNumber
                            nameFormItem={'services_quantity_' + index}
                            nameInput={'services_quantity_' + index}
                            placeholder="Please enter quantity"
                            rules={[
                                // { required: true, message: 'Please input Quantity' },
                                {
                                    validator: (_, value) => {
                                        if (value <= 0) {
                                            return Promise.reject(new Error('Quantity must be greater than 0'));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}
                            initForm={initForm}
                            customvalue={record?.quantity}
                            precision={record?.uomAllowDecimal ? 2 : 0}
                            isView={isView}
                            onChange={(e) => onChangeInputRow("quantity", e, index)}
                            onFocus={() => handleOnFocusAndBlur(true)}
                            onBlur={() => handleOnFocusAndBlur(false)}
                            useInTable={true}
                            alignView="right"
                        />
                    </Col>
                );
            }
        },
        {
            title: 'UOM',
            dataIndex: 'uomName',
            className: 'custom-table-edit-row-input',
            width: "80px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: <IconRequired text="Price" />,
            dataIndex: 'price',
            className: "custom-table-edit-row-input",
            align: 'right',
            width: "180px",
            render: (text, record, index) => {
                return (
                    <Col>
                        <CustomFormItemMoney
                            nameFormItem={'services_price_' + index}
                            nameInput={'services_price_' + index}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value <= 0) {
                                            return Promise.reject(new Error('Price must be greater than 0'));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}
                            initForm={initForm}
                            customvalue={record?.price}
                            onChange={(e) => onChangeInputRow("price", e, index)}
                            onFocus={(e) => handleOnFocusAndBlur(true)}
                            onBlur={(e) => handleOnFocusAndBlur(false)}
                            isView={isView || header?.prTypeId === Constant.PRType.CATALOG}
                            useInTable={true}
                            alignView="right"
                        />
                    </Col>
                )
            }
        },
        {
            title: 'Sub Total',
            dataIndex: 'subTotal',
            className: 'custom-table-edit-row-input',
            align: 'right',
            width: "180px",
            render: (subTotal) => {
                if (!subTotal) {
                    return 0;
                }
                return formatterAmount(subTotal);
            }
        },
    ];

    const nonCatalogColumns = [...catalogColumns].filter(x => !x.isCatalog)

    // console.log("services item", dataItems);
    // console.log("selected", selectedItemForView);
    // console.log("ignoreRowClick " + ignoreRowClick);
    // console.log("rowHighlight " + rowHighlight);

    return (
        <>
            <div className="mb-2">
                <p className="font-semibold">Services Item</p>
                {!isView && <Space className='mb-1'>
                    <ButtonAdd type="primary" onClick={() => handleAddItem(true)} icon={null} />
                </Space>
                }

                <CustomAntdTable
                    className='custom-table-edit-row table-services-item'
                    rowKey={"itemId"}
                    columns={header?.prTypeId === Constant.PRType.CATALOG ? catalogColumns : nonCatalogColumns}
                    dataSource={dataItems}
                    pagination={false}
                    ignoreRowClick={isView ? ignoreRowClick : true}
                    idDataHighlight={rowHighlight}
                    rowClick={isView ? (data, index) => handleViewItem(data, index) : () => { }}
                // summary={pageData => {
                //     return (
                //         dataItems.length > 0 && <>
                //             <Table.Summary.Row>
                //                 <Table.Summary.Cell {...styleSummary}></Table.Summary.Cell>
                //                 <Table.Summary.Cell className='label'>Total</Table.Summary.Cell>
                //                 <Table.Summary.Cell align='right' className="summary-item">
                //                     <Text>{formatterAmount(header.totalAmountServicesBeforeTax)}</Text>
                //                 </Table.Summary.Cell>
                //             </Table.Summary.Row>

                //             <Table.Summary.Row>
                //                 <Table.Summary.Cell {...styleSummary}></Table.Summary.Cell>
                //                 <Table.Summary.Cell className='label vat-summary'>
                //                     <CustomFormItemNumber
                //                         nameFormItem={'servicesTax'}
                //                         label='VAT'
                //                         nameInput={'servicesTax'}
                //                         className='services-item-tax'
                //                         rules={[
                //                             {
                //                                 required: true
                //                             },
                //                         ]}
                //                         max="100"
                //                         min="0"
                //                         initForm={initForm}
                //                         customvalue={header.servicesVatPercentage}
                //                         isView={isView}
                //                         onChange={(val) => {
                //                             setHeader(prev => ({
                //                                 ...prev,
                //                                 servicesVatPercentage: val,
                //                             }));
                //                         }}
                //                         wrapperCol={{ xs: 16 }}
                //                         labelCol={{ xs: 8 }}
                //                         alignView="right"
                //                     />
                //                 </Table.Summary.Cell>
                //                 <Table.Summary.Cell align='right' className="summary-item">
                //                     <Text>{formatterAmount(header.totalAmountServicesVatPercentage)}</Text>
                //                 </Table.Summary.Cell>
                //             </Table.Summary.Row>

                //             <Table.Summary.Row>
                //                 <Table.Summary.Cell {...styleSummary}></Table.Summary.Cell>
                //                 <Table.Summary.Cell className='label'>
                //                     <strong>Total (Include Tax)</strong>
                //                 </Table.Summary.Cell>
                //                 <Table.Summary.Cell align='right' className="summary-item total-include-tax">
                //                     <Text>{formatterAmount(calculateTotalWithTax(header.totalAmountServicesBeforeTax, header.totalAmountServicesVatPercentage))}</Text>
                //                 </Table.Summary.Cell>
                //             </Table.Summary.Row>
                //         </>
                //     );
                // }}
                />
            </div>

            {isModalOpen &&
                <LookupItems onClose={() => setIsModalOpen(false)} onSelect={handleSelectItem} prTypeId={header?.prTypeId} itemType={Constant.ItemType.SERVICES} />
            }
        </>
    );
}
