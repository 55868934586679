import { Card, Col, Divider, Form, Input, Row, Space } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import BaseDrawerRole from 'components/MasterData/Role/BaseDrawerRole';
import ButtonAction from 'components/Common/ButtonAction';
import { ButtonAdd } from 'components/Common/Buttons';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import useRoleRepo from 'services/repo/useRoleRepo';
import { dismissToast, loadingToastId } from 'utils/Toast';
import Constant from 'utils/Constants';
import { sorterTable } from 'utils/Helper';
import useAccessPermissionHook from 'hooks/useAccessPermissionHook';
import { PRIVILEGE_ID } from 'utils/AccessPermissionConfig';
import useIsMobileHooks from 'hooks/useIsMobileHooks';
import { ButtonAdvanceFilter } from 'components/Common/ButtonAdvanceFilter';
import SkeletonPage from 'components/Common/SkeletonPage';
import Error403 from 'components/Error/Error403';
import BaseAdvanceFilter from 'components/CustomDrawer/BaseAdvanceFilter';
import CellTable from 'components/Common/CellTable';
import TagActiveInCell from 'components/Common/TagActiveInCell';

export default function Roles() {
    useTopbarTitleHooks("Master Data Role");
    const { isLoading: isLoadingList, getRoleList } = useRoleRepo(false);
    const { isLoading: isLoadingGetRoleById, getRoleById } = useRoleRepo(false);

    const isMobile = useIsMobileHooks()
    const [form] = Form.useForm()
    const [data, setData] = useState();
    const [totalData, setTotalData] = useState(0)
    const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false)
    const [isViewDrawer, setIsViewDrawer] = useState(false)
    const [dataEditDrawer, setDataEditDrawer] = useState(null)

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [idViewData, setIdViewData] = useState(null)

    const { isHasPrivilege } = useAccessPermissionHook()
    const isLoadingPrivilege = useRef(true)
    const hasPrivilegePage = useRef(true)
    const [isLoadedPrivilege, setIsLoadedPrivilege] = useState(false)
    const [showButtonAdd, setShowButtonAdd] = useState(false)

    const [filters, setFilters] = useState({
        roleName: "",
        isActive: null,
    })

    const [tableParams, setTableParams] = useState({
        pageNumber: 1,
        pageSize: 10,
        filter: { ...filters },
        orderBy: "roleName",
        isAscending: true
    });

    const onSearchClick = () => {
        onSearch()
        setIsOpenAdvanceFilter(false)
    }

    const onCloseDrawer = () => {
        setIsOpenDrawer(false);
        setDataEditDrawer(null)
        setIdViewData(null)
    };

    const fetchData = async () => {
        const payload = {
            ...tableParams
        }
        const res = await getRoleList(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setData(res?.data?.listData)
            setTotalData(res?.data?.totalRecords)
        }
    };

    const onSearch = () => {
        setTableParams({
            ...tableParams,
            pageNumber: 1,
            filter: {
                ...filters
            }
        })
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams(prevParams => ({
            ...prevParams,
            pageNumber: pagination?.current,
            pageSize: pagination?.pageSize,
            orderBy: sorter?.field || prevParams?.orderBy,
            isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
        }));

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams?.pageSize) {
            setData([]);
        }
    };

    const handleAdd = () => {
        setDataEditDrawer(null)
        setIsViewDrawer(false)
        setIsOpenDrawer(true)
    }

    const handleRowClick = async (data) => {
        let id = data?.roleId
        const payload = {
            roleId: id
        }
        loadingToastId(Constant.message.loading, id)
        const res = await getRoleById(payload)
        if (res?.isValid && res?.statusCode === 200) {
            dismissToast(id)
            setIsViewDrawer(true)
            setIsOpenDrawer(true)
            setDataEditDrawer(res?.data)
            setIdViewData(res.data.roleId)
        }
    }

    const handleEdit = async (data) => {
        let id = data?.roleId
        const payload = {
            roleId: id
        }
        loadingToastId(Constant.message.loading, id)
        const res = await getRoleById(payload)
        if (res?.isValid && res?.statusCode === 200) {
            dismissToast(id)
            setIsViewDrawer(false)
            setIsOpenDrawer(true)
            setDataEditDrawer(res?.data)
        }
    }

    const fetchCheckPrivilege = async () => {
        let pvlgAddEdit = await isHasPrivilege(PRIVILEGE_ID.AddEditRole)
        let pvlgAccess = await isHasPrivilege(PRIVILEGE_ID.Role)
        Promise.all([pvlgAccess, pvlgAddEdit]).then(
            ([pvlgAccessValue, pvlgAddEditValue]) => {
                setShowButtonAdd(pvlgAddEditValue)
                isLoadingPrivilege.current = false
                if (pvlgAccessValue) {
                    hasPrivilegePage.current = true;
                    setIsLoadedPrivilege(true)
                    fetchData();
                } else {
                    hasPrivilegePage.current = false
                }
                dismissToast(PRIVILEGE_ID.AddEditRole);
            });
    }

    useEffect(() => {
        fetchCheckPrivilege()
    }, [])

    useEffect(() => {
        if (isLoadedPrivilege) {
            fetchData();
        }
    }, [JSON.stringify(tableParams)]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (isLoadedPrivilege && !isMobile) {
                onSearch()
            }
        }, Constant.delayFilter);
        return () => clearTimeout(timeOutId);
    }, [filters]);

    const filterUI = () => {
        return (
            <Form layout="horizontal" form={form}>
                <Row gutter={16}>
                    <Col xs={24} sm={10} md={7}>
                        <Form.Item name='roleNameFilter' label="Filter By">
                            <Input
                                allowClear
                                placeholder={"role name"}
                                onChange={e => {
                                    setFilters(prev => ({
                                        ...prev,
                                        roleName: e.target.value
                                    }))
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    const columns = [
        {
            title: 'Action',
            key: 'action',
            width: "60px",
            render: (_, record) => (
                <>
                    <ButtonAction
                        buttons={['edit', 'view']}
                        onEdit={handleEdit}
                        onView={handleRowClick}
                        data={record}
                    />
                </>
            )
        },
        {
            title: 'Role Name',
            dataIndex: 'roleName',
            sorter: true,
            width: "200px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: true,
            width: "300px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Is Active',
            dataIndex: 'isActive',
            width: "60px",
            render: (isActive) => (
                <>
                    <TagActiveInCell isActive={isActive} />
                </>
            ),
        },
    ];

    return (
        <>
            {hasPrivilegePage.current ?
                <Space direction="vertical" size="large" className='space-vertical-flex'>
                    <Card size="big" bordered={false}>
                        {isLoadingPrivilege.current ? <SkeletonPage /> :
                            <>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={20} xl={18}>
                                        {showButtonAdd && <ButtonAdd onClick={handleAdd} />}
                                    </Col>
                                </Row>

                                <Divider dashed className='mb-1 mt-1' />

                                {isMobile ?
                                    <ButtonAdvanceFilter onClick={() => setIsOpenAdvanceFilter(true)} />
                                    : filterUI()
                                }

                                <CustomAntdTable
                                    columns={columns}
                                    dataSource={data}
                                    rowKey={"roleId"}
                                    tableParam={tableParams}
                                    totalData={totalData}
                                    loading={isLoadingList}
                                    onChange={handleTableChange}
                                    rowClick={handleRowClick}
                                    isOpenDrawer={isOpenDrawer}
                                    idDataHighlight={idViewData}
                                />
                            </>
                        }
                    </Card>
                </Space> : (<Error403 />)}

            {isOpenDrawer && (
                <BaseDrawerRole
                    onClose={onCloseDrawer}
                    isView={isViewDrawer}
                    dataEdit={dataEditDrawer}
                    loading={isLoadingGetRoleById}
                    onRefreshList={fetchData}
                    setModeView={(d) => setIsViewDrawer(d)}
                    isAddEditPrivileged={showButtonAdd}
                />
            )}

            {isOpenAdvanceFilter &&
                <BaseAdvanceFilter
                    onClose={() => setIsOpenAdvanceFilter(false)}
                    onSearchClick={onSearchClick}>
                    {filterUI()}
                </BaseAdvanceFilter>}
        </>
    )
}
