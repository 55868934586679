import { Button } from 'antd';
import React from 'react'
import { SearchOutlined } from "@ant-design/icons";
import Search from 'antd/es/input/Search';

export default function InputLookup(props) {
    const { className = "", disableiconsearch = false, placeholder = "search...", onSearch = () => { }, value = "" } = props
    // debugger
    return (
        <Search
            value={value}
            onSearch={onSearch}
            className={`eproc-input-lookup ${className}`}
            placeholder={placeholder}
            disabled
            enterButton={<Button type="primary" disabled={disableiconsearch} icon={<SearchOutlined />} />}
        />
    )
}
