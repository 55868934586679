/**
 * Jun
 * 2023
 * 
 * refactor:
 * Aris - 31/8/23
 * 
 * Updated: March 1st 2024
 * Updated by: 🫰 Aris bimas n
 */

import { Button, Card, Col, Form, Row } from 'antd'
import { CustomFormItemSelectDropdown, CustomFormItemSwitch, CustomFormItemText } from 'components/Common/CustomFormItem'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import useDepartmentRepo from 'services/repo/useDepartmentRepo'
import useDivisionRepo from 'services/repo/useDivisionRepo'
import Constant from 'utils/Constants'
import CustomAlert from 'utils/CustomAlert'
import DevLog from 'utils/DevLog'
import { dismissToast, loadingToastId, successToast } from 'utils/Toast'

const init_field = {
  departmentId: null,
  departmentCode: "",
  departmentName: "",
  divisionId: null,
  isActive: true,
  lastModifiedAt: '',
}

export default function BaseDrawerDepartment(props) {
  const {
    onClose,
    dataEdit = null,
    onRefreshList,
    isView = false,
    loading = false,
    setModeView = () => { },
    isAddEditPrivileged,
    handleUnhighlight = () => { },
  } = props
  const [form] = Form.useForm()
  const [dataField, setDataField] = useState(init_field)
  const { isLoading: isLoadingCreate, createDepartment } = useDepartmentRepo(false);
  const { isLoading: isLoadingUpdate, updateDepartment } = useDepartmentRepo(false);
  const { isLoading: isLoadingDivList, getDivisionList } = useDivisionRepo(false);

  //* DIVISION DROPDOWN
  const [divOptions, setDivOptions] = useState([])
  const fetchDataDiv = async () => {
    const payload = {
      pageNumber: 1,
      pageSize: 0,
      filter: {
        divisionId: null,
        divisionCode: "",
        divisionName: "",
        isActive: true,
        previousDivisionId: dataEdit?.division?.divisionId
      },
      orderBy: "divisionCode",
      isAscending: true
    }

    const res = await getDivisionList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setDivOptions(() => {
        return res?.data?.listData.map(item => {
          return { label: item?.divisionName, value: item?.divisionId }
        })
      })
    }
  }

  const handleCloseDrawer = () => {
    form.resetFields()
    setDataField(init_field)
    setModeView(true)
    onClose()
  }

  const onSaveCreate = async () => {
    let payload = {
      departmentCode: dataField.departmentCode,
      departmentName: dataField.departmentName,
      divisionId: dataField.divisionId,
      isActive: dataField.isActive
    }
    loadingToastId(Constant.message.savingData, payload?.departmentCode)
    let response = await createDepartment(payload)
    dismissToast(payload.departmentCode);
    if (response?.isValid && response?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const onSaveEdit = async () => {
    let payload = {
      departmentId: dataField.departmentId,
      departmentCode: dataField.departmentCode,
      departmentName: dataField.departmentName,
      divisionId: dataField.divisionId,
      isActive: dataField.isActive,
      lastModifiedAt: dataField.lastModifiedAt,
    }
    loadingToastId(Constant.message.savingData, payload?.departmentId)
    let response = await updateDepartment(payload)
    dismissToast(payload.departmentId);
    if (response?.isValid && response?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const handleFormSubmit = () => {
    CustomAlert.confirm({
      title: "Save Department!",
      description: `Are you sure you want to save this data?`,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "primary",
      onConfirmButton: () => {
        form.validateFields()
          .then((values) => {
            if (dataEdit !== null) {
              onSaveEdit()
            } else {
              onSaveCreate()
            }
          })
          .catch((errorInfo) => {
            DevLog("err", errorInfo);
          });
      }
    });
  };

  useEffect(() => {
    setModeView(isView)
  }, [isView])

  useEffect(() => {
    if (dataEdit !== null) {
      setDataField(dataEdit)
      form.setFieldsValue({
        departmentCode: dataEdit?.departmentCode,
        departmentName: dataEdit?.departmentName,
        divisionName: dataEdit?.divisionId,
        isActive: dataEdit?.isActive,
      })
    } else {
      setDataField(init_field)
      form.setFieldsValue({
        departmentCode: init_field.departmentCode,
        departmentName: init_field.departmentName,
        divisionName: init_field.divisionId,
        isActive: init_field.isActive,
      })
      handleUnhighlight()
    }
    fetchDataDiv()
  }, [dataEdit])

  return (
    <>
      <BaseDrawer
        title={`${isView ? "View" : dataEdit === null ? "Add" : "Edit"} Department`}
        placement="right"
        size="large"
        onClose={handleCloseDrawer}
        open={true}
        loading={loading}
        extras={
          <>
            {(isAddEditPrivileged && isView) &&
              (<Button
                type="primary"
                onClick={() => setModeView(false)}>
                Edit
              </Button>)
            }
            {(isAddEditPrivileged && !isView) &&
              (<Button type="primary" onClick={handleFormSubmit} htmlType='submit'
                loading={isLoadingCreate || isLoadingUpdate}>
                Submit
              </Button>)
            }
          </>
        }>
        <Card>
          <Form
            form={form}
            layout="vertical">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="departmentCode"
                  label="Code"
                  placeholder="Fill in Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.departmentCode}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      departmentCode: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="departmentName"
                  label="Name"
                  placeholder="Fill in Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.departmentName}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      departmentName: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSelectDropdown
                  nameFormItem="divisionName"
                  label='Division'
                  placeholder="Select Division"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  showSearch={true}
                  value={dataField?.divisionId}
                  loading={isLoadingDivList}
                  options={divOptions}
                  isView={isView}
                  onChange={value => {
                    setDataField(prev =>
                      ({ ...prev, divisionId: value || null })
                    )
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSwitch
                  nameFormItem="isActive"
                  label="Is Active"
                  value={dataField?.isActive}
                  isView={isView}
                  onChange={(checked) => {
                    setDataField(prev =>
                      ({ ...prev, isActive: checked }))
                  }}
                />
              </Col>

            </Row>
          </Form>
        </Card>
      </BaseDrawer>
    </>
  )
}
