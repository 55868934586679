import { Form, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import { useAtom } from 'jotai';
import Constant from 'utils/Constants';
import { useNavigate, useParams } from 'react-router-dom';
import { APP_ROUTE_PREFIX, RouteConstant } from 'routes/routesConstant';
import { attachmentsROGS, goodsItemROGS, headerROGSAtom, servicesItemROGS } from 'store/ROGS/rogsAtom';
import { checkErrorFormToOpenCollapse, validateIsLastRogsTerm } from 'utils/Helper';
import SectionHeaderRoGS from 'components/RoGSV2/SectionHeaderRoGS';
import SectionItemsRoGS from 'components/RoGSV2/SectionItemsRoGS';
import useRoGSRepo from 'services/repo/useRoGSRepo';
import CustomAlert from 'utils/CustomAlert';
import { dismissToast, errorToast, loadingToastId, successToast } from 'utils/Toast';
import useAccessPermissionHook from 'hooks/useAccessPermissionHook';
import { PRIVILEGE_ID } from 'utils/AccessPermissionConfig';
import Error403 from 'components/Error/Error403';
import SkeletonPage from 'components/Common/SkeletonPage';
import { ButtonBack, ButtonDiscard, ButtonSaveDraft, ButtonSubmit } from 'components/Common/Buttons';
import useFormROGSHook from 'hooks/useFormROGSHook';
import BaseSectionAttachment from 'components/RoGSV2/BaseSectionAttachment';
import CustomCollapse from 'components/Common/CustomCollapse';

export default function CreateRoGS() {
    const { rogsId } = useParams();
    useTopbarTitleHooks(`${rogsId ? 'Edit' : 'Create'} Receive of Goods / Service`);
    const { isLoading: isLoadingSaveDraft, saveAsDraft, updateAsDraft } = useRoGSRepo(false);
    const { isLoading: isLoadingSubmit, submit, submitFromDraft } = useRoGSRepo(false);
    const { isLoading: isLoadingGetRoGSById, getRoGSById } = useRoGSRepo(false);
    const { isLoading: isLoadingDiscardDraft, discardDraft } = useRoGSRepo(false);
    const { isAllowEditDraftRoGS } = useRoGSRepo(false);
    const { isHasPrivilege } = useAccessPermissionHook()
    const { setDataFormROGS, clearFormAndAtomROGS, generateItemsROGS } = useFormROGSHook()

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState(['1']);
    const [errorListItem, setErrorListItem] = useState(false)
    const [messageErrorItems, setMessageErrorItems] = useState("")
    const [dataHeader,] = useAtom(headerROGSAtom);
    const [dataItemGoods,] = useAtom(goodsItemROGS)
    const [dataItemServices,] = useAtom(servicesItemROGS)
    const [dataAttachments,] = useAtom(attachmentsROGS)

    const isLoadingPrivilege = useRef(true)
    const hasPrivilegePage = useRef(true)

    const fetchGetRoGSById = async (id) => {
        loadingToastId(Constant.message.loading, id)
        let response = await getRoGSById({ rogsId: id });
        dismissToast(id)
        if (response?.isValid && response?.statusCode === 200) {
            let data = response?.data;
            setDataFormROGS(form, data)
        } else {
            // if (response?.statusCode === 500) {
            //     dismissAllToast();
            //     navigate(APP_ROUTE_PREFIX + "/404", { replace: true })
            // }
        }
    };

    const fetchCheckIsAllowEdit = async (id) => {
        loadingToastId(Constant.message.loading, id)
        let response = await isAllowEditDraftRoGS({ rogsId: id });
        dismissToast(id)
        if (response?.isValid && response?.statusCode === 200) {
            if (response?.data?.isAllow) {
                fetchGetRoGSById(id)
            } else {
                hasPrivilegePage.current = false
            }
        }
        isLoadingPrivilege.current = false
    }

    function handleClick(key) {
        setActiveKey(key);
    }

    const doSaveAsDraft = async () => {
        let payloadData = {
            poId: dataHeader.poId,
            topTerm: dataHeader.topTerm,
            expectedDeliveryDate: dataHeader.expectedDeliveryDate,
            actualDeliveryDate: dataHeader.actualDeliveryDate,
            rescheduleDate: dataHeader.rescheduleDate,
            vendorId: dataHeader.vendorId,
            vendorName: dataHeader.vendorName,
            doBastNo: dataHeader.doBastNo,
            remarks: dataHeader.remarks,
            isLastRogsTerm: dataHeader?.isLastRogsTerm,
            receiverId: dataHeader.receiverId,
            receiverName: dataHeader.receiverName,
            lastModifiedAt: dataHeader.lastModifiedAt,
            listGoodsDetails: generateItemsROGS(dataItemGoods, Constant.ItemType.GOODS),
            listServiceDetails: generateItemsROGS(dataItemServices, Constant.ItemType.SERVICES),
            listAttachmentDetails: [...dataAttachments].filter(obj => obj.hasOwnProperty('rogsAttachmentDetailId')),
        };

        if (rogsId) {
            payloadData = { ...payloadData, rogsId: rogsId };
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(payloadData));
        for (let i = 0; i < dataAttachments?.length; i++) {
            if (!dataAttachments[i]?.rogsAttachmentDetailId) {
                formData.append("AttachmentHeader", dataAttachments[i]?.file);
            }
        }
        loadingToastId(Constant.message.savingData, formData);
        let response;

        if (rogsId) {
            response = await updateAsDraft(formData);
        } else {
            response = await saveAsDraft(formData);
        }

        dismissToast(formData);

        if (response?.isValid && response?.statusCode === 200) {
            successToast(Constant.message.saveDataSuccess);
            navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.rogs.index, { replace: true });
        }
    }

    const handleSaveDraft = () => {
        CustomAlert.confirm({
            title: "Save as Draft Receive of Goods / Service!",
            description: `Are you sure you want to save as a draft?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => {
                form.validateFields(['poNo'])
                    .then((values) => {
                        doSaveAsDraft()
                    })
                    .catch((errorInfo) => {
                        setActiveKey(prev => ([...new Set([...prev, '1'])]));
                        errorToast(Constant.message.errorForm)
                    })
            },
        });
    };

    const doSubmit = async () => {
        let payloadData = {
            poId: dataHeader.poId,
            topTerm: dataHeader.topTerm,
            expectedDeliveryDate: dataHeader.expectedDeliveryDate,
            actualDeliveryDate: dataHeader.actualDeliveryDate,
            rescheduleDate: dataHeader.rescheduleDate,
            vendorId: dataHeader.vendorId,
            vendorName: dataHeader.vendorName,
            doBastNo: dataHeader.doBastNo,
            remarks: dataHeader.remarks,
            isLastRogsTerm: dataHeader?.isLastRogsTerm,
            receiverId: dataHeader.receiverId,
            receiverName: dataHeader.receiverName,
            lastModifiedAt: dataHeader.lastModifiedAt,
            listGoodsDetails: generateItemsROGS(dataItemGoods, Constant.ItemType.GOODS),
            listServiceDetails: generateItemsROGS(dataItemServices, Constant.ItemType.SERVICES),
            listAttachmentDetails: [...dataAttachments].filter(obj => obj.hasOwnProperty('rogsAttachmentDetailId')),
        };

        if (rogsId) {
            payloadData = { ...payloadData, rogsId: rogsId };
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(payloadData));
        for (let i = 0; i < dataAttachments?.length; i++) {
            if (!dataAttachments[i]?.rogsAttachmentDetailId) {
                formData.append("AttachmentHeader", dataAttachments[i]?.file);
            }
        }

        loadingToastId(Constant.message.savingData, formData);
        let response;

        if (rogsId) {
            response = await submitFromDraft(formData);
        } else {
            response = await submit(formData);
        }

        dismissToast(formData);

        if (response?.isValid && response?.statusCode === 200) {
            successToast(Constant.message.saveDataSuccess);
            navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.rogs.index, { replace: true });
        }
    }

    const validateItems = () => {
        if (dataItemGoods.length <= 0 && dataItemServices.length <= 0) {
            setMessageErrorItems("Items can not be empty")
            setErrorListItem(true)
            return false
        } else {
            setErrorListItem(false)
        }

        let isError = true
        const items = [...dataItemGoods, ...dataItemServices];
        items.forEach(item => {
            if (item.qtyReceived > 0 && isError) {
                isError = false
            }
        });

        if (isError) {
            setMessageErrorItems("Please ensure at least one item has a received quantity greater than 0.")
            setErrorListItem(true)
            return false
        } else {
            setErrorListItem(false)
        }
        return true
    }

    const validateSubmit = (event) => {
        form.validateFields()
            .then((values) => {
                if (validateItems()) {
                    let fieldsError = form.getFieldsError()
                    const isLastRogsTerm = validateIsLastRogsTerm(fieldsError, dataItemGoods, dataItemServices)
                    // debugger
                    if (!isLastRogsTerm) {
                        // alert('ok')
                        doSubmit()
                        // console.log("submit");
                    } else {
                        // event.preventDefault()
                        return CustomAlert.confirm({
                            title: "Confirmation!",
                            description: `This is the last RoGS, but the quantity received of the item "${isLastRogsTerm}" is not the same as the quantity expected, are you sure you want to continue?`,
                            confirmButtonText: "Yes",
                            type: "primary",
                            onConfirmButton: () => {
                                // alert('ok')
                                doSubmit()
                                // console.log("submit");
                            }
                        });
                    }
                } else {
                    errorToast(Constant.message.errorForm)
                }
            })
            .catch((errorInfo) => {
                errorToast(Constant.message.errorForm)
                if (checkErrorFormToOpenCollapse(errorInfo?.errorFields, ["rogsNo", "receivedDate"])) {
                    setActiveKey(prev => ([...new Set([...prev, '1'])]));
                }
                if (!validateItems()) {
                    setActiveKey(prev => ([...new Set([...prev, '2'])]));
                }
            });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        CustomAlert.confirm({
            title: "Submit Receive of Goods / Service!",
            description: `Are you sure you want to submit this data?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => validateSubmit(e)
        });
    };

    const doDiscardDraft = async () => {
        CustomAlert.confirm({
            title: "Discard Receive of Goods / Service!",
            description: `Are you sure you want to discard this data?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: async () => {

                loadingToastId(Constant.message.savingData, rogsId);
                let response = await discardDraft({ rogsId: rogsId });
                dismissToast(rogsId)
                if (response?.isValid && response?.statusCode === 200) {
                    navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.rogs.index, { replace: true })
                    successToast("Receive of Goods / Service has been discarded")
                }
            }
        });
    };

    const checkPrivilege = async () => {
        loadingToastId(Constant.message.loading, PRIVILEGE_ID.CreateRoGS)
        let CreateRoGS = await isHasPrivilege(PRIVILEGE_ID.CreateRoGS)
        if (!CreateRoGS) hasPrivilegePage.current = false
        isLoadingPrivilege.current = false
        dismissToast(PRIVILEGE_ID.CreateRoGS)
    }

    useEffect(() => {
        if (rogsId) {
            fetchCheckIsAllowEdit(rogsId)
        } else {
            checkPrivilege()
        }
    }, [rogsId])

    useEffect(() => {
        clearFormAndAtomROGS(form, true, true)
        return () => {
            clearFormAndAtomROGS(form, true, true)
        };
    }, []);

    return (
        hasPrivilegePage.current ?
            <Space direction="vertical" size="small" className='wrap-rogs-form space-vertical-flex'>
                {(isLoadingPrivilege.current || isLoadingGetRoGSById) ? <SkeletonPage />
                    : <Form
                        name="form-create-rogs"
                        form={form}
                        layout='vertical'
                    >
                        <CustomCollapse
                            className="rogs-form-collapse"
                            onChange={handleClick}
                            activeKey={activeKey}
                            items={[
                                {
                                    key: 1,
                                    label: "Receive of Goods / Service Header",
                                    forceRender: true,
                                    children: <SectionHeaderRoGS initForm={form} isView={false} />
                                },
                                {
                                    key: 2,
                                    label: errorListItem ? <div>Items | <span className="error-message">{messageErrorItems}</span></div> : "Items",
                                    forceRender: true,
                                    className: "rogs-form-collapse-panel-items",
                                    children: <SectionItemsRoGS initForm={form} isView={false} />
                                },
                                {
                                    key: 3,
                                    label: "Attachments",
                                    forceRender: true,
                                    children: <BaseSectionAttachment initForm={form} isView={false}
                                    />
                                }
                            ]}
                        />

                        <div style={{ display: "flex", gap: "10px", margin: "10px 0" }}>
                            <ButtonBack
                                onClick={() => navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.rogs.index)}
                                disabled={isLoadingSaveDraft || isLoadingGetRoGSById || isLoadingSubmit || isLoadingDiscardDraft} />
                            <ButtonSaveDraft
                                onClick={handleSaveDraft}
                                disabled={isLoadingGetRoGSById || isLoadingSubmit || isLoadingDiscardDraft}
                                loading={isLoadingSaveDraft} />
                            <ButtonSubmit
                                onClick={handleFormSubmit}
                                loading={isLoadingSubmit}
                                disabled={isLoadingSaveDraft || isLoadingGetRoGSById || isLoadingDiscardDraft} />
                            {dataHeader?.isDraft && <ButtonDiscard
                                onClick={doDiscardDraft}
                                loading={isLoadingDiscardDraft}
                                disabled={isLoadingSaveDraft || isLoadingGetRoGSById || isLoadingSubmit} />
                            }
                        </div>
                    </Form>}
            </Space>
            : <Error403 />
    );
}

