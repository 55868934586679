import React, { useEffect, useState } from 'react'
import { Menu, Skeleton } from 'antd';
import LogoBrand from 'assets/img/LOGO-FULL-LIGHT.png'
import useUserRepo from 'services/repo/useUserRepo';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { APP_ROUTE_PREFIX, RouteConstant } from 'routes/routesConstant';
import { toggleSideMenu } from 'utils/Helper';
import BurgerMenu from 'assets/img/BurgerMenu';
import { TbShoppingCartPlus, TbShoppingCartSearch, TbShoppingCartQuestion, TbShoppingCartStar, TbShoppingCartCopy, TbShoppingCartFilled, TbShoppingCartDollar, TbDatabaseCog, TbReport, TbClipboardData } from 'react-icons/tb';
import Constant from 'utils/Constants';
import useIsMobileHooks from 'hooks/useIsMobileHooks';

const SIZE_ICON = "1.4em";

const generateIcon = (iconString) => {
  switch (iconString) {
    case Constant.MenuIconString.Dashboard:
      return <TbClipboardData size={SIZE_ICON} />
    case Constant.MenuIconString.PR:
      return <TbShoppingCartPlus size={SIZE_ICON} />
    case Constant.MenuIconString.RFQ:
      return <TbShoppingCartSearch size={SIZE_ICON} />
    case Constant.MenuIconString.VQ:
      return <TbShoppingCartQuestion size={SIZE_ICON} />
    case Constant.MenuIconString.VS:
      return <TbShoppingCartStar size={SIZE_ICON} />
    case Constant.MenuIconString.PO:
      return <TbShoppingCartCopy size={SIZE_ICON} />
    case Constant.MenuIconString.ROGS:
      return <TbShoppingCartFilled size={SIZE_ICON} />
    case Constant.MenuIconString.Invoice:
      return <TbShoppingCartDollar size={SIZE_ICON} />
    case Constant.MenuIconString.Report:
      return <TbReport size={SIZE_ICON} />
    case Constant.MenuIconString.Master:
      return <TbDatabaseCog size={SIZE_ICON} />
    default:
      return ""
  }
}

const getItem = (label, key, icon, children) => {
  return {
    key,
    icon: generateIcon(icon),
    children,
    label,
  };
}

const navLinkItem = (label, url) => {
  return <NavLink to={url}>
    {(props) => {
      const { isActive } = props
      return <div className={`wrapper-label ${isActive ? "menu-active" : ""}`}>{label}</div>
    }}
  </NavLink>
}

const buildChildren = (childs) => {
  return childs?.map(item => {
    return getItem(navLinkItem(item?.menuName, APP_ROUTE_PREFIX + item?.menuUrl), item?.menuUrl)
    // return getItem(navLinkItem(item?.label, APP_ROUTE_PREFIX + item?.url), item?.url)
  })
}

export default function LeftSidebar() {

  const { isLoading: isLoadingMenu, getMenu } = useUserRepo(false, false)
  const [menus, setMenus] = useState([])
  const isMobile = useIsMobileHooks()

  const fetchGetMenu = async () => {
    const response = await getMenu()
    if (response?.isValid && response?.statusCode === 200) {
      let menus = response?.data?.children
      let newMenu = menus?.map((item, i) => {
        if (item?.children.length > 0) {
          return getItem(item?.menuName, APP_ROUTE_PREFIX + item?.menuUrl, item?.menuIcon, buildChildren(item?.children))
        } else {
          return getItem(navLinkItem(item?.menuName, APP_ROUTE_PREFIX + item?.menuUrl), APP_ROUTE_PREFIX + item?.menuUrl, item?.menuIcon)
        }
      })
      setMenus(newMenu)
    }
  }

  useEffect(() => {
    fetchGetMenu()
  }, [])

  /**
   * when child menu active and expand the parent
   * https://github.com/ant-design/ant-design/issues/7612
   */
  const [openKeys, setOpenKeys] = useState(null);
  const loc = useLocation()
  useEffect(() => {
    if (menus.length > 0) {
      let check = !menus.find((item) => APP_ROUTE_PREFIX + item.key === loc.pathname)
      if (check) {

        const currentSubItem = menus.find((item) => item.children?.find((itemChild) => {
          let test = loc.pathname?.includes(item?.key)
          // debugger
          return test
        }));
        // debugger
        if (currentSubItem) {
          // debugger
          setOpenKeys([currentSubItem.key])
        } else setOpenKeys([])
        return;
      }
      setOpenKeys([]);
    }
  }, [menus, loc.pathname]);
  /* */

  return (
    <aside className='eproc-sidebar'>
      <div className='brand-container'>
        <Link to={APP_ROUTE_PREFIX + "/" + RouteConstant.dashboard}>
          <img src={LogoBrand} alt='Brand Logo' />
        </Link>
        <div className='mobile-wrapper-burger'>
          <div className='wrapper-burger' onClick={toggleSideMenu}> <BurgerMenu color='#fff' /></div>
        </div>
      </div>
      <nav className='nav-container'>
        {isLoadingMenu ? <Skeleton active paragraph={{
          rows: 4,
        }} />
          : (
            <>
              {/* <div className="logo"><h1>{Constant.brandName}</h1></div> */}
              {openKeys && (
                <Menu
                  theme="dark"
                  mode="inline"
                  // inlineIndent={13}
                  style={{
                    width: '100%',
                  }}
                  defaultSelectedKeys={[loc.pathname]}
                  selectedKeys={[loc.pathname]}
                  {...(openKeys && { defaultOpenKeys: openKeys })}
                  items={menus}
                  onClick={() => {
                    isMobile && toggleSideMenu()
                  }}
                />
              )}
            </>
          )}
      </nav>

      {/* <button type='button' className='mobile-open-sidebar' onClick={toggleSidebar}>
        <span className='sidebar-hide'><MenuOutlined /> </span>
        <span className='sidebar-show'><CloseOutlined /></span>
      </button> */}
    </aside>
  )
}
