import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import { useAtom } from 'jotai';
import { goodsItemPr, headerPRAtom, servicesItemPr } from 'store/PurchaseRequest/purchaseRequestAtom';
import dayjs from 'dayjs';
import Constant from 'utils/Constants';
import { setFieldValues } from 'utils/Helper';
import CustomAlert from 'utils/CustomAlert';
import usePRRepo from 'services/repo/usePRRepo';
import { CustomFormItemDate, CustomFormItemSelectDropdown, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem';

const styleParentCol = {
    className: "col-effect-drawer",
}

export default function SectionHeaderPR({ initForm, isView = false }) {
    const { isLoading: isLoadingGetListCostCenter, getListCostCenter } = usePRRepo(false);

    const [dataField, setDataField] = useAtom(headerPRAtom)
    const [, setGoodsItem] = useAtom(goodsItemPr)
    const [, setServiceItem] = useAtom(servicesItemPr)
    const { isLoading: isLoadingPRTypes, getPRTypes } = usePRRepo(false);
    const [dataPRTypes, setDataPRTypes] = useState([])
    const [dataCostCenter, setDataCostCenter] = useState([])

    const fetchDataCostCenter = async () => {
        const payload = {
            pageNumber: 1,
            pageSize: 0,
            filter: {
                code: "",
                name: "",
                isActive: true
            },
            orderBy: "name",
            isAscending: true
        }
        const res = await getListCostCenter(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setDataCostCenter(res?.data?.listData?.map(dt => {
                return { label: dt?.name, value: dt?.costCenterId }
            }))
        }
    };

    const fetchPRTypes = async () => {
        let response = await getPRTypes({
            pageNumber: 1,
            pageSize: 10,
            orderBy: "prTypeName",
            isAscending: true,
            filter: {
                isActive: true
            }
        })
        if (response?.isValid && response?.statusCode === 200) {
            let datas = response?.data?.listData?.map(dt => {
                return { label: dt?.prTypeName, value: dt?.prTypeId }
            })
            setDataPRTypes(datas)
        }
    }

    const handleSelectCostCenter = (val, option) => {
        setDataField(prev => ({
            ...prev,
            costCenter: {
                ...prev?.costCenter,
                costCenterId: val,
                name: option?.label
            }
        }))
        setFieldValues(initForm, "costCenter", val)
    }

    const onChangePrType = (value, option) => {
        if (dataField?.prTypeId) {
            CustomAlert.confirm({
                title: "Change PR Type!",
                description: `If you change this PR Type, the items will also be deleted, are you sure ?`,
                confirmButtonText: "Yes",
                type: "primary",
                onConfirmButton: () => {
                    setDataField(prev => ({
                        ...prev,
                        prTypeId: value || null,
                        prTypeName: option?.label
                    }))
                    setGoodsItem([])
                    setServiceItem([])
                },
                onCancelButton: () => {
                    setFieldValues(initForm, "prTypeId", dataField?.prTypeId)
                }
            });
        } else {
            setDataField(prev => ({
                ...prev,
                prTypeId: value,
                prTypeName: option?.label
            }))
        }
    }

    useEffect(() => {
        fetchPRTypes()
        fetchDataCostCenter()
    }, [])

    // console.log("dataField", dataField);

    return (
        <div className='pr-section-header'>
            <Row gutter={[16]}>
                <>
                    {(dataField?.prNo && !dataField?.isDraft) && <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                        <CustomFormItemText
                            nameFormItem="prNo"
                            label="PR Number"
                            value={dataField?.prNo}
                            isView={true}
                        />
                    </Col>}

                    {(dataField?.prNo && !dataField?.isDraft) &&
                        <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                            <CustomFormItemDate
                                nameFormItem="prSubmitDate"
                                label="PR Date"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                value={dataField?.prSubmitDate}
                                isView={true}
                            />
                        </Col>
                    }

                    {(dataField?.prNo && !dataField?.isDraft) &&
                        <Col {...styleParentCol} xs={24} sm={12} lg={12}>
                            <CustomFormItemText
                                nameFormItem="prStatus"
                                label="PR Status"
                                value={dataField?.prStatus}
                                isView={true}
                            />
                        </Col>
                    }

                    <Col {...styleParentCol} xs={24} sm={12} lg={12}>
                        <CustomFormItemText
                            nameFormItem="prPic"
                            label="PIC PR"
                            value={dataField?.prPicFullName}
                            isView={true}
                        />
                    </Col>

                    <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                        <CustomFormItemText
                            nameFormItem="department"
                            label="Department"
                            value={dataField?.departmentName}
                            isView={true}
                        />
                    </Col>

                    <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                        <CustomFormItemText
                            nameFormItem="division"
                            label="Division"
                            value={dataField?.divisionName}
                            isView={true}
                        />
                    </Col>

                    <Col {...styleParentCol} xs={24} sm={12} lg={12}>
                        <CustomFormItemText
                            nameFormItem="prSubject"
                            label="PR Subject"
                            value={dataField?.prSubject}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            isView={isView}
                            maxLength={200}
                            placeholder="Fill in PR Subject"
                            onChange={(e) => {
                                setDataField(prev => ({
                                    ...prev,
                                    prSubject: e.target.value
                                }))
                            }}
                        />
                    </Col>

                    <Col {...styleParentCol} xs={24} sm={12} lg={12}>
                        <CustomFormItemSelectDropdown
                            nameFormItem="costCenter"
                            label="Cost Center"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            value={dataField?.costCenter?.costCenterId}
                            isView={isView}
                            placeholder="Select Cost Center"
                            showSearch={true}
                            loading={isLoadingGetListCostCenter}
                            options={dataCostCenter}
                            // value={dataField?.costCenter?.costCenterId}
                            // selectedLabel={dataField?.costCenter?.name}
                            onChange={(val, option) => handleSelectCostCenter(val, option)}
                        />
                    </Col>

                    <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                        <CustomFormItemSelectDropdown
                            nameFormItem="prTypeId"
                            label="PR Type"
                            value={dataField?.prTypeId}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            isView={isView}
                            placeholder="Select PR Type"
                            loading={isLoadingPRTypes}
                            options={dataPRTypes}
                            // selectedLabel={dataField?.prTypeName}
                            onChange={onChangePrType}
                        />


                        {/* <Form.Item
                            name="prTypeId"
                            label="PR Type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            initialValue={dataField?.prTypeId}
                        >
                            <Select
                                allowClear
                                placeholder="Select PR Type"
                                onChange={onChangePrType}
                                // selectedValue={dataField?.prTypeName}
                                options={dataPRTypes}
                                loading={isLoadingPRTypes}
                            />
                        </Form.Item> */}
                    </Col>

                    <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                        <CustomFormItemDate
                            nameFormItem="requiredDate"
                            label="Required Date"
                            value={dataField?.requiredDate}
                            isView={isView}
                            style={{ width: "100%" }}
                            placeholder='Select Require Date'
                            disabledDate={d => !d || d.isBefore(dayjs().format(Constant.datePickerFormat))}
                            onChange={(d, dString) => {
                                setDataField(prev => ({
                                    ...prev,
                                    requiredDate: dString ? dayjs(dString).format(Constant.stateDateFormat) : null
                                }))
                            }}
                        />
                    </Col>

                    <Col {...styleParentCol} xs={24} sm={12} lg={12}>
                        <CustomFormItemText
                            nameFormItem="projectCode"
                            label="Project Code"
                            value={dataField?.projectCode}
                            isView={isView}
                            placeholder="Fill in Project Code"
                            onChange={(e) => {
                                setDataField(prev => ({
                                    ...prev,
                                    projectCode: e.target.value
                                }))
                            }}
                        />
                    </Col>

                    <Col {...styleParentCol} xs={24}>
                        <CustomFormItemTextArea
                            nameFormItem="remarks"
                            label="Remarks"
                            value={dataField?.remarks}
                            isView={isView}
                            maxLength={2000}
                            placeholder="Fill in Remarks"
                            onChange={(e) => {
                                setDataField(prev => ({
                                    ...prev,
                                    remarks: e.target.value
                                }))
                            }}
                        />
                    </Col>
                </>
            </Row>
        </div>
    )
}