export const SessionStorageConst = {
    currentJwtToken: 'currentJwtToken',
}

const SessionStorageUtils = {
    currentJwtToken: {
        get: () => sessionStorage.getItem(SessionStorageConst.currentJwtToken) || '',
        set: (token) => sessionStorage.setItem(SessionStorageConst.currentJwtToken, token),
        clear: () => sessionStorage.removeItem(SessionStorageConst.currentJwtToken)
    },

    all: {
        clear: () => sessionStorage.clear()
    },
}

export default SessionStorageUtils
