import React from 'react'
import moment from 'moment';
import { GlobalOutlined } from '@ant-design/icons';
import { Footer as AntFooter } from 'antd/es/layout/layout';

export default function Footer() {
    return (
        <AntFooter>
            <p className="left">Copyright © {moment().format('YYYY')} Inspiro. All rights reserved.</p>
            <div className="right">

                <a href='https://inspirotechs.com' target='_blank' rel="noreferrer">
                    <GlobalOutlined /> inspirotechs.com
                </a>
            </div>
        </AntFooter>
    )
}
