import { Col } from 'antd';
import React, { useEffect, useRef } from 'react'
import { calculateSubotalWithDiscount, formatterAmount, setFieldValues, taxCalculation } from 'utils/Helper';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { useAtom } from 'jotai';
import { headerPOAtom, serviceItemPOAtom } from 'store/PurchaseOrder/PurchaseOrder';
import CellTable from 'components/Common/CellTable';
import { CustomFormItemMoney } from 'components/Common/CustomFormItem';

// const { Text } = Typography;

// const styleSummary = {
//     colSpan: 5,
//     align: 'end',
// }

export default function ServiceItem(props) {
    const { initForm, isView = false } = props
    const [header, setHeader] = useAtom(headerPOAtom)
    const [dataItems, setDataItems] = useAtom(serviceItemPOAtom)
    const indexDiscountRef = useRef(null)

    const onChangeInputRow = (field, value, index) => {
        let datas = [...dataItems];
        let currentData = datas[index]
        currentData[field] = value;

        if (field === "discount") {
            let discount = value
            currentData.discount = discount;

            let subTotal = calculateSubotalWithDiscount(currentData.price, currentData.quantity, discount) //value * currentData.price
            currentData.subTotal = subTotal;
            indexDiscountRef.current = index;
        } else {
            indexDiscountRef.current = null;
        }

        setHeader(prev => ({
            ...prev,
            isGrandTotalItemsChanged: true
        }))
        setDataItems(datas)
    }

    //ITEMS
    const calculateTotalWithTax = (amountBeforeTax, amountTax) => {
        return amountBeforeTax + amountTax
    }

    const updateFieldTableValues = (index, data) => {
        if (indexDiscountRef.current === index) {
            setFieldValues(initForm, `service_discount_${index}`, data.discount);
        } else {
            setFieldValues(initForm, `service_discount_${index}`, formatterAmount(data.discount));
        }
    };

    useEffect(() => {
        //trigger to change tax of total
        let totalAmountServicesVatPercentage = taxCalculation(header.totalAmountServicesBeforeTax, header?.servicesVatPercentage) || 0
        setHeader(prev => ({
            ...prev,
            totalAmountServicesVatPercentage: totalAmountServicesVatPercentage,
            totalServicesAmountWithTax: calculateTotalWithTax(header.totalAmountServicesBeforeTax, totalAmountServicesVatPercentage)
        }))
    }, [header?.servicesVatPercentage])

    //effect when dataitems change
    useEffect(() => {
        //trigger to change total amount tax, total before tax 
        let sumTotalBeforeTax = 0;

        [...dataItems]?.map((data, index) => {
            updateFieldTableValues(index, data);
            sumTotalBeforeTax += data.subTotal;
            return sumTotalBeforeTax;
        });

        let amountVAT = taxCalculation(sumTotalBeforeTax, header.servicesVatPercentage);

        setHeader(prev => ({
            ...prev,
            totalAmountServicesVatPercentage: amountVAT,
            totalAmountServicesBeforeTax: sumTotalBeforeTax,
            totalServicesAmountWithTax: calculateTotalWithTax(sumTotalBeforeTax, amountVAT)
        }));
    }, [dataItems]);

    const columnsService = [
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            className: "custom-table-edit-row-input",
            width: "200px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Category',
            dataIndex: 'itemCategoryName',
            className: "custom-table-edit-row-input",
            width: "150px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: "70px",
            className: "custom-table-edit-row-input",
            align: "right"
        },
        {
            title: 'UOM',
            dataIndex: 'uomName',
            className: "custom-table-edit-row-input",
            width: "70px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Price',
            dataIndex: 'price',
            className: "custom-table-edit-row-input",
            align: 'right',
            width: "120px",
            render: (price) => {
                return formatterAmount(price)
            }
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            className: "custom-table-edit-row-input",
            align: 'right',
            width: "180px",
            render: (text, record, index) => {
                return (
                    <Col>
                        <CustomFormItemMoney
                            nameFormItem={'service_discount_' + index}
                            nameInput={'service_discount_' + index}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value < 0) {
                                            return Promise.reject(new Error('Discount must be greater than or equal to 0.'));
                                        }

                                        const currentPrice = dataItems?.[index]?.price;
                                        if (value > currentPrice) {
                                            return Promise.reject(new Error('Discount cannot exceed the price.'));
                                        }

                                        return Promise.resolve();
                                    }
                                }
                            ]}
                            initForm={initForm}
                            customvalue={record?.discount}
                            onChange={(e) => onChangeInputRow("discount", e, index)}
                            isView={isView}
                            useInTable={true}
                            alignView="right"
                        />
                    </Col>
                )
            }
        },
        {
            title: 'Sub Total',
            dataIndex: 'subTotal',
            className: "custom-table-edit-row-input",
            align: 'right',
            width: "120px",
            render: (subTotal) => {
                if (!subTotal) {
                    return 0
                }
                return formatterAmount(subTotal)
            }
        }
    ];

    return (
        <>
            <div className='mb-2'>
                <p className="font-semibold">Services Item</p>
                <CustomAntdTable
                    className='custom-table-edit-row table-services-item'
                    rowKey={"itemId"}
                    columns={columnsService}
                    dataSource={dataItems}
                    pagination={false}
                    ignoreRowClick={true}
                // summary={pageData => {
                //     return (
                //         dataItems.length > 0 && <>
                //             <Table.Summary.Row>
                //                 <Table.Summary.Cell {...styleSummary}></Table.Summary.Cell>
                //                 <Table.Summary.Cell className='label'>Total</Table.Summary.Cell>
                //                 <Table.Summary.Cell align='right' className="summary-item">
                //                     <Text>{formatterAmount(header.totalAmountServicesBeforeTax)}</Text>
                //                 </Table.Summary.Cell>
                //             </Table.Summary.Row>
                //             <Table.Summary.Row>
                //                 <Table.Summary.Cell {...styleSummary}></Table.Summary.Cell>
                //                 <Table.Summary.Cell className='label vat-summary'>
                //                     <CustomFormItemNumber
                //                         nameFormItem={'servicesTax'}
                //                         label='VAT'
                //                         nameInput={'servicesTax'}
                //                         className='service-item-tax'
                //                         rules={[
                //                             {
                //                                 required: true
                //                             },
                //                         ]}
                //                         max="100"
                //                         min="0"
                //                         initForm={initForm}
                //                         customvalue={header.servicesVatPercentage}
                //                         isView={isView}
                //                         onChange={(val) => {
                //                             setHeader(prev => ({
                //                                 ...prev,
                //                                 servicesVatPercentage: val,
                //                             }));
                //                         }}
                //                         wrapperCol={{ xs: 16 }}
                //                         labelCol={{ xs: 8 }}
                //                         alignView="right"
                //                     />
                //                 </Table.Summary.Cell>
                //                 <Table.Summary.Cell align='right' className="summary-item">
                //                     <Text>{formatterAmount(header.totalAmountServicesVatPercentage)}</Text>
                //                 </Table.Summary.Cell>
                //             </Table.Summary.Row>
                //             <Table.Summary.Row>
                //                 <Table.Summary.Cell {...styleSummary}></Table.Summary.Cell>
                //                 <Table.Summary.Cell className='label'>
                //                     <strong>Total (Include Tax)</strong>
                //                 </Table.Summary.Cell>
                //                 <Table.Summary.Cell align='right' className="summary-item">
                //                     <Text>{formatterAmount(calculateTotalWithTax(header.totalAmountServicesBeforeTax, header.totalAmountServicesVatPercentage))}</Text>
                //                 </Table.Summary.Cell>
                //             </Table.Summary.Row>
                //         </>
                //     );
                // }}
                />
            </div>
        </>
    )
}
