import { Form, Space } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import SectionHeaderPR from '../SectionHeaderPR'
import SectionItems from '../SectionItems';
import SectionWorkflowHistory from '../SectionWorkflowHistory';
import Error403 from 'components/Error/Error403';
import { ButtonCancelDocument, ButtonCloseDocument } from 'components/Common/Buttons'
import BaseSectionAttachment from '../BaseSectionAttachment';
import CustomCollapse from 'components/Common/CustomCollapse';

export default function BaseDetailPR(props) {
    const {
        onClose,
        loading = false,
        initForm,
        dataHeader,
        handleCancelPR = () => { },
        handleClosePR = () => { },
        isLoadingCancelPR = false,
        isLoadingClosePR = false,
        notAuthorized = false,
        showCancelPRButton = false,
        showClosePRButton = false,
    } = props

    const handleCloseDrawer = () => {
        onClose()
    }

    const itemsCollapseDefault = [
        {
            key: 1,
            label: "Purchase Request Header",
            forceRender: true,
            children: <SectionHeaderPR initForm={initForm} isView={true} />
        },
        {
            key: 2,
            label: "Items",
            forceRender: true,
            className: "pr-form-collapse-panel-items",
            children: <SectionItems initForm={initForm} isView={true} />
        },
        // {
        //     key: 3,
        //     label: "Budget Detail",
        //      forceRender: true,
        //     children: <SectionHeaderPR initForm={initForm} isView={true} />
        // },
        {
            key: 4,
            label: "Attachments",
            forceRender: true,
            children: <BaseSectionAttachment initForm={initForm} isView={true} />
        }
    ]

    let itemsCollapseWithWfHistory = [
        ...itemsCollapseDefault,
        {
            key: 5,
            label: "Workflow History",
            forceRender: true,
            children: <SectionWorkflowHistory prId={dataHeader?.prId} />
        }
    ]

    return (
        <BaseDrawer
            title={"Purchase Request"}
            placement="right"
            size="large"
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
            extras={
                <>
                    {showCancelPRButton && <ButtonCancelDocument onClick={handleCancelPR} loading={isLoadingCancelPR} buttonText='Cancel PR' />}
                    {showClosePRButton && <ButtonCloseDocument onClick={handleClosePR} loading={isLoadingClosePR} buttonText='Close PR' />}
                </>
            }
        >
            <Space direction="vertical" size="small" className='wrap-pr-form space-vertical-flex' >
                {notAuthorized ? <Error403 /> :
                    <Form
                        name="form-view-pr"
                        form={initForm}
                        layout='vertical'
                        className='custom-form'
                    >
                        <CustomCollapse
                            className="pr-form-collapse"
                            defaultActiveKey={["1", "2", "4", "5"]}
                            items={!dataHeader.isDraft ? itemsCollapseWithWfHistory : itemsCollapseDefault}
                        />
                    </Form>
                }
            </Space>
        </BaseDrawer>
    )
}
