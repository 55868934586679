/**
 * September 19th
 * 2023
 * 
 * created:
 * Try Fanny Poerna Maulana
 */

import { Button, Card, Col, Form, Row } from 'antd'
import { CustomFormItemSwitch, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import useWfRoleRepo from 'services/repo/useWfRoleRepo'
import Constant from 'utils/Constants'
import CustomAlert from 'utils/CustomAlert'
import DevLog from 'utils/DevLog'
import { dismissToast, loadingToastId, successToast } from 'utils/Toast'

const init_field = {
  wfRoleId: null,
  wfRoleName: "",
  wfDescription: "",
  isActive: true,
  lastModifiedAt: '',
}

export default function BaseDrawerWfRole(props) {
  const {
    onClose,
    dataEdit = null,
    onRefreshList,
    isView = false,
    loading = false,
    setModeView = () => { },
    isAddEditPrivileged,
    handleUnhighlight = () => { },
  } = props
  const [form] = Form.useForm()
  const [dataField, setDataField] = useState(init_field)
  const { isLoading: isLoadingCreate, createWfRole } = useWfRoleRepo(false);
  const { isLoading: isLoadingUpdate, updateWfRole } = useWfRoleRepo(false);

  const handleCloseDrawer = () => {
    form.resetFields()
    setDataField(init_field)
    setModeView(true)
    onClose()
  }

  const onSaveCreate = async () => {
    let payload = {
      wfRoleName: dataField.wfRoleName,
      wfDescription: dataField.wfDescription,
      isActive: dataField.isActive
    }
    loadingToastId(Constant.message.savingData, payload?.wfRoleName)
    let res = await createWfRole(payload)
    dismissToast(payload?.wfRoleName)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const onSaveEdit = async () => {
    let payload = {
      wfRoleId: dataField.wfRoleId,
      wfRoleName: dataField.wfRoleName,
      wfDescription: dataField.wfDescription,
      isActive: dataField.isActive,
      lastModifiedAt: dataField.lastModifiedAt,
    }
    loadingToastId(Constant.message.savingData, payload?.wfRoleId)
    let res = await updateWfRole(payload)
    dismissToast(payload?.wfRoleId)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const handleFormSubmit = () => {
    CustomAlert.confirm({
      title: "Save Workflow Role!",
      description: `Are you sure you want to save this data?`,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "primary",
      onConfirmButton: () => {
        form.validateFields()
          .then((values) => {
            if (dataEdit !== null) {
              onSaveEdit()
            } else {
              onSaveCreate()
            }
          })
          .catch((errorInfo) => {
            DevLog("err", errorInfo);
          });
      }
    });
  };

  useEffect(() => {
    setModeView(isView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isView])

  useEffect(() => {
    if (dataEdit !== null) {
      setDataField(dataEdit)
      form.setFieldsValue({
        wfRoleName: dataEdit?.wfRoleName,
        wfDescription: dataEdit?.wfDescription,
        isActive: dataEdit?.isActive,
      })
    } else {
      setDataField(init_field)
      form.setFieldsValue({
        wfRoleName: init_field.wfRoleName,
        wfDescription: init_field.wfDescription,
        isActive: init_field.isActive,
      })
      handleUnhighlight()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit])

  return (
    <>
      <BaseDrawer
        title={`${isView ? "View" : dataEdit === null ? "Add" : "Edit"} Workflow Role`}
        placement="right"
        size="large"
        onClose={handleCloseDrawer}
        open={true}
        loading={loading}
        extras={
          <>
            {(isAddEditPrivileged && isView) &&
              (<Button
                type="primary"
                onClick={() => setModeView(false)}>
                Edit
              </Button>)
            }
            {(isAddEditPrivileged && !isView) &&
              (<Button type="primary" onClick={handleFormSubmit} htmlType='submit'
                loading={isLoadingCreate || isLoadingUpdate}>
                Submit
              </Button>)
            }
          </>
        }>
        <Card>
          <Form
            form={form}
            layout="vertical">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="wfRoleName"
                  label="Name"
                  placeholder="Fill in Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.wfRoleName}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      wfRoleName: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSwitch
                  nameFormItem="isActive"
                  label="Is Active"
                  value={dataField?.isActive}
                  isView={isView}
                  onChange={(checked) => {
                    setDataField(prev =>
                      ({ ...prev, isActive: checked }))
                  }}
                />
              </Col>
              <Col xs={24}>
                <CustomFormItemTextArea
                  nameFormItem="wfDescription"
                  label="Description"
                  value={dataField?.wfDescription}
                  isView={isView}
                  placeholder="Fill in Description"
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      wfDescription: e.target.value
                    }))
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </BaseDrawer>
    </>
  )
}
