import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import { useEffect, useState } from 'react';
import CustomAlert from 'utils/CustomAlert';
import DevLog from 'utils/DevLog';
import { convertFileSize, displayMediaTypeFromDB, readURL } from 'utils/Helper';
import { errorToast } from 'utils/Toast';
import { fallbackImage } from 'utils/FallbackImage'

export default function CustomUploadImage({
    name,
    imageSrc = '',
    onChange = () => { },
    readOnly = false,
    mediaType = "",
    maxSize,
    widthImage = 0,
    heightImage = 0
}) {

    const [acceptExtention, setAcceptExtention] = useState("")
    useEffect(() => {
        let extentionWithDot = ""
        mediaType?.split(";")?.map((f, i) => {
            if (i === 0) {
                return extentionWithDot += "." + f
            } else {
                return extentionWithDot += ",." + f
            }
        })
        setAcceptExtention(extentionWithDot)
    }, [mediaType])

    function validateFileExtension(filename, acceptExtension) {
        let allowedExtensions = acceptExtension.split(',');
        let fileExtension = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
        let isValidExtension = false;

        for (let i = 0; i < allowedExtensions.length; i++) {
            if (allowedExtensions[i].toLowerCase().indexOf(fileExtension) !== -1) {
                isValidExtension = true;
                break;
            }
        }

        return isValidExtension;
    }

    const onFileChanged = async (event) => {
        try {
            let imageSrc = await readURL(event)
            let fileName = event?.target?.files[0]?.name
            let size = event.target.files[0]?.size
            let file = event.target.files[0]

            if (!validateFileExtension(fileName, acceptExtention)) {
                event.target.value = ""
                return errorToast("Incorrect file format")
            }

            if (size > maxSize) {
                event.target.value = ""
                return errorToast("The file size exceeds the maximum limit")
            }

            if (file) {
                event.target.value = ""
                onChange(imageSrc, file, fileName, size)
            }

        } catch (error) {
            DevLog('error', error)
        }
    }

    const onDeleteImage = (e) => {
        e.preventDefault()
        CustomAlert.confirm({
            title: "Delete Image",
            description: "Are you sure you want to delete this image?",
            confirmButtonText: "Delete",
            onConfirmButton: () => {
                // setImageSrc('')
                onChange('', null)
            }
        })
    }

    // console.log(imageSrc);

    if (imageSrc === '' || imageSrc === null) {
        return (
            <div className='custom-upload-image-container'>
                {!readOnly && <input type="file" name={name} accept={acceptExtention} onChange={onFileChanged} />}
                <PlusOutlined />
                <div className='label'>Browse Image</div>
                <p>
                    Format allowed {displayMediaTypeFromDB(mediaType)}. Maximum {convertFileSize(maxSize || 0)}
                    {/* <br />
                    {"Minimum resolution 261 x 221px"} */}
                </p>
            </div>
        )
    } else {
        return (
            <div className='custom-upload-image-container image-selected'>
                <Image
                    style={{ width: widthImage, height: heightImage }}
                    src={imageSrc}
                    alt="preview"
                    fallback={fallbackImage} />
                <div className='action'>
                    {!readOnly && <Button htmlType='button' type='primary' danger onClick={onDeleteImage} icon={<DeleteOutlined />} />}
                </div>
            </div>
        )
    }
}
