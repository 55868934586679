import { Button } from 'antd'
import React from 'react'
import { PlusOutlined, CaretLeftOutlined, SyncOutlined, FileExcelOutlined, FileMarkdownOutlined, FilterOutlined, FilterFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
// import IconPlus from 'assets/icons/IconPlus';
// import IconAdvanceFilter from 'assets/icons/IconAdvanceFilter';


export function ButtonPrimary({
    htmlType = "button", className = "", buttonText = "...", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button htmlType={htmlType} className={`${className} button-custom button-primary`} type="primary" icon={icon} loading={loading} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonSecondary({
    className = "", buttonText = "...", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom button-secondary`} icon={icon} loading={loading} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonAdd({
    className = "", buttonText = "Add", loading, icon = null, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom button-add`} type="primary" icon={icon} loading={loading} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonCreate({
    className = "", buttonText = "Create", loading, icon = <PlusOutlined />, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom button-create`} type="primary" loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function LinkButtonToPendingTask({
    className = "", buttonText = "Pending Task", toUrl, countPendingTask = 0, loading, icon
}) {
    return (
        <Link to={toUrl}>
            <Button className={`${className} button-custom`} type="primary" danger={countPendingTask > 0 ? true : false} loading={loading} icon={icon}>
                {buttonText} ({countPendingTask})
            </Button>
        </Link>
    )
}

export function LinkButtonToCreateDocument({
    className = "", buttonText = "Create", toUrl, loading, icon
}) {
    return (
        <Link to={toUrl}>
            <Button className={`${className} button-custom`} type="primary" loading={loading} icon={icon}>
                {buttonText}
            </Button>
        </Link>
    )
}

export function ButtonCancelDocument({
    className = "", buttonText = "Cancel", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="primary" danger loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonCloseDocument({
    className = "", buttonText = "Close", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="primary" danger loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonSaveDraft({
    className = "", buttonText = "Save as Draft", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="primary" loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonSubmit({
    className = "", buttonText = "Submit", loading, icon, disabled = false, htmlType = 'submit', onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="primary" loading={loading} icon={icon} disabled={disabled} onClick={onClick} htmlType={htmlType}>
            {buttonText}
        </Button>
    )
}

export function ButtonDiscard({
    className = "", buttonText = "Discard", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="primary" danger loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonApprove({
    className = "", buttonText = "Approve", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="primary" loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonReject({
    className = "", buttonText = "Reject", loading, icon, disabled = false, htmlType = 'submit', onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="primary" danger loading={loading} icon={icon} disabled={disabled} onClick={onClick} htmlType={htmlType}>
            {buttonText}
        </Button>
    )
}

export function ButtonRevise({
    className = "", buttonText = "Revise", loading, icon, disabled = false, htmlType = 'submit', onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="dashed" danger loading={loading} icon={icon} disabled={disabled} onClick={onClick} htmlType={htmlType}>
            {buttonText}
        </Button>
    )
}

export function ButtonBack({
    className = "", buttonText = "Back", loading, icon = <CaretLeftOutlined />, disabled = false, onClick = () => { }
}) {
    return (
        <Button loading={loading} icon={icon} disabled={disabled} onClick={onClick} className={`btn-back button-custom ${className}`}>
            {buttonText}
        </Button>
    )
}

export function ButtonCancelAction({
    className = "", buttonText = "Cancel", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="primary" danger loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonConfirmedAction({
    className = "", buttonText = "Yes", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} type="primary" loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonRefresh({
    className = "", buttonText = "", loading, icon, disabled = false, size = 'middle', onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom`} loading={loading} icon={<SyncOutlined />} disabled={disabled} onClick={onClick} size={size}>
            {buttonText}
        </Button>
    )
}

export function ButtonPaid({
    className = "", buttonText = "Paid", loading, icon, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom eproc-button eproc-button-paid`} type="default" loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonAdvancedFilter({
    size = "large", className = "", buttonText = "Filter", loading, disabled = false, onClick = () => { }, isFilled = false
}) {
    return (
        <Button size={size} className={`${className} button-custom advance-filter`} type="default" loading={loading} icon={isFilled ? <FilterFilled /> : <FilterOutlined />} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonExcel({
    className = "", buttonText = "Upload Excel", loading, icon = <FileExcelOutlined />, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom button-excel`} type="ghost" loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}

export function ButtonDownloadTemplate({
    className = "", buttonText = "Download Template", loading, icon = <FileMarkdownOutlined />, disabled = false, onClick = () => { }
}) {
    return (
        <Button className={`${className} button-custom button-download-template`} type="default" loading={loading} icon={icon} disabled={disabled} onClick={onClick}>
            {buttonText}
        </Button>
    )
}
