import { Button, Card, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { RouteConstant } from 'routes/routesConstant';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import useSimpleCaptcha from 'hooks/useSimpleCaptcha';
import useUserRepo from 'services/repo/useUserRepo';
import { successToast } from 'utils/Toast';
import DevLog from 'utils/DevLog';
import { builderName, setFieldValues } from 'utils/Helper';
import BrandLogo from 'assets/img/LOGO-FULL-DARK.png'
import Constant from 'utils/Constants';
import useEncriptRSAHook from 'hooks/useEncriptRSAHook';

const propsItem = {
    colon: false,
    labelCol: { sm: 12 },
    validateTrigger: false,
    className: "form-item-reset-password"
}

const styleFormItem = {
    marginBottom: "0"
}


export default function ResetPassword() {
    const { resetPassword } = useUserRepo(false);
    const { isLoading: isLoadingGetUser, getUserByUserId } = useUserRepo(false);
    const { getEncriptedText } = useEncriptRSAHook()

    const {
        captchaUI,
        isValid: isValidCaptcha,
        handleInputCaptchaChange,
    } = useSimpleCaptcha();

    const [newPassword, setNewPassword] = useState("")
    const [, setConfirmNewPassword] = useState("")
    const [, setCaptcha] = useState("")
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const resetPasswordId = searchParams.get('resetPasswordId');
    const [form] = Form.useForm()
    const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false)

    const onFinish = async (values) => {
        setIsLoadingResetPassword(true)
        const encriptedPassword = await getEncriptedText(newPassword)
        if (encriptedPassword) {
            const payload = {
                userId: userId,
                resetPasswordId: resetPasswordId,
                encryptedNewPassword: encriptedPassword
            }
            const res = await resetPassword(payload)
            if (res?.isValid && res?.statusCode === 200) {
                successToast('Reset password success!')
                navigate(RouteConstant.login, { replace: true })
            }
            setIsLoadingResetPassword(false)
        }
        setIsLoadingResetPassword(false)
    };

    const fetchGetUser = async () => {
        const payload = {
            userId: userId,
            resetPasswordId: resetPasswordId
        }
        const res = await getUserByUserId(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setFieldValues(form, "username", builderName(res.data.firstName, res.data.lastName))
        }
    }

    const onFinishFailed = (errorInfo) => {
        DevLog('Failed:', errorInfo);
    };

    useEffect(() => {
        if (userId && resetPasswordId) {
            fetchGetUser()
        } else {
            navigate(RouteConstant.login, { replace: true })
        }
    }, [userId, resetPasswordId])


    return (
        <div className='reset-password-page'>
            <div className='wrap-reset-password'>
                <Card className='card'>
                    <img src={BrandLogo} alt='brand logo' height={60} className='brand-img' />
                    <p className="auth-container-title">Reset Password</p>
                    <Form
                        form={form}
                        name="reset-password"
                        autoComplete="off"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            {...propsItem}
                            name="username"
                            style={styleFormItem}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            {...propsItem}
                            name="newPassword"
                            style={styleFormItem}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your New Password!',
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder='New Password'
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

                                onChange={(e) => {
                                    setNewPassword(e.target.value)
                                }} />
                        </Form.Item>
                        <Form.Item
                            {...propsItem}
                            name="confirmPassword"
                            style={styleFormItem}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value !== newPassword) {
                                            return Promise.reject(new Error("Confirm New Password doesn't match with New Password."));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input.Password
                                placeholder='Confirm Password'
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                onChange={(e) => {
                                    setConfirmNewPassword(e.target.value)
                                }} />
                        </Form.Item>
                        {Constant.useCaptchaValidationResetPassword &&
                            <>
                                <Form.Item
                                    {...propsItem}
                                    style={styleFormItem}
                                >
                                    {captchaUI}
                                </Form.Item>
                                <Form.Item
                                    {...propsItem}
                                    name="captcha"
                                    style={styleFormItem}
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (!isValidCaptcha) {
                                                    return Promise.reject(new Error('Invalid Captcha!'));
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <Input placeholder='Captcha' onChange={(e) => {
                                        setCaptcha(e.target.value)
                                        handleInputCaptchaChange(e.target.value)
                                    }} />
                                </Form.Item>
                            </>
                        }
                        <div className='wrap-button'>
                            <Link to={RouteConstant.login}>
                                <Button
                                    type='primary'
                                    danger
                                    htmlType='button'
                                    disabled={isLoadingResetPassword || isLoadingGetUser}
                                    size='large'>Back to Login</Button>
                            </Link>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={isLoadingResetPassword}
                                size='large'>Reset Password</Button>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>
    )
}
