import { formatterAmount, getRandomColorArray } from "./Helper";

export const generateColorDoughnutChart = (returnSingle = false, index, length) => {
    let newArray = []
    const colors = [
        "#1C64F2",
        "#F49E5D",
        "#0E9F6E",
        "#9CA3AF",
        "#FFCD56",
        "#550527",
        "#688E26",
        "#F44708",
        "#A10702",
        "#7C7287",
        "#9DC0BC",
        "#D4AA7D",
        "#C86FC9",
        "#626C66",
        "#C11AC0",
        "#68C3D4",
        "#226CE0",
        "#E6AF2E",
        "#B9314F",
        "#BEB7A4",
        "#F8F32B",
        "#90A9B7",
    ]

    if (returnSingle) {
        return colors[index]
    }
    for (let i = 0; i < length; i++) {
        newArray.push(colors[i])
    }
    return newArray
}

export const ConfigDoughnutChart = (data, label, withFormater = true, autoColor = true) => {
    let color = getRandomColorArray(data.length)
    return {
        labels: data?.map((data) => data.label),
        datasets: [{
            label: label,
            data: data?.map((data) => data.value),
            backgroundColor: autoColor ? color : data?.map((data) => {
                // debugger
                return data.color
            }),
            // borderColor: color,

            borderWidth: 0.3,
            datalabels: {
                color: '#111827',
                textShadowBlur: 10,
                textShadowColor: 'white',
                textStrokeWidth: 1,
                anchor: 'end', // Posisi label (end, start, center, atau auto)
                align: 'auto', // Alihkan label (end, start, atau center)
                formatter: function (value, context) {
                    // return context.chart.data.labels[context.dataIndex];
                    return withFormater ? formatterAmount(value) : value
                }
            },
        }]
    }
}
