import { formatterAmount } from "./Helper";

function hexToRgb(hex) {
    // Hapus karakter "#" jika ada
    hex = hex.replace(/^#/, '');

    // Ubah nilai hex menjadi array RGB
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Kembalikan string format RGB
    return `${r},${g},${b}`;
}

export const generateColorLineAreaChart = (index) => {
    const colors = [
        "#1C64F2",
        "#F49E5D",
        "#F44708",
        "#9DC0BC",
        "#550527",
        "#D4AA7D",
        "#C86FC9",
        "#626C66",
        "#C11AC0",
        "#68C3D4",
        "#68C324",
        "#226CE0",
        "#E6AF2E",
        "#B9314F",
        "#BEB7A4",
        "#F8F32B",
        "#272727",
        "#90A9B7",
    ]
    return colors[index]
}

export const ConfigLineAreaChart = (labels = [], data, withFormater = true) => {
    return {
        labels: labels,
        datasets: data?.map((dt, index) => {
            return {
                label: dt?.label,
                data: dt?.data?.map((item) => item?.value),
                borderWidth: 2.2,
                pointBorderColor: "#fff",
                pointBorderWidth: 2,
                borderColor: `rgb(${hexToRgb(generateColorLineAreaChart(index))})`,
                pointBackgroundColor: `rgb(${hexToRgb(generateColorLineAreaChart(index))})`,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const height = context.chart.height;
                    const gradient = ctx.createLinearGradient(0, 0, 0, height);
                    gradient.addColorStop(0, `rgba(${hexToRgb(generateColorLineAreaChart(index))},0.21)`);
                    gradient.addColorStop(1, `rgba(${hexToRgb(generateColorLineAreaChart(index))},0)`);
                    return gradient;
                },
                fill: {
                    target: "origin", // Set the fill options
                    below: "rgba(250,250,250, 0.3)",
                },
                datalabels: {
                    color: '#111827',
                    textShadowBlur: 10,
                    textShadowColor: 'white',
                    textStrokeWidth: 1,
                    anchor: 'auto', // Posisi label (end, start, center, atau auto)
                    align: 'start', // Alihkan label (end, start, atau center)
                    formatter: function (value, context) {
                        // return context.chart.data.labels[context.dataIndex];
                        if (value > 0) {
                            return withFormater ? formatterAmount(value) : value
                        }
                        return ''
                    }
                },
            }
        })
    }
}
