/**
 * October 10th
 * 2023
 * 
 * created:
 * Try Fanny Poerna Maulana
 * 
 * Updated: March 1st 2024
 * Updated by: 🫰 Aris bimas n
 */

import { Button, Card, Col, Form, Row } from 'antd'
import { CustomFormItemSwitch, CustomFormItemText } from 'components/Common/CustomFormItem'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import useCountryRepo from 'services/repo/useCountryRepo'
import Constant from 'utils/Constants'
import CustomAlert from 'utils/CustomAlert'
import DevLog from 'utils/DevLog'
import { dismissToast, loadingToastId, successToast } from 'utils/Toast'

const init_field = {
  countryId: null,
  countryCode: "",
  countryName: "",
  isActive: true,
  lastModifiedAt: '',
}

export default function BaseDrawerCountry(props) {
  const {
    onClose,
    dataEdit = null,
    onRefreshList,
    isView = false,
    loading = false,
    setModeView = () => { },
    isAddEditPrivileged,
    handleUnhighlight = () => { },
  } = props
  const [form] = Form.useForm()
  const [dataField, setDataField] = useState(init_field)
  const { isLoading: isLoadingCreate, createCountry } = useCountryRepo(false);
  const { isLoading: isLoadingUpdate, updateCountry } = useCountryRepo(false);

  const handleCloseDrawer = () => {
    form.resetFields()
    setDataField(init_field)
    setModeView(true)
    onClose()
  }

  const onSaveCreate = async () => {
    let payload = {
      countryCode: dataField.countryCode,
      countryName: dataField.countryName,
      isActive: dataField.isActive
    }
    loadingToastId(Constant.message.savingData, payload?.countryCode)
    let res = await createCountry(payload)
    dismissToast(payload?.countryCode)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const onSaveEdit = async () => {
    let payload = {
      countryId: dataField.countryId,
      countryCode: dataField.countryCode,
      countryName: dataField.countryName,
      isActive: dataField.isActive,
      lastModifiedAt: dataField.lastModifiedAt,
    }
    loadingToastId(Constant.message.savingData, payload?.countryId)
    let res = await updateCountry(payload)
    dismissToast(payload?.countryId)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const handleFormSubmit = () => {
    CustomAlert.confirm({
      title: "Save Country!",
      description: `Are you sure you want to save this data?`,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "primary",
      onConfirmButton: () => {
        form.validateFields()
          .then((values) => {
            if (dataEdit !== null) {
              onSaveEdit()
            } else {
              onSaveCreate()
            }
          })
          .catch((errorInfo) => {
            DevLog("err", errorInfo);
          });
      }
    });
  };

  useEffect(() => {
    setModeView(isView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isView])

  useEffect(() => {
    if (dataEdit !== null) {
      setDataField(dataEdit)
      form.setFieldsValue({
        countryCode: dataEdit?.countryCode,
        countryName: dataEdit?.countryName,
        isActive: dataEdit?.isActive,
      })
    } else {
      setDataField(init_field)
      form.setFieldsValue({
        countryCode: init_field.countryCode,
        countryName: init_field.countryName,
        isActive: init_field.isActive,
      })
      handleUnhighlight()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit])

  return (
    <>
      <BaseDrawer
        title={`${isView ? "View" : dataEdit === null ? "Add" : "Edit"} Country`}
        placement="right"
        size="large"
        onClose={handleCloseDrawer}
        open={true}
        loading={loading}
        extras={
          <>
            {(isAddEditPrivileged && isView) &&
              (<Button
                type="primary"
                onClick={() => setModeView(false)}>
                Edit
              </Button>)
            }
            {(isAddEditPrivileged && !isView) &&
              (<Button type="primary" onClick={handleFormSubmit} htmlType='submit'
                loading={isLoadingCreate || isLoadingUpdate}>
                Submit
              </Button>)
            }
          </>
        }>
        <Card>
          <Form
            form={form}
            layout="vertical">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="countryCode"
                  label="Code"
                  placeholder="Fill in Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.countryCode}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      countryCode: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="countryName"
                  label="Name"
                  placeholder="Fill in Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.countryName}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      countryName: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSwitch
                  nameFormItem="isActive"
                  label="Is Active"
                  value={dataField?.isActive}
                  isView={isView}
                  onChange={(checked) => {
                    setDataField(prev =>
                      ({ ...prev, isActive: checked }))
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </BaseDrawer>
    </>
  )
}
