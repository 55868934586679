import { Collapse, Form, Space } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer';
import Error403 from 'components/Error/Error403';
import SectionHeader from '../SectionHeader';
import SectionItems from '../SectionItems';
import SectionVendor from '../SectionVendor';
import SectionWorkflowHistory from '../SectionWorkflowHistory';
import { ButtonCancelDocument, ButtonCloseDocument } from 'components/Common/Buttons';
import SectionNotesAndAttachment from '../SectionNotesAndAttachment';
import CustomCollapse from 'components/Common/CustomCollapse';

export default function BaseDetailRFQ(props) {
    const {
        onClose,
        loading = false,
        initForm,
        dataHeader,
        handleCancelRFQ = () => { },
        handleCloseRFQ = () => { },
        isLoadingCancelRFQ = false,
        isLoadingCloseRFQ = false,
        notAuthorized = false,
        showCancelRFQButton = false,
        showCloseRFQButton = false,
    } = props

    const handleCloseDrawer = () => {
        onClose()
    }

    const itemsCollapseDefault = [
        {
            key: 1,
            label: "RFQ Header",
            forceRender: true,
            children: <SectionHeader initForm={initForm} isView={true} />
        },
        {
            key: 2,
            label: "Items",
            forceRender: true,
            className: "rfq-form-collapse-panel-items",
            children: <SectionItems initForm={initForm} isView={true} />
        },
        {
            key: 3,
            label: "Vendor List",
            forceRender: true,
            children: <SectionVendor initForm={initForm} isView={true} />
        },
        {
            key: 4,
            label: "Notes and Attachments",
            forceRender: true,
            className: "rfq-form-collapse-panel-notes-and-attachments",
            children: <SectionNotesAndAttachment initForm={initForm} isView={true} />
        }
    ]

    let itemsCollapseWithWfHistory = [
        ...itemsCollapseDefault,
        {
            key: 5,
            label: "Workflow History",
            forceRender: true,
            children: <SectionWorkflowHistory rfqId={dataHeader?.rfqId} />
        }
    ]

    return (
        <BaseDrawer
            title={"Request for Quotation"}
            placement="right"
            size="large"
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
            extras={
                <>
                    {showCancelRFQButton && <ButtonCancelDocument onClick={handleCancelRFQ} loading={isLoadingCancelRFQ} buttonText='Cancel RFQ' />}
                    {showCloseRFQButton && <ButtonCloseDocument onClick={handleCloseRFQ} loading={isLoadingCloseRFQ} buttonText='Close RFQ' />}
                </>
            }
        >
            <Space direction="vertical" size="small" className='wrap-rfq-form space-vertical-flex' >
                {notAuthorized ? <Error403 /> :
                    <Form
                        name="form-rfq-view"
                        form={initForm}
                        layout='vertical'
                    >
                        <CustomCollapse
                            className="rfq-form-collapse"
                            defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
                            items={!dataHeader.isDraft ? itemsCollapseWithWfHistory : itemsCollapseDefault}
                        />
                    </Form>
                }
            </Space>
        </BaseDrawer>
    )
}
