import CustomAlertContent from "components/CustomAlert/CustomAlertContent"
import Swal from "sweetalert2"
import withReactContent from 'sweetalert2-react-content'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const ReactSwal = withReactContent(Swal)

const success = (alertParam) => {
    const { title, description, confirmButtonText, onConfirmButton, swalOptions = {}, icon = <CheckCircleOutlined />, } = alertParam
    ReactSwal.fire({
        html: <CustomAlertContent
            title={title}
            description={description}
            icon={icon}
            confirmButtonText={confirmButtonText}
            onConfirmButtonClicked={onConfirmButton} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        ...swalOptions
    })
}

const error = (alertParam) => {
    const { title, description, confirmButtonText, onConfirmButton, swalOptions = {}, icon = <ExclamationCircleOutlined /> } = alertParam
    return ReactSwal.fire({
        customClass: {
            container: "custom-alert alert-error",
        },
        html: <CustomAlertContent title={title}
            description={description}
            icon={icon}
            confirmButtonText={confirmButtonText}
            onConfirmButtonClicked={onConfirmButton}
            type="error" />,
        showConfirmButton: false,
        allowOutsideClick: false,
        ...swalOptions
    })
}

const confirm = (alertParam) => {
    const {
        title,
        description,
        confirmButtonText,
        cancelButtonText,
        onConfirmButton,
        onCancelButton,
        icon = undefined,
        type = "primary",
        swalOptions = {} } = alertParam

    ReactSwal.fire({
        customClass: {
            container: "custom-alert alert-confirm",
        },
        html: <CustomAlertContent title={title}
            description={description}
            icon={icon}
            showCancelButton={true}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
            onConfirmButtonClicked={onConfirmButton}
            onCancelButtonClicked={onCancelButton}
            type={type} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        ...swalOptions
    })
}

const confirmWithRemarks = (alertParam) => {
    const {
        title,
        description,
        confirmButtonText,
        cancelButtonText,
        onConfirmButton,
        onCancelButton,
        icon = undefined,
        type = "primary",
        swalOptions = {},
        withRemarks = true,
        isRequiredRemarks = false,
        maxRemarks = 4000
    } = alertParam

    ReactSwal.fire({
        customClass: {
            container: "custom-alert alert-confirm",
        },
        html: <CustomAlertContent title={title}
            description={description}
            icon={icon}
            showCancelButton={true}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
            onConfirmButtonClicked={onConfirmButton}
            onCancelButtonClicked={onCancelButton}
            type={type}
            withRemarks={withRemarks}
            isRequiredRemarks={isRequiredRemarks}
            maxRemarks={maxRemarks} />,
        showConfirmButton: false,
        allowOutsideClick: false,
        ...swalOptions
    })
}

const taskExpiredAlert = (params = {}) => {

    ReactSwal.fire({
        customClass: {
            container: "custom-alert alert-error",
        },
        html: <CustomAlertContent
            icon={<ExclamationCircleOutlined />}
            type="error"
            title={"Workflow Task Expired!"}
            description={"Your workflow task has expired."}
            confirmButtonText='Ok' />,
        showConfirmButton: false,
        allowOutsideClick: false,
    })
}

const CustomAlert = {
    success,
    confirm,
    confirmWithRemarks,
    error,
    taskExpiredAlert
}

export default CustomAlert
