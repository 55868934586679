import * as React from "react";

function IconPurchaseOrder(props) {
    return (
        <svg
            width={61}
            height={60}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M40.958 56.25a4.375 4.375 0 100-8.75 4.375 4.375 0 000 8.75zM20.958 56.25a4.375 4.375 0 100-8.75 4.375 4.375 0 000 8.75zM12.433 9.85l-.5 6.125c-.1 1.175.825 2.15 2 2.15h38.275c1.05 0 1.925-.8 2-1.85.325-4.425-3.05-8.025-7.475-8.025H16.008c-.25-1.1-.75-2.15-1.525-3.025-1.25-1.325-3-2.1-4.8-2.1h-4.35c-1.025 0-1.875.85-1.875 1.875s.85 1.875 1.875 1.875h4.35c.775 0 1.5.325 2.025.875.525.575.775 1.325.725 2.1zM51.609 21.875h-38.35c-1.05 0-1.9.8-2 1.825l-.9 10.875c-.35 4.275 3 7.925 7.275 7.925h27.8c3.75 0 7.05-3.075 7.325-6.825L53.584 24c.1-1.15-.8-2.125-1.975-2.125z"
                fill="#1C64F2"
            />
        </svg>
    );
}

export default IconPurchaseOrder;