import { generateUUID } from 'utils/Helper'
import { useState } from 'react'
import { errorToast } from 'utils/Toast'
import { useEffect } from 'react';
import DevLog from 'utils/DevLog';
// import { CloudUploadOutlined } from '@ant-design/icons';

export default function InputUploadFile({
    onSelected = () => { },
    // onClick = () => { },
    // onFocus = () => { },
    mediaType,
    maxSize,
    txtButton = "Choose file",
    icon = null,
    multiple = false,
    sizeFileList = 0,
    labelClass,
    uploadRef }) {

    const [acceptExtention, setAcceptExtention] = useState("")
    const generateId = generateUUID()
    useEffect(() => {
        let extentionWithDot = ""
        mediaType?.split(";")?.map((f, i) => {
            if (i === 0) {
                return extentionWithDot += "." + f
            } else {
                return extentionWithDot += ",." + f
            }
        })
        setAcceptExtention(extentionWithDot)
    }, [mediaType])

    function validateFileExtension(filename, acceptExtension) {
        let allowedExtensions = acceptExtension.split(',');
        let fileExtension = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
        let isValidExtension = false;

        for (let i = 0; i < allowedExtensions.length; i++) {
            if (allowedExtensions[i].toLowerCase().indexOf(fileExtension) !== -1) {
                isValidExtension = true;
                break;
            }
        }

        return isValidExtension;
    }

    const onFileChanged = async (event) => {
        try {
            let fileName = event?.target?.files[0]?.name
            let size = event.target.files[0]?.size
            let file = event.target.files[0]

            if (!validateFileExtension(fileName, acceptExtention)) {
                event.target.value = ""
                return errorToast("Incorrect file format")
            }

            // let tmpSumSize = size + sizeFileList
            // if (sizeFileList !== 0) {
            //     if (tmpSumSize > maxSize) {
            //         event.target.value = ""
            //         return errorToast("The file size exceeds the maximum limit")
            //     }
            // } else {
            //     if (size > maxSize) {
            //         event.target.value = ""
            //         return errorToast("The file size exceeds the maximum limit")
            //     }
            // }

            if (size > maxSize) {
                event.target.value = ""
                return errorToast("The file size exceeds the maximum limit")
            }

            if (file) {
                event.target.value = ""
                onSelected(file, fileName, size)
            }

        } catch (error) {
            DevLog('error', error)
        }
    }

    return (
        <div className='wrap-input-upload-file'>
            <input type="file" id={`input-upload-file-upload-${generateId}`} hidden accept={acceptExtention} onChange={onFileChanged} multiple={multiple} />
            <label htmlFor={`input-upload-file-upload-${generateId}`} className={`${labelClass}`} ref={uploadRef}>
                {/* <span style={{ marginRight: "4px" }}>{<CloudUploadOutlined />}</span> */}
                <>
                    {icon}
                    {txtButton}
                </>
            </label>
        </div>
    )
}
