import { InputNumber } from 'antd'
import React from 'react'
import { formatterAmount, setFieldValues } from 'utils/Helper'

export default function InputNumberCustom(props) {
    const {
        form,
        name,
        customvalue,
        withformat = false,

        min,
        max,
        disabled,
        style = null,
        value,
        id,
        controls = false,
        precision = 2,
        className,
        onBlur = () => { },
        onFocus = () => { },
        onChange = () => { },
        decimalSeparator = ',',
        size = "middle",
        addonAfter = null
    } = props
    // console.log("withformat", withformat);
    // console.log("style", style);

    const handleOnBlur = () => {
        //ini untuk format uang tidak ada control atas/bawah
        if (withformat && !controls) {
            formatOnBlur(form, name, customvalue)
            onBlur()
        } else {
            if (!customvalue || customvalue === 0) {
                setFieldValues(form, name, 0)
            } else {
                setFieldValues(form, name, customvalue)
            }
        }
    }

    const handleOnFocus = (e) => {
        //ini untuk format uang tidak ada control atas/bawah
        if (withformat && !controls) {
            unFormatOnFocus(form, name, customvalue)
            onFocus()
        } else {
            e.target.select()
            // if (!customvalue || customvalue === 0) {
            //     setFieldValues(form, name, customvalue || null)
            // }
        }
    }

    return (
        <InputNumber
            className={`custom-input-number ${className} ${!controls ? "withformat" : ""}`}
            precision={precision}
            decimalSeparator={decimalSeparator}
            controls={controls}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onChange={onChange}
            disabled={disabled}
            id={id}
            value={value}
            style={{ ...style, width: "100%" }}
            min={min}
            max={max}
            name={name}
            size={size}
            addonAfter={addonAfter}
            onStep={(value) => value || 0}
        />
    )
}

const formatOnBlur = (form, nameField, value) => {
    setFieldValues(form, nameField, value ? formatterAmount(value) : 0)
}

const unFormatOnFocus = (form, nameField, value) => {
    if (!value || value === 0) {
        setFieldValues(form, nameField, null)
    } else {
        setFieldValues(form, nameField, value)
    }
}