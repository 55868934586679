import { atom } from "jotai";
import dayjs from "dayjs";
import Constant from "utils/Constants";

export const INIT_HEADER_VS_ATOM = {
    vsId: null,
    rfqId: null,
    rfqNo: null,
    // rfqSubject: "",
    vsTypeId:null,
    vsSubject: "",
    remarks: "",
    vsNo: null,
    vsDate: dayjs().format(Constant.stateDateFormat),
    quotationSubmissionDueDate:"",
    targetAppointmentDate:"",
    tenderBriefingDate:"",
    appointedVendorId: null,
    appointedVendorName: "",
    businessOwnerRecommendation: "",
    procurementRecommendation: "",
    vsStatus: "",

    // totalAmountGoodsBeforeTax: 0,
    // totalAmountServicesBeforeTax: 0,
    // totalAmountGoodsVatPercentage: 0,
    // totalAmountServicesVatPercentage: 0,
    // goodsVatPercentage: 0,
    // servicesVatPercentage: 0,
    // totalGoodsAmountWithTax: 0,
    // totalServicesAmountWithTax: 0,
    // grandTotal: 0,
}
export const headerVSAtom = atom(INIT_HEADER_VS_ATOM);
export const vendorListAtom = atom([])
export const attachmentsVS = atom([])
export const remarksApproval = atom("")

// {
//     "vsId": null,
//     "rfqId": "00000000-0000-0000-0000-000000000000",
//     "vsTypeId": "00000000-0000-0000-0000-000000000000",
//     "vsSubject": null,
//     "remarks": null,
//     "vsNo": null,
//     "vsDate": null,
//     "quotationSubmissionDueDate": null,
//     "targetAppointmentDate": null,
//     "listVendorDetails": [
// 	{
// 		"vendorSelectionDetailId":"",
// 		"vendorId":"",
// 		"lastQuotationAmount":10000,
// 		"firsQuotationAmount":10000
		
// 	}
	
// 	]
// }