import { atom } from "jotai"

export const INIT_HEADER_ITEM_CATALOG = {
    contractCatalogId: null,
    contractCatalogNo: "",
    vendorId: null,
    vendorName: "",
    validFrom: null,
    validTo: null,
    isActive: true,
}
export const headerItemCatalog = atom(INIT_HEADER_ITEM_CATALOG)
export const goodsItemItemCatalogAtom = atom([])
export const servicesItemItemCatalogAtom = atom([])