import React, { useEffect, useRef, useState } from "react"
import { SyncOutlined } from '@ant-design/icons';
import { Button } from "antd";
import Constant from "utils/Constants";
import DevLog from "utils/DevLog";

function randomColor() {
    let r = Math.floor(Math.random() * 256);
    let g = Math.floor(Math.random() * 256);
    let b = Math.floor(Math.random() * 256);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
}

const useSimpleCaptcha = () => {
    const [captchaText, setCaptchaText] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(false);
    const captchaCanvasRef = useRef(null); // Ref untuk elemen <canvas>

    useEffect(() => {
        generateCaptcha();
    }, []);

    const generateCaptcha = () => {
        let captcha = '';
        let canvasWinth = 250;
        let canvasHeight = 60;
        const canvas = captchaCanvasRef.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            for (let i = 0; i <= Constant.lengthCaptcha - 1; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                captcha += characters[randomIndex];

                let sDeg = (Math.random() * 10 * Math.PI) / 180;
                let x = 30 + i * 10;
                let y = 30 + Math.random() * 8;

                // Set canvas size and font
                canvas.width = canvasWinth;
                canvas.height = canvasHeight;

                context.font = 'bold 26px Arial';
                context.translate(x, y);
                context.rotate(sDeg);

                context.fillStyle = randomColor();
                context.fillText(captcha, 0, 0);

                context.rotate(-sDeg);
                context.translate(-x, -y);
            }
            for (let i = 0; i <= 5; i++) {
                context.strokeStyle = randomColor();
                context.beginPath();
                context.moveTo(
                    Math.random() * canvasWinth,
                    Math.random() * canvasHeight
                );
                context.lineTo(
                    Math.random() * canvasWinth,
                    Math.random() * canvasHeight
                );
                context.stroke();
            }
            for (let i = 0; i < 30; i++) {
                context.strokeStyle = randomColor();
                context.beginPath();
                let x = Math.random() * canvasWinth;
                let y = Math.random() * canvasHeight;
                context.moveTo(x, y);
                context.lineTo(x + 1, y + 1);
                context.stroke();
            }
            setCaptchaText(captcha);
            setInputValue('');
            setIsValid(false);
        } else {
            DevLog('not generating captcha')
        }
    };

    const handleInputCaptchaChange = (value) => {
        setInputValue(value);
        setIsValid(value === captchaText);
    };

    const captchaUI = (
        <div className="wrap-simple-captcha">
            <div>
                <canvas
                    className="captcha-text"
                    ref={captchaCanvasRef}
                    width="120"
                    height="40"
                />
            </div>
            <div className="wrap-captcha-refresh">
                <Button
                    type='dashed'
                    className="captcha-refresh"
                    onClick={generateCaptcha}
                    icon={<SyncOutlined />} />
            </div>
        </div>

    );

    return {
        inputValue,
        isValid,
        captchaText,
        handleInputCaptchaChange,
        generateCaptcha,
        captchaUI,
    };
};

export default useSimpleCaptcha