import CellTable from 'components/Common/CellTable';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import React, { useEffect, useState } from 'react'
import usePRRepo from 'services/repo/usePRRepo';
import { builderName, displayDate, sorterTable } from 'utils/Helper';

export default function SectionWorkflowHistory({ prId }) {
    const { isLoading: isLoadingWfHistory, workflowHistory } = usePRRepo(false)
    const [dataWfHistory, setDataWfHistory] = useState([])
    const [totalData, setTotalData] = useState(0)

    const columns = [
        {
            title: 'User',
            dataIndex: 'actionBy',
            width: "100px",
            render: (actionBy) => {
                return builderName(actionBy?.employee?.firstName, actionBy?.employee?.lastName)
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: "60px",
            // sorter: true,
        },
        {
            title: 'Action Date',
            dataIndex: 'actionDatetime',
            sorter: true,
            width: "130px",
            render: (actionDatetime) => {
                return displayDate(actionDatetime, true)
            }
        },
        {
            title: 'Status',
            dataIndex: 'statusAfterAction',
            width: "250px",
            render: (data) => <CellTable data={data} />
            // sorter: true,
        },
        {
            title: 'Remark',
            dataIndex: 'remarks',
            width: "350px",
            render: (data) => <CellTable data={data} />
            // sorter: true,
        },
    ];

    const [tableParams, setTableParams] = useState({
        pageNumber: 1,
        pageSize: 0,
        filter: {
            prId: prId
        },
        orderBy: "actionDatetime",
        isAscending: false
    });

    const fetchDataWfHistory = async (prId) => {
        const payload = { ...tableParams, filter: { prId: prId } }
        const res = await workflowHistory(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setDataWfHistory(res?.data?.listData)
            setTotalData(res?.data?.totalRecords)
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams(prevParams => ({
            ...prevParams,
            pageNumber: pagination?.current,
            pageSize: pagination?.pageSize,
            orderBy: sorter?.field || prevParams?.orderBy,
            isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
        }));

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams?.pageSize) {
            setDataWfHistory([]);
        }
    };

    useEffect(() => {
        if (prId) {
            fetchDataWfHistory(prId)
        }
    }, [prId, JSON.stringify(tableParams)])

    return (
        <div className='pr-section-wf-history'>
            <CustomAntdTable
                rowKey={"wfHistoryId"}
                columns={columns}
                dataSource={dataWfHistory}
                tableParam={tableParams}
                totalData={totalData}
                loading={isLoadingWfHistory}
                onChange={handleTableChange}
                ignoreRowClick={true}
                pagination={false}
            />
        </div>
    )
}
