import { Card, Col, Form, Row, } from 'antd';
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer';
import React, { useEffect, useState } from 'react';
import Constant from 'utils/Constants';
import { formatterAmount, setFieldValues } from 'utils/Helper';
import dayjs from 'dayjs';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import ButtonAction from 'components/Common/ButtonAction';
import { useAtom } from 'jotai';
import { goodsItemRFQAtom, serviceItemRFQAtom } from 'store/RFQ/RFQAtom';
import { successToast } from 'utils/Toast';
import { CustomFormItemNumber, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem';
import { ButtonPrimary } from 'components/Common/Buttons';

export default function BaseRFQItemsDetail(props) {
    const { isOpen, onClose, isView = false, data = null, loading = false, type = Constant.typeItemRFQ.GOODS } = props;
    const [form] = Form.useForm();
    // const [dataTable, setDataTable] = useState([])
    const [goodsItem, setGoodsItem] = useAtom(goodsItemRFQAtom)
    const [serviceItem, setServiceItem] = useAtom(serviceItemRFQAtom)
    const [dataEdit, setDataEdit] = useState(null);

    useEffect(() => {
        if (data) {
            let currentData = null
            if (type === Constant.typeItemRFQ.GOODS) {
                currentData = goodsItem.find(item => item.itemId === data?.itemId);
                setDataEdit(currentData);
            }
            if (type === Constant.typeItemRFQ.SERVICES) {
                currentData = serviceItem.find(item => item.itemId === data?.itemId);
                setDataEdit(currentData);
            }
            setFieldValues(form, "itemName", currentData?.itemName);
            setFieldValues(form, "itemCategory", currentData?.itemCategoryName);
            setFieldValues(form, "itemDescription", currentData?.description);
            setFieldValues(form, "itemRemarks", currentData?.remarks);
            setFieldValues(form, "itemRequiredDate", dayjs(currentData?.requiredDate));
            setFieldValues(form, "itemQty", currentData?.quantity);
            setFieldValues(form, "subTotal", formatterAmount(currentData?.subTotal));
        }
    }, [data, type, isView]);

    const handleCloseDrawer = () => {
        onClose();
    };

    const handleDelete = (record, index) => {
        //delete on table
        let tmpDatas = [...dataEdit.listItemPrDetails]
        let newItems = tmpDatas.filter(el => el.prNo !== record?.prNo);
        setDataEdit(prev => ({
            ...prev,
            listItemPrDetails: newItems
        }))
        successToast(Constant.message.deleteDataSuccess)
    }

    const columns = [
        {
            title: 'Action',
            key: 'action',
            hidden: isView ? true : false,
            render: (_, record, index) => {
                let buttons = !isView ? ['delete'] : [];
                return (
                    <>
                        <ButtonAction
                            buttons={buttons}
                            onDelete={() => handleDelete(record, index)}
                        />
                    </>
                )
            }
        },
        {
            title: 'PR No',
            dataIndex: 'prNo',
        },
        {
            title: 'Remark',
            dataIndex: 'remarks',
            render: (_, record) => record?.prItemDetail?.remarks
        },
        {
            title: 'PR PIC',
            dataIndex: 'picName',
            render: (_, record) => record?.prItemDetail?.picName
        },
        {
            title: 'Division',
            dataIndex: 'divisionName',
            render: (_, record) => record?.prItemDetail?.divisionName
        },
        {
            title: 'Department',
            dataIndex: 'departmentName',
            render: (_, record) => record?.prItemDetail?.departmentName
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            align: "right",
            render: (_, record) => record?.prItemDetail?.quantity
        },
        {
            title: 'UOM',
            dataIndex: 'uomName',
            render: (_, record) => record?.prItemDetail?.uomName
        },
    ].filter(item => !item.hidden)

    const handleSave = () => {
        if (type === Constant.typeItemRFQ.SERVICES) {
            let tmpItemAtom = [...serviceItem]
            const findIndex = tmpItemAtom.findIndex(x => x.itemId === data?.itemId)
            let currData = tmpItemAtom[findIndex]
            currData.remarks = dataEdit?.remarks
            // setFieldValues(initForm, `service_remarks_${findIndex}`, dataEdit?.remarks);

            //items
            currData.listItemPrDetails = [...dataEdit?.listItemPrDetails]
            currData.quantity = currData.listItemPrDetails?.reduce((prev, cur) => {
                return prev + cur?.prItemDetail.quantity
            }, 0)

            if (currData.listItemPrDetails?.length < 1) {
                const filterItem = tmpItemAtom.filter(x => x.itemId !== data?.itemId)
                setServiceItem(filterItem)
            } else {
                setServiceItem(tmpItemAtom)
            }
        }
        if (type === Constant.typeItemRFQ.GOODS) {
            let tmpItemAtom = [...goodsItem]
            const findIndex = tmpItemAtom.findIndex(x => x.itemId === data?.itemId)
            let currData = tmpItemAtom[findIndex]
            currData.remarks = dataEdit?.remarks
            // setFieldValues(initForm, `goods_remarks_${findIndex}`, dataEdit?.remarks);

            //items
            currData.listItemPrDetails = [...dataEdit?.listItemPrDetails]
            currData.quantity = currData.listItemPrDetails?.reduce((prev, cur) => {
                return prev + cur?.prItemDetail.quantity
            }, 0)

            if (currData.listItemPrDetails?.length < 1) {
                const filterItem = tmpItemAtom.filter(x => x.itemId !== data?.itemId)
                setGoodsItem(filterItem)
            } else {
                setGoodsItem(tmpItemAtom)
            }
        }
        onClose();
    }

    // console.log("goodsItem", goodsItem[0].listItemPrDetails);
    // console.log("serviceItem", serviceItem.listItemPrDetails);


    return (
        <BaseDrawer
            title={`${type === 1 ? 'Goods' : 'Services'} Item Detail`}
            placement="right"
            rootClassName={"rfq-detail-items"}
            size="large"
            onClose={handleCloseDrawer}
            open={isOpen}
            loading={loading}
            extras={
                <>
                    {!isView && <ButtonPrimary
                        type="primary"
                        onClick={handleSave}
                        buttonText='Save'
                    />}
                </>
            }
        >
            <Card className='card-item-detail'>
                <Form form={form} layout="vertical" name="form-view-detail-items">
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <CustomFormItemText
                                nameFormItem='itemName'
                                label='Name'
                                value={dataEdit?.itemName}
                                isView={true}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <CustomFormItemText
                                nameFormItem='itemCategory'
                                label='Category'
                                value={dataEdit?.itemCategoryName}
                                isView={true}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <CustomFormItemText
                                nameFormItem='itemDescription'
                                label='Description'
                                value={dataEdit?.itemDescription}
                                isView={true}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <CustomFormItemNumber
                                nameFormItem='itemQty'
                                nameInput='itemQty'
                                label='Quantity'
                                customvalue={dataEdit?.quantity}
                                precision={dataEdit?.uomAllowDecimal ? 2 : 0}
                                addonAfter={dataEdit?.uomName || 'UOM'}
                                isView={true}
                            />
                        </Col>
                        <Col xs={24}>
                            <CustomFormItemTextArea
                                nameFormItem='itemRemarks'
                                label='Remarks'
                                rows={2}
                                value={dataEdit?.remarks}
                                isView={isView}
                                onChange={(e) => setDataEdit(prev => ({ ...prev, remarks: e.target.value }))}
                            />
                        </Col>
                    </Row>
                </Form>

                <div className='mt-1'>
                    <CustomAntdTable
                        columns={columns}
                        dataSource={dataEdit?.listItemPrDetails || []}
                        rowKey={"prNo"}
                        pagination={false}
                        ignoreRowClick={true}
                    />
                </div>
            </Card>
        </BaseDrawer >
    );
}
