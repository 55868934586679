import toast from 'react-hot-toast'

/**
 * 
 * @param {String} message 
 * @param {import('react-hot-toast').ToastOptions} options 
 * @returns {String}
 */
export const successToast = (message, options = {}) => {
    return toast.success(message, { ...options, className: 'quickacq-toast-success', duration: 5000 })
}

/**
 * 
 * @param {String} message 
 * @param {import('react-hot-toast').ToastOptions} options 
 * @returns {String}
 */
export const errorToast = (message, options = {}) => {
    return toast.error(message, { ...options, className: 'quickacq-toast-error', duration: 5000 })
}

export const dismissAllToast = () => {
    toast.dismiss()
}

export const dismissToast = (toastId) => {
    toast.dismiss(toastId)
}

export const loadingToast = (message, options = {}) => {
    toast.loading(message, { ...options, className: 'quickacq-toast-loading' })
}

export const loadingToastId = (message, id) => {
    toast.loading(message, { id: id, className: 'quickacq-toast-loading' })
}