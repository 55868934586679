import Swal from "sweetalert2"
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ButtonCancelAction, ButtonConfirmedAction } from "components/Common/Buttons";
import { Form, Input } from "antd";
import { useState } from "react";
import IconRequired from "components/Common/IconRequired";

const CustomAlertContent = ({ title, description, icon = <QuestionCircleOutlined />, confirmButtonText = 'Yes', cancelButtonText = "Cancel", type = "primary", showCancelButton = false, onConfirmButtonClicked = () => { }, onCancelButtonClicked = () => { }, withRemarks = false, isRequiredRemarks = false, maxRemarks = 4000 }) => {

    const [form] = Form.useForm()
    const [remarks, setRemarks] = useState("")

    const onPreConfirmButtonClicked = () => {
        if (withRemarks && isRequiredRemarks) {
            form.validateFields()
                .then(() => {
                    Swal.close()
                    onConfirmButtonClicked(remarks)
                })
                .catch(() => {
                    // errorToast(Constant.message.errorForm)
                });
        } else {
            Swal.close()
            onConfirmButtonClicked(remarks)
        }
    }

    const onPreCancelButtonClicked = () => {
        Swal.close()
        onCancelButtonClicked()
    }

    return (
        <div className="custom-alert-content-container">
            <div className="custom-alert-content">
                <div className={`custom-alert-icon-container ${type}`}>
                    {icon}
                </div>

                <div className="custom-alert-main-content">
                    <p className="title">{title}</p>
                    <p className="description">
                        {isRequiredRemarks ? <IconRequired text={description} /> : description}
                    </p>
                    {withRemarks ?
                        <Form form={form} name="formConfirmRemarks">
                            <Form.Item
                                name="confirmRemarks"
                                rules={[
                                    {
                                        required: isRequiredRemarks,
                                        message: "Please input remark!"
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    placeholder="Please input remark"
                                    onChange={(e) => setRemarks(e.target.value)}
                                    rows={4}
                                    maxLength={maxRemarks}
                                />
                            </Form.Item>
                        </Form>
                        : null}
                </div>
            </div>

            <div className="custom-alert-footer">
                {showCancelButton && <ButtonCancelAction onClick={onPreCancelButtonClicked} buttonText={cancelButtonText} />}
                <ButtonConfirmedAction onClick={onPreConfirmButtonClicked} buttonText={confirmButtonText} />
            </div>
        </div>
    )
}

export default CustomAlertContent
