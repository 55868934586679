import { useState } from "react"
import unauthenticatedApi from "./unauthenticatedApi"
import api from "./api"
import { dismissAllToast, dismissToast, errorToast, loadingToastId } from "utils/Toast"
import LocalStorageUtils from "utils/LocalStorage"
import Constant from "utils/Constants"

export const BASE_API_CONSTANT = {
    METHOD: {
        POST: 'POST',
        PUT: 'PUT',
        GET: 'GET',
        DELETE: 'DELETE'
    }
}

const useBaseApiHook = (initialLoading = false, showError = true) => {
    const [isLoading, setIsLoading] = useState(initialLoading)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    let defaultConfig = {
        Accept: 'application/json',
        ContentType: 'application/json'
    }

    const executeApi = async (url, method, data, axiosOptions = {}, checkToken = true) => {
        setIsLoading(true)
        let newAxiosOptions = { ...axiosOptions, headers: { ...defaultConfig } }
        const { isUnauthenticated = false } = newAxiosOptions
        const _url = `${url}`
        switch (method) {
            case BASE_API_CONSTANT.METHOD.POST:
                try {
                    let response;
                    // debugger
                    if (isUnauthenticated) response = await unauthenticatedApi.post(_url, data, newAxiosOptions)
                    else {
                        if (checkToken) {
                            if (!LocalStorageUtils.checkTokenExpired()) {
                                response = await api.post(_url, data, newAxiosOptions)
                            } else {
                                return false
                            }
                        } else {
                            response = await api.post(_url, data, newAxiosOptions)
                        }
                    }
                    if (response?.status === 200) {
                        setIsLoading(false)
                        if (!response?.data?.isValid || response?.data?.statusCode !== 200) {
                            if (showError) {
                                dismissAllToast()
                                let errMessage = response?.data?.message ? response?.data?.message : `Failed getting data`
                                // if (!response?.data?.message) {
                                //     rollbar.error(`Error getting data on ${url}`, {
                                //         'response': response
                                //     })
                                // }
                                errorToast(errMessage)
                            }
                        }
                        setData(response?.data)

                        return response?.data
                    }
                    setIsLoading(false)
                    return false

                } catch (e) {
                    setIsLoading(false)
                    setData(null)
                    dismissAllToast()
                    setError(e?.response?.data)

                    if (e?.code == "ERR_NETWORK" || e?.message == "timeout exceeded") {
                        errorToast("Cannot connect to server")
                        // rollbar.error(`Can not connect to server in ${url}`, {
                        //     'user_token': LocalStorageUtils.jwtToken.get(),
                        //     'device': navigator?.userAgentData,
                        //     'reason': e?.message,
                        //     'response': e?.response
                        // })

                        return {
                            success: false,
                            isError: true,
                            message: `Can not connect to serever. Reason: ${e?.message}`,
                            error: e
                        }
                    }

                    if (showError) {
                        let errMessage = e?.response?.data?.message ? e?.response?.data?.message : `Failed getting data`
                        if (!e?.response?.data?.message) {
                            // rollbar.error(`Error getting data on ${url}`, {
                            //     'user_token': LocalStorageUtils.jwtToken.get(),
                            //     'response': e?.response
                            // })
                        }
                        errorToast(errMessage)
                    }
                    return false
                }

            default:
                try {
                    let response;

                    if (isUnauthenticated) response = await unauthenticatedApi.get(_url, newAxiosOptions)
                    else response = await api.get(_url, newAxiosOptions)
                    // debugger
                    if (response?.status === 200) {
                        setIsLoading(false)
                        setData(response?.data)
                        dismissAllToast()

                        if (!response?.data?.isValid || response?.data?.statusCode !== 200) {
                            let errMessage = response?.data?.message ? response?.data?.message : `Failed getting data`
                            // if (!response?.data?.message) {
                            //     rollbar.error(`Error getting data on ${url}`, {
                            //         'response': response
                            //     })
                            // }
                            if (showError) errorToast(errMessage)
                        }
                        return response?.data
                    }

                    return false

                } catch (e) {
                    setIsLoading(false)
                    setData(null)
                    dismissAllToast()

                    if (e?.code == "ERR_NETWORK" || e?.message == "timeout exceeded") {
                        errorToast("Cannot connect to server")
                        // rollbar.error(`Can not connect to server in ${url}`, {
                        //     'user_token': LocalStorageUtils.jwtToken.get(),
                        //     'device': navigator?.userAgentData,
                        //     'response': e?.response,
                        //     'reason': e?.message
                        // })

                        return {
                            success: false,
                            isError: true,
                            message: `Can not connect to server. Reason: ${e?.message}`,
                            error: e
                        }
                    }

                    if (showError) {
                        // if (!e?.response?.data?.message) {
                        //     rollbar.error(`Error getting data on ${url}`, {
                        //         'response': e?.response
                        //     })
                        // }
                        errorToast(e?.message)
                    }

                    setError(e?.response?.data)
                    if (e?.name === "AxiosError") {
                        return {
                            success: false,
                            isError: true,
                            message: e?.message,
                            error: e
                        }
                    }
                    return false
                }
        }
    }

    const executeApiFormData = async (url, formData) => {
        setIsLoading(true)
        // const token = LocalStorageUtils.jwtToken.get()

        // const formDataApi = axios.create({
        //     baseURL: Constant.baseApiURL,
        //     method: "post",
        //     headers: {
        //         "Accept": "*/*",
        //         'Content-Type': 'multipart/form-data',
        //         "Access-Control-Allow-Origin": "*",
        //         "Authorization": `Bearer ${token}`
        //     },
        //     data,
        // })

        try {
            // debugger
            let response;
            if (!LocalStorageUtils.checkTokenExpired()) {
                response = await api.post(url, formData, {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'multipart/form-data',
                        "Access-Control-Allow-Origin": "*",
                    }
                })
            } else {
                errorToast("Token expired, please login again!")
                return false
            }
            if (response?.status === 200) {
                setIsLoading(false)
                if (!response?.data?.isValid || response?.data?.statusCode !== 200) {
                    if (showError) {
                        dismissAllToast()
                        let errMessage = response?.data?.message ? response?.data?.message : `Failed getting data`
                        // if (!response?.data?.message) {
                        //     rollbar.error(`Error getting data on ${url}`, {
                        //         'response': response
                        //     })
                        // }
                        errorToast(errMessage)
                    }
                }
                setData(response?.data)
                return response?.data
            }
            setIsLoading(false)
            return false
        } catch (e) {
            setIsLoading(false)
            setData(null)
            dismissAllToast()
            setError(e?.response?.data)

            if (e?.code == "ERR_NETWORK" || e?.message == "timeout exceeded") {
                errorToast("Cannot connect to server")
                // rollbar.error(`Can not connect to server in ${url}`, {
                //     'user_token': LocalStorageUtils.jwtToken.get(),
                //     'device': navigator?.userAgentData,
                //     'reason': e?.message,
                //     'response': e?.response
                // })

                return {
                    success: false,
                    isError: true,
                    message: `Can not connect server. Reason: ${e?.message}`,
                    error: e
                }
            }

            if (showError) {
                let errMessage = e?.response?.data?.error ? e?.response?.data?.error : `Failed getting data`
                if (!e?.response?.data?.error) {
                    // rollbar.error(`Error getting data on ${url}`, {
                    //     'user_token': LocalStorageUtils.jwtToken.get(),
                    //     'response': e?.response
                    // })
                }
                errorToast(errMessage)
            }
            return false
        }
    }

    const handleBlobResponse = (blobFileData) => {
        // debugger
        const url = window.URL.createObjectURL(new Blob([blobFileData?.data]))
        const link = document.createElement('a')
        const split1 = blobFileData?.headers?.['content-disposition']?.split(';')?.[1];
        const fileName = split1.split('filename=')?.[1]?.replace(/"/g, "");
        link.href = url
        link.setAttribute('download', fileName) // nama file dan extension sesuaikan dengan file yang di download
        document.body.appendChild(link)
        link.click()
    }

    const download = async (url, data, isPreview = false) => {
        setIsLoading(true)
        loadingToastId(!isPreview ? Constant.message.downloading : Constant.message.loading, "DOWNLOAD");
        // const token = LocalStorageUtils.jwtToken.get()
        // const downloadApi = axios.create({
        //     baseURL: Constant.baseApiURL,
        //     responseType: 'blob',
        //     headers: {
        //         "Accept": '*/*',
        //         // "ContentType": 'application/json',
        //         "Content-Type": 'application/json',
        //         "Access-Control-Allow-Origin": '*',
        //         "Authorization": `Bearer ${token}`
        //     }
        // })

        try {
            let response;
            if (!LocalStorageUtils.checkTokenExpired()) {
                response = await api.post(url, data, {
                    responseType: 'blob',
                    headers: {
                        "Accept": '*/*',
                        "Content-Type": 'application/json',
                        "Access-Control-Allow-Origin": '*',
                    }
                })
            } else {
                return false
            }

            if (response?.status === 200) {
                //check file not found with content-disposition
                dismissToast("DOWNLOAD");
                if (response?.headers?.['content-disposition']) {
                    setIsLoading(false)
                    if (isPreview) {
                        const url = window.URL.createObjectURL(new Blob([response?.data]))
                        return url
                    } else {
                        handleBlobResponse(response, isPreview)
                        return true
                    }
                } else {
                    errorToast("file not found.")
                }

            } else {
                errorToast("file not found.")
            }
            setIsLoading(false)
            dismissToast("DOWNLOAD");
            return false
        } catch (e) {
            setIsLoading(false)
            errorToast(`An error occurred while downloading the file: ${e}`)
            setError(e?.response?.data)
            dismissToast("DOWNLOAD");
            return false
        }
    }

    const executeApiForImage = async (url, data) => {
        setIsLoading(true)
        // const token = LocalStorageUtils.jwtToken.get()
        // const api = axios.create({
        //     baseURL: Constant.baseApiURL,
        //     responseType: 'blob',
        //     headers: {
        //         "Accept": '*/*',
        //         // "ContentType": 'application/json',
        //         "Content-Type": 'application/json',
        //         "Access-Control-Allow-Origin": '*',
        //         "Authorization": `Bearer ${token}`
        //     }
        // })

        try {
            let response;
            if (!LocalStorageUtils.checkTokenExpired()) {
                response = await api.post(url, data, {
                    responseType: 'blob',
                    headers: {
                        "Accept": '*/*',
                        "Content-Type": 'application/json',
                        "Access-Control-Allow-Origin": '*',
                    }
                })
            } else {
                return false
            }
            if (response?.status === 200) {
                setIsLoading(false)
                return response?.data
            }
            setIsLoading(false)
            return false
        } catch (e) {
            setIsLoading(false)
            errorToast(e?.response?.data?.error || "Request Failed")
            setError(e?.response?.data)
            return false
        }
    }

    return {
        isLoading,
        data,
        error,
        executeApi,
        executeApiFormData,
        executeApiForImage,
        download,
    }
}

export default useBaseApiHook
