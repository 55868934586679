/**
 * September 19th
 * 2023
 * 
 * created:
 * Try Fanny Poerna Maulana
 */

import { Card, Col, Divider, Form, Input, Row, Space, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ButtonAction from 'components/Common/ButtonAction';
import { ButtonAdd } from 'components/Common/Buttons';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { dismissToast, loadingToastId } from 'utils/Toast';
import Constant from 'utils/Constants';
import { sorterTable } from 'utils/Helper';
import useWfRoleRepo from 'services/repo/useWfRoleRepo';
import useIsMobileHooks from 'hooks/useIsMobileHooks';
import useAccessPermissionHook from 'hooks/useAccessPermissionHook';
import { PRIVILEGE_ID } from 'utils/AccessPermissionConfig';
import SkeletonPage from 'components/Common/SkeletonPage';
import { ButtonAdvanceFilter } from 'components/Common/ButtonAdvanceFilter';
import Error403 from 'components/Error/Error403';
import BaseDrawerWfRole from 'components/MasterData/WfRole/BaseDrawerWfRole';
import BaseAdvanceFilter from 'components/CustomDrawer/BaseAdvanceFilter';
import CellTable from 'components/Common/CellTable';
import TagActiveInCell from 'components/Common/TagActiveInCell';


export default function WfRole() {
  useTopbarTitleHooks("Workflow Role");
  const { isLoading: isLoadingList, getWfRoleList } = useWfRoleRepo(false)
  const { isLoading: isLoadingById, getWfRoleById } = useWfRoleRepo(false)

  const isMobile = useIsMobileHooks()
  const [form] = Form.useForm()
  const [data, setData] = useState()
  const [totalData, setTotalData] = useState(0)
  const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false)
  const [isViewDrawer, setIsViewDrawer] = useState(false)
  const [dataEditDrawer, setDataEditDrawer] = useState(null)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [idHighlightRow, setIdHighlightRow] = useState(null)

  const { isHasPrivilege } = useAccessPermissionHook()
  const isLoadingPrivilege = useRef(true)
  const hasPrivilegePage = useRef(true)
  const [isLoadedPrivilege, setIsLoadedPrivilege] = useState(false)
  const [showButtonAdd, setShowButtonAdd] = useState(false)

  const [filters, setFilters] = useState({
    wfRoleId: "",
    wfRoleName: "",
    wfDescription: "",
    isActive: null,
  })
  const [tableParams, setTableParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    filter: { ...filters },
    orderBy: "wfRoleName",
    isAscending: true
  });

  const onSearch = (value) => {
    setTableParams(prev => ({
      ...prev,
      pageNumber: 1,
      filter: {
        ...filters
      }
    }))
  };

  const onSearchClick = () => {
    onSearch()
    setIsOpenAdvanceFilter(false)
  }

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
    setDataEditDrawer(null)
    setIdHighlightRow(null)
  };

  const fetchData = async () => {
    const payload = {
      ...tableParams
    }
    const res = await getWfRoleList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setData(res?.data?.listData)
      setTotalData(res?.data?.totalRecords)
      setTableParams(prevParams => ({ ...prevParams }));
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams(prevParams => ({
      ...prevParams,
      pageNumber: pagination?.current,
      pageSize: pagination?.pageSize,
      orderBy: sorter?.field || prevParams?.orderBy,
      isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
    }));
    if (pagination.pageSize !== tableParams?.pageSize) {
      setData([]);
    }
  };

  const handleAdd = () => {
    setDataEditDrawer(null)
    setIsViewDrawer(false)
    setIsOpenDrawer(true)
  }

  const handleRowClick = async (data) => {
    let id = data?.wfRoleId
    const payload = {
      wfRoleId: id
    }
    loadingToastId(Constant.message.loading, id)
    const res = await getWfRoleById(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setIsViewDrawer(true)
      setIsOpenDrawer(true)
      setDataEditDrawer(res?.data)
      setIdHighlightRow(res.data.wfRoleId)
    }
    dismissToast(id)
  }

  const handleEdit = async (data) => {
    let id = data?.wfRoleId
    const payload = {
      wfRoleId: id,
    }
    loadingToastId(Constant.message.loading, id)
    const res = await getWfRoleById(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setIsViewDrawer(false)
      setIsOpenDrawer(true)
      setDataEditDrawer(res?.data)
      setIdHighlightRow(res.data.wfRoleId)
    }
    dismissToast(id)
  }

  const fetchCheckPrivilege = async () => {
    let pvlgAddEdit = await isHasPrivilege(PRIVILEGE_ID.AddEditWfRole)
    let pvlgAccess = await isHasPrivilege(PRIVILEGE_ID.WfRole)
    Promise.all([pvlgAccess, pvlgAddEdit]).then(
      ([pvlgAccessValue, pvlgAddEditValue]) => {
        setShowButtonAdd(pvlgAddEditValue)
        isLoadingPrivilege.current = false
        if (pvlgAccessValue) {
          hasPrivilegePage.current = true;
          setIsLoadedPrivilege(true)
          fetchData();
        } else {
          hasPrivilegePage.current = false
        }
        dismissToast(PRIVILEGE_ID.AddEditWfRole);
      });
  }

  useEffect(() => {
    fetchCheckPrivilege()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoadedPrivilege) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (isLoadedPrivilege && !isMobile) {
        onSearch()
      }
    }, Constant.delayFilter);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterUI = () => {
    return (
      <Form layout="horizontal" form={form}>
        <Row gutter={16}>
          <Col xs={24} sm={10} md={8}>
            <Form.Item name='wfRoleName' label="Filter By">
              <Input
                allowClear
                placeholder={Constant.placeholder.enterName}
                onChange={(e) => {
                  setFilters(prev => ({
                    ...prev,
                    wfRoleName: e.target.value
                  }))
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  const columns = [
    {
      title: 'Action',
      key: 'action',
      width: "60px",
      render: (_, record) => (
        <>
          {showButtonAdd ?
            <ButtonAction
              buttons={['edit', 'view']}
              onEdit={handleEdit}
              onView={handleRowClick}
              data={record}
            /> :
            <ButtonAction
              buttons={['view']}
              onView={handleRowClick}
              data={record}
            />
          }
        </>
      )
    },
    {
      title: 'Name',
      dataIndex: 'wfRoleName',
      sorter: true,
      width: "200px",
      render: (data) => <CellTable data={data} />
    },
    {
      title: 'Description',
      dataIndex: 'wfDescription',
      width: "200px",
      render: (data) => <CellTable data={data} />
    },
    {
      width: "60px",
      title: 'Is Active',
      dataIndex: 'isActive',
      render: (isActive) => (
        <>
          <TagActiveInCell isActive={isActive} />
        </>
      ),
    },
  ];

  return (
    <>
      {hasPrivilegePage.current ?
        <Space direction="vertical" size="large" className='space-vertical-flex'>
          <Card size="big" bordered={false}>
            {isLoadingPrivilege.current ? <SkeletonPage /> :
              <>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={20} xl={18}>
                    {showButtonAdd &&
                      <Tooltip placement="top" title="add workflow role">
                        <ButtonAdd onClick={handleAdd} />
                      </Tooltip>
                    }
                  </Col>
                </Row>
                <Divider dashed className='mb-1 mt-1' />

                {isMobile ?
                  <ButtonAdvanceFilter onClick={() => setIsOpenAdvanceFilter(true)} />
                  : filterUI()
                }

                <CustomAntdTable
                  columns={columns}
                  dataSource={data}
                  rowKey={"wfRoleId"}
                  tableParam={tableParams}
                  totalData={totalData}
                  loading={isLoadingList}
                  onChange={handleTableChange}
                  rowClick={handleRowClick}
                  isOpenDrawer={isOpenDrawer}
                  idDataHighlight={idHighlightRow}
                />
              </>
            }
          </Card>
        </Space> : (<Error403 />)
      }

      {isOpenDrawer && (
        <BaseDrawerWfRole
          onClose={onCloseDrawer}
          dataEdit={dataEditDrawer}
          isView={isViewDrawer}
          loading={isLoadingById}
          onRefreshList={fetchData}
          setModeView={setIsViewDrawer}
          isAddEditPrivileged={showButtonAdd}
          handleUnhighlight={() => {
            setIdHighlightRow(null)
          }}
        />
      )}

      {isOpenAdvanceFilter &&
        <BaseAdvanceFilter
          onClose={() => setIsOpenAdvanceFilter(false)}
          onSearchClick={onSearchClick}>
          {filterUI()}
        </BaseAdvanceFilter>}
    </>
  )
}
