// import { Alert } from "antd";
import axios from "axios";
import Constant from "utils/Constants";
// import CustomAlert from "utils/CustomAlert";
import LocalStorageUtils from "utils/LocalStorage";
import { dismissAllToast, errorToast, successToast } from "utils/Toast";

export const AXIOS_ABORT_CONTROLLER = new AbortController();

const api = axios.create({
    baseURL: Constant.baseApiURL,
    withCredentials: true,
    signal: AXIOS_ABORT_CONTROLLER.signal,
    headers: {
        // Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        ContentType: 'application/json'
    }
})

api.interceptors.request.use(
    (config) => {
        const tokenBeforeRequest = LocalStorageUtils.jwtToken.get()

        config.headers['Authorization'] = `Bearer ${tokenBeforeRequest}`
        // config.headers['Accept'] = `application/json`
        // config.headers['ContentType'] = `application/json`
        config.headers = { ...config.headers }

        // if (!tokenBeforeRequest) {
        //     // CustomAlert.error({
        //     //     title: "Not Authenticated",
        //     //     description: "Your login token is expired or invalid. Please try logging in again",
        //     //     confirmButtonText: "Login Page",
        //     //     swalOptions: {
        //     //         allowEnterKey: false,
        //     //         allowEscapeKey: false,
        //     //         allowOutsideClick: false
        //     //     },
        //     //     onConfirmButton: () => {
        //     //         window.location.replace('/')
        //     //     }
        //     // })

        //     return false;
        // }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

let isLoggingOut = false;
api.interceptors.response.use(async function (response) {
    return response
}, async function (error) {
    if (error?.response?.status === 401) {
        if (error?.response?.data?.message === "InvalidToken") {
            dismissAllToast()
            errorToast(`${error?.response?.data?.message}. Please try logging in again.`)
            if (!isLoggingOut) {
                isLoggingOut = true;
                try {
                    await api.post('/User/revoke-cookies')
                } catch (logoutError) {
                } finally {
                    setTimeout(() => {
                        LocalStorageUtils.all.clear();
                        window.location.replace('/');
                        isLoggingOut = false;
                    }, 1000);
                }
            }
            return Promise.resolve(false);
        }

        dismissAllToast()
        errorToast(`${error?.response?.data?.message || "Err 401"}.`)
        return false;
    }

    // if (error?.response?.data?.statusCode === 401 && error?.response?.data?.message === "InvalidToken") {
    //     // debugger
    //     errorToast(`${error?.response?.data?.message} Please try logging in again.`)
    //     // return false
    //     // // loadingToastId(Constant.message.onLogout, 1)
    //     // AXIOS_ABORT_CONTROLLER.abort()
    //     // // debugger
    //     // const response = await api.post('/User/logout')
    //     // // dismissAllToast()
    //     // // dismissToast(1)
    //     // if (response?.data?.isValid && response?.data?.statusCode === 200) {
    //     //     successToast(Constant.message.logoutSuccess)
    //     //     LocalStorageUtils.all.clear()
    //     //     // navigate('/', { replace: true })
    //     //     window.location.replace('/')
    //     //     return false
    //     // } else {
    //     //     errorToast("Error Logout")
    //     //     return false
    //     // }
    // }

    // if (error?.response?.status === 401) {

    //     // AXIOS_ABORT_CONTROLLER.abort()

    //     // toast.dismiss()
    //     // await CustomAlert.error({
    //     //     title: "Not Authenticated",
    //     //     description: "Your login token is expired or invalid. Please try logging in again",
    //     //     confirmButtonText: "Login Page",
    //     //     swalOptions: {
    //     //         allowEnterKey: false,
    //     //         allowEscapeKey: false,
    //     //         allowOutsideClick: false
    //     //     },
    //     //     onConfirmButton: () => {
    //     //         window.location.replace('/')
    //     //     }
    //     // })

    //     // window.location.replace('/')
    //     return false;
    // }

    return Promise.reject(error)
})

api.interceptors.response.use(function (response) {
    return response
}, async function (error) {
    if (error?.response?.status === 500) {
        // debugger
        // AXIOS_ABORT_CONTROLLER.abort()

        dismissAllToast()
        errorToast(error?.response?.data?.message || "Err 500.")
        return false;
    }

    return Promise.reject(error)
})


export default api
