import { atom } from "jotai";
import moment from "moment";
import Constant from "utils/Constants";

export const INIT_HEADER_PO_ATOM = {
    poId: "",
    poSubmitDate: moment().format(Constant.stateDateFormat),
    deliveryAddress: "",
    remarksToVendor: "",

    poNo: null,
    vendorName: "",
    vendorPicName: "",
    vendorPicPhone: "",
    vendorPicEmail: "",
    vendorPicTaxId: "",
    rfqNo: null,
    isAttachmentSendToVendor: false,

    totalAmountGoodsBeforeTax: 0,
    totalAmountServicesBeforeTax: 0,
    totalAmountGoodsVatPercentage: 0,
    totalAmountServicesVatPercentage: 0,
    goodsVatPercentage: 0,
    servicesVatPercentage: 0,
    totalGoodsAmountWithTax: 0,
    totalServicesAmountWithTax: 0,
    grandTotalItem: 0,
    totalAmountPaymentTerm: 0,
    poStatus: "",

    isGrandTotalItemsChanged: false, //👈🏼 ini akan diubah ke true jika grandTotalItem, yang mengubah adalah vat dan discount input
}
export const headerPOAtom = atom(INIT_HEADER_PO_ATOM);
export const goodsItemPOAtom = atom([])
export const serviceItemPOAtom = atom([])
export const termPOList = atom([])
export const attachmentsPO = atom([])
export const remarksApproval = atom("")
export const termAndConditionsPOAtom = atom("")