import { Form, Space } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer';
import Error403 from 'components/Error/Error403';
import SectionHeader from '../SectionHeader';
import SectionItems from '../SectionItems';
import SectionPaymentTerm from '../SectionPaymentTerm';
import SectionWorkflowHistory from '../SectionWorkflowHistory';
import { ButtonCancelDocument, ButtonCloseDocument } from 'components/Common/Buttons';
import BaseSectionAttachment from '../BaseSectionAttachment';
import CustomCollapse from 'components/Common/CustomCollapse';
import SectionTermAndConditions from '../SectionTermAndConditions';

export default function BaseDetailPO(props) {
    const {
        onClose,
        loading = false,
        initForm,
        dataHeader,
        handleCancelPO = () => { },
        handleClosePO = () => { },
        isLoadingCancelPO = false,
        isLoadingClosePO = false,
        notAuthorized = false,
        showCancelPOButton = false,
        showClosePOButton = false,
    } = props

    const handleCloseDrawer = () => {
        onClose()
    }

    const itemsCollapseDefault = [
        {
            key: 1,
            label: "Purchase Order Header",
            forceRender: true,
            children: <SectionHeader initForm={initForm} isView={true} />
        },
        {
            key: 2,
            label: "Items",
            forceRender: true,
            className: "purchase-order-form-collapse-panel-items",
            children: <SectionItems initForm={initForm} isView={true} />
        },
        {
            key: 3,
            label: "Term & Conditions",
            forceRender: true,
            className: "purchase-order-form-collapse-panel-items",
            children: <SectionTermAndConditions isView={true} />
        },
        {
            key: 4,
            label: "Payment Term",
            forceRender: true,
            className: "purchase-order-form-collapse-panel-items",
            children: <SectionPaymentTerm initForm={initForm} isView={true} />
        },
        {
            key: 5,
            label: "Attachment",
            forceRender: true,
            children: <BaseSectionAttachment
                isView={true}
                initValueCheckboxHeader={dataHeader?.isAttachmentSendToVendor}
            />
        },
    ]

    let itemsCollapseWithWfHistory = [
        ...itemsCollapseDefault,
        {
            key: 6,
            label: "Workflow History",
            forceRender: true,
            children: <SectionWorkflowHistory poId={dataHeader?.poId} />
        }
    ]

    return (
        <BaseDrawer
            title={"Purchase Order"}
            placement="right"
            size="large"
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
            extras={
                <>
                    {showCancelPOButton && <ButtonCancelDocument onClick={handleCancelPO} loading={isLoadingCancelPO} buttonText='Cancel PO' />}
                    {showClosePOButton && <ButtonCloseDocument onClick={handleClosePO} loading={isLoadingClosePO} buttonText='Close PO' />}

                </>
            }
        >
            <Space direction="vertical" size="small" className='wrap-purchase-order-form space-vertical-flex' >
                {notAuthorized ? <Error403 /> :
                    <Form
                        name="form-purchase-order"
                        form={initForm}
                        layout='vertical'
                    >
                        <CustomCollapse
                            className="urchase-order-form-collapse"
                            defaultActiveKey={["1", "2", "3", "4", "5"]}
                            items={!dataHeader.isDraft ? itemsCollapseWithWfHistory : itemsCollapseDefault}
                        />
                    </Form>
                }
            </Space >
        </BaseDrawer>
    )
}
