import { atom } from "jotai";
import moment from "moment";
import dayjs from "dayjs";
import Constant from "utils/Constants";

export const INIT_HEADER_PR = {
    prId: null,
    prNo: null,
    prSubmitDate: moment().format(Constant.stateDateFormat),
    prPic: {
        userId: null,
        name: "",
        divisionId: null,
        divisionName: "",
        departmentId: null,
        departmentName: "",
    },
    remarks: "",
    prSubject: "",
    projectCode: "",
    costCenter: {
        costCenterId: null,
        code: "",
        description: "",
        name: "",
    },
    prTypeId: null,
    prTypeName: "",
    totalAmountGoodsBeforeTax: 0,
    totalAmountServicesBeforeTax: 0,
    totalAmountGoodsVatPercentage: 0,
    totalAmountServicesVatPercentage: 0,
    goodsVatPercentage: 0,
    servicesVatPercentage: 0,
    totalGoodsAmountWithTax: 0,
    totalServicesAmountWithTax: 0,
    prTotalAmount: 0,
    wfStatus: null,
    requiredDate: null,
    prStatus: ""
    // prDate: moment().format(Constant.stateDateFormat),
}
export const headerPRAtom = atom(INIT_HEADER_PR);

export const INIT_GOODS_ITEM_PR = {
    // sortKey: null,
    itemGoodsId: null,
    itemCategoryGoodsId: null,
    quantity: null,
    uomId: null,
    price: null,
    priceInOc: null,
    currencyId: null,
    requiredDate: moment().format(Constant.stateDateFormat),
    remarks: "",
    description: "",
    subTotal: 0,
    uomAllowDecimal: false,
    attachmentFile: ""
}
//export const goodsItemDetailPr = atom(INIT_GOODS_ITEM_PR)
export const goodsItemPr = atom([])

export const INIT_SERVICES_ITEM_PR = {
    // sortKey: null,
    itemId: null,
    itemServiceId: null,
    itemCategoryServiceId: null,
    quantity: null,
    uomId: null,
    price: null,
    priceInOc: null,
    currencyId: null,
    requiredDate: moment().format(Constant.stateDateFormat),
    remarks: "",
    description: "",
    subTotal: 0,
    uomAllowDecimal: false,
    attachmentFile: ""
}
//export const servicesItemDetailPr = atom(INIT_SERVICES_ITEM_PR)
export const servicesItemPr = atom([])

export const itemsPR = atom([])

export const attachmentPr = atom([])

export const remarksApproval = atom("")