import { Form, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import SectionHeaderPR from 'components/PurchaseRequest/SectionHeaderPR';
import SectionItems from 'components/PurchaseRequest/SectionItems';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import CustomAlert from 'utils/CustomAlert';
import { useAtom } from 'jotai';
import { attachmentPr, goodsItemPr, headerPRAtom, remarksApproval, servicesItemPr } from 'store/PurchaseRequest/purchaseRequestAtom';
import usePRRepo from 'services/repo/usePRRepo';
import { dismissToast, errorToast, loadingToastId, successToast } from 'utils/Toast';
import Constant from 'utils/Constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APP_ROUTE_PREFIX, RouteConstant } from 'routes/routesConstant';
import SectionWorkflowHistory from 'components/PurchaseRequest/SectionWorkflowHistory';
import DevLog from 'utils/DevLog';
import { ButtonBack, ButtonSubmit } from 'components/Common/Buttons';
import SectionRemarksApproval from 'components/Collapse.Item/SectionRemarksApproval';
import useFormPRHook from 'hooks/useFormPRHook';
import BaseSectionAttachment from 'components/PurchaseRequest/BaseSectionAttachment';
import useCheckWorkflowTaskHook from 'hooks/useCheckWorkflowTaskHook';
import CustomCollapse from 'components/Common/CustomCollapse';
import SkeletonPage from 'components/Common/SkeletonPage';


export default function RevisePR() {
    useTopbarTitleHooks("Revise Purchase Request");
    const { isLoading: isLoadingReSubmit, resubmitPR } = usePRRepo(false);
    const { isLoading: isLoadingGetPRById, getPRById } = usePRRepo(false);
    const { setDataFormPR, clearFormAndAtomPR, generateItemsPR } = useFormPRHook()
    const { isLoading: isLoadingCheckIsTaskExpired, checkIsTaskExpired } = useCheckWorkflowTaskHook()

    const [searchParams] = useSearchParams();
    const prId = searchParams.get('prId');
    const tId = searchParams.get('tId');
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState(["1", "2", "4", "5", "6"]);
    const [dataHeader,] = useAtom(headerPRAtom);
    const [dataGoodsItem,] = useAtom(goodsItemPr);
    const [dataServicesItem,] = useAtom(servicesItemPr);
    const [dataAttachment,] = useAtom(attachmentPr);
    const [errorListItem, setErrorListItem] = useState(false)
    const [dataRemarks, setDataRemarks] = useAtom(remarksApproval);
    const [isTaskExpired, setIsTaskExpired] = useState(true);

    function handleClick(key) {
        setActiveKey(key);
    }

    const checkErrorToOpenCollapse = (errors, nameFields) => {
        return errors.some(x => nameFields.some(f => x.name.indexOf(f) >= 0));
    };

    const fetchGetPRById = async (id) => {
        loadingToastId(Constant.message.loading, id)
        let response = await getPRById({ prId: id });
        if (response?.isValid && response?.statusCode === 200) {
            let data = response?.data;
            setDataFormPR(form, data)
        }
        dismissToast(id)
    };

    const validateItems = () => {
        if (dataGoodsItem.length <= 0 && dataServicesItem <= 0) {
            setErrorListItem(true)
            return false
        } else {
            setErrorListItem(false)
            return true
        }
    }

    const doResubmit = async () => {
        let payloadData = {
            prId: prId,
            prPicId: dataHeader?.prPic?.userId,
            remarks: dataHeader?.remarks,
            projectCode: dataHeader?.projectCode,
            costCenterId: dataHeader?.costCenter?.costCenterId,
            prTypeId: dataHeader?.prTypeId,
            prSubject: dataHeader?.prSubject,
            requiredDate: dataHeader.requiredDate,
            goodsVatPercentage: dataHeader?.goodsVatPercentage,
            servicesVatPercentage: dataHeader?.servicesVatPercentage,
            lastModifiedAt: dataHeader?.lastModifiedAt,
            listGoodsDetails: generateItemsPR(dataGoodsItem, Constant.ItemType.GOODS),
            listServiceDetails: generateItemsPR(dataServicesItem, Constant.ItemType.SERVICES),
            listAttachmentDetails: [...dataAttachment].filter(obj => obj.hasOwnProperty('prAttachmentId')),
        };

        const formData = new FormData();
        formData.append("data", JSON.stringify(payloadData));
        formData.append("remarks", dataRemarks);
        formData.append("taskId", tId);

        for (let i = 0; i < dataAttachment?.length; i++) {
            //just send a new file
            if (!dataAttachment[i]?.prAttachmentId) {
                formData.append("AttachmentHeader", dataAttachment[i]?.file);
            }
        }

        for (let i = 0; i < dataGoodsItem?.length; i++) {
            formData.append(`AttachmentGoods[${i}].itemId`, dataGoodsItem[i]?.itemId);
            if (dataGoodsItem[i]?.attachmentFile?.file) {
                formData.append(`AttachmentGoods[${i}].AttachmentContent`, dataGoodsItem[i]?.attachmentFile?.file);
            }
        }

        for (let i = 0; i < dataServicesItem?.length; i++) {
            formData.append(`AttachmentService[${i}].itemId`, dataServicesItem[i]?.itemId);
            if (dataServicesItem[i]?.attachmentFile?.file) {
                formData.append(`AttachmentService[${i}].AttachmentContent`, dataServicesItem[i]?.attachmentFile?.file);
            }
        }

        loadingToastId(Constant.message.savingData, formData);
        let response = await resubmitPR(formData);
        dismissToast(formData);

        if (response?.isValid && response?.statusCode === 200) {
            successToast(Constant.message.saveDataSuccess);
            navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.pendingTask);
        }
    }

    const validateSubmit = () => {
        form.validateFields()
            .then((values) => {
                if (validateItems()) {
                    doResubmit()
                } else {
                    errorToast(Constant.message.errorForm)
                }
            })
            .catch((errorInfo) => {
                errorToast(Constant.message.errorForm)
                if (checkErrorToOpenCollapse(errorInfo?.errorFields, ["prDate", "prPic", "costCenter", "prSubject", "prTypeId"])) {
                    setActiveKey(prev => ([...new Set([...prev, '1'])]));
                }
                if (!validateItems()) {
                    setActiveKey(prev => ([...new Set([...prev, '2'])]));
                }
            });
    }

    const handleReSubmit = (e) => {
        e.preventDefault()
        CustomAlert.confirm({
            title: "Resubmit Purchase Request!",
            description: `Are you sure you want to resubmit this data?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => validateSubmit()
        });
    };

    useEffect(() => {
        clearFormAndAtomPR(form, true, true)
        return () => {
            DevLog("cleanup state pr form");
            clearFormAndAtomPR(form, true)
        };
    }, []);

    useEffect(() => {
        if (prId && tId) {
            checkIsTaskExpired(tId, (status) => {
                setIsTaskExpired(status)
                fetchGetPRById(prId)
            })
        } else {
            navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.pendingTask)
        }
    }, [prId]);

    return (
        <Space direction="vertical" size="small" className='wrap-pr-form space-vertical-flex'>
            {(isLoadingCheckIsTaskExpired || isLoadingGetPRById) ? <SkeletonPage /> : <Form
                name="form-revise-pr"
                form={form}
                layout='vertical'
            >
                <CustomCollapse
                    title="Revise Purchase Request"
                    className="pr-form-collapse"
                    onChange={handleClick}
                    activeKey={activeKey} items={[
                        {
                            key: 1,
                            label: "Purchase Request Header",
                            forceRender: true,
                            children: <SectionHeaderPR initForm={form} isView={isTaskExpired} />
                        },
                        {
                            key: 2,
                            label: errorListItem ? <div>Items | <span className="error-message">Please Select Item</span></div> : "Items",
                            forceRender: true,
                            className: "pr-form-collapse-panel-items",
                            children: <SectionItems initForm={form} isView={isTaskExpired} />
                        },
                        {
                            key: 4,
                            label: "Attachments",
                            forceRender: true,
                            children: <BaseSectionAttachment initForm={form} isView={isTaskExpired}
                            />
                        },
                        {
                            key: 5,
                            label: "Resubmit Remark",
                            forceRender: true,
                            children: <SectionRemarksApproval initForm={form} isRequired={false} setDataRemarks={setDataRemarks} isView={isTaskExpired} />
                        },
                        {
                            key: 6,
                            label: "Workflow History",
                            forceRender: true,
                            children: <SectionWorkflowHistory prId={prId} />
                        }
                    ]} />

                <div style={{ display: "flex", gap: "10px", margin: "10px 0" }}>
                    <ButtonBack
                        onClick={() => navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.pendingTask)}
                        disabled={isLoadingGetPRById || isLoadingReSubmit}
                    />
                    <ButtonSubmit
                        buttonText='Resubmit'
                        onClick={handleReSubmit}
                        loading={isLoadingReSubmit}
                        disabled={isTaskExpired || isLoadingGetPRById}
                    />
                </div>
            </Form>}
        </Space>
    );
}

