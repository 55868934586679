import { Col, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import { setFieldValues } from 'utils/Helper';
import ButtonAction from 'components/Common/ButtonAction';
import { useAtom } from 'jotai';
import Constant from 'utils/Constants';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { errorToast, successToast } from 'utils/Toast';
import { headerRFQAtom, serviceItemRFQAtom } from 'store/RFQ/RFQAtom';
import LookupPRItems from '../Modal/LookupPRItems';
import CellTable from 'components/Common/CellTable';
import { ButtonAdd } from 'components/Common/Buttons';
import { CustomFormItemTextArea } from 'components/Common/CustomFormItem';

export default function ServiceItem(props) {
    const { initForm, isView = false, propsDrawer = () => { }, idRowHighlight = null } = props
    const [dataHeader,] = useAtom(headerRFQAtom)
    const [dataItems, setDataItems] = useAtom(serviceItemRFQAtom)
    const [isModalOpenAddService, setIsModalOpenAddService] = useState(false);
    const [ignoreRowClick, setIgnoreRowClick] = useState(false)
    const [rowHighlight, setRowHighlight] = useState(null)

    const onChangeInputRow = (field, value, index) => {
        let datas = [...dataItems];
        let currentData = datas[index]
        currentData[field] = value;
        setDataItems(datas)
    }

    //ITEMS
    const handleAddItem = () => {
        setIsModalOpenAddService(true)
    }

    const handleDeleteItem = (data, index) => {
        let tmpDatas = [...dataItems]
        let newItems = tmpDatas.filter(el => el.itemId !== data?.itemId);
        setDataItems(newItems)
        propsDrawer(false, rowHighlight, 0)
    }

    const handleViewItem = (data, isView) => {
        propsDrawer(true, data?.itemId, data, Constant.typeItemRFQ.SERVICES, isView)
    }

    const handleOnFocusAndBlur = (ignore) => {
        if (isView) {
            setRowHighlight(rowHighlight)
            setIgnoreRowClick(ignore)
            propsDrawer(null, rowHighlight, 0)
        }
    }

    const updateFieldTableValues = (index, data) => {
        setFieldValues(initForm, `service_remarks_${index}`, data.remarks);
    };

    const builderPrServiceDetail = (data) => {
        return {
            // ...data,
            prNo: data.prNo,
            remarks: data.remarks,
            picName: data.prPicFullName,
            divisionName: data.divisionName,
            departmentName: data.departmentName,
            quantity: data.quantity,
            uomName: data.uomName,
        }
    }

    const handleSelectItem = (data) => {
        //check item already add/selected
        const checkAlready = dataItems.some(x => x.itemId === data.itemId
            && x.listItemPrDetails?.some(y => y.prItemDetailId === data.prItemDetailId))
        if (checkAlready) {
            errorToast("Item already on list")
        } else {
            //if items already but different pr, sum the quantity,join remarks, and merge listItemPrDetails
            const indexSameItem = [...dataItems].findIndex(x => x.itemId === data.itemId)
            if (indexSameItem !== -1) {
                let currData = [...dataItems][indexSameItem]
                let joinRemarks = currData.remarks + (data.remarks ? ", " + data.remarks : "")
                let qty = Number(currData.quantity + data.quantity).toFixed(2)
                currData.quantity = Number(qty)
                currData.remarks = joinRemarks
                let newListServicePrDetails = [...currData.listItemPrDetails, {
                    prItemDetailId: data.prItemDetailId,
                    prItemDetail: builderPrServiceDetail(data),
                    prNo: data?.prNo
                }]
                let sortListServicePrDetails = newListServicePrDetails.sort((a, b) => a.prNo.localeCompare(b.prNo))
                currData.listItemPrDetails = sortListServicePrDetails
                updateFieldTableValues(indexSameItem, currData)
                setDataItems(dataItems)
                return successToast(Constant.message.itemAddSuccess)
            } else {
                //is full new data
                let newData = {
                    ...data,
                    itemCategoryId: data?.itemCategoryId,
                    itemCategoryName: data?.itemCategoryName,
                    itemId: data?.itemId,
                    itemTypeId: data?.itemTypeId,
                    itemCode: data?.itemCode,
                    itemName: data?.itemName,
                    description: data?.description,
                    uomId: data.uomId,
                    uomName: data.uomName,
                    remarks: data?.remarks,
                    listItemPrDetails: [{
                        prItemDetailId: data.prItemDetailId,
                        prItemDetail: builderPrServiceDetail(data),
                        prNo: data?.prNo
                    }]
                }
                setDataItems(prev => {
                    let newList = [...prev, newData]?.map((dt, index) => {
                        return {
                            ...dt,
                            sortKey: index
                        }
                    })
                    return newList.sort((a, b) => b.sortKey - a.sortKey);
                })
                return successToast(Constant.message.itemAddSuccess)
            }
        }
    }

    useEffect(() => {
        //update new field remarks
        [...dataItems]?.map((data, index) => {
            updateFieldTableValues(index, data);
        });
    }, [dataItems])

    useEffect(() => {
        setRowHighlight(idRowHighlight)
    }, [idRowHighlight])

    const columnsService = [
        {
            title: 'Action',
            key: 'action',
            align: "left",
            width: "60px",
            className: "custom-table-edit-row-input",
            render: (_, record, index) => {
                let buttons = !isView ? ['view', 'delete', 'edit'] : ['view'];
                return (
                    <>
                        <ButtonAction
                            buttons={buttons}
                            onView={() => handleViewItem(record, true)}
                            onDelete={() => handleDeleteItem(record, index)}
                            onEdit={() => handleViewItem(record, false)}
                        />
                    </>
                )
            }
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            className: "custom-table-edit-row-input",
            width: '200px',
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Remark',
            dataIndex: 'remarks',
            className: "custom-table-edit-row-input",
            width: '200px',
            render: (text, record, index) => {
                return (
                    <Col>
                        <CustomFormItemTextArea
                            nameFormItem={'service_remarks_' + index}
                            value={record?.remarks}
                            isView={isView}
                            rows={2}
                            maxLength={8000}
                            placeholder="Fill in Remarks"
                            onChange={(e) => {
                                onChangeInputRow("remarks", e.target.value, index)
                            }}
                            onFocus={() => handleOnFocusAndBlur(true)}
                            onBlur={() => handleOnFocusAndBlur(false)}
                            useInTable={true}
                        />
                    </Col>
                )
            }
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            align: 'right',
            width: '60px',
            className: "custom-table-edit-row-input",
        },
        {
            title: 'UOM',
            dataIndex: 'uomName',
            className: "custom-table-edit-row-input",
            width: '60px',
            render: (data) => <CellTable data={data} />
        },
    ];

    return (
        <>
            <div className='mb-2'>
                <p className="font-semibold">Services Item</p>
                {(!isView) && <Space className='mb-1'>
                    <ButtonAdd type="primary" onClick={() => handleAddItem(true)} icon={null} />
                </Space>
                }

                <CustomAntdTable
                    className='custom-table-edit-row table-services-item'
                    rowKey={"itemId"}
                    columns={columnsService}
                    dataSource={dataItems}
                    pagination={false}
                    ignoreRowClick={isView ? ignoreRowClick : true}
                    idDataHighlight={rowHighlight}
                    rowClick={isView ? (data, index) => handleViewItem(data, true) : () => { }}
                />
            </div>

            {isModalOpenAddService &&
                <LookupPRItems onClose={() => setIsModalOpenAddService(false)} onSelect={handleSelectItem} itemType={Constant.ItemType.SERVICES} rfqId={dataHeader?.rfqId} />
            }
        </>
    )
}