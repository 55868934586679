import GoodsItem from "./Items/GoodsItem";
import ServicesItem from "./Items/ServicesItem";
import { useEffect } from "react";
import { formatterAmount, setFieldValues } from "utils/Helper";
import { useAtom } from "jotai";
import { goodsItemPOAtom, headerPOAtom, serviceItemPOAtom, termPOList } from "store/PurchaseOrder/PurchaseOrder";
import CustomAntdTable from "components/Common/CustomAntdTable";
import IconRequired from "components/Common/IconRequired";
import { CustomFormItemNumber } from "components/Common/CustomFormItem";
import { Table, Typography } from "antd";
const { Text } = Typography;

const renderCustomFormItemNumber = (nameFormItem, nameInput, className, vatPercentage, initForm, isView, onChange) => {
    return (
        <CustomFormItemNumber
            nameFormItem={nameFormItem}
            nameInput={nameInput}
            className={className}
            rules={[
                {
                    validator: (_, value) => {
                        if (value < 0) {
                            return Promise.reject(new Error('VAT must be greater or equal to 0'));
                        } else {
                            return Promise.resolve();
                        }
                    }
                },
            ]}
            max="100"
            min="0"
            initForm={initForm}
            customvalue={vatPercentage}
            isView={isView}
            onChange={(val) => {
                onChange(val || 0);
            }}
            alignView="right"
            useInTable={true}
        />
    );
};

export default function SectionItems(props) {
    const { initForm, isView = false } = props;
    const [dataHeader, setDataHeader] = useAtom(headerPOAtom);
    const [dataTermList, setDataTermList] = useAtom(termPOList);
    const [dataItemGoods] = useAtom(goodsItemPOAtom);
    const [dataItemServices] = useAtom(serviceItemPOAtom);

    useEffect(() => {
        setDataHeader(prev => ({
            ...prev,
            grandTotalItem: dataHeader?.totalGoodsAmountWithTax + dataHeader?.totalServicesAmountWithTax
        }));
    }, [dataHeader?.totalGoodsAmountWithTax, dataHeader?.totalServicesAmountWithTax]);

    //trigger to change term, when grandTotalItem changed
    useEffect(() => {
        let grandTotal = dataHeader.grandTotalItem
        if (dataHeader.grandTotalItem > 0 && dataTermList?.length > 0 && dataHeader.isGrandTotalItemsChanged) {
            let totalAmountPaymentTerm = 0;
            let newTerm = [...dataTermList]?.map((data, index) => {
                let sumAmount = grandTotal * (data?.percentage / 100)
                setFieldValues(initForm, `term_amount_${index}`, sumAmount);
                totalAmountPaymentTerm += sumAmount
                return { ...data, amount: sumAmount }
            })
            setDataTermList(newTerm)
            setDataHeader(prev => ({ ...prev, totalAmountPaymentTerm: totalAmountPaymentTerm }))
        }
    }, [dataHeader.grandTotalItem])

    const columns = [
        {
            title: 'Item Type',
            dataIndex: 'itemType',
            className: "custom-table-edit-row-input",
            width: "80px",
        },
        {
            title: 'Total',
            dataIndex: 'totalBeforeTax',
            className: "custom-table-edit-row-input",
            width: "140px",
            align: 'right',
        },
        {
            title: <IconRequired text="VAT Rate (%)" />,
            dataIndex: 'vatRate',
            className: "custom-table-edit-row-input",
            width: "76px",
            align: 'right',
            render: (text, record, index) => {
                return (
                    <>
                        {record?.index === 1 && renderCustomFormItemNumber(`goodsTax`, `goodsTax`, 'goods-item-tax', dataHeader.goodsVatPercentage, initForm, isView, (val) => {
                            setDataHeader(prev => ({
                                ...prev,
                                goodsVatPercentage: val,
                                isGrandTotalItemsChanged: true
                            }));
                        })}
                        {record?.index === 2 && renderCustomFormItemNumber(`servicesTax`, (`servicesTax`), 'services-item-tax', dataHeader.servicesVatPercentage, initForm, isView, (val) => {
                            setDataHeader(prev => ({
                                ...prev,
                                servicesVatPercentage: val,
                                isGrandTotalItemsChanged: true
                            }));
                        })}
                    </>
                );
            }
        },
        {
            title: 'VAT Amount',
            dataIndex: 'vatAmount',
            className: "custom-table-edit-row-input",
            width: "120px",
            align: 'right',
        },
        {
            title: 'Total (After Tax)',
            dataIndex: 'totalAfterTax',
            className: "custom-table-edit-row-input",
            width: "140px",
            align: 'right',
        },
    ]

    return (
        <div className="purchase-order-section-items">
            <GoodsItem
                isView={isView}
                initForm={initForm}
            />
            <ServicesItem
                isView={isView}
                initForm={initForm}
            />
            {/* <Divider dashed className="divider-total-items" /> */}
            {/* {(dataItemGoods.length > 0 || dataItemServices.length > 0) && <div className="wrap-section-total">
                <div className="section-total">
                    <p>Grand Total (Goods & Service)</p>
                    <p className="amount">{formatterAmount(dataHeader?.grandTotalItem)}</p>
                </div>
            </div>} */}

            {
                (dataItemGoods.length > 0 || dataItemServices.length > 0) &&
                <>
                    <p className="font-semibold">Grand Total (Goods & Service)</p>
                    <CustomAntdTable
                        className='custom-table-edit-row table-goods-item'
                        rowKey={"index"}
                        columns={columns}
                        dataSource={[
                            dataItemGoods.length > 0 ? {
                                index: 1,
                                itemType: "Goods",
                                totalBeforeTax: formatterAmount(dataHeader.totalAmountGoodsBeforeTax),
                                vatRate: formatterAmount(dataHeader.goodsVatPercentage),
                                vatAmount: formatterAmount(dataHeader.totalAmountGoodsVatPercentage),
                                totalAfterTax: formatterAmount(dataHeader.totalAmountGoodsBeforeTax + dataHeader.totalAmountGoodsVatPercentage),
                            } : null,
                            dataItemServices.length > 0 ? {
                                index: 2,
                                itemType: "Service",
                                totalBeforeTax: formatterAmount(dataHeader.totalAmountServicesBeforeTax),
                                vatRate: formatterAmount(dataHeader.servicesVatPercentage),
                                vatAmount: formatterAmount(dataHeader.totalAmountServicesVatPercentage),
                                totalAfterTax: formatterAmount(dataHeader.totalAmountServicesBeforeTax + dataHeader.totalAmountServicesVatPercentage),
                            } : null,
                        ].filter(item => item !== null)}
                        pagination={false}
                        ignoreRowClick={true}
                        summary={pageData => (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell className="summary-item total-include-tax" colSpan={4}>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell className="summary-item total-include-tax" align="right">
                                        <Text>{formatterAmount(dataHeader?.grandTotalItem)}</Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        )}
                    />
                </>
            }
        </div>
    );
}
