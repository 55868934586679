import { formatterAmount } from "./Helper";

const generateColor = (length) => {
    let newArray = []
    const colors = [
        "#9df08d",
        "#36A2EB",
        "#FF9F40",
        "#FF6384",
        "#FFCD56",
        "#550527",
        "#688E26",
        "#F44708",
        "#A10702",
        "#7C7287",
        "#9DC0BC",
        "#D4AA7D",
        "#C86FC9",
        "#626C66",
        "#C11AC0",
        "#68C3D4",
        "#226CE0",
        "#E6AF2E",
        "#B9314F",
        "#BEB7A4",
        "#F8F32B",
        "#90A9B7",
    ]
    for (let i = 0; i < length; i++) {
        newArray.push(colors[i])
    }
    return newArray
}

export const ConfigPieChart = (data, label, withFormater = true, autoColor = true) => {
    let color = generateColor(data.length)
    return {
        labels: data?.map((data) => data.label),
        datasets: [{
            label: label,
            data: data?.map((data) => data.value),
            backgroundColor: autoColor ? color : data?.map((data) => data.color),
            borderColor: color,

            borderWidth: 0.3,
            datalabels: {
                anchor: 'auto', // Posisi label (end, start, center, atau auto)
                align: 'auto', // Alihkan label (end, start, atau center)
                formatter: function (value, context) {
                    // return context.chart.data.labels[context.dataIndex];
                    if (withFormater) return formatterAmount(value)
                    return value;
                }
            },
        }]
    }
}
