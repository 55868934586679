import { atom } from "jotai";
import moment from "moment";
import Constant from "utils/Constants";

export const INIT_HEADER_VQ_ATOM = {
    vqId: null,
    rfqId: null,
    rfqNo: "",
    rfqSubject: "",
    rfqDate: "",
    remarks: "",
    quotationNo: "",
    quotationDate: moment().format(Constant.stateDateFormat),
    validUntil: null,
    quotationSubmissionDueDate: null,
    vendorId: null,
    vendorName: "",
    vendorList: [],

    totalAmountGoodsBeforeTax: 0,
    totalAmountServicesBeforeTax: 0,
    totalAmountGoodsVatPercentage: 0,
    totalAmountServicesVatPercentage: 0,
    goodsVatPercentage: 0,
    servicesVatPercentage: 0,
    totalGoodsAmountWithTax: 0,
    totalServicesAmountWithTax: 0,
    grandTotal: 0,
}
export const headerVQAtom = atom(INIT_HEADER_VQ_ATOM);

export const goodsItemsVQAtom = atom([])
export const serviceItemsVQAtom = atom([])
export const attachmentsVQ = atom([])


// {
//   "VqId": null,
//   "RfqId": "00000000-0000-0000-0000-000000000000",
//   "RfqNo": "",
//   "RfqSubject": null,
//   "Remarks": null,
//   "QuotationNo": "",
//   "LastModifiedAt": null,
//   "RfqDate": "0001-01-01T00:00:00",
//   "QuotationDate": "0001-01-01",
//   "ValidUntil": "0001-01-01",
//   "QuotationSubmissionDueDate": "0001-01-01T00:00:00",
//   "VendorId": 0,
//   "VendorName": null,
//   "IsLastQuotation": false,
//   "Version": 0,
//   "ListAttachmentDetails": [],
//   "ListGoodsDetails": [],
//   "ListServiceDetails": [],
//   "AttachmentHeader": null
// }