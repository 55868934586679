import { Form, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import SectionHeaderPR from 'components/PurchaseRequest/SectionHeaderPR';
import SectionItems from 'components/PurchaseRequest/SectionItems';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import CustomAlert from 'utils/CustomAlert';
import { useAtom } from 'jotai';
import { attachmentPr, goodsItemPr, headerPRAtom, servicesItemPr } from 'store/PurchaseRequest/purchaseRequestAtom';
import usePRRepo from 'services/repo/usePRRepo';
import { dismissToast, errorToast, loadingToastId, successToast } from 'utils/Toast';
import Constant from 'utils/Constants';
import { builderName, setFieldValues } from 'utils/Helper';
import useUserRepo from 'services/repo/useUserRepo';
import { useNavigate, useParams } from 'react-router-dom';
import { APP_ROUTE_PREFIX, RouteConstant } from 'routes/routesConstant';
import useAccessPermissionHook from 'hooks/useAccessPermissionHook';
import { PRIVILEGE_ID } from 'utils/AccessPermissionConfig';
import SkeletonPage from 'components/Common/SkeletonPage';
import Error403 from 'components/Error/Error403';
import DevLog from 'utils/DevLog';
import { ButtonBack, ButtonDiscard, ButtonSaveDraft, ButtonSubmit } from 'components/Common/Buttons';
import useFormPRHook from 'hooks/useFormPRHook';
import BaseSectionAttachment from 'components/PurchaseRequest/BaseSectionAttachment';
import CustomCollapse from 'components/Common/CustomCollapse';

export default function CreatePR() {
    const { prId } = useParams();
    useTopbarTitleHooks(`${prId ? 'Edit' : 'Create'} Purchase Request `);
    const { isLoading: isLoadingSaveDraft, saveAsDraft, updateAsDraft } = usePRRepo(false);
    const { isLoading: isLoadingSubmit, submitPR, submitPRFromDraft } = usePRRepo(false);
    const { isLoading: isLoadingGetPRById, getPRById } = usePRRepo(false);
    const { isLoading: isLoadingGetPIC, getUserDataLogin } = useUserRepo(false);
    const { isLoading: isLoadingDiscardDraft, discardDraft } = usePRRepo(false);
    const { isAllowEditDraftPR } = usePRRepo(false);
    const { setDataFormPR, clearFormAndAtomPR, generateItemsPR } = useFormPRHook()

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState(['1']);
    const [dataHeader, setDataHeader] = useAtom(headerPRAtom);
    const [dataGoodsItem,] = useAtom(goodsItemPr);
    const [dataServicesItem,] = useAtom(servicesItemPr);
    const [dataAttachment,] = useAtom(attachmentPr);
    const [errorListItem, setErrorListItem] = useState(false)
    const { isHasPrivilege } = useAccessPermissionHook()
    const isLoadingPrivilege = useRef(true)
    const hasPrivilegePage = useRef(true)

    function handleClick(key) {
        setActiveKey(key);
    }

    const checkErrorToOpenCollapse = (errors, nameFields) => {
        return errors.some(x => nameFields.some(f => x.name.indexOf(f) >= 0));
    };

    const fetchGetPRById = async (id) => {
        loadingToastId(Constant.message.loading, id)
        let response = await getPRById({ prId: id });
        if (response?.isValid && response?.statusCode === 200) {
            let data = response?.data;
            setDataFormPR(form, data)
        }
        dismissToast(id)
    };

    const checkPRCanEdit = async (id) => {
        loadingToastId(Constant.message.loading, id)
        let response = await isAllowEditDraftPR({ prId: id });
        if (response?.isValid && response?.statusCode === 200) {
            if (response?.data?.isHavePrivilege) {
                fetchGetPRById(id)
            } else {
                hasPrivilegePage.current = false
            }
        }
        isLoadingPrivilege.current = false
        dismissToast(id)
    }

    const fetchGetPICPR = async () => {
        let response = await getUserDataLogin();
        if (response?.isValid && response?.statusCode === 200) {
            let data = response?.data;
            setFieldValues(form, "prPic", builderName(data?.employee?.firstName, data?.employee?.lastName));
            setFieldValues(form, "department", data?.employee?.department?.departmentName);
            setFieldValues(form, "division", data?.employee?.division?.divisionName);
            setDataHeader(prev => ({
                ...prev,
                prPicFullName: builderName(data?.employee?.firstName, data?.employee?.lastName),
                departmentName: data?.employee?.department?.departmentName,
                divisionName: data?.employee?.division?.divisionName,
                prPic: {
                    ...prev?.prPic,
                    userId: data?.userId,
                    name: builderName(data?.employee?.firstName, data?.employee?.lastName),
                    divisionId: data?.employee?.division?.divisionId,
                    departmentId: data?.employee?.department?.departmentId,
                    divisionName: data?.employee?.division?.divisionName,
                    departmentName: data?.employee?.department?.departmentName
                }
            }));
            // console.log(data);
        }
    };

    const doDiscardDraft = async () => {
        CustomAlert.confirm({
            title: "Discard Purchase Request!",
            description: `Are you sure you want to discard this PR?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: async () => {

                loadingToastId(Constant.message.savingData, prId);
                let response = await discardDraft({ prId: prId });
                dismissToast(prId)
                if (response?.isValid && response?.statusCode === 200) {
                    navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.index, { replace: true })
                    successToast("Purchase Request has been discarded")
                }
            }
        });
    };

    const validateItems = () => {
        if (dataGoodsItem.length <= 0 && dataServicesItem <= 0) {
            setErrorListItem(true)
            return false
        } else {
            setErrorListItem(false)
            return true
        }
    }

    const doSubmit = async () => {
        let payloadData = {
            prPicId: dataHeader.prPic?.userId,
            remarks: dataHeader.remarks,
            projectCode: dataHeader.projectCode,
            costCenterId: dataHeader.costCenter?.costCenterId,
            prTypeId: dataHeader.prTypeId,
            prSubject: dataHeader.prSubject,
            requiredDate: dataHeader.requiredDate,
            goodsVatPercentage: dataHeader.goodsVatPercentage,
            servicesVatPercentage: dataHeader.servicesVatPercentage,
            lastModifiedAt: dataHeader.lastModifiedAt,

            listGoodsDetails: generateItemsPR(dataGoodsItem, Constant.ItemType.GOODS),
            listServiceDetails: generateItemsPR(dataServicesItem, Constant.ItemType.SERVICES),
            listAttachmentDetails: [...dataAttachment].filter(obj => obj.hasOwnProperty('prAttachmentId'))
        };

        if (prId) {
            payloadData = { ...payloadData, prId: prId };
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(payloadData));
        for (let i = 0; i < dataAttachment?.length; i++) {
            //just send a new file
            if (!dataAttachment[i]?.prAttachmentId) {
                formData.append("AttachmentHeader", dataAttachment[i]?.file);
            }
        }

        for (let i = 0; i < dataGoodsItem?.length; i++) {
            formData.append(`AttachmentGoods[${i}].itemId`, dataGoodsItem[i]?.itemId);
            if (dataGoodsItem[i]?.attachmentFile?.file) {
                formData.append(`AttachmentGoods[${i}].AttachmentContent`, dataGoodsItem[i]?.attachmentFile?.file);
            }
        }

        for (let i = 0; i < dataServicesItem?.length; i++) {
            formData.append(`AttachmentService[${i}].itemId`, dataServicesItem[i]?.itemId);
            if (dataServicesItem[i]?.attachmentFile?.file) {
                formData.append(`AttachmentService[${i}].AttachmentContent`, dataServicesItem[i]?.attachmentFile?.file);
            }
        }

        loadingToastId(Constant.message.savingData, formData);
        let response;

        if (prId) {
            response = await submitPRFromDraft(formData);
        } else {
            response = await submitPR(formData);
        }

        dismissToast(formData);

        if (response?.isValid && response?.statusCode === 200) {
            successToast(Constant.message.saveDataSuccess);
            navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.index);
        }
    }

    const validateSubmit = () => {
        form.validateFields()
            .then((values) => {
                if (validateItems()) {
                    // console.log("then", values);
                    // form.resetFields();
                    doSubmit()
                } else {
                    errorToast(Constant.message.errorForm)
                }
            })
            .catch((errorInfo) => {
                errorToast(Constant.message.errorForm)
                if (checkErrorToOpenCollapse(errorInfo?.errorFields, ["prDate", "prPic", "costCenter", "prSubject", "prTypeId"])) {
                    setActiveKey(prev => ([...new Set([...prev, '1'])]));
                }
                if (!validateItems()) {
                    setActiveKey(prev => ([...new Set([...prev, '2'])]));
                }
            });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        CustomAlert.confirm({
            title: "Submit Purchase Request!",
            description: `Are you sure you want to submit this data?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => validateSubmit()
        });
    };

    const doSaveAsDraft = async () => {
        let payloadData = {
            prPicId: dataHeader.prPic?.userId,
            remarks: dataHeader.remarks,
            projectCode: dataHeader.projectCode,
            costCenterId: dataHeader.costCenter?.costCenterId,
            prTypeId: dataHeader.prTypeId,
            prSubject: dataHeader.prSubject,
            requiredDate: dataHeader.requiredDate,
            goodsVatPercentage: dataHeader.goodsVatPercentage,
            servicesVatPercentage: dataHeader.servicesVatPercentage,
            lastModifiedAt: dataHeader.lastModifiedAt,

            listGoodsDetails: generateItemsPR(dataGoodsItem, Constant.ItemType.GOODS),
            listServiceDetails: generateItemsPR(dataServicesItem, Constant.ItemType.SERVICES),
            listAttachmentDetails: [...dataAttachment].filter(obj => obj.hasOwnProperty('prAttachmentId'))
        };

        if (prId) {
            payloadData = { ...payloadData, prId: prId };
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(payloadData));
        for (let i = 0; i < dataAttachment?.length; i++) {
            //just send a new file
            if (!dataAttachment[i]?.prAttachmentId) {
                formData.append("AttachmentHeader", dataAttachment[i]?.file);
            }
        }

        for (let i = 0; i < dataGoodsItem?.length; i++) {
            formData.append(`AttachmentGoods[${i}].itemId`, dataGoodsItem[i]?.itemId);
            if (dataGoodsItem[i]?.attachmentFile?.file) {
                formData.append(`AttachmentGoods[${i}].AttachmentContent`, dataGoodsItem[i]?.attachmentFile?.file);
            }
        }

        for (let i = 0; i < dataServicesItem?.length; i++) {
            formData.append(`AttachmentService[${i}].itemId`, dataServicesItem[i]?.itemId);
            if (dataServicesItem[i]?.attachmentFile?.file) {
                formData.append(`AttachmentService[${i}].AttachmentContent`, dataServicesItem[i]?.attachmentFile?.file);
            }
        }

        loadingToastId(Constant.message.savingData, formData);
        let response;

        if (prId) {
            response = await updateAsDraft(formData);
        } else {
            response = await saveAsDraft(formData);
        }

        dismissToast(formData);

        if (response?.isValid && response?.statusCode === 200) {
            successToast(Constant.message.saveDataSuccess);
            navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.index);
        }
    };

    const handleSaveDraft = () => {
        CustomAlert.confirm({
            title: "Save as Draft!",
            description: `Are you sure you want to save as a draft?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => {
                form.validateFields(['prTypeId'])
                    .then((values) => {
                        doSaveAsDraft()
                    })
                    .catch((errorInfo) => {
                        setActiveKey(prev => ([...new Set([...prev, '1'])]));
                        errorToast(Constant.message.errorForm)
                    })
            },
        });
    };

    const checkPrivilege = async () => {
        loadingToastId(Constant.message.loading, PRIVILEGE_ID.CreatePR)
        let createPR = await isHasPrivilege(PRIVILEGE_ID.CreatePR)
        if (!createPR) hasPrivilegePage.current = false
        else fetchGetPICPR()
        isLoadingPrivilege.current = false
        dismissToast(PRIVILEGE_ID.CreatePR)
    }

    useEffect(() => {
        clearFormAndAtomPR(form, true, true)
        return () => {
            DevLog("cleanup state pr form");
            clearFormAndAtomPR(form, true)
        };
    }, []);

    useEffect(() => {
        //set ddl pr type with initial value
        if (prId) {
            checkPRCanEdit(prId)
        } else {
            setFieldValues(form, "prTypeId", null)
            setFieldValues(form, "costCenter", null)
            checkPrivilege()
        }
    }, [prId]);

    return (
        hasPrivilegePage.current ?
            <Space direction="vertical" size="small" className='wrap-pr-form space-vertical-flex'>
                {
                    (isLoadingPrivilege.current || isLoadingGetPRById) ? <SkeletonPage /> :
                        <Form
                            name="form-create-pr"
                            form={form}
                            layout='vertical'
                        >
                            <CustomCollapse
                                title="Create Purchase Request"
                                className="pr-form-collapse"
                                onChange={handleClick}
                                activeKey={activeKey}
                                items={[
                                    {
                                        key: 1,
                                        label: "Purchase Request Header",
                                        forceRender: true,
                                        children: <SectionHeaderPR initForm={form} isView={false} />
                                    },
                                    {
                                        key: 2,
                                        label: errorListItem ? <div>Items | <span className="error-message">Please Select Item</span></div> : "Items",
                                        forceRender: true,
                                        className: "pr-form-collapse-panel-items",
                                        children: <SectionItems initForm={form} isView={false} />
                                    },
                                    {
                                        key: 4,
                                        label: "Attachments",
                                        forceRender: true,
                                        children: <BaseSectionAttachment initForm={form} isView={false}

                                        />
                                    }
                                ]} />

                            <div style={{ display: "flex", gap: "10px", margin: "10px 0" }}>
                                <ButtonBack onClick={() => navigate(APP_ROUTE_PREFIX + "/" + RouteConstant.purchaseRequest.index)} disabled={isLoadingGetPIC || isLoadingSaveDraft || isLoadingGetPRById || isLoadingSubmit} />
                                <ButtonSaveDraft
                                    onClick={handleSaveDraft}
                                    disabled={isLoadingGetPIC || isLoadingGetPRById || isLoadingSubmit}
                                    loading={isLoadingSaveDraft}
                                />
                                <ButtonSubmit
                                    onClick={handleFormSubmit}
                                    disabled={isLoadingGetPIC || isLoadingSaveDraft || isLoadingGetPRById || isLoadingDiscardDraft}
                                    loading={isLoadingSubmit}
                                />
                                {dataHeader?.isDraft &&
                                    <ButtonDiscard
                                        onClick={doDiscardDraft}
                                        disabled={isLoadingGetPIC || isLoadingSaveDraft || isLoadingGetPRById || isLoadingSubmit}
                                        loading={isLoadingDiscardDraft}
                                    />
                                }
                            </div>
                        </Form>
                }
            </Space> : <Error403 />
    );
}

