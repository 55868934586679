import * as React from "react";

function IconFullScreen(props) {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.667 14.667h-3.333m-10-10V1.333v3.334zm0-3.334h3.333-3.333zm0 0L5.501 5.5 1.334 1.333zm13.333 3.334V1.333v3.334zm0-3.334h-3.333 3.333zm0 0L10.501 5.5l4.166-4.167zm-13.333 10v3.334-3.334zm0 3.334h3.333-3.333zm0 0L5.501 10.5l-4.167 4.167zm13.333 0L10.501 10.5l4.166 4.167zm0 0v-3.334 3.334z"
                stroke="#000"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IconFullScreen;