import Constant from "utils/Constants";

const PURCHASE_REQUEST = "purchase-request";
const PURCHASE_ORDER = "purchase-order";
const RFQ = "rfq";
const VENDOR_QUOTATION = "vendor-quotation"
const VENDOR_SELECTION = "vendor-selection"
const INVOICE_RECEIVE = "invoice-receive"
const RECEIVE_OF_GOODS = "rogs"
const INITIAL_BUDGET = "budget/initial-budget"
const MASTER_DATA = "master"
const REPORT = "report"

// const queryStringListTab = `?${Constant.ConstantParamTab}=${Constant.ConstantQueryStringTab.list}`
const queryStringPendingTaskTab = `?${Constant.ConstantParamTab}=${Constant.ConstantQueryStringTab.pendingTask}`

export const APP_ROUTE_PREFIX = '/app'
export const RouteConstant = {
    login: "/",
    dashboard: "dashboard",
    dashboardSummaryPRChart: "dashboard-summary-pr-chart",
    dashboardSummaryPRDetail: "dashboard-summary-pr-detail",
    dashboardSummaryPOChart: "dashboard-summary-po-chart",
    dashboardSummaryPODetail: "dashboard-summary-po-detail",
    dashboardSummaryInvoiceChart: "dashboard-summary-invoice-chart",
    dashboardSummaryInvoiceDetail: "dashboard-summary-invoice-detail",
    dashboardSummaryVendorChart: "dashboard-summary-vendor-chart",
    dashboardSummaryVendorDetail: "dashboard-summary-vendor-detail",
    dashboardTopSpendVendorChart: "dashboard-top-spend-vendor-chart",
    dashboardTopSpendVendorDetail: "dashboard-top-spend-vendor-detail",
    dashboardTopSpendItemDetail: "dashboard-top-spend-item-detail",
    dashboardTopSpendItemCategoryChart: "dashboard-top-spend-item-category-chart",
    dashboardTopSpendItemCategoryDetail: "dashboard-top-spend-item-category-detail",
    dashboardPOInvoiceAmountMonthlyProgressStatus: "dashboard-amount-monthly-chart",
    dashboardPOInvoiceAmountMonthlyDetailPO: "dashboard-amount-monthly-detail-po",
    dashboardPOInvoiceAmountMonthlyDetailInvoice: "dashboard-amount-monthly-detail-invoice",
    dashboardPOInvoiceAmountMonthlyDetail: "dashboard-amount-monthly-detail",



    myProfile: "my-profile",
    resetPassword: "/reset-password/user",
    forgotPassword: "/forgot-password",
    purchaseRequest: {
        index: `${PURCHASE_REQUEST}/list`,
        create: `${PURCHASE_REQUEST}/create`,
        edit: (prId) => `${PURCHASE_REQUEST}/edit/${prId}`,
        view: (prId) => `${PURCHASE_REQUEST}/view/${prId}`,
        pendingTask: `${PURCHASE_REQUEST}/list${queryStringPendingTaskTab}`,
        approval: `${PURCHASE_REQUEST}/approval`,
        revise: `${PURCHASE_REQUEST}/revise`,
    },
    rfq: {
        index: `${RFQ}/list`,
        create: `${RFQ}/create`,
        edit: (rfqId) => `${RFQ}/edit/${rfqId}`,
        pendingTask: `${RFQ}/list${queryStringPendingTaskTab}`,
        approval: `${RFQ}/approval`,
        revise: `${RFQ}/revise`,
    },
    vendorQuotation: {
        index: `${VENDOR_QUOTATION}/list`,
        create: `${VENDOR_QUOTATION}/create`,
        edit: (vqId) => `${VENDOR_QUOTATION}/edit/${vqId}`,
    },
    vendorSelection: {
        index: `${VENDOR_SELECTION}/list`,
        create: `${VENDOR_SELECTION}/create`,
        pendingTask: `${VENDOR_SELECTION}/list${queryStringPendingTaskTab}`,
        approval: `${VENDOR_SELECTION}/approval`,
        revise: `${VENDOR_SELECTION}/revise`,
    },
    purchaseOrder: {
        index: `${PURCHASE_ORDER}/list`,
        edit: (poId) => `${PURCHASE_ORDER}/edit/${poId}`,
        pendingTask: `${PURCHASE_ORDER}/list${queryStringPendingTaskTab}`,
        approval: `${PURCHASE_ORDER}/approval`,
        revise: `${PURCHASE_ORDER}/revise`,
    },
    invoiceReceive: {
        index: `${INVOICE_RECEIVE}/list`,
        create: `${INVOICE_RECEIVE}/create`,
        pendingTask: `${INVOICE_RECEIVE}/list${queryStringPendingTaskTab}`,
        edit: (ivcrId) => `${INVOICE_RECEIVE}/edit/${ivcrId}`,
        approval: `${INVOICE_RECEIVE}/approval`,
        revise: `${INVOICE_RECEIVE}/revise`,
    },
    rogs: {
        index: `${RECEIVE_OF_GOODS}/list`,
        create: `${RECEIVE_OF_GOODS}/create`,
        edit: (rogsId) => `${RECEIVE_OF_GOODS}/edit/${rogsId}`,
        pendingTask: `${RECEIVE_OF_GOODS}/list${queryStringPendingTaskTab}`,
        approval: `${RECEIVE_OF_GOODS}/approval`,
        revise: `${RECEIVE_OF_GOODS}/revise`,
    },
    masterData: {
        user: {
            index: `${MASTER_DATA}/users`
        },
        role: {
            index: `${MASTER_DATA}/roles`
        },
        uom: {
            index: `${MASTER_DATA}/uom/list`
        },
        itemCategory: {
            index: `${MASTER_DATA}/item-category/list`,
        },
        division: {
            index: `${MASTER_DATA}/division/list`
        },
        department: {
            index: `${MASTER_DATA}/department/list`
        },
        costCenter: {
            index: `${MASTER_DATA}/cost-center/list`
        },
        departmentCostCenter: {
            index: `${MASTER_DATA}/department-cost-center/list`
        },
        items: {
            index: `${MASTER_DATA}/item/list`
        },
        itemsCatalog: {
            index: `${MASTER_DATA}/item-catalog/list`,
            create: `${MASTER_DATA}/item-catalog/create`,
            edit: (contractCatalogId) => `${MASTER_DATA}/item-catalog/${contractCatalogId}`,
        },
        employee: {
            index: `${MASTER_DATA}/employee/list`
        },
        wfRole: {
            index: `${MASTER_DATA}/wf-role/list`,
        },
        emailTemplate: {
            index: `${MASTER_DATA}/email-template/list`,
        },
        country: {
            index: `${MASTER_DATA}/country/list`,
        },
        bank: {
            index: `${MASTER_DATA}/bank/list`,
        },
        bankAccount: {
            index: `${MASTER_DATA}/bank-account/list`,
        },
        wfApprovalSetting: {
            index: `${MASTER_DATA}/wf-approval-setting/list`,
        },
        industryType: {
            index: `${MASTER_DATA}/industry-type/list`,
        },
        costCenterApprovalMapping: {
            index: `${MASTER_DATA}/cost-center-approval-mapping/list`,
            create: `${MASTER_DATA}/cost-center-approval-mapping/create`,
            update: (costCenterId) => `${MASTER_DATA}/cost-center-approval-mapping/update/${costCenterId}`,
        },
        vendor: {
            index: `${MASTER_DATA}/vendor/list`,
            create: `${MASTER_DATA}/vendor/create`,
            edit: (vendorId) => `${MASTER_DATA}/vendor/${vendorId}`,
        },
        coa: {
            index: `${MASTER_DATA}/coa/list`,
        },
    },
    report: {
        prByDivision: `${REPORT}/pr-by-division`,
        slaProcurement: `${REPORT}/sla-procurement`,
        prTracking: `${REPORT}/pr-tracking`,
        poByVendor: `${REPORT}/po-by-vendor`,
        poByVendorDetail: `${REPORT}/po-by-vendor-detail`,
    },
    initialBudget: {
        index: `${INITIAL_BUDGET}/list`,
        create: `${INITIAL_BUDGET}/create`,
        edit: (budgetId) => `${INITIAL_BUDGET}/edit/${budgetId}`,
        pendingTask: `${INITIAL_BUDGET}/list${queryStringPendingTaskTab}`,
        approval: `${INITIAL_BUDGET}/approval`,
        revise: `${INITIAL_BUDGET}/revise`,
    },
}