import { atom } from "jotai";
// import moment from "moment";
// import Constant from "utils/Constants";

export const INIT_HEADER_IVCR_ATOM = {
    ivcrId: null,
    ivcrNo: "",
    poId: "",
    topTerm: null,
    remarks: "",
    vendorId: null,
    vendorName: "",


    picName: "",
    picId: "",
    poNo: "",
    vendorInvoiceNo: "",
    invoiceReceivedDate: "",
    originalInvoiceDate: "",
    invoiceDueDate: "",
    invoicePaidDate: "",
    paidAmount: 0,
    vendorBankAccountId: null,
    vendorBankAccountName: "",
    dp: false,
    paymentTypeId: null,
    bankAccountId: null,
    bankAccountName: "",

    paidAmountGoods: 0,
    paidAmountServices: 0,
    netAmountGoodsBeforeTax: 0,
    netAmountServicesBeforeTax: 0,
    netAmountGoods: 0,
    netAmountServices: 0,
    totalAmountVatGoods: 0,
    totalAmountVatServices: 0,
    totalWhtAmountServices: 0,
    grandTotal: 0,
    ivcrStatus: "",
    adjustmentReason: "",
    adjustmentAmount: 0,
}
export const headerIVCRAtom = atom(INIT_HEADER_IVCR_ATOM);
export const goodsItemIVCRAtom = atom([])
export const servicesItemIVCRAtom = atom([])
export const attachmentsIVCRAtom = atom([])
export const remarksApprovalAtom = atom("")
