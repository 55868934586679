import { BaseDrawer } from "components/CustomDrawer/BaseDrawer"
import ManageCostCenterApprovalMapping from "pages/MasterData/CostCenterApprovalMapping/ManageCostCenterApprovalMapping"

export default function ViewCostCenterApprovalMapping(props) {
    const {
        onClose,
        loading = false,
        costCenterId = null
    } = props

    const handleCloseDrawer = () => {
        onClose()
    }

    return (
        <BaseDrawer
            title={"View Cost Center Approval Mapping"}
            placement="right"
            size="large"
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
        >
            <ManageCostCenterApprovalMapping isView={true} propsCostCenterId={costCenterId} />
        </BaseDrawer>
    )
}
