import { Button, Card, Col, Form, Row, Tree } from 'antd'
import React, { useEffect, useState } from 'react'
import DevLog from 'utils/DevLog';
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer';
import useUserRepo from 'services/repo/useUserRepo';
import { dismissToast, errorToast, loadingToastId, successToast } from 'utils/Toast';
import CustomAlert from 'utils/CustomAlert';
import Constant from 'utils/Constants';
import LookupEmployee from 'components/RoGSV2/Modal/LookupEmployee';
import { builderName, setFieldValues } from 'utils/Helper';
import useEncriptRSAHook from 'hooks/useEncriptRSAHook';
import EmptyData from 'components/Common/EmptyData';
import { CustomFormItemLookup, CustomFormItemPassword, CustomFormItemSwitch, CustomFormItemText } from 'components/Common/CustomFormItem';

const init_field = {
    userName: "",
    password: "",
    confirmPassword: "",
    employeeId: null,
    employeeName: "",
    isActive: true,
    listSelectedRoleId: [],
    listSelectedWfRoleId: []
}

export default function BaseDrawerUser(props) {
    const {
        onClose,
        dataEdit = null,
        onRefreshList,
        isView = false,
        loading = false,
        setModeView = () => { },
        isAddEditPrivileged,
    } = props

    const { create, update } = useUserRepo(false);
    const { isLoading: isLoadingRole, getUserRole } = useUserRepo(false);
    const { isLoading: isLoadingWfRole, getUserWfRole } = useUserRepo(false);

    const [form] = Form.useForm()
    const [dataField, setDataField] = useState(init_field)
    const [selectedRole, setSelectedRole] = useState([])
    const [selectedWfRole, setSelectedWfRole] = useState([])
    const [roleList, setRoleList] = useState([])
    const [wfRoleList, setWfRoleList] = useState([])
    const [isOpenLookupEmployee, setIsOpenLookupEmployee] = useState(false)
    const { getEncriptedText } = useEncriptRSAHook()
    const [isLoadingSave, setIsLoadingSave] = useState(false)

    const fetchDataUserRole = async (userId) => {
        const response = await getUserRole({ userId: userId || null })
        if (response?.isValid && response?.statusCode === 200) {
            const list = response.data.map((item) => ({
                title: item.roleName,
                key: item.roleId,
                isHasRole: item.isHasRole
            }));

            setRoleList(list);

            const selected = list.reduce((acc, item, index) => {
                if (item.isHasRole) {
                    acc.push(item?.key);
                }
                return acc;
            }, []);
            setSelectedRole(selected);
        }
    }

    const fetchDataUserWfRole = async (userId) => {
        const response = await getUserWfRole({ userId: userId || null })
        if (response?.isValid && response?.statusCode === 200) {
            const list = response.data.map((item, index) => {
                return {
                    title: item.wfRoleName,
                    key: item.wfRoleId,
                    isHasWfRole: item.isHasWfRole
                }
            })
            setWfRoleList(list)

            const selected = list.reduce((acc, item, index) => {
                if (item.isHasWfRole) {
                    acc.push(item?.key);
                }
                return acc;
            }, []);
            setSelectedWfRole(selected);
        }
    }

    const handleCloseDrawer = () => {
        form.resetFields()
        setDataField(init_field)
        setModeView(true)
        onClose()
    }

    const handleSelectEmployee = (data) => {
        const fullName = builderName(data?.firstName, data?.lastName)
        setFieldValues(form, "employeeName", fullName)
        setDataField(prev => ({
            ...prev,
            employeeId: data?.employeeId,
            employeeName: fullName,
        }))
        setIsOpenLookupEmployee(false)
    }

    const handleCheckRole = (checkedKeys, info) => {
        if (info.checked) {
            setSelectedRole(prev => ([...prev, info?.node?.key]))
        } else {
            let selected = [...selectedRole];
            const filteredArray = selected.filter((element) => element !== info?.node?.key);
            setSelectedRole(filteredArray)
        }
    }

    const handleCheckWfRole = (checkedKeys, info) => {
        if (info.checked) {
            setSelectedWfRole(prev => ([...prev, info?.node?.key]))
        } else {
            let selected = [...selectedWfRole];
            const filteredArray = selected.filter((element) => element !== info?.node?.key);
            setSelectedWfRole(filteredArray)
        }
    }

    const onSave = async () => {
        try {
            setIsLoadingSave(true)
            loadingToastId(Constant.message.loading, "SAVE")
            const encryptedPassword = await getEncriptedText(dataField.password)
            if (encryptedPassword) {
                let payloadData = {
                    userId: dataField?.userId,
                    userName: dataField.userName,
                    encryptedPassword: encryptedPassword,
                    employeeId: dataField.employeeId,
                    isActive: dataField.isActive,
                    listSelectedRoleId: selectedRole,
                    listSelectedWfRoleId: selectedWfRole,
                    lastModifiedAt: dataField?.lastModifiedAt
                }

                let response;
                if (dataEdit !== null) {
                    response = await update(payloadData)
                } else {
                    response = await create(payloadData)
                }
                dismissToast("SAVE")
                if (response?.isValid && response?.statusCode === 200) {
                    successToast(Constant.message.saveDataSuccess);
                    handleCloseDrawer()
                    onRefreshList()
                }

                setIsLoadingSave(false)
            }
            setIsLoadingSave(false)
        } catch (error) {
            dismissToast("SAVE")
            errorToast(`Err change password. err: ${error}`)
            setIsLoadingSave(false)
        }
    }

    const handleFormSubmit = () => {
        CustomAlert.confirm({
            title: "Save User!",
            description: `Are you sure you want to save this data?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "primary",
            onConfirmButton: () => {
                form.validateFields()
                    .then((values) => {
                        onSave()
                    })
                    .catch((errorInfo) => {
                        DevLog("err", errorInfo);
                    });
            }
        });
    };

    useEffect(() => {
        if (!dataEdit) {
            setSelectedRole([])
            setSelectedWfRole([])
            form.setFieldsValue({
                userName: init_field?.userName,
                password: init_field?.password,
                confirmPassword: init_field?.confirmPassword,
                employeeName: init_field?.employeeName,
                isActive: init_field?.isActive,
            })
            setDataField(init_field)
            fetchDataUserRole()
            fetchDataUserWfRole()
        } else {
            setSelectedRole([])
            setSelectedWfRole([])
            const fullName = builderName(dataEdit?.employee?.firstName, dataEdit?.employee?.lastName)
            form.setFieldsValue({
                userName: dataEdit?.userName,
                password: "",
                confirmPassword: "",
                employeeName: fullName,
                isActive: dataEdit?.isActive,
            })
            setDataField({
                userId: dataEdit?.userId,
                userName: dataEdit?.userName,
                password: "",
                confirmPassword: "",
                employeeName: fullName,
                employeeId: dataEdit?.employee?.employeeId,
                isActive: dataEdit?.isActive,
                lastModifiedAt: dataEdit?.lastModifiedAt
            })
            fetchDataUserRole(dataEdit?.userId)
            fetchDataUserWfRole(dataEdit?.userId)
        }
    }, [dataEdit])

    // console.log("selectedRole", selectedRole);
    // console.log("selectedWfRole", selectedWfRole);
    // console.log("wfrole list", wfRoleList);

    return (
        <>
            <BaseDrawer
                title={`${isView ? "View" : dataEdit === null ? "Add" : "Edit"} User`}
                placement="right"
                size="large"
                onClose={handleCloseDrawer}
                open={true}
                loading={loading}
                extras={
                    <>
                        {(isAddEditPrivileged && isView) && <Button
                            type="primary"
                            onClick={() => setModeView(false)}>
                            Edit
                        </Button>

                        }
                        {(isAddEditPrivileged && !isView) &&
                            (<Button type="primary" onClick={handleFormSubmit} htmlType='submit'
                                loading={isLoadingSave}
                            >
                                Submit
                            </Button>)
                        }
                    </>
                }>
                <Form
                    form={form}
                    layout="vertical"
                    className='form-md-user'>
                    <Card>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <CustomFormItemText
                                    nameFormItem="userName"
                                    label="Username"
                                    placeholder="Fill in Username"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    isView={isView}
                                    value={dataField?.userName}
                                    onChange={(e) => {
                                        setDataField(prev => ({
                                            ...prev,
                                            userName: e.target.value
                                        }))
                                    }}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <CustomFormItemSwitch
                                    nameFormItem="isActive"
                                    label="Is Active"
                                    value={dataField?.isActive}
                                    isView={isView}
                                    onChange={(checked) => {
                                        setDataField(prev =>
                                            ({ ...prev, isActive: checked }))
                                    }}
                                />
                            </Col>
                            {!dataEdit && <>
                                <Col xs={24} md={12}>
                                    <CustomFormItemPassword
                                        nameFormItem="password"
                                        label="Password"
                                        placeholder="Fill in Password"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        isView={isView}
                                        value={dataField?.password}
                                        onChange={(e) => {
                                            debugger
                                            setDataField(prev => ({
                                                ...prev,
                                                password: e.target.value
                                            }))
                                        }}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <CustomFormItemPassword
                                        nameFormItem="confirmPassword"
                                        label="Confirm Password"
                                        placeholder="Fill in Confirm Password"
                                        rules={[
                                            {
                                                required: true,
                                                //validateTrigger: false
                                            },
                                            {
                                                //validateTrigger: false,
                                                validator: (_, value) => {
                                                    if (value !== dataField?.password) {
                                                        return Promise.reject(new Error("Confirm Password doesn't match with Password."));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            },
                                        ]}
                                        isView={isView}
                                        value={dataField?.confirmPassword}
                                        onChange={(e) => {
                                            debugger
                                            setDataField(prev => ({
                                                ...prev,
                                                confirmPassword: e.target.value
                                            }))
                                        }}
                                    />
                                </Col>
                            </>}
                            <Col xs={24} md={12}>
                                <CustomFormItemLookup
                                    nameFormItem="employeeName"
                                    label="Employee"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select employee',
                                        },
                                    ]}
                                    placeholder="Select Employee"
                                    initialValue={dataField?.employeeName}
                                    valueLookup={dataField?.employeeName}
                                    onSearch={() => setIsOpenLookupEmployee(true)}
                                    isView={isView}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    style={{ marginBottom: "0px" }}
                                    name="role"
                                    rules={[
                                        {
                                            validator: (_) => {
                                                if (selectedRole?.length > 0) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Please select role'));
                                            }
                                        },
                                    ]}
                                >
                                    <h3 style={{ marginBottom: 0 }}>Role</h3>
                                </Form.Item>
                                <div className='container-role'>
                                    {isLoadingRole ? <p>Loading...</p> :
                                        <>
                                            {roleList.length > 0 ? <Tree
                                                checkable
                                                defaultExpandAll
                                                checkStrictly={true}
                                                selectable={false}
                                                treeData={roleList}
                                                onCheck={handleCheckRole}
                                                checkedKeys={selectedRole}
                                                disabled={isView}
                                            /> : <EmptyData />}
                                        </>
                                    }
                                </div>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    style={{ marginBottom: "0px" }}
                                    name="wfRole"
                                // rules={[
                                //     {
                                //         validator: (_) => {
                                //             if (selectedWfRole?.length > 0) {
                                //                 return Promise.resolve();
                                //             }
                                //             return Promise.reject(new Error('Please select wf role'));
                                //         }
                                //     },
                                // ]}
                                >
                                    <h3 style={{ marginBottom: 0 }}>Wf Role</h3>
                                </Form.Item>
                                <div className='container-wf-role'>
                                    {isLoadingWfRole ? <p>Loading...</p> :
                                        <>
                                            {
                                                wfRoleList.length > 0 ? (
                                                    <Tree
                                                        checkable
                                                        defaultExpandAll
                                                        checkStrictly={true}
                                                        selectable={false}
                                                        treeData={wfRoleList}
                                                        onCheck={handleCheckWfRole}
                                                        checkedKeys={selectedWfRole}
                                                        disabled={isView}
                                                    />
                                                ) : <EmptyData />
                                            }
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Form>

                {isOpenLookupEmployee && <LookupEmployee
                    onClose={() => setIsOpenLookupEmployee(false)}
                    onSelect={handleSelectEmployee}
                />}

            </BaseDrawer>
        </>
    )
}
