import moment from "moment";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import Constant from "./Constants";
import { dismissToast } from "./Toast";
const locale = "id-ID";
const optionsCurrency = {
    style: 'currency',
    currency: 'IDR',
}

export function formatterAmount(amount, withCurrency = false, minFractionDigits = 2) {
    // return `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    amount = Number(amount)
    let currency = new Intl.NumberFormat(locale, optionsCurrency);

    if (withCurrency) {
        let withFormat = currency.format(amount)
        return withFormat !== "NaN" ? withFormat : 0;
    }
    let result = currency.format(amount).replace(/^(-)?Rp\s*/, '$1');
    // debugger
    return result !== "NaN" ? result : 0;
}

export function currencyParser(val) {
    // debugger
    if (!val) return 0;
    let toFloat = Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, "."))
    return toFloat
};

export function formatterInputNumberCurrencyV2(amount) {
    return new Intl.NumberFormat(locale, optionsCurrency).format(amount).replace(/Rp/g, '');
}

export const parserInputNumberCurrencyV2 = val => {
    try {
        // for when the input gets clears
        if (typeof val === "string" && !val.length) {
            val = "0.0";
        }

        // detecting and parsing between comma and dot
        var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
        var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
        var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
        reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
        //  => 1232.21 €

        // removing everything except the digits and dot
        reversedVal = reversedVal.replace(/[^0-9.]/g, "");
        //  => 1232.21

        // appending digits properly
        const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
        const needsDigitsAppended = digitsAfterDecimalCount > 2;

        if (needsDigitsAppended) {
            reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
        }

        return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
        console.error(error);
    }
};

//https://stackoverflow.com/questions/63430864/regex-inputnumber-formatter-ant-design
//another input number formater realtime
export const formatterNumber = (val) => {
    if (!val) return 0;
    let res = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",");
    return res
}

export const parserNumber = (val) => {
    if (!val) return 0;
    // let res = Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")).toFixed(2)
    let res = parseFloat(`${val}`.replace(/,/, '#').replace(/\./g, '').replace(/#/, '.')).toFixed(2)
    return res
}

export function sorterTable(sorter, defaultSort) {
    if (sorter === "ascend") {
        return true
    } else if (sorter === "descend") {
        return false
    }
    return defaultSort
}

export function paginationPropsTableAntd(tableParam, total) {
    return {
        current: tableParam?.pageNumber,
        pageSize: tableParam?.pageSize,
        total: total,
        pageSizeOptions: ["5", "10", "25", "50"],
        showSizeChanger: true,
        locale: { items_per_page: "" },
    }
}

export function displayDate(date, withTime = false) {
    if (date) {
        return withTime ? dayjs(date).format(Constant.dateFormatWithTimeWoSecond) : dayjs(date).format(Constant.datePickerFormat)
    }
    return ""
}

export function readURL(input) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            resolve(e.target.result)
        }

        reader.readAsDataURL(input.target.files[0]);

    })
}

export function generateUUID() {
    return uuidv4();
}

//form is antd form
//single field
export function setFieldValues(form, field, value) {
    form.setFieldValue(field, value)
}

//multi field
export function setMultiFieldValue(form, fieldObj) {
    form.setFieldsValue(fieldObj)
}

export function builderName(fistName, lastName) {
    if (lastName) {
        return fistName + " " + lastName
    }
    return fistName
}

export function downloadLocalFile(file, isPreview = false) {
    const url = URL.createObjectURL(file)
    if (isPreview) {
        dismissToast("download")
        return url
    } else {
        const link = document.createElement('a')
        link.href = url
        link.download = file.name
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }
}

export function dateGreaterThan(dateStart, dateEnd) {
    // debugger
    return moment(dateStart).isAfter(dateEnd)
}

export function blobToSrcImage(blob) {
    // return new Promise(resolve => {
    //     const url = URL.createObjectURL(blob)
    //     let img = new Image()
    //     img.onload = () => {
    //         URL.revokeObjectURL(url)
    //         resolve(img)
    //     }
    //     img.src = url
    // })
    const url = URL.createObjectURL(blob)
    return url
}

export function taxCalculation(amount = 0, tax = 0) {
    return (amount * tax) / 100
}

export function calculatePercentage(value = 0, total = 0) {
    return (value / total) * 100
}

export function calculateSubTotalWithTax(price, tax) {
    // if (tax) {
    //     let subTotal = price * qty
    //     let totalTax = (subTotal * tax) / 100
    //     return subTotal + totalTax
    // }
    // return Number(price * qty)
    let totalTax = (price * tax) / 100
    return price + totalTax
}

export function calculatePRItemTotalBeforeTax(list) {
    return list.reduce((prev, cur) => {
        return prev + (cur.quantity * cur.price)
    }, 0)
}

export function calculateVQItemTotalBeforeTax(list) {
    let sum = list.reduce((prev, cur) => {
        return prev + (cur.qty * cur.price)
    }, 0)
    return sum
}

export function calculatePOItemTotalBeforeTax(list) {
    let sum = list.reduce((prev, cur) => {
        return prev + (cur.quantity * cur.price)
    }, 0)
    return sum
}


export function convertFileSize(sizeInBytes) {
    var kilobytes = sizeInBytes / 1024;
    var megabytes = sizeInBytes / (1024 * 1024);
    if (megabytes >= 1) {
        return megabytes.toFixed(2) + " MB";
    } else {
        return kilobytes.toFixed(2) + " KB";
    }
}

// https://gist.github.com/yudapc/4c94a02f71cbe102d5105a109d9f4499
export function formatNpwp(value) {
    if (typeof value === 'string') {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6');
    }
}

export const checkErrorFormToOpenCollapse = (errors, nameFields) => {
    return errors.some(x => nameFields.some(f => x.name.indexOf(f) >= 0));
};

export function sumAmountWithDiscount(discount, amount) {
    return amount - ((discount / 100) * amount)
}

export function calculateSubotalWithDiscount(price = 0, qty = 0, discountAmount = 0) {
    return (price - discountAmount) * qty
}

export function displayMediaTypeFromDB(types) {
    return types?.split(";").join(",")
}

export function validateIsLastRogsTerm(fieldsError = [], dataItemGoods = [], dataItemServices = []) {
    const filteredWarning = [...fieldsError].filter(item => item.warnings.length > 0);
    const filteredGoods = [...filteredWarning].filter(item => item.name.some(name => name.includes('goods_qtyReceived')));
    const filteredServices = [...filteredWarning].filter(item => item.name.some(name => name.includes('service_qtyReceived')));
    const indexsGoods = [...filteredGoods]?.map((item) => {
        const name = item?.name[0] // get name exaple "goods_qtyReceived_1"
        const splitName = name.split("_")?.[2] //get index from name, _1 = index 1 from goodsatom
        return Number(splitName)
    })
    const indexsServices = [...filteredServices]?.map((item) => {
        const name = item?.name[0] // get name exaple "services_qtyReceived_1"
        const splitName = name.split("_")?.[2] //get index from name, _1 = index 1 from goodsatom
        return Number(splitName)
    })
    const goodsWarning = [...indexsGoods]?.map((index) => {
        const selectedData = [...dataItemGoods]?.[index]?.['itemName']
        return selectedData
    })
    const servicesWarning = [...indexsServices]?.map((index) => {
        const selectedData = [...dataItemServices]?.[index]?.['itemName']
        return selectedData
    })
    const itemNameJoin = [...goodsWarning, ...servicesWarning].join(",")
    return itemNameJoin
}

export function toggleSideMenu(e) {
    // e.preventDefault()
    // document.querySelector('.eproc-master').classList.toggle('sidebar-show')

    let element = document.querySelector('.eproc-master');
    // Menambahkan class baru jika class 'existing-class' sudah ada
    if (element.classList.contains('sidebar-show')) {
        element.classList.remove('sidebar-show');
        element.classList.add('sidebar-hide');
    } else {
        element.classList.add('sidebar-show');
        element.classList.remove('sidebar-hide');
    }
}

export function generateColorDashboardSummaryProgressStatus(status) {
    if (status === Constant.typeProgressStatusDashboardSummary.completed || status === Constant.typeProgressStatusDashboardSummary.paid) return "#0E9F6E"
    if (status === Constant.typeProgressStatusDashboardSummary.inprogress) return "#1C64F2"
    if (status === Constant.typeProgressStatusDashboardSummary.closed) return "#9CA3AF"
    if (status === Constant.typeProgressStatusDashboardSummary.late) return "#F49E5D"
}

export function checkProgressStatusDashboardSummary(status) {
    if (status === Constant.typeProgressStatusDashboardSummary.completed || status === Constant.typeProgressStatusDashboardSummary.paid) return "completed"
    if (status === Constant.typeProgressStatusDashboardSummary.inprogress) return "inprogress"
    if (status === Constant.typeProgressStatusDashboardSummary.closed) return "closed"
    if (status === Constant.typeProgressStatusDashboardSummary.late) return "late"
}

export function validateFileImage(fileName) {
    // debugger
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return Constant.fileImageExtenstion.includes(fileExtension);
}

export function getRandomColorArray(length) { //generates random colours and puts them in string
    let colors = [];
    function random_rgba() {
        const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const rgb = `rgb(${r},${g},${b}, 0.4)`; // Collect all to a css color string
        return rgb
    }
    for (let i = 0; i < length; i++) {
        colors.push(random_rgba())
    }
    return colors;
}


export function getSingleRandomColor(length) {
    function random_rgba() {
        const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const rgb = `rgb(${r},${g},${b}, 9)`; // Collect all to a css color string
        return rgb
    }
    return random_rgba();
}

export function isObjectNotEmptyExceptKeyword(obj, expect) {
    let result = false
    for (const key in obj) {
        if (key !== expect && key !== "keyword" && obj.hasOwnProperty(key) && (obj[key] !== null && obj[key] !== "" && obj[key] !== undefined)) {
            result = true;
        }
    }
    return result;
}