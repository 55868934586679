import * as React from "react";

function IconInvoice(props) {
    return (
        <svg
            width={60}
            height={60}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M47.5 30H55v20a5 5 0 01-5 5h-2.5V30zm-5-20v45H10a5 5 0 01-5-5V10a5 5 0 015-5h27.5a5 5 0 015 5zM25 40H12.5v5H25v-5zm10-12.5H12.5v5H35v-5zM35 15H12.5v5H35v-5z"
                fill="#1C64F2"
            />
        </svg>
    );
}

export default IconInvoice;