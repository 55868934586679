import { Col, Row } from 'antd';
import React, { useState } from 'react'
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
import Constant from 'utils/Constants';
import { builderName, setFieldValues } from 'utils/Helper';
import { goodsItemROGS, headerROGSAtom, servicesItemROGS } from 'store/ROGS/rogsAtom';
import LookupPO from './Modal/LookupPO';
import useRoGSRepo from 'services/repo/useRoGSRepo';
import { dismissToast, loadingToastId } from 'utils/Toast';
import LookupEmployee from './Modal/LookupEmployee';
import { CustomFormItemDate, CustomFormItemLookup, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem';

const styleParentCol = {
    className: "col-effect-drawer",
}

export default function SectionHeaderRoGS({ initForm, isView = false }) {
    const { getListItemFromPO } = useRoGSRepo(false);
    const [dataField, setDataField] = useAtom(headerROGSAtom)
    const [dataItemsGoods, setDataItemsGoods] = useAtom(goodsItemROGS)
    const [dataItemsServices, setDataItemsServices] = useAtom(servicesItemROGS)
    const [isOpenLookupPO, setIsOpenLookupPO] = useState(false)
    const [isOpenLookupReceiver, setIsOpenLookupReceiver] = useState(false)

    const fetchListItems = async (id) => {
        const payload = {
            pageNumber: 1,
            pageSize: 0,
            orderBy: "pr.prNo",
            isAscending: true,
            filter: {
                poId: id
            }
        }
        loadingToastId(Constant.message.loading, id)
        let response = await getListItemFromPO(payload);
        dismissToast(id)
        if (response?.isValid && response?.statusCode === 200) {
            const data = response?.data?.listData
            let goods = data?.filter(x => x.itemTypeId === Constant.ItemType.GOODS)
            let services = data?.filter(x => x.itemTypeId === Constant.ItemType.SERVICES)
            let receiverId = null;
            let receiverName = "";
            if (dataField.receiverId) {
                receiverId = dataField.receiverId;
                receiverName = dataField.receiverName
            }
            const newGoods = goods?.map((item) => {
                return {
                    rogsItemDetailId: item?.rogsItemDetailId,
                    rogsId: item?.rogsId,
                    prItemDetailId: item?.prItemDetailId,
                    poItemDetailId: item?.poItemDetailId,
                    qtyReceived: 0,
                    receiverName: receiverName,
                    receiverId: receiverId,
                    notes: "",

                    prNo: item?.prNo,
                    itemName: item?.itemName,
                    qtyOrdered: item?.quantity,
                    qtyExpected: item?.quantityExpected,
                    uomName: item?.uomName,
                    isDifferenceReceiverName: false,
                }
            })
            const newServices = services?.map((item) => {
                return {
                    rogsItemDetailId: "",
                    rogsId: "",
                    prItemDetailId: item?.prItemDetailId,
                    poItemDetailId: item?.poItemDetailId,
                    qtyReceived: 0,
                    receiverName: receiverName,
                    receiverId: receiverId,
                    notes: "",

                    prNo: item?.prNo,
                    itemName: item?.itemName,
                    qtyOrdered: item?.quantity,
                    qtyExpected: item?.quantityExpected,
                    uomName: item?.uomName,
                    isDifferenceReceiverName: false,
                }
            })
            setDataItemsGoods(newGoods)
            setDataItemsServices(newServices)
        }
    }

    const handleSelectPO = (data) => {
        fetchListItems(data?.poId)
        setDataField(prev => ({
            ...prev,
            poId: data?.poId,
            poNo: data?.poNo,
            topTerm: data?.nextRogsTerm,
            expectedDeliveryDate: dayjs(data?.expectedDeliveryDate).format(Constant.stateDateFormat),
            vendorId: data?.vendorId,
            vendorName: data?.vendorName,
            isLastRogsTerm: data?.isLastRogsTerm
        }))
        setFieldValues(initForm, "poNo", data?.poNo)
        setFieldValues(initForm, "vendorName", data?.vendorName)
        setFieldValues(initForm, "expectedDeliveryDate", data?.expectedDeliveryDate ? dayjs(data?.expectedDeliveryDate) : "")
        setFieldValues(initForm, "paymentTerm", data?.nextRogsTerm)
        setIsOpenLookupPO(false)
    }

    const handleSelectReceiver = (data) => {
        // debugger
        const employeeName = builderName(data?.firstName, data?.lastName)
        const employeeId = data.employeeId
        setDataField(prev => ({
            ...prev,
            receiverId: employeeId,
            receiverName: employeeName
        }))
        setFieldValues(initForm, "picReceiver", employeeName)

        //insert to items
        let good = [...dataItemsGoods]
        let newGoods = good.map((item) => {
            if (item.isDifferenceReceiverName) {
                return { ...item }
            }
            return {
                ...item,
                receiverId: employeeId,
                receiverName: employeeName,
            }
        })
        setDataItemsGoods(newGoods)

        let services = [...dataItemsServices]
        let newServices = services.map((item) => {
            if (item.isDifferenceReceiverName) {
                return { ...item }
            }
            return {
                ...item,
                receiverId: employeeId,
                receiverName: employeeName,
            }
        })
        setDataItemsServices(newServices)

        setIsOpenLookupReceiver(false)
    }

    // console.log("header", dataField);

    return (
        <div className='rogs-section-header'>
            <Row gutter={16}>
                {(dataField?.rogsNo && !dataField?.isDraft) &&
                    <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                        <CustomFormItemText
                            nameFormItem="rogsNo"
                            label="RoGS No"
                            value={dataField?.rogsNo}
                            isView={true}
                        />
                    </Col>}
                {(dataField?.rogsNo && !dataField?.isDraft) &&
                    <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                        <CustomFormItemDate
                            nameFormItem="rogsSubmitDate"
                            label="RoGS Date"
                            value={dataField?.rogsSubmitDate}
                            isView={true}
                        />
                    </Col>
                }
                {(dataField?.rogsNo && !dataField?.isDraft) &&
                    <Col {...styleParentCol} xs={24} sm={12} lg={12}>
                        <CustomFormItemText
                            nameFormItem="rogsStatus"
                            label="RoGS Status"
                            value={dataField?.rogsStatus}
                            isView={true}
                        />
                    </Col>
                }
                <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                    <CustomFormItemLookup
                        nameFormItem="poNo"
                        label="PO No"
                        rules={[
                            {
                                required: true,
                                message: 'Please select PO',
                            },
                        ]}
                        placeholder="Select PO No"
                        initialValue={dataField?.poNo}
                        valueLookup={dataField?.poNo}
                        onSearch={() => setIsOpenLookupPO(true)}
                        isView={isView || dataField?.rogsId}
                    />
                </Col>
                <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                    <CustomFormItemText
                        nameFormItem="vendorName"
                        label="Vendor Name"
                        value={dataField?.vendorName}
                        isView={true}
                    />
                </Col>
                <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                    <CustomFormItemText
                        nameFormItem="paymentTerm"
                        label="Payment Term"
                        value={dataField?.topTerm}
                        isView={true}
                    />
                </Col>
                <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                    <CustomFormItemDate
                        nameFormItem="expectedDeliveryDate"
                        label="Expected Delivery Date"
                        value={dataField?.expectedDeliveryDate}
                        isView={true}
                    />
                </Col>
                <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                    <CustomFormItemLookup
                        nameFormItem="picReceiver"
                        label="PIC Receiver"
                        rules={[
                            {
                                required: true,
                                message: 'Please select PIC Receiver',
                            },
                        ]}
                        placeholder="Select PIC Receiver"
                        initialValue={dataField?.receiverName}
                        valueLookup={dataField?.receiverName}
                        onSearch={() => setIsOpenLookupReceiver(true)}
                        isView={isView}
                    />
                </Col>
                <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                    <CustomFormItemDate
                        nameFormItem="receivedDate"
                        label="Received Date"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        value={dataField?.actualDeliveryDate}
                        onChange={(d, dString) => {
                            setDataField(prev => ({
                                ...prev,
                                actualDeliveryDate: dString ? dayjs(dString).format(Constant.stateDateFormat) : null
                            }))
                        }}
                        disabledDate={d => !d || d.isAfter(new Date())}
                        isView={isView}
                    />
                </Col>
                <Col {...styleParentCol} xs={24} sm={12} lg={6}>
                    <CustomFormItemText
                        nameFormItem="doBastNo"
                        label="DO / BAST Number"
                        placeholder="Fill in DO / BAST Number"
                        value={dataField?.doBastNo}
                        onChange={(e) => {
                            setDataField(prev => ({
                                ...prev,
                                doBastNo: e.target.value
                            }))
                        }}
                        isView={isView}
                    />
                </Col>
                <Col {...styleParentCol} xs={24}>
                    <CustomFormItemTextArea
                        nameFormItem="remarks"
                        label="Remarks"
                        value={dataField?.remarks}
                        isView={isView}
                        maxLength={8000}
                        placeholder="Fill in Remarks"
                        onChange={(e) => {
                            setDataField(prev => ({
                                ...prev,
                                remarks: e.target.value
                            }))
                        }}
                    />
                </Col>
            </Row>
            {
                isOpenLookupPO && <LookupPO
                    onClose={() => setIsOpenLookupPO(false)}
                    onSelect={handleSelectPO}
                />
            }

            {
                isOpenLookupReceiver && <LookupEmployee
                    onClose={() => setIsOpenLookupReceiver(false)}
                    onSelect={handleSelectReceiver}
                />
            }
        </div >
    )
}
