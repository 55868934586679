import GoodsItem from "./GoodsItem";
import ServicesItem from "./ServicesItem";
import { useEffect, useState } from "react";
import useConfigRepo from "services/repo/useConfigRepo";
import Constant from "utils/Constants";
import { formatterAmount, setFieldValues } from "utils/Helper";
import { useAtom } from "jotai";
import { goodsItemPr, headerPRAtom, servicesItemPr } from "store/PurchaseRequest/purchaseRequestAtom";
import BaseItemsDetailV2 from "./Drawer/BaseItemsDetailV2";
import { useParams } from "react-router-dom";
import useDownloadOrPreviewMediaHook from "hooks/useDownloadOrPreviewMediaHook";
import CustomAntdTable from "components/Common/CustomAntdTable";
import { Table, Typography } from "antd";
import IconRequired from "components/Common/IconRequired";
import { CustomFormItemNumber } from "components/Common/CustomFormItem";
const { Text } = Typography;

const renderCustomFormItemNumber = (nameFormItem, nameInput, className, vatPercentage, initForm, isView, onChange) => {
    return (
        <CustomFormItemNumber
            nameFormItem={nameFormItem}
            nameInput={nameInput}
            className={className}
            rules={[
                {
                    validator: (_, value) => {
                        if (value < 0) {
                            return Promise.reject(new Error('VAT must be greater or equal to 0'));
                        } else {
                            return Promise.resolve();
                        }
                    }
                },
            ]}
            max="100"
            min="0"
            initForm={initForm}
            customvalue={vatPercentage}
            isView={isView}
            onChange={(val) => {
                onChange(val || 0);
            }}
            alignView="right"
            useInTable={true}
        />
    );
};

export default function SectionItems(props) {
    const { initForm, isView = false } = props;
    const { getConfigByKey } = useConfigRepo(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [header, setHeader] = useAtom(headerPRAtom);
    const [goodsItem, setGoodsItem] = useAtom(goodsItemPr)
    const [serviceItem, setServiceItem] = useAtom(servicesItemPr)
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [idRowHighlightGoods, setIdRowHighlightGoods] = useState(null);
    const [idRowHighlightServices, setIdRowHighlightServices] = useState(null);
    const [typeItem, setTypeItem] = useState(Constant.typeItemPR.GOODS);
    const [maxSizeAttachmentItems, setMaxSizeAttachmentItems] = useState(0)
    const [mediaTypesAttachmentItems, setMediaTypesAttachmentItems] = useState("")
    const { prId } = useParams();
    const { UIPreviewImageModal, handleClickAttachmentItemPR } = useDownloadOrPreviewMediaHook()
    const [isDrawerModeView, setIsDrawerModeView] = useState(false)

    const fetchDataMaxSizeAttachmentItems = async () => {
        const response = await getConfigByKey({ key: Constant.keyConfigDb.PR_Attachment_Max_Size });
        if (response?.isValid && response?.statusCode === 200) {
            setMaxSizeAttachmentItems(Number(response?.data?.configValue))
        } else {
            console.error(`Error getting config ${Constant.keyConfigDb.PR_Attachment_Max_Size}`);
        }
    };

    const fetchDataMediaTypesAttachmentItems = async () => {
        const response = await getConfigByKey({ key: Constant.keyConfigDb.PR_Attachment_Allowed_Extension });
        if (response?.isValid && response?.statusCode === 200) {
            setMediaTypesAttachmentItems(response?.data?.configValue)
        } else {
            console.error(`Error getting config ${Constant.keyConfigDb.PR_Attachment_Allowed_Extension}`);
        }
    };

    const fetchDataVAT = async (key, field, vatPercentage) => {
        const response = await getConfigByKey({ key });
        if (response?.isValid && response?.statusCode === 200) {
            vatPercentage(Number(response.data.configValue));
            setFieldValues(initForm, field, Number(response.data.configValue));
        } else {
            console.error(`Error getting config default ${key} items vat`);
        }
    };

    const fetchDataGoodsVAT = () => {
        fetchDataVAT(
            Constant.keyConfigDb.Goods_Percentage_VAT,
            "goodsTax",
            (percentage) => setHeader((prev) => ({ ...prev, goodsVatPercentage: percentage }))
        );
    };

    const fetchDataServicesVAT = () => {
        fetchDataVAT(
            Constant.keyConfigDb.Service_Percentage_VAT,
            "servicesTax",
            (percentage) => setHeader((prev) => ({ ...prev, servicesVatPercentage: percentage }))
        );
    };

    useEffect(() => {
        setTotalAmount(header?.totalGoodsAmountWithTax + header?.totalServicesAmountWithTax);
    }, [header?.totalGoodsAmountWithTax, header?.totalServicesAmountWithTax]);

    useEffect(() => {
        fetchDataMaxSizeAttachmentItems()
        fetchDataMediaTypesAttachmentItems()
        if (!isView) {
            if (!prId) {
                fetchDataGoodsVAT();
                fetchDataServicesVAT();
            }
        }
    }, []);

    const handleCloseItemDetail = () => {
        handleDrawerOpen(false, false, null, typeItem);
    };

    const handleDrawerOpen = (isView = false, isOpen, idHighlight, dataRow, typeItem) => {
        let finalOpen = isOpen;
        let finalIdHighlight = idHighlight //typeItem === Constant.typeItemPR.GOODS ? idRowHighlightGoods : idRowHighlightServices
        let finalDataView = dataRow;

        if (typeItem === Constant.typeItemPR.GOODS) {
            setIdRowHighlightServices(null)
            setTypeItem(Constant.typeItemPR.GOODS)
            if (idHighlight === null) finalIdHighlight = idRowHighlightGoods //ikutin nilai sebelumnya
            setIdRowHighlightGoods(finalIdHighlight)
        } else {
            setIdRowHighlightGoods(null)
            setTypeItem(Constant.typeItemPR.SERVICES)
            if (idHighlight === null) finalIdHighlight = idRowHighlightServices //ikutin nilai sebelumnya
            setIdRowHighlightServices(finalIdHighlight)
        }

        if (!isOpen) {
            setIdRowHighlightGoods(null)
            setIdRowHighlightServices(null)
        }

        if (isOpen === null) finalOpen = isOpenDrawer //null berarti tidak true/false drawer opennya
        if (dataRow === 0) finalDataView = selectedItem //0 artinya tidak ada data yg di pass dari child item goods

        setIsOpenDrawer(finalOpen)
        setSelectedItem(finalDataView)
        setIsDrawerModeView(isView)
    }

    const columns = [
        {
            title: 'Item Type',
            dataIndex: 'itemType',
            className: "custom-table-edit-row-input",
            width: "80px",
        },
        {
            title: 'Total',
            dataIndex: 'totalBeforeTax',
            className: "custom-table-edit-row-input",
            width: "140px",
            align: 'right',
        },
        {
            title: <IconRequired text="VAT Rate (%)" />,
            dataIndex: 'vatRate',
            className: "custom-table-edit-row-input",
            width: "76px",
            align: 'right',
            render: (text, record, index) => {
                return (
                    <>
                        {record?.index === 1 && renderCustomFormItemNumber(`goodsTax`, `goodsTax`, 'goods-item-tax', header.goodsVatPercentage, initForm, isView, (val) => {
                            setHeader(prev => ({
                                ...prev,
                                goodsVatPercentage: val,
                            }));
                        })}
                        {record?.index === 2 && renderCustomFormItemNumber(`servicesTax`, (`servicesTax`), 'services-item-tax', header.servicesVatPercentage, initForm, isView, (val) => {
                            setHeader(prev => ({
                                ...prev,
                                servicesVatPercentage: val,
                            }));
                        })}
                    </>
                );
            }
        },
        {
            title: 'VAT Amount',
            dataIndex: 'vatAmount',
            className: "custom-table-edit-row-input",
            width: "120px",
            align: 'right',
        },
        {
            title: 'Total (After Tax)',
            dataIndex: 'totalAfterTax',
            className: "custom-table-edit-row-input",
            width: "140px",
            align: 'right',
        },
    ]

    useEffect(() => {
        let good = [...goodsItem]
        let newGoods = good.map((item) => {
            if (item.isDifferentRequiredDate) {
                return { ...item }
            }
            return {
                ...item,
                requiredDate: header.requiredDate
            }
        })
        setGoodsItem(newGoods)

        let services = [...serviceItem]
        let newServices = services.map((item) => {
            if (item.isDifferentRequiredDate) {
                return { ...item }
            }
            return {
                ...item,
                requiredDate: header.requiredDate
            }
        })
        setServiceItem(newServices)
    }, [header.requiredDate])


    //trigger to close drawer detail when items is null/[], bcs user change type pr in header or delete item
    useEffect(() => {
        handleDrawerOpen(false, false, null, Constant.typeItemPR.GOODS);
    }, [goodsItem])
    useEffect(() => {
        handleDrawerOpen(false, false, null, Constant.typeItemPR.SERVICES);
    }, [serviceItem])

    return (
        <div className="pr-section-items">
            <GoodsItem
                isView={isView}
                initForm={initForm}
                propsDrawer={handleDrawerOpen}
                idRowHighlight={idRowHighlightGoods}
            />
            <ServicesItem
                isView={isView}
                initForm={initForm}
                propsDrawer={handleDrawerOpen}
                idRowHighlight={idRowHighlightServices}
            />
            {/* <Divider dashed className="divider-total-items" /> */}
            {/* <div className="wrap-section-total">
                <div className="section-total"
                // style={{ width: tableWidth, marginInline: 0 }}
                >
                    <p className="font-bold">Grand Total (Goods & Service)</p>
                    <p className="amount">{formatterAmount(totalAmount)}</p>
                </div>
            </div> */}

            {
                (goodsItem.length > 0 || serviceItem.length > 0) &&
                <>
                    <p className="font-semibold">Grand Total (Goods & Service)</p>
                    <CustomAntdTable
                        className='custom-table-edit-row table-goods-item'
                        rowKey={"index"}
                        columns={columns}
                        dataSource={[
                            goodsItem.length > 0 ? {
                                index: 1,
                                itemType: "Goods",
                                totalBeforeTax: formatterAmount(header.totalAmountGoodsBeforeTax),
                                vatRate: formatterAmount(header.goodsVatPercentage),
                                vatAmount: formatterAmount(header.totalAmountGoodsVatPercentage),
                                totalAfterTax: formatterAmount(header.totalAmountGoodsBeforeTax + header.totalAmountGoodsVatPercentage),
                            } : null,
                            serviceItem.length > 0 ? {
                                index: 2,
                                itemType: "Service",
                                totalBeforeTax: formatterAmount(header.totalAmountServicesBeforeTax),
                                vatRate: formatterAmount(header.servicesVatPercentage),
                                vatAmount: formatterAmount(header.totalAmountServicesVatPercentage),
                                totalAfterTax: formatterAmount(header.totalAmountServicesBeforeTax + header.totalAmountServicesVatPercentage),
                            } : null,
                        ].filter(item => item !== null)}
                        pagination={false}
                        ignoreRowClick={true}
                        summary={pageData => (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell className="summary-item total-include-tax" colSpan={4}>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell className="summary-item total-include-tax" align="right">
                                        <Text>{formatterAmount(totalAmount)}</Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        )}
                    />
                </>
            }

            <UIPreviewImageModal />

            {isOpenDrawer && (
                <BaseItemsDetailV2
                    onClose={handleCloseItemDetail}
                    data={selectedItem}
                    type={typeItem}
                    maxSizeAttachmentItems={maxSizeAttachmentItems}
                    mediaTypesAttachmentItems={mediaTypesAttachmentItems}
                    handleDownloadAttachment={handleClickAttachmentItemPR}
                    isView={isDrawerModeView}
                />
            )}

        </div>
    );
}
