import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { paginationPropsTableAntd } from 'utils/Helper'

const generatePaginationTotal = (from, to, total) => {
    return (
        <span>
            Showing <strong>{from}</strong>-
            <strong>{to}</strong> of <strong>{total}</strong>
        </span>
    )
}

export default function CustomAntdTable(props) {
    const [idDataHighlightOnClickRow, setIdDataHighlightOnClickRow] = useState(null)
    const { className, columns, pagination = true, rowKey, rowClick = () => { }, tableParam, totalData, idDataHighlight = null, ignoreRowClick = false, tableRef, size = 'middle' } = props

    const handleRowClick = (record, rowIndex) => {
        if (!ignoreRowClick) {
            rowClick(record, rowIndex)
            setIdDataHighlightOnClickRow(record?.[props.rowKey])
        }
    }

    //for highlight row
    useEffect(() => {
        if (idDataHighlight === null) {
            setIdDataHighlightOnClickRow(null)
        }
        else {
            setIdDataHighlightOnClickRow(idDataHighlight)
        }
    }, [idDataHighlight])

    return (
        <div>
            <Table
                {...props}
                ref={tableRef}
                size={size}
                // scroll={{ x: 'max-content' }}
                scroll={props?.scroll ? { ...props?.scroll } : { x: 'max-content' }}
                className={`antd-table-custom ${className}`}
                columns={columns}
                rowKey={(record) => (record[rowKey])}
                sortDirections={["ascend", "descend", "ascend"]}
                showSorterTooltip={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            handleRowClick(record, rowIndex)
                        },
                    };
                }}
                pagination={pagination ?
                    {
                        ...paginationPropsTableAntd(tableParam, totalData),
                        showTotal: (total, range) => generatePaginationTotal(range[0], range[1], total),
                        jumpPrevIcon: <span>...</span>,
                        jumpNextIcon: <span>...</span>,
                    }
                    : pagination}
                rowClassName={(record, index) => record?.[props.rowKey] === idDataHighlightOnClickRow ? 'custom-table-row-color-selected' : ""}
            // locale={{
            //     emptyText: (
            //         <span>No data</span>
            //     )
            // }}
            />
        </div>
    )
}
