import { Card, Col, Form, Row, } from 'antd';
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer';
import React, { useEffect, useState } from 'react';
import Constant from 'utils/Constants';
import { formatterAmount, setFieldValues } from 'utils/Helper';
import dayjs from 'dayjs';
import { CustomFormItemDate, CustomFormItemMoney, CustomFormItemNumber, CustomFormItemText, CustomFormItemTextArea, CustomFormItemUploadSingle } from 'components/Common/CustomFormItem';
import { useAtom } from 'jotai';
import { goodsItemPr, headerPRAtom, servicesItemPr } from 'store/PurchaseRequest/purchaseRequestAtom';
import { ButtonPrimary } from 'components/Common/Buttons';
import { errorToast } from 'utils/Toast';

export default function BaseItemsDetailV2(props) {
    const { onClose,
        data = null,
        loading = false,
        type = Constant.typeItemPR.GOODS,
        isView = false,
        maxSizeAttachmentItems = 0,
        mediaTypesAttachmentItems = "",
        handleDownloadAttachment = () => { },
    } = props;

    const [form] = Form.useForm();
    const [dataHeader,] = useAtom(headerPRAtom);
    const [dataItemsGoods, setDataItemsGoods] = useAtom(goodsItemPr);
    const [dataItemsService, setDataItemsService] = useAtom(servicesItemPr);
    const [dataEdit, setDataEdit] = useState(null);

    useEffect(() => {
        if (data) {
            // const { itemName, itemCategoryName, description, remarks, requiredDate, quantity, price, subTotal, contractCatalogNo, vendorName } = data;
            let currentData = null
            if (type === Constant.typeItemPR.GOODS) {
                currentData = dataItemsGoods.find(item => item.itemId === data?.itemId);
                setDataEdit(currentData);
            }
            if (type === Constant.typeItemPR.SERVICES) {
                currentData = dataItemsService.find(item => item.itemId === data?.itemId);
                setDataEdit(currentData);
            }
            // debugger
            setFieldValues(form, "itemNameDetail", currentData?.itemName);
            setFieldValues(form, "itemCategoryDetail", currentData?.itemCategoryName);
            setFieldValues(form, "itemDescriptionDetail", currentData?.description);
            setFieldValues(form, "itemRemarksDetail", currentData?.remarks);
            setFieldValues(form, "itemRequiredDateDetail", currentData?.requiredDate ? dayjs(currentData?.requiredDate) : "");
            setFieldValues(form, "itemQtyDetail", currentData?.quantity);
            setFieldValues(form, "priceDetail", formatterAmount(currentData?.price));
            setFieldValues(form, "subTotalDetail", formatterAmount(currentData?.subTotal));
            setFieldValues(form, "contractCatalogNoDetail", currentData?.contractCatalogNo);
            setFieldValues(form, "vendorNameDetail", currentData?.vendorName);
            setFieldValues(form, "itemAttachmentDetail", currentData?.attachmentFile);
        }
    }, [data, type, isView]);

    const handleCloseDrawer = () => {
        onClose();
    };

    const handleSave = () => {
        form.validateFields()
            .then((values) => {
                if (type === Constant.typeItemPR.GOODS) {
                    let currentData = dataItemsGoods.find(item => item.itemId === data?.itemId);
                    currentData["price"] = dataEdit?.price;
                    currentData["quantity"] = dataEdit?.quantity;
                    currentData["subTotal"] = dataEdit?.price * dataEdit?.quantity;
                    currentData["remarks"] = dataEdit?.remarks;
                    currentData["requiredDate"] = dataEdit?.requiredDate;
                    currentData["attachmentFile"] = dataEdit?.attachmentFile;
                    setDataItemsGoods([...dataItemsGoods]);
                }
                if (type === Constant.typeItemPR.SERVICES) {
                    let currentData = dataItemsService.find(item => item.itemId === data?.itemId);
                    currentData["price"] = dataEdit?.price;
                    currentData["quantity"] = dataEdit?.quantity;
                    currentData["subTotal"] = dataEdit?.price * dataEdit?.quantity;
                    currentData["remarks"] = dataEdit?.remarks;
                    currentData["requiredDate"] = dataEdit?.requiredDate;
                    currentData["attachmentFile"] = dataEdit?.attachmentFile;
                    setDataItemsService([...dataItemsService]);
                }

                onClose();
            })
            .catch((errorInfo) => {
                errorToast(Constant.message.errorForm)
            });
    }

    // console.log("Data edit", dataEdit);
    // console.log("Data Goods", dataItemsGoods);
    // console.log("Data Service", dataItemsService);

    return (
        <BaseDrawer
            title={`${type === 1 ? 'Goods' : 'Services'} Item Detail`}
            placement="right"
            size="large"
            rootClassName={"detail-items"}
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
            extras={
                <>
                    {!isView && <ButtonPrimary
                        type="primary"
                        onClick={handleSave}
                        buttonText='Save'
                    />}
                </>
            }
        >
            <Card>
                <Form form={form} layout="vertical" name="form-view-detail-items">
                    <Row gutter={16}>
                        <Col xs={24} lg={12}>
                            <CustomFormItemText
                                nameFormItem='itemNameDetail'
                                label="Item Name"
                                value={dataEdit?.itemName}
                                isView={true}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CustomFormItemText
                                nameFormItem="itemNameCategoryDetail"
                                label="Item Category"
                                value={dataEdit?.itemCategoryName}
                                isView={true}
                            />
                        </Col>
                        {dataHeader?.prTypeId === Constant.PRType.CATALOG && <>
                            <Col xs={24} lg={12}>
                                <CustomFormItemText
                                    nameFormItem="contractCatalogNoDetail"
                                    label="Catalog No"
                                    value={dataEdit?.contractCatalogNo}
                                    isView={true}
                                />
                            </Col>
                            <Col xs={24} lg={12}>
                                <CustomFormItemText
                                    nameFormItem="vendorNameDetail"
                                    label="Vendor Name"
                                    value={dataEdit?.vendorName}
                                    isView={true}
                                />
                            </Col>
                        </>}
                        <Col xs={24}>
                            <CustomFormItemTextArea
                                nameFormItem="itemDescriptionDetail"
                                label="Description"
                                value={dataEdit?.description}
                                isView={true}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CustomFormItemDate
                                nameFormItem="itemRequiredDateDetail"
                                label="Required Date"
                                value={dataEdit?.requiredDate}
                                isView={isView ? isView : !dataEdit?.isDifferentRequiredDate}
                                onChange={(date, dString) => setDataEdit(prev => ({
                                    ...prev,
                                    requiredDate: dString ? dayjs(dString).format(Constant.stateDateFormat) : null
                                }))}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CustomFormItemUploadSingle
                                nameFormItem="itemAttachmentDetail"
                                label="Attachment"
                                isHasFile={dataEdit?.attachmentFile?.fileOriginalName ? true : false}
                                maxSize={maxSizeAttachmentItems}
                                mediaType={mediaTypesAttachmentItems}
                                onSelectedFile={(file, filename, filesize) => {
                                    setDataEdit(prev => ({
                                        ...prev,
                                        attachmentFile: {
                                            fileOriginalName: filename,
                                            file: file,
                                            itemId: prev?.itemId
                                        }
                                    }));
                                }}
                                onDownloadFile={handleDownloadAttachment}
                                onDeleteFile={() => {
                                    setDataEdit(prev => ({
                                        ...prev,
                                        attachmentFile: null
                                    }));
                                }}
                                fileName={dataEdit?.attachmentFile?.fileOriginalName}
                                data={dataEdit}
                                isView={isView}
                            />
                        </Col>
                        <Col xs={24}>
                            <CustomFormItemTextArea
                                nameFormItem="itemRemarksDetail"
                                label="Remarks"
                                value={dataEdit?.remarks}
                                onChange={(e) => setDataEdit(prev => ({ ...prev, remarks: e.target.value }))}
                                isView={isView}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CustomFormItemNumber
                                nameFormItem="itemQtyDetail"
                                nameInput='itemQtyDetail'
                                label="Quantity"
                                placeholder="Please enter quantity"
                                initForm={form}
                                rules={[{ required: true }]}
                                customvalue={dataEdit?.quantity}
                                wrapperCol={{ span: 12 }}
                                precision={dataEdit?.uomAllowDecimal ? 2 : 0}
                                addonAfter={dataEdit?.uomName || 'UOM'}
                                onChange={(value) => setDataEdit(prev => ({ ...prev, quantity: value, subTotal: value * prev?.price }))}
                                isView={isView}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CustomFormItemMoney
                                nameFormItem="priceDetail"
                                nameInput="priceDetail"
                                label="Price"
                                placeholder="Please enter price"
                                initForm={form}
                                customvalue={dataEdit?.price}
                                rules={[{ required: true }]}
                                onChange={(value) => setDataEdit(prev => ({ ...prev, price: value, subTotal: value * prev?.quantity }))}
                                isView={isView}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <CustomFormItemMoney
                                nameFormItem="subTotalDetail"
                                label="Sub Total"
                                customvalue={dataEdit?.subTotal}
                                isView={true}
                            />
                        </Col>
                    </Row>
                </Form>
            </Card>
        </BaseDrawer>
    );
}
