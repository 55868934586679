/**
 * September 26th
 * 2023
 *
 * created:
 * Try Fanny Poerna Maulana
 */

import { Button, Card, Col, Form, Row } from "antd";
import { BaseDrawer } from "components/CustomDrawer/BaseDrawer";
import React, { useEffect, useRef, useState } from "react";
import useEmailTemplateRepo from "services/repo/useEmailTemplateRepo";
import Constant from "utils/Constants";
import CustomAlert from "utils/CustomAlert";
import { dismissToast, loadingToastId, successToast } from "utils/Toast";
// import "react-quill/dist/quill.snow.css";
// import ReactQuill from "react-quill";
import DevLog from "utils/DevLog";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { CustomFormItemText, CustomFormItemTextArea } from "components/Common/CustomFormItem";

const init_field = {
  emailTemplateId: null,
  code: "",
  subject: "",
  body: "",
  description: "",
  isActive: true,
  lastModifiedAt: "",
};

export default function BaseDrawerEmailTemplate(props) {
  const {
    onClose,
    dataEdit = null,
    onRefreshList,
    isView = false,
    loading = false,
    setModeView = () => { },
    isAddEditPrivileged,
  } = props;
  const [form] = Form.useForm();
  const [dataField, setDataField] = useState(init_field);
  const { isLoading: isLoadingUpdate, updateEmailTemplate } =
    useEmailTemplateRepo(false);
  const editorRef = useRef();

  const handleCloseDrawer = () => {
    form.resetFields();
    setDataField(init_field);
    setModeView(true);
    onClose();
  };

  const onSaveEdit = async () => {
    let payload = {
      emailTemplateId: dataField.emailTemplateId,
      code: dataField.code,
      subject: dataField.subject,
      body: dataField.body,
      description: dataField.description,
      isActive: dataField.isActive,
      lastModifiedAt: dataField.lastModifiedAt,
    };
    loadingToastId(Constant.message.savingData, payload?.emailTemplateId);
    let res = await updateEmailTemplate(payload);
    dismissToast(payload?.emailTemplateId);
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  };

  const handleFormSubmit = () => {
    CustomAlert.confirm({
      title: "Save Email Template!",
      description: `Are you sure you want to save this data?`,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "primary",
      onConfirmButton: () => {
        form.validateFields()
          .then((values) => {
            if (dataEdit !== null) {
              onSaveEdit();
            }
          })
          .catch((errorInfo) => {
            DevLog("err", errorInfo);
          });
      }
    });
  };

  useEffect(() => {
    setModeView(isView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isView]);

  useEffect(() => {
    if (dataEdit !== null) {
      setDataField(dataEdit);
      form.setFieldsValue({
        code: dataEdit?.code,
        subject: dataEdit?.subject,
        body: dataEdit?.body,
        description: dataEdit?.description,
        isActive: dataEdit?.isActive,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  // const modules = {
  //   toolbar: [
  //     [{ 'header': [1, 2, false] }],
  //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  //     [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
  //     ['link', 'image'],
  //     ['clean', 'table'],
  //   ],
  // }

  // console.log("dataField?.body", dataField?.body);

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  useEffect(() => {
    if (editorRef.current) {
      if (dataEdit !== null) {
        editorRef.current.setContents(dataEdit?.body);
      } else {
        editorRef.current.setContents("");
      }
    }
  }, [editorRef.current])

  const modifyBody = (value) => {
    let newBody = `<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Document</title>
      <style>
          table.se-table-size-auto {
              width: auto !important;
          }

          table {
              display: table;
              table-layout: auto !important;
              border: 1px solid #ccc;
              width: 100%;
              max-width: 100%;
              margin: 0 0 10px;
              background-color: transparent;
              border-spacing: 0;
              border-collapse: collapse;
          }

          table tr {
              border: 1px solid #efefef;
          }

          table td,
          table th {
              border: 1px solid #e1e1e1;
              padding: 0.4em;
              background-clip: padding-box;
          }

          div {
              display: block;
              margin: 0;
              padding: 0;
          }
      </style>
    </head>
    <body>
      ${value}
    </body>
    </html>`
    setDataField((prev) => ({ ...prev, body: newBody }))
  }

  // console.log(dataField?.body);

  return (
    <>
      <BaseDrawer
        title={`${isView ? "View" : dataEdit === null ? "Add" : "Edit"
          } Email Template`}
        placement="right"
        size="large"
        onClose={handleCloseDrawer}
        open={true}
        loading={loading}
        extras={
          <>
            {(isAddEditPrivileged && isView) && (
              <Button type="primary" onClick={() => setModeView(false)}>
                Edit
              </Button>
            )}
            {(isAddEditPrivileged && !isView) && (
              <Button
                type="primary"
                onClick={handleFormSubmit}
                htmlType="submit"
                loading={isLoadingUpdate}
              >
                Submit
              </Button>
            )}
          </>
        }
      >
        <Card>
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col xs={24}>
                <CustomFormItemTextArea
                  nameFormItem="description"
                  label="Description"
                  value={dataField?.description}
                  isView={isView}
                  placeholder="Fill in Description"
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      description: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="subject"
                  label="Subject"
                  placeholder="Fill in Subject"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.subject}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      subject: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="code"
                  label="Code"
                  placeholder="Fill in Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.code}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      code: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24}>
                <Form.Item label="Body" className="font-semibold" >
                  {/* <ReactQuill
                  modules={modules}
                  theme="snow"
                  placeholder="Please fill body email"
                  readOnly={isView}
                  value={dataField?.body}
                  onChange={(value) =>
                    setDataField((prev) => ({ ...prev, body: value }))
                  }
                /> */}
                  <SunEditor
                    getSunEditorInstance={getSunEditorInstance}
                    setContents={dataField?.body}
                    onChange={(value) =>
                      // setDataField((prev) => ({ ...prev, body: value }))
                      modifyBody(value)
                    }
                    readOnly={isView}
                    setOptions={{
                      height: '500',
                      buttonList: [
                        ['undo', 'redo'],
                        ['font', 'fontSize', 'formatBlock'],
                        ['paragraphStyle', 'blockquote'],
                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                        ['fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        // '/', // Line break
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                        ['table', /**'link', 'image',  'video', 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                        // ['fullScreen', 'showBlocks', 'codeView'],
                        // ['preview', 'print'],
                        // ['save', 'template'],
                        /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                      ]
                    }}
                  />

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </BaseDrawer>
    </>
  );
}
