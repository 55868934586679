import * as React from "react";

function IconSearch({ color = "#6B7280" }) {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 2a4 4 0 100 8 4 4 0 000-8zM0 6a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414L9.477 10.89A6 6 0 010 6z"
                fill={color}
            />
        </svg>
    );
}

export default IconSearch;