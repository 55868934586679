/**
 * 
 * @param {any} message 
 * @returns 
 */
const DevLog = (message) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return console.log(message)
    }
}

export default DevLog