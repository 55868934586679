/*
*TIDAK DIPAKAI
*/

import { Col } from 'antd';
import React, { useEffect, useState } from 'react'
import { builderName, setFieldValues } from 'utils/Helper';
import { useAtom } from 'jotai';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { goodsItemROGS, headerROGSAtom } from 'store/ROGS/rogsAtom';
import LookupEmployee from './Modal/LookupEmployee';
import IconRequired from 'components/Common/IconRequired';
import CellTable from 'components/Common/CellTable';
import { CustomFormItemCheckbox, CustomFormItemLookup, CustomFormItemNumber, CustomFormItemTextArea } from 'components/Common/CustomFormItem';

export default function GoodsItem(props) {
    const { initForm, isView = false } = props
    const [indexGoods, setIndexGoods] = useState(null)
    const [dataHeader,] = useAtom(headerROGSAtom)
    const [dataItems, setDataItems] = useAtom(goodsItemROGS)

    const [isOpenLookupEmployee, setIsOpenLookupEmployee] = useState(false)

    const onChangeInputRow = (field, value, index) => {
        let datas = [...dataItems];
        let currentData = datas[index]

        if (field === 'isDifferenceReceiverName') {
            currentData.isDifferenceReceiverName = value
            if (value) {
                currentData["receiverId"] = null;
                currentData["receiverName"] = "";
                setFieldValues(initForm, `goods_receiverName_${index}`, "");
            } else {
                if (dataHeader.receiverId) {
                    currentData["receiverId"] = dataHeader.receiverId;
                    currentData["receiverName"] = dataHeader.receiverName;
                    setFieldValues(initForm, `goods_receiverName_${index}`, dataHeader.receiverName);
                }
            }
        }

        if (field === "receiverName") {
            const receiverName = builderName(value?.firstName, value?.lastName)
            currentData["receiverId"] = value?.employeeId;
            currentData["receiverName"] = receiverName;
            setFieldValues(initForm, `goods_receiverName_${index}`, receiverName);
        } else {
            currentData[field] = value;
        }

        setDataItems(datas)
    }

    const handleSelectReceiver = (data, index) => {
        onChangeInputRow("receiverName", data, index)
        setIsOpenLookupEmployee(false)
    }

    const updateFieldTableValues = (index, data) => {
        setFieldValues(initForm, `goods_qtyReceived_${index}`, data.qtyReceived);
        setFieldValues(initForm, `goods_isDifferenceReceiverName_${index}`, data.isDifferenceReceiverName);
        setFieldValues(initForm, `goods_receiverName_${index}`, data.receiverName);
        setFieldValues(initForm, `goods_notes_${index}`, data?.notes);
    };

    useEffect(() => {
        [...dataItems]?.map((data, index) => {
            updateFieldTableValues(index, data);
        });
    }, [dataItems])

    // useEffect(() => {
    //     setDataWarning(warnings)
    // }, [warnings])


    const columnsGoods = [
        {
            title: 'PR Number',
            dataIndex: 'prNo',
            className: "custom-table-edit-row-input",
            width: "180px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            className: "custom-table-edit-row-input",
            width: "200px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Quantity Ordered',
            dataIndex: 'qtyOrdered',
            className: "custom-table-edit-row-input",
            width: "70px",
            align: "right"
        },
        {
            title: 'Quantity Remaining',
            dataIndex: 'qtyExpected',
            className: "custom-table-edit-row-input",
            width: "70px",
            align: "right"
        },
        {
            title: <IconRequired text='Quantity Received' />,
            dataIndex: 'qtyReceived',
            className: "custom-table-edit-row-input",
            align: 'right',
            width: "120px",
            render: (text, record, index) => {
                return (
                    <Col>
                        <CustomFormItemNumber
                            nameFormItem={'goods_qtyReceived_' + index}
                            nameInput={'goods_qtyReceived_' + index}
                            rules={[
                                { required: true, message: 'Please input Quantity' },
                                // {
                                //     validator: (_, value) => {
                                //         const currentData = dataItems?.[index]?.qtyReceived
                                //         if (currentData <= 0) {
                                //             return Promise.reject(new Error('Quantity Received must be greater than 0'));
                                //         } else {
                                //             return Promise.resolve();
                                //         }
                                //     },
                                //     validateTrigger: true,
                                // },
                                // {
                                //     validator: (_, value) => {
                                //         const currentData = dataItems?.[index];
                                //         const qtyReceived = currentData?.qtyReceived;
                                //         const qtyExpected = currentData?.qtyExpected;
                                //         if (qtyReceived > qtyExpected) {
                                //             return Promise.reject(new Error('Quantity Received cannot be greater than Quantity Remaining.'));
                                //         } else {
                                //             return Promise.resolve();
                                //         }
                                //     },
                                //     validateTrigger: true,
                                // },
                                {
                                    validator: (_, value) => {
                                        const currentData = dataItems?.[index]
                                        const qtyReceived = currentData?.qtyReceived
                                        const qtyExpected = currentData?.qtyExpected
                                        if (dataHeader?.isLastRogsTerm && !dataHeader?.rescheduleDate) {
                                            if (qtyReceived < qtyExpected) {
                                                return Promise.reject();
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                        return Promise.resolve();
                                    },
                                    validateTrigger: true,
                                    warningOnly: true,
                                },
                            ]}
                            initForm={initForm}
                            customvalue={record?.qtyReceived}
                            precision={record?.uomAllowDecimal ? 2 : 0}
                            isView={isView}
                            onChange={(e) => onChangeInputRow("qtyReceived", e, index)}
                            useInTable={true}
                            alignView="right"
                        />
                    </Col>
                )
            }
        },
        {
            title: 'UOM',
            dataIndex: 'uomName',
            className: "custom-table-edit-row-input",
            width: "70px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: <IconRequired text='Receiver Name' />,
            dataIndex: 'receiverName',
            className: "custom-table-edit-row-input",
            width: "250px",
            render: (text, record, index) => {
                return (
                    <div style={{ display: 'flex', gap: "10px" }}>
                        <CustomFormItemCheckbox
                            nameFormItem={'goods_isDifferenceReceiverName_' + index}
                            value={record?.isDifferenceReceiverName && "checked"}
                            isView={isView}
                            onChange={(e) => {
                                onChangeInputRow("isDifferenceReceiverName", e.target.checked, index)
                            }}
                            title="Check to input different PIC Receiver."
                            useInTable={true}
                        />
                        <CustomFormItemLookup
                            nameFormItem={'goods_receiverName_' + index}
                            rules={[
                                // { required: true, message: 'Please input Receiver Name' },
                                {
                                    validator: (_, value) => {
                                        let datas = [...dataItems];
                                        let currentData = datas[index]
                                        const checkIsDifferenceReceiverName = currentData.isDifferenceReceiverName
                                        if (!value && checkIsDifferenceReceiverName) {
                                            return Promise.reject(new Error("Please choose Receiver Name"))
                                        } else {
                                            return Promise.resolve();
                                        }

                                    }
                                }
                            ]}
                            placeholder="Select Receiver Name"
                            initialValue={record?.receiverName}
                            valueLookup={record?.receiverName}
                            onSearch={() => {
                                setIndexGoods(index)
                                setIsOpenLookupEmployee(true)
                            }}
                            isView={isView || !record?.isDifferenceReceiverName}
                            useLookupView={!isView}
                            useInTable={true}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            className: "custom-table-edit-row-input",
            width: "300px",
            render: (text, record, index) => {
                return (
                    <Col>
                        <CustomFormItemTextArea
                            nameFormItem={'goods_notes_' + index}
                            value={record?.notes}
                            isView={isView}
                            rows={2}
                            placeholder="Fill in Notes"
                            onChange={(e) => {
                                onChangeInputRow("notes", e.target.value, index)
                            }}
                            useInTable={true}
                        />
                    </Col>
                )
            }
        },
    ];

    // console.log("goods", dataItems);

    return (
        <>
            <div className='mb-2'>
                <p className="font-semibold">Goods Item</p>
                <CustomAntdTable
                    className='custom-table-edit-row table-goods-item'
                    rowKey={"poItemDetailId"}
                    columns={columnsGoods}
                    dataSource={dataItems}
                    pagination={false}
                    ignoreRowClick={true}
                />
            </div>

            {isOpenLookupEmployee && <LookupEmployee
                indexFromParent={indexGoods}
                onClose={() => {
                    setIndexGoods(null)
                    setIsOpenLookupEmployee(false)
                }}
                onSelect={handleSelectReceiver}
            />}
        </>
    )
}
