/**
 * September 13th
 * 2023
 * 
 * Updated: October 10th
 * 2023
 * 
 * created:
 * Try Fanny Poerna Maulana
 */

import { Card, Col, Divider, Form, Input, Row, Select, Space, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ButtonAction from 'components/Common/ButtonAction';
import { ButtonAdd } from 'components/Common/Buttons';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { dismissToast, loadingToastId } from 'utils/Toast';
import Constant from 'utils/Constants';
import { sorterTable } from 'utils/Helper';
import useItemCategoryRepo from 'services/repo/useItemCategoryRepo';
import useIsMobileHooks from 'hooks/useIsMobileHooks';
import useAccessPermissionHook from 'hooks/useAccessPermissionHook';
import { PRIVILEGE_ID } from 'utils/AccessPermissionConfig';
import SkeletonPage from 'components/Common/SkeletonPage';
import { ButtonAdvanceFilter } from 'components/Common/ButtonAdvanceFilter';
import Error403 from 'components/Error/Error403';
import BaseDrawerItemCategory from 'components/MasterData/ItemCategory/BaseDrawerItemCategory';
import BaseAdvanceFilter from 'components/CustomDrawer/BaseAdvanceFilter';
import useItemTypeRepo from 'services/repo/useItemTypeRepo';
import CellTable from 'components/Common/CellTable';
import TagActiveInCell from 'components/Common/TagActiveInCell';


export default function ItemCategory() {
  useTopbarTitleHooks("Master Data Item Category");
  const { isLoading: isLoadingList, getItemCategoryList } = useItemCategoryRepo(false)
  const { isLoading: isLoadingById, getItemCategoryById } = useItemCategoryRepo(false)
  const { isLoading: isLoadingItemTypeList, getItemTypeList } = useItemTypeRepo(false)

  const isMobile = useIsMobileHooks()
  const [form] = Form.useForm()
  const [data, setData] = useState()
  const [totalData, setTotalData] = useState(0)
  const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false)
  const [isViewDrawer, setIsViewDrawer] = useState(false)
  const [dataEditDrawer, setDataEditDrawer] = useState(null)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [idHighlightRow, setIdHighlightRow] = useState(null)

  const { isHasPrivilege } = useAccessPermissionHook()
  const isLoadingPrivilege = useRef(true)
  const hasPrivilegePage = useRef(true)
  const [isLoadedPrivilege, setIsLoadedPrivilege] = useState(false)
  const [showButtonAdd, setShowButtonAdd] = useState(false)

  const [filters, setFilters] = useState({
    itemTypeId: null,
    itemTypeName: "",
    itemCategoryId: null,
    itemCategoryCode: "",
    itemCategoryName: "",
    description: "",
    isActive: null,
  })
  const [tableParams, setTableParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    filter: { ...filters },
    orderBy: "itemCategoryCode",
    isAscending: true
  });

  const onSearch = (value) => {
    setTableParams(prev => ({
      ...prev,
      pageNumber: 1,
      filter: {
        ...filters
      }
    }))
  };

  const onSearchClick = () => {
    onSearch()
    setIsOpenAdvanceFilter(false)
  }

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
    setDataEditDrawer(null)
    setIdHighlightRow(null)
  };

  const fetchData = async () => {
    const payload = {
      ...tableParams
    }
    const res = await getItemCategoryList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setData(res?.data?.listData)
      setTotalData(res?.data?.totalRecords)
      setTableParams(prevParams => ({ ...prevParams }));
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams(prevParams => ({
      ...prevParams,
      pageNumber: pagination?.current,
      pageSize: pagination?.pageSize,
      orderBy: sorter?.field || prevParams?.orderBy,
      isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
    }));
    if (pagination.pageSize !== tableParams?.pageSize) {
      setData([]);
    }
  };

  const handleAdd = () => {
    setDataEditDrawer(null)
    setIsViewDrawer(false)
    setIsOpenDrawer(true)
  }

  const handleRowClick = async (data) => {
    let id = data?.itemCategoryId
    const payload = {
      itemCategoryId: id
    }
    loadingToastId(Constant.message.loading, id)
    const res = await getItemCategoryById(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setIsViewDrawer(true)
      setIsOpenDrawer(true)
      setDataEditDrawer(res?.data)
      setIdHighlightRow(res.data.itemCategoryId)
    }
    dismissToast(id)
  }

  const handleEdit = async (data) => {
    let id = data?.itemCategoryId
    const payload = {
      itemCategoryId: id,
    }
    loadingToastId(Constant.message.loading, id)
    const res = await getItemCategoryById(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setIsViewDrawer(false)
      setIsOpenDrawer(true)
      setDataEditDrawer(res?.data)
      setIdHighlightRow(res.data.itemCategoryId)
    }
    dismissToast(id)
  }

  const fetchCheckPrivilege = async () => {
    let pvlgAddEdit = await isHasPrivilege(PRIVILEGE_ID.AddEditItemCategory)
    let pvlgAccess = await isHasPrivilege(PRIVILEGE_ID.ItemCategory)
    Promise.all([pvlgAccess, pvlgAddEdit]).then(
      ([pvlgAccessValue, pvlgAddEditValue]) => {
        setShowButtonAdd(pvlgAddEditValue)
        isLoadingPrivilege.current = false
        if (pvlgAccessValue) {
          hasPrivilegePage.current = true;
          setIsLoadedPrivilege(true)
          fetchData();
        } else {
          hasPrivilegePage.current = false
        }
        dismissToast(PRIVILEGE_ID.AddEditItemCategory);
      });
  }

  // Item Type dropdown
  const [itemTypeOptions, setItemTypeOptions] = useState([])
  const fetchDataItemType = async () => {
    const payload = {
      pageNumber: 1,
      pageSize: 0,
      orderBy: "itemTypeId",
      isAscending: true,
      filter: {
        itemTypeId: null,
        itemTypeName: "",
        isActive: true,
      },
    }

    const res = await getItemTypeList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setItemTypeOptions(() => {
        return res?.data?.listData.map(item => {
          return { label: item?.itemTypeName, value: item?.itemTypeId }
        })
      })
    }
  }

  useEffect(() => {
    fetchCheckPrivilege()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoadedPrivilege) {
      fetchData();
    }
    fetchDataItemType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (isLoadedPrivilege && !isMobile) {
        onSearch()
      }
    }, Constant.delayFilter);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterUI = () => {
    return (
      <Form layout="horizontal" form={form}>
        <Row gutter={16}>
          <Col xs={24} sm={10} md={8}>
            <Form.Item name='itemTypeName' label="Filter By">
              <Select
                allowClear
                placeholder={Constant.placeholder.enterItemTypeName}
                loading={isLoadingItemTypeList}
                options={itemTypeOptions}
                onChange={(value) => {
                  setFilters(prev => ({
                    ...prev,
                    itemTypeId: value
                  }))
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={10} md={7}>
            <Form.Item name='itemCategoryCode'>
              <Input
                allowClear
                placeholder={Constant.placeholder.enterName}
                onChange={e => {
                  setFilters(prev => ({
                    ...prev,
                    itemCategoryCode: e.target.value
                  }))
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={10} md={7}>
            <Form.Item name='itemCategoryName'>
              <Input
                allowClear
                placeholder={Constant.placeholder.enterName}
                onChange={e => {
                  setFilters(prev => ({
                    ...prev,
                    itemCategoryName: e.target.value
                  }))
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  const columns = [
    {
      title: 'Action',
      key: 'action',
      width: "60px",
      canHide: false,
      render: (_, record) => (
        <>
          {showButtonAdd ?
            <ButtonAction
              buttons={['edit', 'view']}
              onEdit={handleEdit}
              onView={handleRowClick}
              data={record}
            /> :
            <ButtonAction
              buttons={['view']}
              onView={handleRowClick}
              data={record}
            />
          }
        </>
      )
    },
    {
      title: 'Item Type',
      dataIndex: 'itemTypeName',
      width: "80px",
      render: (data) => <CellTable data={data} />
    },
    {
      title: 'Code',
      dataIndex: 'itemCategoryCode',
      sorter: true,
      width: "100px",
      render: (data) => <CellTable data={data} />
    },
    {
      title: 'Name',
      dataIndex: 'itemCategoryName',
      sorter: true,
      width: "200px",
      render: (data) => <CellTable data={data} />
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: "200px",
      render: (data) => <CellTable data={data} />
    },
    {
      width: "60px",
      title: 'Is Active',
      dataIndex: 'isActive',
      render: (isActive) => (
        <>
          <TagActiveInCell isActive={isActive} />
        </>
      ),
    },
  ];

  return (
    <>
      {hasPrivilegePage.current ?
        <Space direction="vertical" size="large" className='space-vertical-flex'>
          <Card size="big" bordered={false}>
            {isLoadingPrivilege.current ? <SkeletonPage /> :
              <>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={20} xl={18}>
                    {showButtonAdd &&
                      <Tooltip placement="top" title="add item category">
                        <ButtonAdd onClick={handleAdd}>Add</ButtonAdd>
                      </Tooltip>
                    }
                  </Col>
                </Row>
                <Divider dashed className='mb-1 mt-1' />

                {isMobile ?
                  <ButtonAdvanceFilter onClick={() => setIsOpenAdvanceFilter(true)} />
                  : filterUI()
                }

                <CustomAntdTable
                  columns={columns}
                  dataSource={data}
                  rowKey={"itemCategoryId"}
                  tableParam={tableParams}
                  totalData={totalData}
                  loading={isLoadingList}
                  onChange={handleTableChange}
                  rowClick={handleRowClick}
                  isOpenDrawer={isOpenDrawer}
                  idDataHighlight={idHighlightRow}
                />
              </>
            }
          </Card>
        </Space> : (<Error403 />)
      }

      {isOpenDrawer && (
        <BaseDrawerItemCategory
          onClose={onCloseDrawer}
          dataEdit={dataEditDrawer}
          isView={isViewDrawer}
          loading={isLoadingById}
          onRefreshList={fetchData}
          setModeView={setIsViewDrawer}
          isAddEditPrivileged={showButtonAdd}
          handleUnhighlight={() => {
            setIdHighlightRow(null)
          }}
        />
      )}

      {isOpenAdvanceFilter &&
        <BaseAdvanceFilter
          onClose={() => setIsOpenAdvanceFilter(false)}
          onSearchClick={onSearchClick}>
          {filterUI()}
        </BaseAdvanceFilter>}
    </>
  )
}
