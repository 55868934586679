import { Card, Col, Divider, Form, Input, Row, Space } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ButtonAction from 'components/Common/ButtonAction';
import BaseDrawerUser from 'components/MasterData/User/BaseDrawerUser';
import { dismissToast, loadingToastId, successToast } from 'utils/Toast';
import { ButtonAdd } from 'components/Common/Buttons';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import useAccessPermissionHook from 'hooks/useAccessPermissionHook';
import Error403 from 'components/Error/Error403';
import SkeletonPage from 'components/Common/SkeletonPage';
import useIsMobileHooks from 'hooks/useIsMobileHooks';
import { ButtonAdvanceFilter } from 'components/Common/ButtonAdvanceFilter';
import useUserRepo from 'services/repo/useUserRepo';
import BaseAdvanceFilter from 'components/CustomDrawer/BaseAdvanceFilter';
import { PRIVILEGE_ID } from 'utils/AccessPermissionConfig';
import Constant from 'utils/Constants';
import { builderName, sorterTable } from 'utils/Helper';
import CustomAlert from 'utils/CustomAlert';
import CellTable from 'components/Common/CellTable';
import TagActiveInCell from 'components/Common/TagActiveInCell';


export default function Users() {
    useTopbarTitleHooks("Master Data User");
    const { isLoading: isLoadingList, getUserList } = useUserRepo(false)
    const { isLoading: isLoadingById, getUserById } = useUserRepo(false)
    const { sendLinkResetPassword } = useUserRepo(false)

    const isMobile = useIsMobileHooks()
    const [form] = Form.useForm()
    const [data, setData] = useState()
    const [totalData, setTotalData] = useState(0)
    const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false)
    const [isViewDrawer, setIsViewDrawer] = useState(false)
    const [dataEditDrawer, setDataEditDrawer] = useState(null)
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [idHighlightRow, setIdHighlightRow] = useState(null)

    const { isHasPrivilege } = useAccessPermissionHook()
    const isLoadingPrivilege = useRef(true)
    const hasPrivilegePage = useRef(true)
    const [isLoadedPrivilege, setIsLoadedPrivilege] = useState(false)
    const [showButtonAdd, setShowButtonAdd] = useState(false)

    const [filters, setFilters] = useState({
        userName: "",
        employeeName: "",
        isActive: null,
    })

    const [tableParams, setTableParams] = useState({
        pageNumber: 1,
        pageSize: 10,
        filter: { ...filters },
        orderBy: "userId",
        isAscending: true
    });

    const fetchCheckPrivilege = async () => {
        let pvlgAddEdit = await isHasPrivilege(PRIVILEGE_ID.AddEditUser)
        let pvlgAccess = await isHasPrivilege(PRIVILEGE_ID.User)
        Promise.all([pvlgAccess, pvlgAddEdit]).then(
            ([pvlgAccessValue, pvlgAddEditValue]) => {
                setShowButtonAdd(pvlgAddEditValue)
                isLoadingPrivilege.current = false
                if (pvlgAccessValue) {
                    hasPrivilegePage.current = true;
                    setIsLoadedPrivilege(true)
                    fetchDataTable();
                } else {
                    hasPrivilegePage.current = false
                }
                dismissToast(PRIVILEGE_ID.AddEditUser);
            });
    }

    const fetchDataTable = async () => {
        const payload = {
            ...tableParams
        }
        const res = await getUserList(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setData(res?.data?.listData)
            setTotalData(res?.data?.totalRecords)
        }
    }

    const fetchGetById = async (id) => {
        loadingToastId(Constant.message.loading, id)
        const responseGetById = await getUserById({ userId: id })
        dismissToast(id)
        if (responseGetById?.isValid && responseGetById?.statusCode === 200) {
            setIsOpenDrawer(true)
            setDataEditDrawer(responseGetById?.data)
            setIdHighlightRow(responseGetById.data.userId)
        }
    }

    const fetchSendEmailResetPassword = async (data) => {
        loadingToastId(Constant.message.loading, data)
        const response = await sendLinkResetPassword({ userId: data?.userId, userName: data?.userName })
        dismissToast(data)
        if (response?.isValid && response?.statusCode === 200) {
            successToast("Email has been sent to the user.")
        }
    }

    const onCloseDrawer = () => {
        setIsOpenDrawer(false);
        setDataEditDrawer(null)
        setIdHighlightRow(null)
    };

    const onSearch = (value) => {
        setTableParams(prev => ({
            ...prev,
            pageNumber: 1,
            filter: {
                ...filters
            }
        }))
    };

    const onSearchClick = () => {
        onSearch()
        setIsOpenAdvanceFilter(false)
    }

    const handleAdd = () => {
        setDataEditDrawer(null)
        setIsViewDrawer(false)
        setIsOpenDrawer(true)
    }

    const handleRowClick = async (data) => {
        fetchGetById(data?.userId)
        setIsViewDrawer(true)
    }

    const handleEdit = (data) => {
        fetchGetById(data?.userId)
        setIsViewDrawer(false)
    }

    const handleResetPassword = (data) => {
        CustomAlert.confirm({
            title: "Reset Password.",
            description: "Are you sure you want to reset password for this user?",
            confirmButtonText: "Yes",
            onConfirmButton: () => {
                fetchSendEmailResetPassword(data)
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams(prevParams => ({
            ...prevParams,
            pageNumber: pagination?.current,
            pageSize: pagination?.pageSize,
            orderBy: sorter?.field || prevParams?.orderBy,
            isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
        }));
        if (pagination.pageSize !== tableParams?.pageSize) {
            setData([]);
        }
    };

    useEffect(() => {
        fetchCheckPrivilege()
    }, [])

    useEffect(() => {
        if (isLoadedPrivilege) {
            fetchDataTable();
        }
    }, [JSON.stringify(tableParams)]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (isLoadedPrivilege && !isMobile) {
                onSearch()
            }
        }, Constant.delayFilter);
        return () => clearTimeout(timeOutId);
    }, [filters]);


    const columns = [
        {
            title: 'Action',
            key: 'action',
            width: "60px",
            render: (data, row) => (
                <>
                    <ButtonAction
                        buttons={['edit', 'view', 'resetPassword']}
                        onEdit={() => handleEdit(row)}
                        onView={() => handleRowClick(row)}
                        onResetPassword={() => handleResetPassword(row)}
                    />
                </>
            )
        },
        {
            title: 'Username',
            dataIndex: 'userName',
            sorter: true,
            width: "180px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee.firstName',
            sorter: true,
            width: "250px",
            render: (data, row) => <CellTable data={builderName(row?.employee?.firstName, row?.employee?.lastName)} />
        },
        {
            width: "60px",
            title: 'Is Active',
            dataIndex: 'isActive',
            render: (isActive) => (
                <>
                    <TagActiveInCell isActive={isActive} />
                </>
            ),
        }
    ];

    const filterUI = () => {
        return (
            <Form layout="horizontal" form={form}>
                <Row gutter={16}>
                    <Col xs={24} md={8}>
                        <Form.Item name='userNameFilter' label="Filter By">
                            <Input
                                allowClear
                                placeholder="username"
                                onChange={(e) => {
                                    setFilters(prev => ({
                                        ...prev,
                                        userName: e.target.value
                                    }))
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={7}>
                        <Form.Item name='employeeNameFilter'>
                            <Input
                                allowClear
                                placeholder="employee name"
                                onChange={e => {
                                    setFilters(prev => ({
                                        ...prev,
                                        employeeName: e.target.value
                                    }))
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    return (
        <>
            {hasPrivilegePage.current ?
                <Space direction="vertical" size="large" className='space-vertical-flex'>
                    <Card size="big" bordered={false}>
                        {isLoadingPrivilege.current ? <SkeletonPage /> :
                            <>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={20} xl={18}>
                                        {showButtonAdd && <ButtonAdd onClick={handleAdd} />}
                                    </Col>
                                </Row>
                                <Divider dashed className='mb-1 mt-1' />

                                {isMobile ?
                                    <ButtonAdvanceFilter onClick={() => setIsOpenAdvanceFilter(true)} />
                                    : filterUI()
                                }

                                <CustomAntdTable
                                    columns={columns}
                                    dataSource={data}
                                    rowKey={"userId"}
                                    tableParam={tableParams}
                                    totalData={totalData}
                                    loading={isLoadingList}
                                    onChange={handleTableChange}
                                    rowClick={handleRowClick}
                                    isOpenDrawer={isOpenDrawer}
                                    idDataHighlight={idHighlightRow}
                                />
                            </>
                        }
                    </Card>
                </Space>
                : <Error403 />}

            {isOpenDrawer && (
                <BaseDrawerUser
                    onClose={onCloseDrawer}
                    dataEdit={dataEditDrawer}
                    isView={isViewDrawer}
                    loading={isLoadingById}
                    onRefreshList={fetchDataTable}
                    setModeView={setIsViewDrawer}
                    isAddEditPrivileged={showButtonAdd}
                />
            )}

            {isOpenAdvanceFilter &&
                <BaseAdvanceFilter
                    onClose={() => setIsOpenAdvanceFilter(false)}
                    onSearchClick={onSearchClick}>
                    {filterUI()}
                </BaseAdvanceFilter>}

        </>
    )
}
