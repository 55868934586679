/**
 * September 13th
 * 2023
 * 
 * Updated: October 12th
 * 2023
 * 
 * created:
 * Try Fanny Poerna Maulana
 * 
 * Updated: March 1st 2024
 * Updated by: 🫰 Aris bimas n
 */

import { Button, Card, Col, Form, Row } from 'antd'
import { CustomFormItemSelectDropdown, CustomFormItemSwitch, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import useItemCategoryRepo from 'services/repo/useItemCategoryRepo'
import useItemTypeRepo from 'services/repo/useItemTypeRepo'
import Constant from 'utils/Constants'
import CustomAlert from 'utils/CustomAlert'
import DevLog from 'utils/DevLog'
import { dismissToast, loadingToastId, successToast } from 'utils/Toast'

const init_field = {
  itemTypeId: null,
  itemTypeName: "",
  itemCategoryId: null,
  itemCategoryCode: "",
  itemCategoryName: "",
  description: "",
  isActive: true,
  lastModifiedAt: '',
}

export default function BaseDrawerItemCategory(props) {
  const {
    onClose,
    dataEdit = null,
    onRefreshList,
    isView = false,
    loading = false,
    setModeView = () => { },
    isAddEditPrivileged,
    handleUnhighlight = () => { },
  } = props
  const [form] = Form.useForm()
  const [dataField, setDataField] = useState(init_field)
  const { isLoading: isLoadingCreate, createItemCategory } = useItemCategoryRepo(false);
  const { isLoading: isLoadingUpdate, updateItemCategory } = useItemCategoryRepo(false);
  const { isLoading: isLoadingItemTypeList, getItemTypeList } = useItemTypeRepo(false);

  // Item Type dropdown
  const [itemTypeOptions, setItemTypeOptions] = useState([])
  const fetchDataItemType = async () => {
    const payload = {
      pageNumber: 1,
      pageSize: 0,
      orderBy: "itemTypeId",
      isAscending: true,
      filter: {
        itemTypeId: null,
        itemTypeName: "",
        isActive: true,
      },
    }

    const res = await getItemTypeList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setItemTypeOptions(() => {
        return res?.data?.listData.map(item => {
          return { label: item?.itemTypeName, value: item?.itemTypeId }
        })
      })
    }
  }

  const handleCloseDrawer = () => {
    form.resetFields()
    setDataField(init_field)
    setModeView(true)
    onClose()
  }

  const onSaveCreate = async () => {
    let payload = {
      itemTypeId: dataField.itemTypeId,
      itemCategoryCode: dataField.itemCategoryCode,
      itemCategoryName: dataField.itemCategoryName,
      description: dataField.description,
      isActive: dataField.isActive
    }
    loadingToastId(Constant.message.savingData, payload?.itemCategoryCode)
    let res = await createItemCategory(payload)
    dismissToast(payload?.itemCategoryCode)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const onSaveEdit = async () => {
    let payload = {
      itemTypeId: dataField.itemTypeId,
      itemCategoryId: dataField.itemCategoryId,
      itemCategoryCode: dataField.itemCategoryCode,
      itemCategoryName: dataField.itemCategoryName,
      description: dataField.description,
      isActive: dataField.isActive,
      lastModifiedAt: dataField.lastModifiedAt,
    }
    loadingToastId(Constant.message.savingData, payload?.itemCategoryId)
    let res = await updateItemCategory(payload)
    dismissToast(payload?.itemCategoryId)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const handleFormSubmit = () => {
    CustomAlert.confirm({
      title: "Save Item Category!",
      description: `Are you sure you want to save this data?`,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "primary",
      onConfirmButton: () => {
        form.validateFields()
          .then((values) => {
            if (dataEdit !== null) {
              onSaveEdit()
            } else {
              onSaveCreate()
            }
          })
          .catch((errorInfo) => {
            DevLog("err", errorInfo);
          });
      }
    });
  };

  useEffect(() => {
    setModeView(isView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isView])

  useEffect(() => {
    if (dataEdit !== null) {
      setDataField(dataEdit)
      form.setFieldsValue({
        itemTypeName: dataEdit?.itemTypeId,
        itemCategoryCode: dataEdit?.itemCategoryCode,
        itemCategoryName: dataEdit?.itemCategoryName,
        description: dataEdit?.description,
        isActive: dataEdit?.isActive,
      })
    } else {
      setDataField(init_field)
      form.setFieldsValue({
        itemTypeName: init_field.itemTypeId,
        itemCategoryCode: init_field.itemCategoryCode,
        itemCategoryName: init_field.itemCategoryName,
        description: init_field.description,
        isActive: init_field.isActive,
      })
      handleUnhighlight()
    }
    fetchDataItemType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit])

  return (
    <>
      <BaseDrawer
        title={`${isView ? "View" : dataEdit === null ? "Add" : "Edit"} Item Category`}
        placement="right"
        size="large"
        onClose={handleCloseDrawer}
        open={true}
        loading={loading}
        extras={
          <>
            {(isAddEditPrivileged && isView) &&
              (<Button
                type="primary"
                onClick={() => setModeView(false)}>
                Edit
              </Button>)
            }
            {(isAddEditPrivileged && !isView) &&
              (<Button type="primary" onClick={handleFormSubmit} htmlType='submit'
                loading={isLoadingCreate || isLoadingUpdate}>
                Submit
              </Button>)
            }
          </>
        }>
        <Card>
          <Form
            form={form}
            layout="vertical">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <CustomFormItemSelectDropdown
                  nameFormItem="itemTypeName"
                  label='Item Type'
                  placeholder="Select Item Type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  value={dataField?.itemTypeId}
                  loading={isLoadingItemTypeList}
                  options={itemTypeOptions}
                  isView={isView}
                  onChange={value => {
                    setDataField(prev =>
                      ({ ...prev, itemTypeId: value })
                    )
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="itemCategoryCode"
                  label="Code"
                  placeholder="Fill in Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.itemCategoryCode}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      itemCategoryCode: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="itemCategoryName"
                  label="Name"
                  placeholder="Fill in Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.itemCategoryName}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      itemCategoryName: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSwitch
                  nameFormItem="isActive"
                  label="Is Active"
                  value={dataField?.isActive}
                  isView={isView}
                  onChange={(checked) => {
                    setDataField(prev =>
                      ({ ...prev, isActive: checked }))
                  }}
                />
              </Col>
              <Col xs={24}>
                <CustomFormItemTextArea
                  nameFormItem="description"
                  label="Description"
                  placeholder="Fill in Description"
                  isView={isView}
                  value={dataField?.description}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      description: e.target.value
                    }))
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </BaseDrawer>
    </>
  )
}
