import { Col, Form, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Constant from 'utils/Constants'
import CustomAntdTable from 'components/Common/CustomAntdTable'
import { formatterAmount, sorterTable } from 'utils/Helper'
import ButtonAction from 'components/Common/ButtonAction';
import useItemCategoryRepo from 'services/repo/useItemCategoryRepo';
import CellTable from 'components/Common/CellTable'
import useItemRepo from 'services/repo/useItemRepo'
import useItemCatalogRepo from 'services/repo/useItemCatalogRepo'
import BaseLookup from 'components/Lookup/BaseLookup'
import { CustomFormItemFilterSelectDropdown, CustomFormItemFilterText } from 'components/Common/CustomFormItem'

export default function LookupItems(props) {
    const { onClose, onSelect, prTypeId = Constant.PRType.CATALOG, itemType = Constant.ItemType.GOODS } = props
    const { isLoading: isLoadingItemNonCatalog, getItemList } = useItemRepo(false)
    const { isLoading: isLoadingItemCatalog, getItemCatalogListForPR } = useItemCatalogRepo(false)
    const { isLoading: isLoadingItemCategory, getItemCategoryList } = useItemCategoryRepo(false)

    const [filters, setFilters] = useState({
        previousItemId: null,
        previousContracCatalogItemDetailId: null,
        itemCode: "",
        itemName: "",
        itemTypeId: itemType,
        itemCategoryId: null,
        isActive: true
    })
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0)
    const [tableParams, setTableParams] = useState({
        pageNumber: 1,
        pageSize: 10,
        filter: {
            ...filters
        },
        orderBy: "ItemId",
        isAscending: true
    });

    const [dataItemCategory, setDataItemCategory] = useState([]);

    //Item Category DDL
    const fetchDataGetItemCategory = async () => {
        const payload = {
            pageNumber: 1,
            pageSize: 0,
            orderBy: "ItemCategoryId",
            isAscending: true,
            filter: {
                previousItemCategoryId: null,
                itemCategoryCode: "",
                itemCategoryName: "",
                itemTypeId: itemType,
                isActive: true
            }
        }
        const res = await getItemCategoryList(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setDataItemCategory(() => {
                return res?.data?.listData?.map(d => {
                    return { label: d?.itemCategoryName, value: d?.itemCategoryId }
                })
            })
        }
    };

    const fetchData = async () => {
        const payload = {
            ...tableParams
        }
        const res = prTypeId === Constant.PRType.CATALOG ? await getItemCatalogListForPR(payload) : await getItemList(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setData(res?.data?.listData)
            setTotalData(res?.data?.totalRecords)
            setTableParams(prevParams => ({
                ...prevParams,
            }));
        }
    };

    const onSearch = (value) => {
        setTableParams(prev => ({
            ...prev,
            pageNumber: 1,
            filter: {
                ...filters
            }
        }))
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams(prevParams => ({
            ...prevParams,
            pageNumber: pagination?.current,
            pageSize: pagination?.pageSize,
            orderBy: sorter?.field || prevParams?.orderBy,
            isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
        }));

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams?.pageSize) {
            setData([]);
        }
    };

    const handleSelect = (data) => {
        onSelect(data)
        // onClose()
    }

    const handleFieldFilterChange = (field, value) => {
        const updatedFilters = {
            ...filters,
            [field]: value
        };
        setFilters(updatedFilters);
    }

    useEffect(() => {
        fetchDataGetItemCategory();
    }, []);

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams)]);

    useEffect(() => {
        const timeOutId = setTimeout(() => onSearch(), Constant.delayFilter);
        return () => clearTimeout(timeOutId);
    }, [filters])

    const catalogColumns = [
        {
            title: 'Action',
            key: 'action',
            width: "60px",
            render: (_, record) => (
                <>
                    <ButtonAction
                        buttons={['add']}
                        onAdd={() => handleSelect(_)}
                    />
                </>
            )
        },
        {
            title: 'Item Code',
            dataIndex: `${prTypeId === Constant.PRType.CATALOG ? 'item.ItemCode' : 'itemCode'}`,
            sorter: true,
            width: "100px",
            render: (data, row) => <CellTable data={row.itemCode} />
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            sorter: true,
            width: "200px",
            render: (data, row) => <CellTable data={data} />
        },

        {
            title: 'Catalog No',
            dataIndex: 'contractCatalog.contractCatalogNo',
            sorter: true,
            isCatalog: true,
            width: "100px",
            render: (data, row) => <CellTable data={row.contractCatalogNo} />
        },
        {
            title: 'Vendor Name',
            dataIndex: 'contractCatalog.vendorName',
            sorter: true,
            isCatalog: true,
            width: "150px",
            render: (data, row) => <CellTable data={row.vendorName} />
        },
        {
            title: 'Price/Unit',
            dataIndex: 'price',
            sorter: true,
            align: "right",
            width: "150px",
            render: (price) => {
                return formatterAmount(price)
            }
        },
        {
            title: 'UOM',
            dataIndex: `${prTypeId === Constant.PRType.CATALOG ? 'item.uom.name' : 'uom.name'}`,
            width: "80px",
            sorter: true,
            render: (data, row) => <CellTable data={row.uom.name} />
        },
        {
            title: 'Item Category',
            dataIndex: `${prTypeId === Constant.PRType.CATALOG ? 'item.itemCategory.itemCategoryName' : 'itemCategory.itemCategoryName'}`,
            sorter: true,
            width: "200px",
            render: (data, row) => <CellTable data={row?.itemCategory.itemCategoryName} />

        },
    ];

    const nonCatalogColumns = [...catalogColumns].filter(x => !x.isCatalog)


    return (
        <>
            <BaseLookup
                title={`${itemType === Constant.ItemType.GOODS ? 'Goods' : 'Service'} Item Lookup`}
                open={true}
                onCancel={onClose}
                width={Constant.modalWidth.lg}
                footer={null}
                filterUI={
                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={8}>
                                <CustomFormItemFilterText
                                    nameFormItem='itemCode'
                                    label="Item Code"
                                    placeholder="Item Code"
                                    value={filters?.itemCode}
                                    onChange={(e) => {
                                        handleFieldFilterChange('itemCode', e.target.value)
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <CustomFormItemFilterText
                                    nameFormItem='itemName'
                                    label="Item Name"
                                    placeholder="Item Name"
                                    value={filters?.itemName}
                                    onChange={(e) => {
                                        handleFieldFilterChange('itemName', e.target.value)
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <CustomFormItemFilterSelectDropdown
                                    nameFormItem='itemCategoryId'
                                    label="Item Category"
                                    placeholder="Select Item Category"
                                    showSearch={true}
                                    value={filters?.itemCategoryId}
                                    options={dataItemCategory}
                                    loading={isLoadingItemCategory}
                                    onChange={(e) => {
                                        handleFieldFilterChange('itemCategoryId', e || null)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                }
                isLoading={prTypeId === Constant.PRType.CATALOG ? isLoadingItemCatalog : isLoadingItemNonCatalog}
            >
                <CustomAntdTable
                    columns={prTypeId === Constant.PRType.CATALOG ? catalogColumns : nonCatalogColumns}
                    dataSource={data}
                    rowKey={prTypeId === Constant.PRType.CATALOG ? "contractCatalogItemDetailId" : "itemId"}
                    tableParam={tableParams}
                    totalData={totalData}
                    loading={prTypeId === Constant.PRType.CATALOG ? isLoadingItemCatalog : isLoadingItemNonCatalog}
                    onChange={handleTableChange}
                />
            </BaseLookup>
        </>
    )
}
