import { atom } from "jotai"

export const INIT_HEADER_VENDOR = {
    vendorId: null,
    vendorCode: "",
    vendorName: "",
    vendorDescription: "",
    vendorPicName: "",
    vendorPicPhone: "",
    vendorPicEmail: "",
    taxId: "",
    taxPayer: "",
    address: "",
    postalCode: "",
    countryId: null,
    countryName: "",
    city: "",
    pkpStatusId: null,
    pkpStatusName: "",
    vendorSizeId: null,
    vendorSizeName: "",
    industryTypeId: null,
    industryTypeName: "",
    isActive: true,
}

export const headerVendor = atom(INIT_HEADER_VENDOR)
export const vendorBankAccount = atom([])