/**
 * Jun
 * 2023
 * 
 * Updated: March 1st 2024
 * Updated by: 🫰 Aris bimas n
 */

import { Button, Card, Col, Form, Row } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import Constant from 'utils/Constants'
import CustomAlert from 'utils/CustomAlert'
import { dismissToast, loadingToastId, successToast } from 'utils/Toast'
import useDivisionRepo from "../../../services/repo/useDivisionRepo";
import DevLog from 'utils/DevLog'
import { CustomFormItemSwitch, CustomFormItemText } from 'components/Common/CustomFormItem'

const init_field = {
  divisionId: null,
  divisionCode: "",
  divisionName: "",
  isActive: true,
  lastModifiedAt: '',
}

export default function BaseDrawerDivision(props) {
  const {
    isOpen, onClose, dataEdit = null, onRefreshList, isView = false, loading = false, setModeView = () => {
    },
    handleUnhighlight = () => { },
  } = props
  const [form] = Form.useForm()
  const [dataField, setDataField] = useState(init_field)
  const { isLoading: isLoadingCreate, createDivision } = useDivisionRepo(false);
  const { isLoading: isLoadingUpdate, updateDivision } = useDivisionRepo(false);

  const isAddEditPrivileged = props.isAddEditPrivileged

  const handleCloseDrawer = () => {
    form.resetFields()
    setDataField(init_field)
    setModeView(true)
    onClose()
  }

  const onSaveCreate = async () => {
    let payload = {
      divisionCode: dataField.divisionCode,
      divisionName: dataField.divisionName,
      isActive: dataField.isActive
    }
    loadingToastId(Constant.message.savingData, payload?.divisionName)
    let res = await createDivision(payload)
    dismissToast(payload?.divisionName)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const onSaveEdit = async () => {
    // let payload = { ...dataField }
    let payload = {
      divisionId: dataField.divisionId,
      lastModifiedAt: dataField.lastModifiedAt,
      divisionCode: dataField.divisionCode,
      divisionName: dataField.divisionName,
      isActive: dataField.isActive
    }
    loadingToastId(Constant.message.savingData, payload?.divisionName)
    let res = await updateDivision(payload)
    dismissToast(payload?.divisionName)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const handleFormSubmit = () => {
    CustomAlert.confirm({
      title: "Save Division!",
      description: `Are you sure you want to save this data?`,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "primary",
      onConfirmButton: () => {
        form.validateFields()
          .then((values) => {
            if (dataEdit !== null) {
              onSaveEdit()
            } else {
              onSaveCreate()
            }
          })
          .catch((errorInfo) => {
            DevLog("err", errorInfo);
          });
      }
    });
  };

  useEffect(() => {
    setModeView(isView)
  }, [isView])

  useEffect(() => {
    if (dataEdit !== null) {
      setDataField(dataEdit);
      form.setFieldsValue({
        divisionCode: dataEdit?.divisionCode,
        divisionName: dataEdit?.divisionName,
        isActive: dataEdit?.isActive,
      })
    } else {
      setDataField(init_field)
      form.setFieldsValue({
        divisionCode: init_field.divisionCode,
        divisionName: init_field.divisionName,
        isActive: init_field.isActive,
      })
      handleUnhighlight()
    }
  }, [dataEdit])

  return (
    <>
      <BaseDrawer
        title={isView ? "View Division" : (dataEdit === null ? "Add Division" : "Edit Division")}
        placement="right"
        size="large"
        onClose={handleCloseDrawer}
        open={isOpen}
        loading={loading}
        extras={
          <>
            {(isAddEditPrivileged && isView) &&
              (<Button
                type="primary"
                onClick={() => setModeView(false)}>
                Edit
              </Button>)
            }
            {(isAddEditPrivileged && !isView) &&
              (<Button type="primary" onClick={handleFormSubmit} htmlType='submit'
                loading={isLoadingCreate || isLoadingUpdate}>
                Submit
              </Button>)
            }
          </>
        }
      >
        <Card>
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="divisionCode"
                  label="Code"
                  placeholder="Fill in Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.divisionCode}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      divisionCode: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="divisionName"
                  label="Name"
                  placeholder="Fill in Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.divisionName}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      divisionName: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSwitch
                  nameFormItem="isActive"
                  label="Is Active"
                  value={dataField?.isActive}
                  isView={isView}
                  onChange={(checked) => {
                    setDataField(prev =>
                      ({ ...prev, isActive: checked }))
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </BaseDrawer>
    </>
  )
}
