import { Form, Space } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import SectionHeaderRoGS from '../SectionHeaderRoGS';
import SectionItemsRoGS from '../SectionItemsRoGS';
import SectionWorkflowHistory from '../SectionWorkflowHistory';
import Error403 from 'components/Error/Error403';
import { ButtonCancelDocument } from 'components/Common/Buttons';
import BaseSectionAttachment from '../BaseSectionAttachment';
import CustomCollapse from 'components/Common/CustomCollapse';

export default function BaseDetailROGS(props) {
    const {
        onClose,
        loading = false,
        initForm,
        dataHeader,
        handleCancelRoGS = () => { },
        isLoadingCancelRoGS = false,
        notAuthorized = false,
        showCancelRoGSButton = false,
    } = props

    const handleCloseDrawer = () => {
        onClose()
    }

    const itemsCollapseDefault = [
        {
            key: 1,
            label: "Receive of Goods / Service Header",
            forceRender: true,
            children: <SectionHeaderRoGS initForm={initForm} isView={true} />
        },
        {
            key: 2,
            label: "Items",
            forceRender: true,
            className: "rogs-form-collapse-panel-items",
            children: <SectionItemsRoGS initForm={initForm} isView={true} />
        },
        {
            key: 3,
            label: "Attachments",
            forceRender: true,
            children: <BaseSectionAttachment initForm={initForm} isView={true} />
        }
    ]

    let itemsCollapseWithWfHistory = [
        ...itemsCollapseDefault,
        {
            key: 4,
            label: "Workflow History",
            forceRender: true,
            children: <SectionWorkflowHistory rogsId={dataHeader?.rogsId} />
        }
    ]

    return (
        <BaseDrawer
            title={"Receive of Goods / Service"}
            placement="right"
            size="large"
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
            extras={
                <>
                    {showCancelRoGSButton && <ButtonCancelDocument onClick={handleCancelRoGS} loading={isLoadingCancelRoGS} buttonText='Cancel RoGS' />}
                </>
            }
        >
            <Space direction="vertical" size="small" className='wrap-rogs-form space-vertical-flex' >
                {notAuthorized ? <Error403 /> :
                    <Form
                        name="form-create-rogs"
                        form={initForm}
                        layout='vertical'
                    >
                        <CustomCollapse
                            className="rogs-form-collapse"
                            defaultActiveKey={["1", "2", "3", "4"]}
                            items={!dataHeader.isDraft ? itemsCollapseWithWfHistory : itemsCollapseDefault}
                        />
                    </Form>
                }
            </Space >
        </BaseDrawer>
    )
}
