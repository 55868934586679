import { Col, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { formatterAmount, setFieldValues, taxCalculation } from 'utils/Helper';
import ButtonAction from 'components/Common/ButtonAction';
import { useAtom } from 'jotai';
import { goodsItemPr, headerPRAtom } from 'store/PurchaseRequest/purchaseRequestAtom';
import Constant from 'utils/Constants';
import CustomAntdTable from 'components/Common/CustomAntdTable';
import { errorToast, successToast } from 'utils/Toast';
import dayjs from 'dayjs';
import LookupItems from './Modal/LookupItems';
import IconRequired from 'components/Common/IconRequired';
import CellTable from 'components/Common/CellTable';
import { ButtonAdd } from 'components/Common/Buttons';
import { GrAttachment } from "react-icons/gr";
import { CustomFormItemCheckbox, CustomFormItemDate, CustomFormItemMoney, CustomFormItemNumber } from 'components/Common/CustomFormItem';

// const { Text } = Typography;

// const styleSummaryRight = {
//     // colSpan: 1,
//     align: "right"
// }

export default function GoodsItem(props) {
    const { initForm,
        isView = false,
        propsDrawer = () => { },
        idRowHighlight = null,
    } = props

    const [header, setHeader] = useAtom(headerPRAtom)
    const [dataItems, setDataItems] = useAtom(goodsItemPr)
    const [isModalOpenAddGoods, setIsModalOpenAddGoods] = useState(false);
    const [ignoreRowClick, setIgnoreRowClick] = useState(false)
    const [rowHighlight, setRowHighlight] = useState(null)
    const indexPriceRef = useRef(null)

    // const styleSummaryLeft = {
    //     colSpan: header?.prTypeId === Constant.PRType.CATALOG ? 7 : 5,
    //     align: 'start',
    // }

    const onChangeInputRow = (field, value, index, filename, filesize) => {
        let datas = [...dataItems];
        let currentData = datas[index]
        currentData[field] = value;

        if (field === 'isDifferentRequiredDate') {
            if (value) {
                currentData.requiredDate = null;
                setFieldValues(initForm, `goods_requiredDate_${index}`, "");
            } else {
                if (header.requiredDate) {
                    currentData.requiredDate = header.requiredDate;
                    setFieldValues(initForm, `goods_requiredDate_${index}`, currentData?.requiredDate ? dayjs(currentData?.requiredDate) : "");
                }
            }
        }

        if (field === "quantity") {
            let subTotal = value * currentData.price
            currentData.subTotal = subTotal;

            //Trigger to change the total price goods while the user inputs VAT first.
            let tax = initForm.getFieldValue("goodsTax")
            setHeader(prev => ({ ...prev, goodsVatPercentage: tax }))
        }

        if (field === "price") {
            let subTotal = value * currentData.quantity
            currentData.subTotal = subTotal;
            indexPriceRef.current = index
        } else {
            // debugger
            indexPriceRef.current = null
        }

        if (field === "requiredDate") {
            setFieldValues(initForm, `goods_requiredDate_${index}`, currentData?.requiredDate ? dayjs(currentData?.requiredDate) : "");
        }

        // if (field === "attachmentFile") {
        //     currentData.attachmentFile = {
        //         fileOriginalName: filename,
        //         file: value,
        //         itemId: currentData?.itemId

        //     }
        // }
        // console.log("onChangeInputRow", datas);
        setDataItems(datas)
    }

    //ITEMS
    const handleAddItem = () => {
        if (header?.prTypeId !== null) {
            setIsModalOpenAddGoods(true)
        } else {
            errorToast("Please Select PR Type.")
        }
    }

    const handleDeleteItem = (data, index) => {
        let tmpDatas = [...dataItems]
        let newItems = tmpDatas.filter(el => el.itemId !== data?.itemId);
        indexPriceRef.current = null
        setDataItems(newItems)
    }

    const handleViewItem = (data, index) => {
        propsDrawer(true, true, data?.itemId, data, Constant.typeItemPR.GOODS)
    }

    const handleEditItem = (data, index) => {
        propsDrawer(false, true, data?.itemId, data, Constant.typeItemPR.GOODS)
    }

    const handleOnFocusAndBlur = (ignore) => {
        if (isView) {
            setRowHighlight(rowHighlight)
            setIgnoreRowClick(ignore)
            propsDrawer(true, null, rowHighlight, 0)
        }
    }

    const handleSelectItem = (data) => {
        //check item  already add/selected
        const checkAlready = dataItems.some(x => x.itemId === data.itemId)
        if (checkAlready) {
            return errorToast(`The item ${data?.itemName} is already exists in Item List`)
        } else {
            //add subtotal, quantity, remarks,requiredDate property bcs those prop hasn't already from item
            let quantity = 0;
            let requiredDate = header.requiredDate ? header.requiredDate : null
            let newData = {
                sortKey: dataItems.length,
                itemId: data?.itemId,
                itemCode: data?.itemCode,
                itemName: data?.itemName,
                description: data?.description,
                itemCategoryId: data?.itemCategory?.itemCategoryId,
                itemCategoryName: data?.itemCategory?.itemCategoryName,
                vendorId: data?.vendorId,
                vendorName: data?.vendorName,
                quantity: quantity,
                uomId: data?.uom?.uomId,
                uomName: data?.uom?.name,
                uomAllowDecimal: data?.uom?.uomAllowDecimal,
                price: data?.price,
                priceInOc: data?.priceInOc,
                remarks: "",
                currencyId: data?.currency?.currencyId,
                currencyCode: data?.currency?.code,
                contractCatalogItemDetailId: data?.contractCatalogItemDetailId,
                contractCatalogId: data?.contractCatalogId,
                contractCatalogNo: data?.contractCatalogNo,
                subTotal: quantity * data.price,
                attachmentFile: "",
                isDifferentRequiredDate: false,
                requiredDate: requiredDate
                // requiredDate: dayjs().format(Constant.stateDateFormat)
            }
            // sort
            // setDataItems(prev => ([...prev, newData]))
            setDataItems(prev => {
                let newList = [...prev, newData]
                const newDataSort = newList.sort((a, b) => {
                    return b.sortKey - a.sortKey
                });
                return newDataSort
            })
            indexPriceRef.current = null
            return successToast(Constant.message.itemAddSuccess)
        }
    }

    const calculateTotalWithTax = (amountBeforeTax, amountTax) => {
        return amountBeforeTax + amountTax
    }

    const updateFieldTableValues = (index, data) => {
        // debugger
        setFieldValues(initForm, `goods_remarks_${index}`, data.remarks);
        setFieldValues(initForm, `goods_requiredDate_${index}`, data?.requiredDate ? dayjs(data?.requiredDate) : "");
        setFieldValues(initForm, `goods_quantity_${index}`, data.quantity);
        // debugger
        if (indexPriceRef.current === index) {
            setFieldValues(initForm, `goods_price_${index}`, data.price);
        } else {
            setFieldValues(initForm, `goods_price_${index}`, formatterAmount(data.price));
        }
        setFieldValues(initForm, `goods_isDifferentRequiredDate_${index}`, data.isDifferentRequiredDate);
    };


    useEffect(() => {
        //trigger to change tax of total
        let totalAmountGoodsVatPercentage = taxCalculation(header.totalAmountGoodsBeforeTax, header?.goodsVatPercentage) || 0
        setHeader(prev => ({
            ...prev,
            totalAmountGoodsVatPercentage: totalAmountGoodsVatPercentage,
            totalGoodsAmountWithTax: calculateTotalWithTax(header.totalAmountGoodsBeforeTax, totalAmountGoodsVatPercentage)
        }))
    }, [header?.goodsVatPercentage])

    const onDataItemsChange = () => {
        //trigger to change total amount tax, total before tax 
        let sumTotalBeforeTax = 0;

        let newData = [...dataItems]?.map((data, index) => {
            updateFieldTableValues(index, data);
            sumTotalBeforeTax += data.subTotal;
            return sumTotalBeforeTax;
        });

        let amountVAT = taxCalculation(sumTotalBeforeTax, header.goodsVatPercentage);

        setHeader(prev => ({
            ...prev,
            totalAmountGoodsVatPercentage: amountVAT,
            totalAmountGoodsBeforeTax: sumTotalBeforeTax,
            totalGoodsAmountWithTax: calculateTotalWithTax(sumTotalBeforeTax, amountVAT)
        }));
    }


    useEffect(() => {
        indexPriceRef.current = null
    }, [header.requiredDate])

    //effect when dataitems change
    useEffect(() => {
        onDataItemsChange()
    }, [dataItems]);

    useEffect(() => {
        setRowHighlight(idRowHighlight)
    }, [idRowHighlight])

    const catalogColumns = [
        {
            title: 'Action',
            key: 'action',
            align: "left",
            width: "10px",
            className: "custom-table-edit-row-input",
            render: (data, row, index) => {
                let buttons = !isView ? ['view', 'delete', 'edit'] : ['view'];
                return (
                    <>
                        <ButtonAction
                            buttons={buttons}
                            onView={() => handleViewItem(row)}
                            onDelete={() => handleDeleteItem(row, index)}
                            onEdit={() => handleEditItem(row, index)}
                        />
                    </>
                )
            }
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            className: "custom-table-edit-row-input",
            width: "250px",
            render: (data, row) => <CellTable data={data} icon={row?.attachmentFile && <GrAttachment />} dataTooltipicon='This data has attachment.' />
        },
        {
            title: 'Catalog No',
            dataIndex: 'contractCatalogNo',
            className: "custom-table-edit-row-input",
            width: "150px",
            isCatalog: true,
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Vendor Name',
            dataIndex: 'vendorName',
            className: "custom-table-edit-row-input",
            width: "150px",
            isCatalog: true,
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Required Date',
            dataIndex: 'requiredDate',
            className: "custom-table-edit-row-input",
            width: "160px",
            render: (text, record, index) => {
                return (
                    <div style={{ display: 'flex', gap: "10px", alignItems: "baseline" }}>
                        <CustomFormItemCheckbox
                            nameFormItem={'goods_isDifferentRequiredDate_' + index}
                            value={record?.isDifferentRequiredDate && "checked"}
                            isView={isView}
                            onChange={(e) => {
                                onChangeInputRow("isDifferentRequiredDate", e.target.checked, index)
                            }}
                            title="Check To input different required date."
                            useInTable={true}
                        />
                        <CustomFormItemDate
                            nameFormItem={'goods_requiredDate_' + index}
                            value={record?.requiredDate}
                            onChange={(d, dString) => {
                                onChangeInputRow("requiredDate", dString ? dayjs(dString).format(Constant.stateDateFormat) : "", index)
                            }}
                            disabledDate={d => !d || d.isBefore(dayjs().format(Constant.datePickerFormat))}
                            onFocus={() => handleOnFocusAndBlur(true)}
                            onBlur={() => handleOnFocusAndBlur(false)}
                            isView={isView || !record?.isDifferentRequiredDate}
                            useDatePickerView={!isView}
                            useInTable={true}
                        />
                    </div>
                )
            }
        },
        {
            title: <IconRequired text="Quantity" />,
            dataIndex: 'quantity',
            className: "custom-table-edit-row-input",
            align: 'right',
            width: "90px",
            render: (text, record, index) => {
                return (
                    <Col>
                        <CustomFormItemNumber
                            nameFormItem={'goods_quantity_' + index}
                            nameInput={'goods_quantity_' + index}
                            placeholder="Please enter quantity"
                            rules={[
                                // { required: true, message: 'Please input Quantity' },
                                {
                                    validator: (_, value) => {
                                        if (value <= 0) {
                                            return Promise.reject(new Error('Quantity must be greater than 0'));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}
                            initForm={initForm}
                            customvalue={record?.quantity}
                            precision={record?.uomAllowDecimal ? 2 : 0}
                            isView={isView}
                            onChange={(e) => onChangeInputRow("quantity", e, index)}
                            onFocus={() => handleOnFocusAndBlur(true)}
                            onBlur={() => handleOnFocusAndBlur(false)}
                            useInTable={true}
                            alignView="right"
                        />
                    </Col>
                )
            }
        },
        {
            title: 'UOM',
            dataIndex: 'uomName',
            className: "custom-table-edit-row-input",
            width: "80px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: <IconRequired text="Price" />,
            dataIndex: 'price',
            className: "custom-table-edit-row-input",
            align: 'right',
            width: "180px",
            render: (text, record, index) => {
                return (
                    <Col>
                        <CustomFormItemMoney
                            nameFormItem={'goods_price_' + index}
                            nameInput={'goods_price_' + index}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value <= 0) {
                                            return Promise.reject(new Error('Price must be greater than 0'));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}
                            initForm={initForm}
                            customvalue={record?.price}
                            onChange={(e) => onChangeInputRow("price", e, index)}
                            onFocus={(e) => handleOnFocusAndBlur(true)}
                            onBlur={(e) => handleOnFocusAndBlur(false)}
                            isView={isView || header?.prTypeId === Constant.PRType.CATALOG}
                            useInTable={true}
                            alignView="right"
                        />
                    </Col>
                )
            }
        },
        {
            title: 'Sub Total',
            dataIndex: 'subTotal',
            className: "custom-table-edit-row-input",
            align: 'right',
            width: "180px",
            render: (subTotal) => {
                if (!subTotal) {
                    return 0
                }
                return formatterAmount(subTotal)
            }
        },
    ]

    const nonCatalogColumns = [...catalogColumns].filter(x => !x.isCatalog)

    // console.log("goods", dataItems);

    return (
        <>
            <div className='mb-2'>
                <p className="font-semibold">Goods Item</p>
                {(!isView) && <Space className='mb-1'>
                    <ButtonAdd type="primary" onClick={() => handleAddItem(true)} icon={null} />
                </Space>
                }

                <CustomAntdTable
                    className='custom-table-edit-row table-goods-item'
                    rowKey={"itemId"}
                    columns={header?.prTypeId === Constant.PRType.CATALOG ? catalogColumns : nonCatalogColumns}
                    dataSource={dataItems}
                    pagination={false}
                    ignoreRowClick={isView ? ignoreRowClick : true}
                    idDataHighlight={rowHighlight}
                    rowClick={isView ? (data, index) => handleViewItem(data, index) : () => { }}
                // summary={pageData => {
                //     return (
                //         dataItems.length > 0 && <>
                //             <Table.Summary.Row>
                //                 <Table.Summary.Cell {...styleSummaryLeft}></Table.Summary.Cell>
                //                 <Table.Summary.Cell className='label'>Total</Table.Summary.Cell>
                //                 <Table.Summary.Cell {...styleSummaryRight} className="summary-item">
                //                     <Text>{formatterAmount(header.totalAmountGoodsBeforeTax)}</Text>
                //                 </Table.Summary.Cell>
                //             </Table.Summary.Row>

                //             <Table.Summary.Row>
                //                 <Table.Summary.Cell {...styleSummaryLeft}></Table.Summary.Cell>
                //                 <Table.Summary.Cell className='label vat-summary'>
                //                     <CustomFormItemNumber
                //                         nameFormItem={'goodsTax'}
                //                         label='VAT'
                //                         nameInput={'goodsTax'}
                //                         className='goods-item-tax'
                //                         rules={[
                //                             {
                //                                 required: true
                //                             },
                //                         ]}
                //                         max="100"
                //                         min="0"
                //                         initForm={initForm}
                //                         customvalue={header.goodsVatPercentage}
                //                         isView={isView}
                //                         onChange={(val) => {
                //                             setHeader(prev => ({
                //                                 ...prev,
                //                                 goodsVatPercentage: val,
                //                             }));
                //                         }}
                //                         wrapperCol={{ xs: 16 }}
                //                         labelCol={{ xs: 8 }}
                //                         alignView="right"
                //                     />
                //                 </Table.Summary.Cell>
                //                 <Table.Summary.Cell className="summary-item" {...styleSummaryRight}>
                //                     <Text>{formatterAmount(header.totalAmountGoodsVatPercentage)}</Text>
                //                 </Table.Summary.Cell>
                //             </Table.Summary.Row>

                //             <Table.Summary.Row>
                //                 <Table.Summary.Cell {...styleSummaryLeft}></Table.Summary.Cell>
                //                 <Table.Summary.Cell className='label'>
                //                     <strong>Total (Include Tax)</strong>
                //                 </Table.Summary.Cell>
                //                 <Table.Summary.Cell className="summary-item total-include-tax" {...styleSummaryRight}>
                //                     <Text>{formatterAmount(calculateTotalWithTax(header.totalAmountGoodsBeforeTax, header.totalAmountGoodsVatPercentage))}</Text>
                //                 </Table.Summary.Cell>
                //             </Table.Summary.Row>
                //         </>
                //     );
                // }}
                />
            </div>

            {isModalOpenAddGoods &&
                <LookupItems onClose={() => setIsModalOpenAddGoods(false)} onSelect={handleSelectItem} prTypeId={header?.prTypeId} itemType={Constant.ItemType.GOODS} />
            }
        </>
    )
}