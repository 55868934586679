import jwtDecode from "jwt-decode";
import SessionStorageUtils from "./SessionStorage";

export const LocalStorageConst = {
    jwtToken: 'jwtToken',
    tryLoginCount: 'tryLoginCount',
}

const LocalStorageUtils = {
    jwtToken: {
        get: () => localStorage.getItem(LocalStorageConst.jwtToken) || '',
        set: (token) => localStorage.setItem(LocalStorageConst.jwtToken, token),
        clear: () => localStorage.removeItem(LocalStorageConst.jwtToken)
    },

    tryLoginCount: {
        get: () => localStorage.getItem(LocalStorageConst.tryLoginCount) || '',
        set: (count) => localStorage.setItem(LocalStorageConst.tryLoginCount, count),
        clear: () => localStorage.removeItem(LocalStorageConst.tryLoginCount)
    },

    all: {
        clear: () => localStorage.clear()
    },

    checkTokenExpired: (redirectToLogin = false) => {
        const token = LocalStorageUtils.jwtToken.get();

        if (token === "") {
            if (redirectToLogin) {
                window.location.replace('/');
            }
            return true;
        }

        const decoded = jwtDecode(token);
        const expired = decoded?.exp;
        const currentDate = new Date();

        if (expired * 1000 < currentDate.getTime()) {
            LocalStorageUtils.jwtToken.clear();
            SessionStorageUtils.all.clear()
            window.location.replace('/');
            return true;
        }

        if (redirectToLogin) {
            window.location.replace('/');
        }

        return false;

        // if (token !== "") {
        //     let decoded = jwtDecode(token);
        //     let expired = decoded?.exp
        //     const currentDate = new Date()
        //     if (expired * 1000 < currentDate.getTime()) {
        //         LocalStorageUtils.jwtToken.clear()
        //         window.location.replace('/')
        //         return true
        //     } else {
        //         return false
        //     }
        // }
        // if (redirectToLogin) window.location.replace('/')
        // return true
    },

    checkExpiredTokenBefore5Minutes: () => {
        const localStorageJwt = LocalStorageUtils.jwtToken.get();
        const decodedLocalStorageJwt = jwtDecode(localStorageJwt);
        const expirationTime = decodedLocalStorageJwt?.exp || 0;

        const now = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds

        const minuteDifference = Math.ceil((expirationTime - now) / 60);

        return {
            minute: minuteDifference,
            isExpired: minuteDifference <= 5,
        };
    }
}

export default LocalStorageUtils
