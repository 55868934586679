import { atom } from "jotai";
import moment from "moment";
import Constant from "utils/Constants";

export const INIT_HEADER_RFQ_ATOM = {
    rfqId: null,
    rfqNo: "",
    rfqTypeId: null,
    rfqSubject: "",
    rfqDate: moment().format(Constant.stateDateFormat),
    buyerPic: {
        userId: null,
        name: "",
        email: ""
    },
    remarks: "",
    tenderBriefingDate: null,
    targetAppointmentDate: null,
    quotationSubmissionDueDate: null,
    notesForInternal: "",
    notesForVendor: "",

    // totalAmountGoodsBeforeTax: 0,
    // totalAmountServicesBeforeTax: 0,
    // totalAmountGoodsVatPercentage: 0,
    // totalAmountServicesVatPercentage: 0,
    goodsVatPercentage: 0,
    serviceVatPercentage: 0,
    // totalGoodsAmountWithTax: 0,
    // totalServicesAmountWithTax: 0,
    // prTotalAmount: 0,
}
export const headerRFQAtom = atom(INIT_HEADER_RFQ_ATOM);

export const INIT_GOODS_ITEM_RFQ_ATOM = {
    // sortKey: null,
    itemGoodsId: null,
    itemCategoryGoodsId: null,
    quantity: null,
    uomId: null,
    price: null,
    priceInOc: null,
    currencyId: null,
    requiredDate: moment().format(Constant.stateDateFormat),
    remarks: "",
    description: "",
    subTotal: 0
}
export const goodsItemDetailRFQAtom = atom(INIT_GOODS_ITEM_RFQ_ATOM)
export const goodsItemRFQAtom = atom([])

export const INIT_SERVICE_ITEM_RFQAtom = {
    // sortKey: null,
    itemServiceId: null,
    itemCategoryServiceId: null,
    quantity: null,
    uomId: null,
    price: null,
    priceInOc: null,
    currencyId: null,
    requiredDate: moment().format(Constant.stateDateFormat),
    remarks: "",
    description: "",
    subTotal: 0
}
export const serviceItemDetailRFQAtom = atom(INIT_SERVICE_ITEM_RFQAtom)
export const serviceItemRFQAtom = atom([])

export const vendorsRFQAtom = atom([])


export const INIT_NOTES_RFQ_ATOM = {
    notesForVendor: "",
    notesForInternal: ""
}
export const notesRFQAtom = atom(INIT_NOTES_RFQ_ATOM)

export const attachmentsForInternalAtom = atom([])
export const attachmentsForVendorAtom = atom([])

export const remarksApproval = atom("")