import { Button, Card, Col, Form, Row } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import Constant from 'utils/Constants'
import { CustomFormItemMoney, CustomFormItemSelectDropdown, CustomFormItemSwitch, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem'
import useItemRepo from 'services/repo/useItemRepo'
import useItemCategoryRepo from 'services/repo/useItemCategoryRepo'
import useUOMRepo from 'services/repo/useUOMRepo'
import useItemTypeRepo from 'services/repo/useItemTypeRepo'
import { dismissToast, loadingToastId, successToast } from 'utils/Toast'
import DevLog from 'utils/DevLog'
import { formatterAmount } from 'utils/Helper'
import CustomAlert from 'utils/CustomAlert'

const init_field = {
  itemId: null,
  itemName: "",
  itemCode: "",
  description: "",
  itemTypeId: null,
  itemCategoryId: null,
  uomId: null,
  currencyId: 1,
  price: 0,
  priceInOc: null,
  isActive: true,
  lastModifiedAt: '',
  itemType: null,
}

export default function BaseDrawerItem(props) {
  const {
    onClose,
    dataEdit = null,
    onRefreshList,
    isView = false,
    loading = false,
    setModeView = () => {
    },
    isAddEditPrivileged,
    handleUnhighlight = () => {
    },
  } = props
  const [form] = Form.useForm()
  const [dataField, setDataField] = useState(init_field)
  const { isLoading: isLoadingCreate, createItem } = useItemRepo(false);
  const { isLoading: isLoadingUpdate, updateItem } = useItemRepo(false);
  const { isLoading: isLoadingListIC, getItemCategoryList } = useItemCategoryRepo(false);
  const { isLoading: isLoadingListUOM, getUOMList } = useUOMRepo(false);
  const { isLoading: isLoadingListItemType, getItemTypeList } = useItemTypeRepo(false);
  const [uoms, setUoms] = useState([])
  const [itemTypes, setItemTypes] = useState([])
  //* Item Category DROPDOWN
  const [itemCategoryOptions, setItemCategoryOptions] = useState([])

  const fetchDataIC = async (itemTypeId) => {
    const payload = {
      pageNumber: 1,
      pageSize: 0,
      filter: {
        itemCategoryId: "",
        itemCategoryCode: "",
        itemCategoryName: "",
        description: "",
        itemTypeId: itemTypeId,
        isActive: true,
        previousItemCategoryId: dataEdit ? dataEdit.itemCategoryId : null
      },
      orderBy: "itemCategoryName",
      isAscending: true
    }
    const res = await getItemCategoryList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setItemCategoryOptions(() => {
        return res?.data?.listData.map(item => {
          return {
            value: item?.itemCategoryId,
            label: item?.itemCategoryName
          }
        })
      })
    }
  }

  //* UOM DROPDOWN
  const fetchDataUOM = async () => {
    const payload = {
      pageNumber: 1,
      pageSize: 0,
      filter: {
        uomId: "",
        name: "",
        description: "",
        isActive: true,
        previousUomId: dataEdit ? dataEdit.uomId : null
      },
      orderBy: "name",
      isAscending: true
    }
    const res = await getUOMList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setUoms(() => {
        return res?.data?.listData.map(item => {
          return {
            value: item?.uomId,
            label: item?.name
          }
        })
      })
    }
  }
  //* ITEM TYPE DROPDOWN
  const fetchDataItemTypes = async () => {
    const payload = {
      pageNumber: 1,
      pageSize: 0,
      filter: {
        itemTypeId: null,
        itemTypeName: "",
        isActive: null,
      },
      orderBy: "itemTypeId",
      isAscending: true
    }
    const res = await getItemTypeList(payload)
    if (res?.isValid && res?.statusCode === 200) {
      setItemTypes(() => {
        return res?.data?.listData.map(item => {
          return {
            value: item?.itemTypeId,
            label: item?.itemTypeName
          }
        })
      })
    }
  }

  const handleCloseDrawer = () => {
    form.resetFields()
    setDataField(init_field)
    setModeView(true)
    onClose()
  }

  const createPayload = {
    itemCode: dataField.itemCode,
    itemName: dataField.itemName,
    description: dataField.description,
    itemTypeId: dataField.itemTypeId,
    uomId: dataField.uomId,
    itemCategoryId: dataField.itemCategoryId,
    currencyId: dataField.currencyId,
    price: dataField.price,
    priceInOc: dataField.priceInOc,
    isActive: dataField.isActive
  }

  const onSaveCreate = async () => {
    loadingToastId(Constant.message.savingData, createPayload?.itemCode)
    let res = await createItem(createPayload)
    dismissToast(createPayload?.itemCode)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess)
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const onSaveEdit = async () => {
    const editPayload = {
      ...createPayload,
      itemId: dataField.itemId,
      lastModifiedAt: dataField.lastModifiedAt,
    }
    loadingToastId(Constant.message.savingData, editPayload?.itemId)
    let res = await updateItem(editPayload)
    dismissToast(editPayload?.itemId)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess)
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const handleFormSubmit = () => {
    CustomAlert.confirm({
      title: "Save Item!",
      description: `Are you sure you want to save this data?`,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "primary",
      onConfirmButton: () => {
        form.validateFields()
          .then((values) => {
            if (dataEdit !== null) {
              onSaveEdit()
            } else {
              onSaveCreate()
            }
          })
          .catch((errorInfo) => {
            DevLog("err", errorInfo);
          });
      }
    });
  };

  useEffect(() => {
    setModeView(isView)
  }, [isView])

  useEffect(() => {
    fetchDataUOM()
    fetchDataItemTypes()
  }, [isView])

  const fields = {
    itemName: dataEdit?.itemName,
    itemCode: dataEdit?.itemCode,
    description: dataEdit?.description,
    itemTypeId: dataEdit?.itemTypeId,
    itemCategoryId: dataEdit?.itemCategoryId,
    uomId: dataEdit?.uomId,
    currencyId: dataEdit?.currencyId,
    price: formatterAmount(dataEdit?.price),
    priceInOc: dataEdit?.priceInOc,
    isActive: dataEdit?.isActive,
  }

  useEffect(() => {
    if (dataEdit !== null) {
      fetchDataIC(dataEdit.itemTypeId)
      setDataField(dataEdit);
      form.setFieldsValue(fields)
    } else {
      setDataField(init_field)
      form.setFieldsValue(init_field)
      handleUnhighlight()
    }
  }, [dataEdit])

  return (
    <>
      <BaseDrawer
        title={isView
          ? "View Item "
          : (dataEdit === null
            ? "Add Item "
            : "Edit Item ")}
        placement="right"
        size="large"
        onClose={handleCloseDrawer}
        open={true}
        loading={loading}
        extras={
          <>
            {(isAddEditPrivileged && isView) &&
              (<Button
                type="primary"
                onClick={() => {
                  setModeView(false)
                }}>Edit</Button>
              )
            }
            {(isAddEditPrivileged && !isView) &&
              (<Button type="primary" onClick={handleFormSubmit} htmlType='submit'
                loading={isLoadingCreate || isLoadingUpdate}>
                Submit
              </Button>)
            }
          </>
        }
      >
        <Card>
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <CustomFormItemSelectDropdown
                  nameFormItem="itemTypeId"
                  label="Item Type"
                  value={dataField?.itemTypeId}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  placeholder="Select Item Type"
                  options={itemTypes}
                  loading={isLoadingListItemType}
                  onChange={value => {
                    fetchDataIC(value)
                    setDataField(prev =>
                      ({ ...prev, itemTypeId: value }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="itemCode"
                  label="Item Code"
                  rules={[
                    {
                      required: true,
                      whitespace: true
                    },
                  ]}
                  placeholder="Fill in Item Code"
                  value={dataField?.itemCode}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      itemCode: e.target.value
                    }))
                  }}
                  isView={isView}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="itemName"
                  label="Item Name"
                  rules={[
                    {
                      required: true,
                      whitespace: true
                    },
                  ]}
                  placeholder="Fill in Item Name"
                  value={dataField?.itemName}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      itemName: e.target.value
                    }))
                  }}
                  isView={isView}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSelectDropdown
                  nameFormItem="itemCategoryId"
                  label="Item Category"
                  showSearch={true}
                  value={dataField?.itemCategoryId}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  placeholder="Select Item Category"
                  options={itemCategoryOptions}
                  loading={isLoadingListIC}
                  onChange={value => {
                    setDataField(prev =>
                      ({ ...prev, itemCategoryId: value }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSelectDropdown
                  nameFormItem="uomId"
                  label="UOM"
                  showSearch={true}
                  value={dataField?.uomId}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  placeholder="Select UOM"
                  options={uoms}
                  loading={isLoadingListUOM}
                  onChange={value => {
                    setDataField(prev =>
                      ({ ...prev, uomId: value }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemMoney
                  nameFormItem="price"
                  nameInput="price"
                  label="Price"
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                  initForm={form}
                  customvalue={dataField?.price}
                  onChange={e => setDataField(prev => ({
                    ...prev,
                    price: e,
                    priceInOc: e
                  }))}
                  isView={isView}
                  alignView="right"
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemTextArea
                  nameFormItem="description"
                  label="Description"
                  value={dataField?.description}
                  isView={isView}
                  placeholder="Fill in Description"
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      description: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSwitch
                  nameFormItem="isActive"
                  label="Is Active"
                  value={dataField?.isActive}
                  isView={isView}
                  onChange={(checked) => {
                    setDataField(prev =>
                      ({ ...prev, isActive: checked }))
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </BaseDrawer>
    </>
  )
}
