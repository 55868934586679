import { Card, Col, Form, Row, Space } from 'antd';
import { CustomFormItemMoney, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem';
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer';
import React, { useEffect } from 'react';
import Constant from 'utils/Constants';
import { setFieldValues } from 'utils/Helper';

export default function BaseItemsDetail(props) {
    const { onClose, data = null, loading = false, type = Constant.typeItemPR.GOODS } = props;
    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            const { itemName, itemCategoryName, description, uomName, price } = data;
            setFieldValues(form, "itemName", itemName);
            setFieldValues(form, "itemCategory", itemCategoryName);
            setFieldValues(form, "itemDescription", description);
            setFieldValues(form, "uomName", uomName);
            setFieldValues(form, "price", price);
        }
    }, [data, type]);

    const handleCloseDrawer = () => {
        onClose();
    };

    return (
        <BaseDrawer
            title={`${type === 1 ? 'Goods' : 'Services'} Item Detail`}
            placement="right"
            size="large"
            rootClassName={"detail-items"}
            onClose={handleCloseDrawer}
            open={true}
            loading={loading}
        >
            <Space direction="vertical" size="small" className='wrap-item-catalog-form space-vertical-flex'>
                <Card>
                    <Form form={form} layout="vertical" name="form-view-detail-items">
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <CustomFormItemText
                                    nameFormItem="itemName"
                                    label="Item Name"
                                    isView={true}
                                    value={data?.itemName}
                                />
                            </Col>
                            <Col xs={24} lg={12}>
                                <CustomFormItemText
                                    nameFormItem="itemCategory"
                                    label="Item Category"
                                    isView={true}
                                    value={data?.itemCategoryName}
                                />
                            </Col>
                            <Col xs={24} sm={12}>
                                <CustomFormItemMoney
                                    nameFormItem="price"
                                    nameInput='price'
                                    label="Price"
                                    initForm={form}
                                    isView={true}
                                    customvalue={data?.price}
                                    alignView="right"
                                />
                            </Col>
                            <Col xs={24} lg={12}>
                                <CustomFormItemText
                                    nameFormItem="uomName"
                                    label="UOM"
                                    isView={true}
                                    value={data?.uomName}
                                />
                            </Col>
                            <Col xs={24}>
                                <CustomFormItemTextArea
                                    nameFormItem="itemDescription"
                                    label="Description"
                                    isView={true}
                                    value={data?.description}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Space>
        </BaseDrawer>
    );
}
