import { Collapse } from 'antd'
import React from 'react'

export default function CustomCollapse(props) {
    return (
        <div className='wrapper-custom-collapse'>
            {/* {props?.title && <p className="title">{props?.title}</p>} */}
            <Collapse
                {...props}
                className={`custom-collapse ${props?.className}`}
                expandIconPosition='start'
                expandIcon={({ isActive }) => isActive ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.7193 5.70692C9.53177 5.89439 9.27746 5.99971 9.0123 5.99971C8.74713 5.99971 8.49283 5.89439 8.3053 5.70692L5.0123 2.41392L1.7193 5.70692C1.62705 5.80243 1.51671 5.87861 1.3947 5.93102C1.2727 5.98343 1.14148 6.01102 1.0087 6.01217C0.87592 6.01332 0.744241 5.98802 0.621345 5.93774C0.498448 5.88746 0.386797 5.81321 0.292904 5.71931C0.199011 5.62542 0.124758 5.51377 0.0744772 5.39087C0.0241963 5.26798 -0.00110613 5.1363 4.76837e-05 5.00352C0.0012015 4.87074 0.0287878 4.73952 0.0811968 4.61751C0.133606 4.49551 0.209789 4.38517 0.305299 4.29292L4.3053 0.292919C4.49283 0.105448 4.74713 0.000132561 5.0123 0.000132561C5.27746 0.000132561 5.53177 0.105448 5.7193 0.292919L9.7193 4.29292C9.90677 4.48045 10.0121 4.73475 10.0121 4.99992C10.0121 5.26508 9.90677 5.51939 9.7193 5.70692V5.70692Z" fill="#364d94" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.293031 1.29308C0.480558 1.10561 0.734866 1.00029 1.00003 1.00029C1.26519 1.00029 1.5195 1.10561 1.70703 1.29308L5.00003 4.58608L8.29303 1.29308C8.38528 1.19757 8.49562 1.12139 8.61763 1.06898C8.73963 1.01657 8.87085 0.988985 9.00363 0.987831C9.13641 0.986677 9.26809 1.01198 9.39098 1.06226C9.51388 1.11254 9.62553 1.18679 9.71943 1.28069C9.81332 1.37458 9.88757 1.48623 9.93785 1.60913C9.98813 1.73202 10.0134 1.8637 10.0123 1.99648C10.0111 2.12926 9.98354 2.26048 9.93113 2.38249C9.87872 2.50449 9.80254 2.61483 9.70703 2.70708L5.70703 6.70708C5.5195 6.89455 5.26519 6.99987 5.00003 6.99987C4.73487 6.99987 4.48056 6.89455 4.29303 6.70708L0.293031 2.70708C0.10556 2.51955 0.000244141 2.26525 0.000244141 2.00008C0.000244141 1.73492 0.10556 1.48061 0.293031 1.29308Z" fill="#364d94" />
                    </svg>
                }
            />
        </div>
    )
}
