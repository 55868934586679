import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './routes/App';
import reportWebVitals from './reportWebVitals';
import "../src/assets/scss/stylesheet.scss";
import { Toaster } from 'react-hot-toast';
import 'react-resizable/css/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <>
    <div><Toaster /></div>
    <App />
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
