import { useEffect } from "react"

const { useAtom } = require("jotai")
const { default: RouteAtom } = require("store/routes/routesAtom")

const useTopbarTitleHooks = (defaultTopbarTitle = '', enableBackBtn = false) => {
    const [topbarTitle, setTopbarTitle] = useAtom(RouteAtom.topbarTitleAtom)
    const [isShowBackButton, setBackButton] = useAtom(RouteAtom.topbarBackButton)
    const [backButtonUrl, setBackButtonUrl] = useAtom(RouteAtom.backButtonUrl)

    useEffect(() => {
        setTopbarTitle(defaultTopbarTitle)
        setBackButton(enableBackBtn)
        setBackButtonUrl('')
    }, [])

    return {
        topbarTitle,
        isShowBackButton,
        setTopbarTitle,
        setBackButtonUrl,
        setBackButton,
        backButtonUrl
    }
}

export default useTopbarTitleHooks
