import { atom } from "jotai";
import moment from "moment";
import Constant from "utils/Constants";

export const INIT_HEADER_ROGS = {
    rogsId: null,
    rogsNo: "",
    rogsSubmitDate: moment().format(Constant.stateDateFormat),
    poId: "",
    poNo: "",
    topTerm: null,
    expectedDeliveryDate: null, //moment().format(Constant.stateDateFormat)
    actualDeliveryDate: null, //moment().format(Constant.stateDateFormat)
    rescheduleDate: null,
    // rescheduleToRogsId: null,
    // rescheduleFromRogsId: null,
    vendorId: null,
    vendorName: "",
    // rogsPicId: null,
    // rogsPicFullName: "",
    doBastNo: "",
    remarks: "",
    isLastRogsTerm: true,
    receiverId: null,
    receiverName: "",
}
export const headerROGSAtom = atom(INIT_HEADER_ROGS);
export const goodsItemROGS = atom([])
export const servicesItemROGS = atom([])
export const attachmentsROGS = atom([])
export const remarksApprovalROGS = atom("")