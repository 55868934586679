import axios from 'axios'
import Constant from 'utils/Constants'

const unauthenticatedApi = axios.create({
    baseURL: Constant.baseApiURL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        ContentType: 'application/json'
    }
})

unauthenticatedApi.interceptors.request.use(
    (config) => {
        config.headers['Accept'] = `application/json`
        config.headers['ContentType'] = `application/json`

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

unauthenticatedApi.interceptors.response.use(function (response) {
    return response
}, function (error) {

    if (error?.response?.status === 401) {
        // alert('Unauthorized')
        // window.localStorage.clear()
        // window.location.href = '/'
    }
    return Promise.reject(error)
})

export default unauthenticatedApi
