/**
 * September 26th
 * 2023
 *
 * created:
 * Try Fanny Poerna Maulana
 */

import { Card, Col, Form, Input, Row, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ButtonAction from "components/Common/ButtonAction";
import useTopbarTitleHooks from "hooks/useTopbarTitleHooks";
import CustomAntdTable from "components/Common/CustomAntdTable";
import { dismissToast, loadingToastId } from "utils/Toast";
import Constant from "utils/Constants";
import { sorterTable } from "utils/Helper";
import useEmailTemplateRepo from "services/repo/useEmailTemplateRepo";
import useIsMobileHooks from "hooks/useIsMobileHooks";
import useAccessPermissionHook from "hooks/useAccessPermissionHook";
import { PRIVILEGE_ID } from "utils/AccessPermissionConfig";
import SkeletonPage from "components/Common/SkeletonPage";
import { ButtonAdvanceFilter } from "components/Common/ButtonAdvanceFilter";
import Error403 from "components/Error/Error403";
import BaseDrawerEmailTemplate from "components/MasterData/EmailTemplate/BaseDrawerEmailTemplate";
import BaseAdvanceFilter from "components/CustomDrawer/BaseAdvanceFilter";
import CellTable from "components/Common/CellTable";

export default function EmailTemplate() {
  useTopbarTitleHooks("Master Data Email Template");
  const { isLoading: isLoadingList, getEmailTemplateList } =
    useEmailTemplateRepo(false);
  const { isLoading: isLoadingById, getEmailTemplateById } =
    useEmailTemplateRepo(false);

  const isMobile = useIsMobileHooks();
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [totalData, setTotalData] = useState(0);
  const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false);
  const [isViewDrawer, setIsViewDrawer] = useState(false);
  const [dataEditDrawer, setDataEditDrawer] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [idHighlightRow, setIdHighlightRow] = useState(null);

  const { isHasPrivilege } = useAccessPermissionHook();
  const isLoadingPrivilege = useRef(true);
  const hasPrivilegePage = useRef(true);
  const [isLoadedPrivilege, setIsLoadedPrivilege] = useState(false);
  const [showButtonAdd, setShowButtonAdd] = useState(false);

  const [filters, setFilters] = useState({
    emailTemplateId: "",
    code: "",
    subject: "",
    description: "",
    isActive: null,
  });
  const [tableParams, setTableParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    filter: { ...filters },
    orderBy: "code",
    isAscending: true,
  });

  const onSearch = (value) => {
    setTableParams((prev) => ({
      ...prev,
      pageNumber: 1,
      filter: {
        ...filters,
      },
    }));
  };

  const onSearchClick = () => {
    onSearch();
    setIsOpenAdvanceFilter(false);
  };

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
    setDataEditDrawer(null);
    setIdHighlightRow(null);
  };

  const fetchData = async () => {
    const payload = {
      ...tableParams,
    };
    const res = await getEmailTemplateList(payload);
    if (res?.isValid && res?.statusCode === 200) {
      setData(res?.data?.listData);
      setTotalData(res?.data?.totalRecords);
      setTableParams((prevParams) => ({ ...prevParams }));
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      pageNumber: pagination?.current,
      pageSize: pagination?.pageSize,
      orderBy: sorter?.field || prevParams?.orderBy,
      isAscending: sorterTable(sorter?.order, prevParams?.isAscending),
    }));
    if (pagination.pageSize !== tableParams?.pageSize) {
      setData([]);
    }
  };

  const handleRowClick = async (data) => {
    let id = data?.emailTemplateId;
    const payload = {
      emailTemplateId: id,
    };
    loadingToastId(Constant.message.loading, id);
    const res = await getEmailTemplateById(payload);
    if (res?.isValid && res?.statusCode === 200) {
      setIsViewDrawer(true);
      setIsOpenDrawer(true);
      setDataEditDrawer(res?.data);
      setIdHighlightRow(res.data.emailTemplateId);
    }
    dismissToast(id);
  };

  const handleEdit = async (data) => {
    let id = data?.emailTemplateId;
    const payload = {
      emailTemplateId: id,
    };
    loadingToastId(Constant.message.loading, id);
    const res = await getEmailTemplateById(payload);
    if (res?.isValid && res?.statusCode === 200) {
      setIsViewDrawer(false);
      setIsOpenDrawer(true);
      setDataEditDrawer(res?.data);
      setIdHighlightRow(res.data.emailTemplateId);
    }
    dismissToast(id);
  };

  const fetchCheckPrivilege = async () => {
    let pvlgAddEdit = await isHasPrivilege(PRIVILEGE_ID.AddEditEmailTemplate);
    let pvlgAccess = await isHasPrivilege(PRIVILEGE_ID.EmailTemplate);
    Promise.all([pvlgAccess, pvlgAddEdit]).then(
      ([pvlgAccessValue, pvlgAddEditValue]) => {
        setShowButtonAdd(pvlgAddEditValue);
        isLoadingPrivilege.current = false;
        if (pvlgAccessValue) {
          hasPrivilegePage.current = true;
          setIsLoadedPrivilege(true);
          fetchData();
        } else {
          hasPrivilegePage.current = false;
        }
        dismissToast(PRIVILEGE_ID.AddEditEmailTemplate);
      }
    );
  };

  useEffect(() => {
    fetchCheckPrivilege();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadedPrivilege) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (isLoadedPrivilege && !isMobile) {
        onSearch();
      }
    }, Constant.delayFilter);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterUI = () => {
    return (
      <Form layout="horizontal" form={form}>
        <Row gutter={16}>
          <Col xs={24} sm={10} md={8}>
            <Form.Item name="code" label="Filter By">
              <Input
                allowClear
                placeholder={Constant.placeholder.enterCode}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    code: e.target.value,
                  }));
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={10} md={7}>
            <Form.Item name="subject">
              <Input
                allowClear
                placeholder={Constant.placeholder.enterSubject}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    subject: e.target.value,
                  }));
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const columns = [
    {
      title: "Action",
      key: "action",
      width: "60px",
      canHide: false,
      render: (data, row) => (
        <>
          {showButtonAdd ? (
            <ButtonAction
              buttons={["edit", "view"]}
              onEdit={handleEdit}
              onView={handleRowClick}
              data={row}
            />
          ) : (
            <ButtonAction
              buttons={["view"]}
              onView={handleRowClick}
              data={row}
            />
          )}
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
      width: "200px",
      render: (data, row) => <CellTable data={data} />
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
      width: "200px",
      render: (data, row) => <CellTable data={data} />
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sorter: true,
      width: "200px",
      render: (data, row) => <CellTable data={data} />
    },
    {
      title: "Body",
      dataIndex: "body",
      width: "200px",
      render: (data, row) => <CellTable data={data} />
    },
  ];

  return (
    <>
      {hasPrivilegePage.current ? (
        <Space
          direction="vertical"
          size="large"
          className="space-vertical-flex"
        >
          <Card size="big" bordered={false}>
            {isLoadingPrivilege.current ? (
              <SkeletonPage />
            ) : (
              <>
                {isMobile ? (
                  <ButtonAdvanceFilter
                    onClick={() => setIsOpenAdvanceFilter(true)}
                  />
                ) : (
                  filterUI()
                )}

                <CustomAntdTable
                  columns={columns}
                  dataSource={data}
                  rowKey={"emailTemplateId"}
                  tableParam={tableParams}
                  totalData={totalData}
                  loading={isLoadingList}
                  onChange={handleTableChange}
                  rowClick={handleRowClick}
                  isOpenDrawer={isOpenDrawer}
                  idDataHighlight={idHighlightRow}
                />
              </>
            )}
          </Card>
        </Space>
      ) : (
        <Error403 />
      )}

      {isOpenDrawer && (
        <BaseDrawerEmailTemplate
          onClose={onCloseDrawer}
          dataEdit={dataEditDrawer}
          isView={isViewDrawer}
          loading={isLoadingById}
          onRefreshList={fetchData}
          setModeView={setIsViewDrawer}
          isAddEditPrivileged={showButtonAdd}
          handleUnhighlight={() => {
            setIdHighlightRow(null);
          }}
        />
      )}

      {isOpenAdvanceFilter && (
        <BaseAdvanceFilter
          onClose={() => setIsOpenAdvanceFilter(false)}
          onSearchClick={onSearchClick}
        >
          {filterUI()}
        </BaseAdvanceFilter>
      )}
    </>
  );
}
