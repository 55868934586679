
import { ConfigProvider, App as AppAnt } from 'antd';
import 'antd/dist/reset.css';
import { Provider } from 'jotai';
import Footer from 'components/layout/V2/Footer';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Users from 'pages/MasterData/User';
import Roles from 'pages/MasterData/Role';
import CreatePR from 'pages/PurchaseRequest/CreatePR';
import ApprovalPR from 'pages/PurchaseRequest/ApprovalPR';
import Login from 'pages/Auth';
import Constant from 'utils/Constants';
import { APP_ROUTE_PREFIX, RouteConstant } from './routesConstant';
import { useEffect, useState } from 'react';
import jwtDecode from "jwt-decode";
import LocalStorageUtils from 'utils/LocalStorage';
import CustomAlert from 'utils/CustomAlert';
import Swal from "sweetalert2"
import useAuthRepo from 'services/repo/useAuthRepo';
import { dismissToast, errorToast, loadingToastId } from 'utils/Toast';
import RevisePR from 'pages/PurchaseRequest/RevisePR';
import ManageRFQAddEdit from 'pages/RFQ/ManageRFQAddEdit';
import VendorQuotationList from 'pages/VendorQuotation';
import ManageVendorQuotationAddEdit from 'pages/VendorQuotation/ManageVendorQuotationAddEdit';
import ManageVendorSelectionAddRevise from 'pages/VendorSelection/ManageVendorSelectionAddRevise';
import ManagePurchaseOrderEdit from 'pages/PurchaseOrder/ManagePurchaseOrderEdit';
import ManageInvoiceReceiveAddEdit from 'pages/InvoiceReceive/ManageInvoiceReceiveAddEdit';
// import { dateGreaterThan } from 'utils/Helper';
import LeftSidebar from 'components/layout/V2/LeftSidebar';
import Topbar from 'components/layout/V2/Topbar';
import ApprovalRFQ from 'pages/RFQ/ApprovalRFQ';
import ReviseRFQ from 'pages/RFQ/ReviseRFQ';
import ApprovalVS from 'pages/VendorSelection/ApprovalVS';
import ApprovalPO from 'pages/PurchaseOrder/ApprovalPO';
import RevisePO from 'pages/PurchaseOrder/RevisePO';
import CreateRoGS from 'pages/RoGSV2/CreateRoGS';
import CostCenter from 'pages/MasterData/CostCenter';
import UOM from 'pages/MasterData/UOM';
import Division from 'pages/MasterData/Division';
import Department from 'pages/MasterData/Department';
import DepartmentCostCenter from 'pages/MasterData/DepartmentCostCenter';
import ApprovalRoGS from 'pages/RoGSV2/ApprovalRoGS';
import ReviseRoGS from 'pages/RoGSV2/ReviseRoGS';
// import Dashboard from 'pages/Dashboard';
import ReviseInvoiceReceive from 'pages/InvoiceReceive/ReviseInvoiceReceive';
import ApprovalInvoiceReceive from 'pages/InvoiceReceive/ApprovalInvoiceReceive';
import Item from 'pages/MasterData/Item';
import ItemCatalog from 'pages/MasterData/ItemCatalog';
import ManageItemCatalog from 'pages/MasterData/ItemCatalog/ManageItemCatalog';
import MyProfile from 'pages/MyProfile';
import AntConfig from 'utils/AntConfig';
import PurchaseRequestByDivision from 'pages/Report/PurchaseRequestByDivision';
import Employee from 'pages/MasterData/Employee';
import ResetPassword from 'pages/Auth/ResetPassword';
import useIsMobileHooks from 'hooks/useIsMobileHooks';
import ItemCategory from 'pages/MasterData/ItemCategory';
import WfRole from 'pages/MasterData/WfRole';
import EmailTemplate from 'pages/MasterData/EmailTemplate';
import ListAndPendingTaskPR from 'pages/PurchaseRequest/ListAndPendingTaskPR';
import Country from 'pages/MasterData/Country';
import Bank from 'pages/MasterData/Bank';
import BankAccount from 'pages/MasterData/BankAccount';
import ListAndPendingTaskRFQ from 'pages/RFQ/ListAndPendingTaskRFQ';
import ListAndPendingTaskVS from 'pages/VendorSelection/ListAndPendingTaskVS';
import ListAndPendingTaskPO from 'pages/PurchaseOrder/ListAndPendingTaskPO';
import ListAndPendingTaskRoGS from 'pages/RoGSV2/ListAndPendingTaskRoGS';
import ListAndPendingTaskInvoice from 'pages/InvoiceReceive/ListAndPendingTaskInvoice';
import WfApprovalSettings from 'pages/MasterData/WfApprovalSetting';
import SLAProcurement from 'pages/Report/SLAProcurement';
import ForgetPassword from 'pages/Auth/ForgetPassword';
import PRTracking from 'pages/Report/PurchaseRequestTracking';
import IndustryType from 'pages/MasterData/IndustryType';
import CostCenterApprovalMapping from 'pages/MasterData/CostCenterApprovalMapping';
import ManageCostCenterApprovalMapping from 'pages/MasterData/CostCenterApprovalMapping/ManageCostCenterApprovalMapping';
// import DashboardV3 from 'pages/Dashboard/V3';
// import SummaryPRDetail from 'pages/Dashboard/V3/SummaryPRDetail';
// import SummaryPRChart from 'pages/Dashboard/V3/SummaryPRChart';
// import SummaryPOChart from 'pages/Dashboard/V3/SummaryPOChart';
// import SummaryPODetail from 'pages/Dashboard/V3/SummaryPODetail';
// import SummaryInvoiceChart from 'pages/Dashboard/V3/SummaryInvoiceChart';
// import SummaryInvoiceDetail from 'pages/Dashboard/V3/SummaryInvoiceDetail';
import Vendor from 'pages/MasterData/Vendor';
import ManageVendor from 'pages/MasterData/Vendor/ManageVendor';
// import TopSpendVendorChart from 'pages/Dashboard/V3/TopSpendVendorChart';
// import TopSpendVendorDetail from 'pages/Dashboard/V3/TopSpendVendorDetail';
// import TopSpendItemDetail from 'pages/Dashboard/V3/TopSpendItemDetail';
// import TopSpendItemCategoryChart from 'pages/Dashboard/V3/TopSpendItemCategoryChart';
// import TopSpendItemCategoryDetail from 'pages/Dashboard/V3/TopSpendItemCategoryDetail';
// import SummaryVendorChart from 'pages/Dashboard/V3/SummaryVendorChart';
// import SummaryVendorDetail from 'pages/Dashboard/V3/SummaryVendorDetail';
// import POInvoiceAmountMonthlyProgressStatus from 'pages/Dashboard/V3/POInvoiceAmountMonthlyProgressStatus';
// import POInvoiceAmountMonthlyDetailPO from 'pages/Dashboard/V3/POInvoiceAmountMonthlyDetailPO';
// import POInvoiceAmountMonthlyDetailInvoice from 'pages/Dashboard/V3/POInvoiceAmountMonthlyDetailInvoice';
import DashboardV4 from 'pages/Dashboard/V4';
import SummaryPRDetailV2 from 'pages/Dashboard/V4/SummaryPRDetail';
import SummaryPODetailV2 from 'pages/Dashboard/V4/SummaryPODetail';
import SummaryInvoiceDetailV2 from 'pages/Dashboard/V4/SummaryInvoiceDetail';
import TopSpendVendorDetailV2 from 'pages/Dashboard/V4/TopSpendVendorDetail';
import POInvoiceAmountMonthlyDetail from 'pages/Dashboard/V4/POInvoiceAmountMonthlyDetail';
import TopSpendItemDetailV2 from 'pages/Dashboard/V4/TopSpendItemDetail';
import TopSpendItemCategoryDetailV2 from 'pages/Dashboard/V4/TopSpendItemCategoryDetail';
import SessionStorageUtils from 'utils/SessionStorage';
import PurchaseOrderByVendor from 'pages/Report/PurchaseOrderByVendor';
import PurchaseOrderByVendorDetail from 'pages/Report/PurchaseOrderByVendor/PurchaseOrderByVendorDetail';
// import ListAndPendingTaskInitialBudget from 'pages/Budget/InitialBudget/ListAndPendingTaskInitialBudget';
// import ManageInitialBudget from 'pages/Budget/InitialBudget/ManageInitialBudget';
// import COA from 'pages/MasterData/COA';
export default function App() {

  const isMobile = useIsMobileHooks(Constant.mediaQuery.isLG)

  return (
    <ConfigProvider theme={AntConfig}>
      <div className={`eproc-master ${!isMobile ? 'sidebar-show' : ""}`}>
        <Provider>
          <BrowserRouter>
            <AppAnt>
              <Routes>
                <Route path='/' element={<Login />} />
                <Route path={"/" + RouteConstant.resetPassword} element={<ResetPassword />} />
                <Route path={"/" + RouteConstant.forgotPassword} element={<ForgetPassword />} />
                <Route path={`${APP_ROUTE_PREFIX}/*`} element={<Template />} />
              </Routes>
            </AppAnt>
          </BrowserRouter>
        </Provider>

      </div>
    </ConfigProvider>
  );
}

const Template = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { isLoading: isLoadingRefreshToken, refreshToken } = useAuthRepo(false)
  const { logout } = useAuthRepo(false)
  const [visiblePopup, setVisiblePopup] = useState(false);

  const fetchRefreshToken = async () => {
    loadingToastId(Constant.message.loading, 1)
    let res = await refreshToken()
    dismissToast(1)
    if (res?.isValid && res?.statusCode === 200) {
      let token = res?.data?.bearerToken
      LocalStorageUtils.jwtToken.set(token)
      SessionStorageUtils.currentJwtToken.set(token)
    } else {
      errorToast("Error Refresh Token")
      logout()
    }
  }

  // CHECK EXPIRED TOKEN
  useEffect(() => {
    if (LocalStorageUtils.checkTokenExpired()) {
      //used for redirect to spesific page after login
      if (location.pathname && location.search) {
        let redirectTo = `${location.pathname}${location.search}`
        return navigate(`/?redirectTo=${redirectTo}`, { replace: true })
      }
      LocalStorageUtils.jwtToken.clear()
      SessionStorageUtils.all.clear()
      navigate('/', { replace: true })
    }
  }, [])

  // CHECK EXPIRED TOKEN BEFORE 5 MINUTES
  // CHECK SESSION STORAGE
  useEffect(() => {
    const intervalId = setInterval(() => {
      let localStorageJwt = LocalStorageUtils.jwtToken.get()
      let sessionStorageJwt = SessionStorageUtils.currentJwtToken.get()

      if (localStorageJwt !== "") {
        let decodedLocalStorageJwt = jwtDecode(localStorageJwt);

        if (!sessionStorageJwt) {
          SessionStorageUtils.currentJwtToken.set(localStorageJwt)
        } else {
          let decodedSessionStorageJwt = jwtDecode(sessionStorageJwt);
          //check session storage and local storage is not same
          if (sessionStorageJwt !== localStorageJwt) {
            let userIdSession = decodedSessionStorageJwt?.userId
            let userIdLocalStorage = decodedLocalStorageJwt?.userId
            if (userIdSession !== userIdLocalStorage) {
              SessionStorageUtils.all.clear()
              window.location.replace('/');
            } else {
              SessionStorageUtils.currentJwtToken.set(localStorageJwt)
            }
          }
        }

        const isTokenExpired = LocalStorageUtils.checkExpiredTokenBefore5Minutes()?.isExpired;
        const minute = LocalStorageUtils.checkExpiredTokenBefore5Minutes()?.minute;
        if (isTokenExpired) {
          if (!visiblePopup) setVisiblePopup(true)
          if (minute <= 0) {
            setVisiblePopup(false)
            Swal.close()
            logout()
          }
        }

      } else {
        window.location.replace('/');
        SessionStorageUtils.all.clear()
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };

  }, [])

  // MODAL CONFIRM REFRESH TOKEN
  useEffect(() => {
    if (visiblePopup && !isLoadingRefreshToken) {
      CustomAlert.confirm({
        title: "Token expired!",
        description: "Your token has expired. Would you like to extend it?",
        confirmButtonText: "Refresh Token",
        swalOptions: {
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        },
        onConfirmButton: () => {
          /*
          *Check the token again because if the user opens 2 or more tabs/windows, and in one tab/window has already performed a fetch to refresh the token and obtained a new token, then the other tabs/windows don't need to fetch a refresh token again.
          * */
          if (LocalStorageUtils.checkExpiredTokenBefore5Minutes()?.isExpired) {
            fetchRefreshToken()
          }
          setVisiblePopup(false)
        },
        onCancelButton: () => {
          if (LocalStorageUtils.checkExpiredTokenBefore5Minutes()?.isExpired) {
            logout()
          }
          setVisiblePopup(false)
        }
      })
    }
  }, [visiblePopup])

  return (
    <>
      <div className='wrap-layout' >
        <LeftSidebar />
        <Topbar />
        <main className='eproc-main-container wrap-main-content'>
          <div className='eproc-content main-content-card'>
            <Routes>
              <Route path={"/" + RouteConstant.dashboard} element={<DashboardV4 />} />
              {/* <Route path={"/" + RouteConstant.dashboardSummaryPRChart} element={<SummaryPRChart />} /> */}
              <Route path={"/" + RouteConstant.dashboardSummaryPRDetail} element={<SummaryPRDetailV2 />} />
              {/* <Route path={"/" + RouteConstant.dashboardSummaryPOChart} element={<SummaryPOChart />} /> */}
              <Route path={"/" + RouteConstant.dashboardSummaryPODetail} element={<SummaryPODetailV2 />} />
              {/* <Route path={"/" + RouteConstant.dashboardSummaryInvoiceChart} element={<SummaryInvoiceChart />} /> */}
              <Route path={"/" + RouteConstant.dashboardSummaryInvoiceDetail} element={<SummaryInvoiceDetailV2 />} />
              {/* <Route path={"/" + RouteConstant.dashboardSummaryVendorChart} element={<SummaryVendorChart />} /> */}
              {/* <Route path={"/" + RouteConstant.dashboardSummaryVendorDetail} element={<SummaryVendorDetail />} /> */}
              {/* <Route path={"/" + RouteConstant.dashboardTopSpendVendorChart} element={<TopSpendVendorChart />} /> */}
              <Route path={"/" + RouteConstant.dashboardTopSpendVendorDetail} element={<TopSpendVendorDetailV2 />} />
              <Route path={"/" + RouteConstant.dashboardTopSpendItemDetail} element={<TopSpendItemDetailV2 />} />
              {/* <Route path={"/" + RouteConstant.dashboardTopSpendItemCategoryChart} element={<TopSpendItemCategoryChart />} /> */}
              <Route path={"/" + RouteConstant.dashboardTopSpendItemCategoryDetail} element={<TopSpendItemCategoryDetailV2 />} />
              {/* <Route path={"/" + RouteConstant.dashboardPOInvoiceAmountMonthlyProgressStatus} element={<POInvoiceAmountMonthlyProgressStatus />} />
              <Route path={"/" + RouteConstant.dashboardPOInvoiceAmountMonthlyDetailPO} element={<POInvoiceAmountMonthlyDetailPO />} />
              <Route path={"/" + RouteConstant.dashboardPOInvoiceAmountMonthlyDetailInvoice} element={<POInvoiceAmountMonthlyDetailInvoice />} /> */}
              <Route path={"/" + RouteConstant.dashboardPOInvoiceAmountMonthlyDetail} element={<POInvoiceAmountMonthlyDetail />} />

              <Route path={"/" + RouteConstant.myProfile} element={<MyProfile />} />
              <Route path={"/" + RouteConstant.masterData.user.index} element={<Users />} />
              <Route path={"/" + RouteConstant.masterData.role.index} element={<Roles />} />
              <Route path={"/" + RouteConstant.masterData.uom.index} element={<UOM />} />
              <Route path={"/" + RouteConstant.masterData.itemCategory.index} element={<ItemCategory />} />
              <Route path={"/" + RouteConstant.masterData.division.index} element={<Division />} />
              <Route path={"/" + RouteConstant.masterData.costCenter.index} element={<CostCenter />} />
              <Route path={"/" + RouteConstant.masterData.department.index} element={<Department />} />
              <Route path={"/" + RouteConstant.masterData.departmentCostCenter.index} element={<DepartmentCostCenter />} />
              <Route path={"/" + RouteConstant.masterData.items.index} element={<Item />} />
              <Route path={"/" + RouteConstant.masterData.itemsCatalog.index} element={<ItemCatalog />} />
              <Route path={"/" + RouteConstant.masterData.itemsCatalog.create} element={<ManageItemCatalog />} />
              <Route path={"/" + RouteConstant.masterData.itemsCatalog.edit(":contractCatalogId")} element={<ManageItemCatalog />} />
              <Route path={"/" + RouteConstant.masterData.employee.index} element={<Employee />} />
              <Route path={"/" + RouteConstant.masterData.wfRole.index} element={<WfRole />} />
              <Route path={"/" + RouteConstant.masterData.emailTemplate.index} element={<EmailTemplate />} />
              <Route path={"/" + RouteConstant.masterData.country.index} element={<Country />} />
              <Route path={"/" + RouteConstant.masterData.bank.index} element={<Bank />} />
              <Route path={"/" + RouteConstant.masterData.bankAccount.index} element={<BankAccount />} />
              <Route path={"/" + RouteConstant.masterData.wfApprovalSetting.index} element={<WfApprovalSettings />} />
              <Route path={"/" + RouteConstant.masterData.industryType.index} element={<IndustryType />} />
              <Route path={"/" + RouteConstant.masterData.costCenterApprovalMapping.index} element={<CostCenterApprovalMapping />} />
              <Route path={"/" + RouteConstant.masterData.costCenterApprovalMapping.create} element={<ManageCostCenterApprovalMapping />} />
              <Route path={"/" + RouteConstant.masterData.costCenterApprovalMapping.update(":costCenterId")} element={<ManageCostCenterApprovalMapping />} />
              <Route path={"/" + RouteConstant.masterData.vendor.index} element={<Vendor />} />
              <Route path={"/" + RouteConstant.masterData.vendor.create} element={<ManageVendor />} />
              <Route path={"/" + RouteConstant.masterData.vendor.edit(":vendorId")} element={<ManageVendor />} />
              {/* <Route path={"/" + RouteConstant.initialBudget.index} element={<ListAndPendingTaskInitialBudget />} />
              <Route path={"/" + RouteConstant.initialBudget.create} element={<ManageInitialBudget />} />
              <Route path={"/" + RouteConstant.masterData.coa.index} element={<COA />} /> */}

              <Route path={"/" + RouteConstant.purchaseRequest.index} element={<ListAndPendingTaskPR />} />
              <Route path={"/" + RouteConstant.purchaseRequest.create} element={<CreatePR />} />
              <Route path={"/" + RouteConstant.purchaseRequest.edit(":prId")} element={<CreatePR />} />
              <Route path={"/" + RouteConstant.purchaseRequest.approval} element={<ApprovalPR />} />
              <Route path={"/" + RouteConstant.purchaseRequest.revise} element={<RevisePR />} />
              <Route path={"/" + RouteConstant.rfq.index} element={<ListAndPendingTaskRFQ />} />
              <Route path={"/" + RouteConstant.rfq.create} element={<ManageRFQAddEdit />} />
              <Route path={"/" + RouteConstant.rfq.edit(":rfqId")} element={<ManageRFQAddEdit />} />
              <Route path={"/" + RouteConstant.rfq.approval} element={<ApprovalRFQ />} />
              <Route path={"/" + RouteConstant.rfq.revise} element={<ReviseRFQ />} />
              <Route path={"/" + RouteConstant.vendorQuotation.index} element={<VendorQuotationList />} />
              <Route path={"/" + RouteConstant.vendorQuotation.create} element={<ManageVendorQuotationAddEdit />} />
              <Route path={"/" + RouteConstant.vendorQuotation.edit(":vqId")} element={<ManageVendorQuotationAddEdit />} />
              <Route path={"/" + RouteConstant.vendorSelection.index} element={<ListAndPendingTaskVS />} />
              <Route path={"/" + RouteConstant.vendorSelection.create} element={<ManageVendorSelectionAddRevise />} />
              <Route path={"/" + RouteConstant.vendorSelection.revise} element={<ManageVendorSelectionAddRevise />} />
              <Route path={"/" + RouteConstant.vendorSelection.approval} element={<ApprovalVS />} />
              <Route path={"/" + RouteConstant.purchaseOrder.index} element={<ListAndPendingTaskPO />} />
              <Route path={"/" + RouteConstant.purchaseOrder.edit(":poId")} element={<ManagePurchaseOrderEdit />} />
              <Route path={"/" + RouteConstant.purchaseOrder.revise} element={<RevisePO />} />
              <Route path={"/" + RouteConstant.purchaseOrder.approval} element={<ApprovalPO />} />
              <Route path={"/" + RouteConstant.rogs.index} element={<ListAndPendingTaskRoGS />} />
              <Route path={"/" + RouteConstant.rogs.create} element={<CreateRoGS />} />
              <Route path={"/" + RouteConstant.rogs.edit(":rogsId")} element={<CreateRoGS />} />
              <Route path={"/" + RouteConstant.rogs.revise} element={<ReviseRoGS />} />
              <Route path={"/" + RouteConstant.rogs.approval} element={<ApprovalRoGS />} />
              <Route path={"/" + RouteConstant.invoiceReceive.index} element={<ListAndPendingTaskInvoice />} />
              <Route path={"/" + RouteConstant.invoiceReceive.create} element={<ManageInvoiceReceiveAddEdit />} />
              <Route path={"/" + RouteConstant.invoiceReceive.edit(":ivcrId")} element={<ManageInvoiceReceiveAddEdit />} />
              <Route path={"/" + RouteConstant.invoiceReceive.revise} element={<ReviseInvoiceReceive />} />
              <Route path={"/" + RouteConstant.invoiceReceive.approval} element={<ApprovalInvoiceReceive />} />

              <Route path={"/" + RouteConstant.report.prByDivision} element={<PurchaseRequestByDivision />} />
              <Route path={"/" + RouteConstant.report.slaProcurement} element={<SLAProcurement />} />
              <Route path={"/" + RouteConstant.report.prTracking} element={<PRTracking />} />
              <Route path={"/" + RouteConstant.report.poByVendor} element={<PurchaseOrderByVendor />} />
              <Route path={"/" + RouteConstant.report.poByVendorDetail} element={<PurchaseOrderByVendorDetail />} />


              {/* <Route path='*' element={<Navigate to={APP_ROUTE_PREFIX + '/404'} />} /> */}
              {/* <Route path={"/404"} element={<Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                  extra={<Button type="primary" to>Back Home</Button>}
                />} /> */}
            </Routes>
          </div>
          <Footer />

        </main>
      </div>
    </>
  )
}
