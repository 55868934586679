import { Button, Card, Col, Form, Row } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import Constant from 'utils/Constants'
import CustomAlert from 'utils/CustomAlert'
import { dismissToast, loadingToastId, successToast } from 'utils/Toast'
import DevLog from 'utils/DevLog'
import { CustomFormItemSwitch, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem'
import useUOMRepo from 'services/repo/useUOMRepo'

const init_field = {
  uomId: null,
  name: "",
  description: "",
  uomAllowDecimal: false,
  isActive: true,
  lastModifiedAt: '',
}

export default function BaseDrawerUOM(props) {
  const {
    isOpen,
    onClose,
    dataEdit = null,
    onRefreshList,
    isView = false,
    loading = false,
    setModeView = () => { },
    handleUnhighlight = () => { },
    isAddEditPrivileged = false
  } = props
  const [form] = Form.useForm()
  const [dataField, setDataField] = useState(init_field)
  const { isLoading: isLoadingCreate, createUOM } = useUOMRepo(false);
  const { isLoading: isLoadingUpdate, updateUOM } = useUOMRepo(false);

  const handleCloseDrawer = () => {
    form.resetFields()
    setDataField(init_field)
    setModeView(true)
    onClose()
  }

  const onSaveCreate = async () => {
    let payload = {
      name: dataField.name,
      description: dataField.description,
      uomAllowDecimal: dataField.uomAllowDecimal,
      isActive: dataField.isActive,
    }
    loadingToastId(Constant.message.savingData, payload?.name)
    let res = await createUOM(payload)
    dismissToast(payload?.name)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const onSaveEdit = async () => {
    let payload = {
      uomId: dataField.uomId,
      lastModifiedAt: dataField.lastModifiedAt,
      name: dataField.name,
      description: dataField.description,
      uomAllowDecimal: dataField.uomAllowDecimal,
      isActive: dataField.isActive
    }
    loadingToastId(Constant.message.savingData, payload?.name)
    let res = await updateUOM(payload)
    dismissToast(payload?.name)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const handleFormSubmit = () => {
    CustomAlert.confirm({
      title: "Save UOM!",
      description: `Are you sure you want to save this data?`,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "primary",
      onConfirmButton: () => {
        form.validateFields()
          .then((values) => {
            if (dataEdit !== null) {
              onSaveEdit()
            } else {
              onSaveCreate()
            }
          })
          .catch((errorInfo) => {
            DevLog("err", errorInfo);
          });
      }
    });
  };

  useEffect(() => {
    setModeView(isView)
  }, [isView])

  useEffect(() => {
    if (dataEdit !== null) {
      setDataField(dataEdit)
      form.setFieldsValue({
        name: dataEdit?.name,
        description: dataEdit?.description,
        uomAllowDecimal: dataEdit?.uomAllowDecimal,
        isActive: dataEdit?.isActive,
      })
    } else {
      setDataField(init_field)
      form.setFieldsValue({
        name: init_field.name,
        description: init_field.description,
        uomAllowDecimal: init_field.uomAllowDecimal,
        isActive: init_field.isActive,
      })
      handleUnhighlight()
    }
  }, [dataEdit])

  return (
    <>
      <BaseDrawer
        title={isView ? "View UOM" : (dataEdit === null ? "Add UOM" : "Edit UOM")}
        placement="right"
        size="large"
        onClose={handleCloseDrawer}
        open={isOpen}
        loading={loading}
        extras={
          <>
            {(isAddEditPrivileged && isView) &&
              (<Button
                type="primary"
                onClick={() => setModeView(false)}>
                Edit
              </Button>)
            }
            {(isAddEditPrivileged && !isView) &&
              (<Button type="primary" onClick={handleFormSubmit} htmlType='submit'
                loading={isLoadingCreate || isLoadingUpdate}>
                Submit
              </Button>)}
          </>
        }
      >
        <Card>
          <Form
            form={form}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="name"
                  label="Name"
                  placeholder="Fill in Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.name}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      name: e.target.value
                    }))
                  }}
                />
              </Col>

              <Col xs={12} md={6}>
                <CustomFormItemSwitch
                  nameFormItem="uomAllowDecimal"
                  label="Allow Decimal"
                  value={dataField?.uomAllowDecimal}
                  isView={isView}
                  onChange={(checked) => {
                    setDataField(prev =>
                      ({ ...prev, uomAllowDecimal: checked }))
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                <CustomFormItemSwitch
                  nameFormItem="isActive"
                  label="Is Active"
                  value={dataField?.isActive}
                  isView={isView}
                  onChange={(checked) => {
                    setDataField(prev =>
                      ({ ...prev, isActive: checked }))
                  }}
                />
              </Col>
              <Col xs={24}>
                <CustomFormItemTextArea
                  nameFormItem="description"
                  label="Description"
                  placeholder="Fill in Description"
                  isView={isView}
                  value={dataField?.description}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      description: e.target.value
                    }))
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </BaseDrawer>
    </>
  )
}
