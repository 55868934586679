import { Avatar, Button, Dropdown, Space, theme } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { UserOutlined, AntDesignOutlined } from '@ant-design/icons';
import useAuthRepo from 'services/repo/useAuthRepo';
import useUserRepo from 'services/repo/useUserRepo';
import useTopbarTitleHooks from 'hooks/useTopbarTitleHooks';
import { blobToSrcImage, builderName, toggleSideMenu } from 'utils/Helper';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from 'store/Auth/authAtom';
import { APP_ROUTE_PREFIX, RouteConstant } from 'routes/routesConstant';
import BurgerMenu from 'assets/img/BurgerMenu';
import useEmployeeRepo from 'services/repo/useEmployeeRepo';

// import DefaultAvatar from 'assets/img/default-user-avatar.png'

export default function Topbar() {
    const { isLoading: isLoadingLogout, logout } = useAuthRepo(false)
    const { getImageUser } = useEmployeeRepo(false, false)
    const { getUserDataLogin } = useUserRepo(false, false)
    const { topbarTitle } = useTopbarTitleHooks()
    const [user, setUser] = useAtom(userAtom)
    const username = useRef("")
    const { token } = theme.useToken();
    const [isOpen, setIsOpen] = useState(false);

    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };

    const fetchUser = async () => {
        let res = await getUserDataLogin()
        if (res?.isValid && res?.statusCode === 200) {
            let dataEmployee = res?.data
            setUser(dataEmployee)
            username.current = builderName(dataEmployee?.employee?.firstName, dataEmployee?.employee?.lastName)
            let response = await getImageUser({
                employeeId: dataEmployee?.employee?.employeeId
            })
            if (response) {
                let url = blobToSrcImage(response)
                setUser((prev) => ({
                    ...prev,
                    photoBlob: url
                }))
            }
        }
    }

    useEffect(() => {
        fetchUser()
    }, [])

    return (
        <>
            <section className='eproc-topbar'>

                <Space direction="horizontal">
                    <div className='show-sidebar'>
                        <div className='wrapper-burger' onClick={toggleSideMenu}><BurgerMenu /></div>
                    </div>
                    <div className='title-top-bar'>{topbarTitle}</div>
                </Space>

                <div className="right-side">
                    <div className='right-item'>
                        <Dropdown
                            // open={isOpen}
                            overlayClassName='dropdown-top-bar-user'
                            placement="bottomLeft"
                            // menu={{ items }}
                            trigger={['hover']}
                            dropdownRender={(menu) => (
                                <div style={contentStyle} className='dropdown-content'>
                                    <div className='wrap-avatar'>
                                        <Space wrap size={16}>
                                            <Avatar size={70} icon={<AntDesignOutlined />} src={user?.photoBlob} />
                                        </Space>
                                    </div>
                                    <p className='username'>{username.current}</p>
                                    <Link to={APP_ROUTE_PREFIX + "/" + RouteConstant.myProfile}>View Profile</Link>
                                    <Button type="dashed" danger onClick={logout} disabled={isLoadingLogout} loading={isLoadingLogout}>Logout</Button>
                                </div>
                            )}
                        >
                            <div>
                                <Avatar shape="square" icon={<UserOutlined />} src={user?.photoBlob} onClick={() => setIsOpen(!isOpen)} />
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </section>
        </>
    )
}
