/*
 * Updated: March 1st 2024
 * Updated by: 🫰 Aris bimas n
 */

import { Button, Card, Col, Form, Row } from 'antd'
import { BaseDrawer } from 'components/CustomDrawer/BaseDrawer'
import React, { useEffect, useState } from 'react'
import Constant from 'utils/Constants'
import CustomAlert from 'utils/CustomAlert'
import { dismissToast, loadingToastId, successToast } from 'utils/Toast'
import DevLog from 'utils/DevLog'
import { CustomFormItemSwitch, CustomFormItemText, CustomFormItemTextArea } from 'components/Common/CustomFormItem'
import useCostCenterRepo from 'services/repo/useCostCenterRepo'

const init_field = {
  costCenterId: null,
  code: "",
  name: "",
  description: "",
  isActive: true,
  lastModifiedAt: '',
}

export default function BaseDrawerCostCenter(props) {
  const {
    isOpen, onClose, dataEdit = null, onRefreshList, isView = false, loading = false, setModeView = () => {
    },
    isAddEditPrivileged,
    handleUnhighlight = () => { }
  } = props
  const [form] = Form.useForm()
  const [dataField, setDataField] = useState(init_field)
  const { isLoading: isLoadingCreate, createCostCenter } = useCostCenterRepo(false);
  const { isLoading: isLoadingUpdate, updateCostCenter } = useCostCenterRepo(false);

  const handleCloseDrawer = () => {
    form.resetFields()
    setDataField(init_field)
    setModeView(true)
    onClose()
  }

  const onSaveCreate = async () => {
    let payload = {
      code: dataField.code,
      name: dataField.name,
      description: dataField.description,
      isActive: dataField.isActive
    }
    loadingToastId(Constant.message.savingData, payload?.name)
    let res = await createCostCenter(payload)
    dismissToast(payload?.name)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const onSaveEdit = async () => {
    let payload = {
      costCenterId: dataField.costCenterId,
      lastModifiedAt: dataField.lastModifiedAt,
      code: dataField.code,
      name: dataField.name,
      description: dataField.description,
      isActive: dataField.isActive,
    }
    loadingToastId(Constant.message.savingData, payload?.costCenterId)
    let res = await updateCostCenter(payload)
    dismissToast(payload?.costCenterId)
    if (res?.isValid && res?.statusCode === 200) {
      successToast(Constant.message.saveDataSuccess);
      handleCloseDrawer()
      onRefreshList()
    }
  }

  const handleFormSubmit = () => {
    CustomAlert.confirm({
      title: "Save Cost Center!",
      description: `Are you sure you want to save this data?`,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      type: "primary",
      onConfirmButton: () => {
        form.validateFields()
          .then((values) => {
            if (dataEdit !== null) {
              onSaveEdit()
            } else {
              onSaveCreate()
            }
          })
          .catch((errorInfo) => {
            DevLog("err", errorInfo);
          });
      }
    });
  };

  useEffect(() => {
    setModeView(isView)
  }, [isView])

  useEffect(() => {
    if (dataEdit !== null) {
      setDataField(dataEdit)
      form.setFieldsValue({
        code: dataEdit?.code,
        name: dataEdit?.name,
        description: dataEdit?.description,
        isActive: dataEdit?.isActive,
      })
    } else {
      setDataField(init_field)
      form.setFieldsValue({
        code: init_field.code,
        name: init_field.name,
        description: init_field.description,
        isActive: init_field.isActive,
      })
      handleUnhighlight()
    }
  }, [dataEdit])

  return (
    <>
      <BaseDrawer
        title={isView
          ? "View Cost Center"
          : (dataEdit === null
            ? "Add Cost Center"
            : "Edit Cost Center")}
        placement="right"
        size="large"
        onClose={handleCloseDrawer}
        open={isOpen}
        loading={loading}
        extras={
          <>
            {(isAddEditPrivileged && isView) &&
              (<Button
                type="primary"
                onClick={() => setModeView(false)}>
                Edit
              </Button>)
            }
            {(isAddEditPrivileged && !isView) &&
              (<Button type="primary" onClick={handleFormSubmit} htmlType='submit'
                loading={isLoadingCreate || isLoadingUpdate}>
                Submit
              </Button>)
            }
          </>
        }>
        <Card>
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="code"
                  label="Code"
                  placeholder="Fill in Code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.code}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      code: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemText
                  nameFormItem="name"
                  label="Name"
                  placeholder="Fill in Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  isView={isView}
                  value={dataField?.name}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      name: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemTextArea
                  nameFormItem="description"
                  label="Description"
                  placeholder="Fill in Description"
                  isView={isView}
                  value={dataField?.description}
                  onChange={(e) => {
                    setDataField(prev => ({
                      ...prev,
                      description: e.target.value
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <CustomFormItemSwitch
                  nameFormItem="isActive"
                  label="Is Active"
                  value={dataField?.isActive}
                  isView={isView}
                  onChange={(checked) => {
                    setDataField(prev =>
                      ({ ...prev, isActive: checked }))
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </BaseDrawer>
    </>
  )
}
