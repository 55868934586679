import { Col, Form, Row, } from 'antd'
import ButtonAction from 'components/Common/ButtonAction'
import CustomAntdTable from 'components/Common/CustomAntdTable'
import React, { useEffect, useState } from 'react'
import Constant from 'utils/Constants'
import { displayDate, formatterAmount, sorterTable } from 'utils/Helper'
import dayjs from 'dayjs'
import useRFQRepo from 'services/repo/useRFQRepo'
import useItemCategoryRepo from 'services/repo/useItemCategoryRepo'
import CellTable from 'components/Common/CellTable'
import BaseLookup from 'components/Lookup/BaseLookup'
import { CustomFormItemFilterDate, CustomFormItemFilterSelectDropdown, CustomFormItemFilterText } from 'components/Common/CustomFormItem'

export default function LookupPRItems(props) {
    const { onClose, onSelect, itemType = null, rfqId = null } = props
    const { isLoading: isLoadingItemCategory, getItemCategoryList } = useItemCategoryRepo(false)
    const { isLoading: isLoadingList, getListItemsFromPR } = useRFQRepo(false)
    const [dataTable, setDataTable] = useState([])

    const [filters, setFilters] = useState({
        previousRfqId: rfqId ? rfqId : null,
        prNo: "",
        prDateFrom: null,
        prDateTo: null,
        prSubject: "",
        itemCode: "",
        itemName: "",
        itemTypeId: itemType === Constant.ItemType.GOODS ? Constant.ItemType.GOODS : Constant.ItemType.SERVICES,
        itemCategoryId: null,
        isActive: true
    })

    const [totalData, setTotalData] = useState(0)
    const [tableParams, setTableParams] = useState({
        pageNumber: 1,
        pageSize: 10,
        filter: {
            ...filters
        },
        orderBy: "",
        isAscending: true
    });

    const [dataItemCategory, setDataItemCategory] = useState([]);

    //Item Category DDL
    const fetchDataGetItemCategory = async () => {
        const payload = {
            pageNumber: 1,
            pageSize: 0,
            orderBy: "ItemCategoryId",
            isAscending: true,
            filter: {
                previousItemCategoryId: null,
                itemCategoryCode: "",
                itemCategoryName: "",
                isActive: true
            }
        }
        const res = await getItemCategoryList(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setDataItemCategory(() => {
                return res?.data?.listData?.map(d => {
                    return { label: d?.itemCategoryName, value: d?.itemCategoryId }
                })
            })
        }
    };

    const fetchData = async () => {
        const payload = {
            ...tableParams
        }
        const res = await getListItemsFromPR(payload)
        if (res?.isValid && res?.statusCode === 200) {
            setDataTable(res?.data?.listData)
            setTotalData(res?.data?.totalRecords)
            setTableParams(prevParams => ({
                ...prevParams,
                // total: res?.data?.totalRecords
            }));
        }
    };

    const onSearch = (value) => {
        setTableParams(prev => ({
            ...prev,
            pageNumber: 1,
            filter: {
                ...filters
            }
        }))
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const valueOrderBy = (prev) => {
            if (sorter.field === "prNo") {
                return "pr.prNo"
            } else if (sorter.field === "prSubmitDate") {
                return "pr.prSubmitDate"
            } else {
                return sorter?.field || prev
            }
        }

        setTableParams(prevParams => ({
            ...prevParams,
            pageNumber: pagination?.current,
            pageSize: pagination?.pageSize,
            orderBy: valueOrderBy(prevParams?.orderBy),
            isAscending: sorterTable(sorter?.order, prevParams?.isAscending)
        }));

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams?.pageSize) {
            setDataTable([]);
        }
    };

    const handleSelect = (data) => {
        onSelect(data)
        // onClose()
    }

    useEffect(() => {
        fetchDataGetItemCategory();
    }, []);

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams)]);

    useEffect(() => {
        const timeOutId = setTimeout(() => onSearch(), Constant.delayFilter);
        return () => clearTimeout(timeOutId);
    }, [filters])

    const columns = [
        {
            title: 'Action',
            key: 'action',
            width: "60px",
            render: (_, record) => (
                <>
                    <ButtonAction
                        buttons={['add']}
                        onAdd={() => handleSelect(_)}
                    />
                </>
            )
        },
        {
            title: 'PR No',
            dataIndex: 'prNo',
            width: "150px",
            sorter: true,
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'PR Date',
            dataIndex: 'prSubmitDate',
            sorter: true,
            render: (prSubmitDate) => displayDate(prSubmitDate)
        },
        {
            title: 'PR Subject',
            dataIndex: 'pr.prSubject',
            sorter: true,
            width: "200px",
            render: (data, row) => <CellTable data={row?.prSubject} />
        },
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            sorter: true,
            width: "200px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Item Code',
            dataIndex: 'itemCode',
            sorter: true,
            width: "100px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Remark',
            dataIndex: 'remarks',
            sorter: true,
            width: "300px",
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Item Category',
            dataIndex: 'itemCategoryName',
            width: "200px",
            sorter: true,
            render: (data) => <CellTable data={data} />
        },
        {
            title: 'Required Date',
            dataIndex: 'requiredDate',
            width: "135px",
            sorter: true,
            render: (requiredDate) => requiredDate ? displayDate(requiredDate) : ""
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            sorter: true,
            align: "right",
            width: "100px",
            render: (_, record) => {
                if (record?.uomAllowDecimal) {
                    return formatterAmount(_)
                } else {
                    return _
                }
            }
        },
        {
            title: 'UOM',
            dataIndex: 'uomName',
            sorter: true,
            width: "80px",
            render: (data) => <CellTable data={data} />
        },

    ];

    return (
        <>
            <BaseLookup
                title={`${itemType === Constant.ItemType.GOODS ? "Goods" : "Service"} PR Lookup`}
                open={true}
                onCancel={onClose}
                isLoading={isLoadingList}
                filterUI={
                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={8}>
                                <CustomFormItemFilterText
                                    nameFormItem='prNo'
                                    label='PR No'
                                    placeholder="PR No"
                                    value={filters?.prNo}
                                    onChange={(e) => {
                                        setFilters(prev => ({
                                            ...prev,
                                            prNo: e.target.value
                                        }))
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <CustomFormItemFilterDate
                                    nameFormItem="prDateFrom"
                                    label="PR Date From"
                                    placeholder='PR Date From'
                                    value={filters?.prDateFrom}
                                    disabledDate={d => !d || d.isAfter(filters?.prDateTo) || d.isAfter(new Date())}
                                    onChange={(date, dateString) => {
                                        setFilters(prev => ({
                                            ...prev,
                                            prDateFrom: dateString ? dayjs(dateString).format(Constant.stateDateFormat) : null
                                        }))
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <CustomFormItemFilterDate
                                    nameFormItem="prDateTo"
                                    label="PR Date To"
                                    placeholder='PR Date To'
                                    value={filters?.prDateTo}
                                    disabledDate={d => !d || d.isBefore(filters?.prDateFrom) || d.isAfter(new Date())}
                                    onChange={(date, dateString) => {
                                        setFilters(prev => ({
                                            ...prev,
                                            prDateTo: dateString ? dayjs(dateString).format(Constant.stateDateFormat) : null
                                        }))
                                    }}
                                />
                            </Col>

                            <Col xs={24} sm={12} md={8}>
                                <CustomFormItemFilterText
                                    nameFormItem='prSubject'
                                    label='PR Subject'
                                    placeholder="PR Subject"
                                    value={filters?.prSubject}
                                    onChange={(e) => {
                                        setFilters(prev => ({
                                            ...prev,
                                            prSubject: e.target.value
                                        }))
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <CustomFormItemFilterText
                                    nameFormItem='itemName'
                                    label='Item Name'
                                    placeholder="Item Name"
                                    value={filters?.itemName}
                                    onChange={(e) => {
                                        setFilters(prev => ({
                                            ...prev,
                                            itemName: e.target.value
                                        }))
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                <CustomFormItemFilterSelectDropdown
                                    nameFormItem='itemCategoryId'
                                    label="Item Category"
                                    placeholder="Select Item Category"
                                    showSearch={true}
                                    value={filters?.itemCategoryId}
                                    options={dataItemCategory}
                                    loading={isLoadingItemCategory}
                                    onChange={(e) => {
                                        setFilters(prev => ({
                                            ...prev,
                                            itemCategoryId: e || null
                                        }))
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                }
            >

                <CustomAntdTable
                    columns={columns}
                    dataSource={dataTable}
                    rowKey={"prItemDetailId"}
                    tableParam={tableParams}
                    totalData={totalData}
                    loading={isLoadingList}
                    onChange={handleTableChange}
                />
            </BaseLookup>
        </>
    )
}
